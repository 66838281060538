import { h, Component } from 'preact';
import linkState from 'linkstate';

import { ModalProps, Modal } from '../common/modal';
import { Remittance } from '../../../../types/remittance.model';
import { Subject, Subscription } from 'rxjs';
import getStore, { appState$ } from '../../store';
import { SUBMIT_EDIT_REQUEST } from '../../reducers/remittance/remittance.actions';
import { EditRequest, DeleteRequest } from '../../../../types/edit-request.model';
import { map } from 'rxjs/operators';
import { RequestState, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../types/request-state.model';

export interface RequestDeleteModalProps extends ModalProps {
  remittance: Remittance;
}

interface RequestDeleteModalState  {

  reason?: string;
  isFormValid?: boolean;

  deleteRequestState?: RequestState;
}

export class RequestDeleteModal extends Modal<RequestDeleteModalProps, RequestDeleteModalState> {
  subs: Subscription[] = [];

  constructor(props: RequestDeleteModalProps) {
    super(props);

    this.setState({
      reason: null,
    });
  }

  componentDidMount() {
    super.componentDidMount();

    const remittance$ = appState$.pipe(
      map(app => app.remittance)
    );

    this.subs.push(
      remittance$.pipe(
        map(remittance => remittance.deleteRequestState)
      ).subscribe(reqState => {
        this.setState({
          deleteRequestState: reqState
        });

        if (reqState === REQUEST_SUCCESS) {
          this.doModalClose();
        }
      })
    );
  }

  doModalClose = () => {
    this.props.close();
  }

  onReasonChange = (e) => {
    const value = e.target.value;
    this.setState({
      reason: value,
      isFormValid: this.isFormValid()
    });
  }

  getSpinnerBlock(state: RequestDeleteModalState) {
    if (state.deleteRequestState === REQUEST_IN_PROGRESS) {
      return (
        <div class="col offset-md-9 d-flex justify-content-end">
          <div class="spinner-border text-primary mt-3" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }    
  }

  onSubmit = () => {

    if (!this.isFormValid()) {
      this.setState({isFormValid: false});
      return;
    }

    this.setState({isFormValid: true});

    const deleteRequest: DeleteRequest = {
      remittanceId: this.props.remittance.remittanceId,
      reason: this.state.reason
    }

    const store = getStore();
    store.dispatch({
      type: SUBMIT_EDIT_REQUEST,
      payload: {
        deleteRequest: deleteRequest
      }
    });
  }

  isFormValid() {
    let isValid = true;

    if (!this.state.reason) {
      isValid = false;
    }

    return isValid;
  }

  render(props: RequestDeleteModalProps, state: RequestDeleteModalState) {
    return (
      <div class="anglo-request-edit-modal container anglo-modal"
          ref={ node => this.refNode = node }>
        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">
              Request to delete remittance | <small>{ props.remittance.remittanceId }</small>
            </h3>
          </div>
          <div class="col-3 col-md-2">
            <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        
        <div class="row anglo-modal__body py-3">
          <div class="col-12 mt-3">
            <h4 class="anglo-request-edit-modal__label">
              Reason*
            </h4>
            <textarea class="form-control anglo-request-edit-modal__reason"
              onInput={e => this.onReasonChange(e)}
              value={state.reason}
              placeholder="Reason for changes"/>
          </div>

          <div class="col-9 mt-3 pt-2">
            {
              !state.isFormValid ? 
                <span class="anglo-request-edit-modal__error">
                  A reason is required
                </span> 
                : null
            }
          </div>

          <div class="col-1">
            {
              this.getSpinnerBlock(state)
            }
          </div>

          <div class="col-2 mt-3">

            <button class="btn btn-primary w-100" onClick={this.onSubmit}>
              Submit
            </button>
          </div>
        </div>

        
      </div>
    );
  }
}