import { h, Component } from 'preact';
import cx from 'classnames';

import { Subscription, Subject } from 'rxjs';
import getStore, { appState$ } from '../../store';
import { map } from 'rxjs/operators';
import { UpdateAction, GET_GRID_UPDATE } from '../../reducers/updates/updates.actions';
import { EditRequest, DeleteRequest } from '../../../../types/edit-request.model';
import { UpdateCard } from './update-card.component';
import { User } from '../../../../types/user.model';
import { Form, FieldList, Field } from '../../../../types/form.model';
import { validateForm, cxValidateField } from '../../../../types/validator.model';
import { AutocompleteSearch } from '../common/autocomplete-search.component';
import linkform, { updateFormFieldValue } from '../../../utils/linkform';
import { EDIT_REQUEST_STATUSES } from '../../../../constants/edit-requests';

export interface DashUpdatesProps {
}

export interface DashUpdatesState {
  editGridData?: EditRequest[];

  isEditActive?: boolean;
  currentUser?: User;

  isFilterVisible?: boolean;
  filters?: Form;
}

export class DashUpdates extends Component<any, DashUpdatesState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props) {
    super(props);

    this.setState({
      isEditActive: true
    });

    this.createForm();
  }

  createForm() {
    let fields: FieldList = {
      'status': new Field([], null, ''),
      'username': new Field([] , null, ''),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      filters: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['filters'] = form;
      this.setState(state);
    });
  }

  componentDidMount() {

    const user$ = appState$.pipe(
      map(appState => appState.user)
    );

    this.subs.push(user$.subscribe((user) => {
        if (user.currentUser) {
          this.setState({
            currentUser: user.currentUser
          });
        }
      })
    );

    const updates$ = appState$.pipe(
      map(appState => appState.updates)
    );
    
     this.subs.push(updates$.pipe(
      map(updates => updates.editGridData)
    ).subscribe((editGridData) => {

        this.setState({
          editGridData: editGridData
        });

      })
    );

    // this.subs.push(updates$.pipe(
    //   map(updates => updates.editGridData)
    // ).subscribe((deleteGridData) => {

    //     this.setState({
    //       deleteGridData: deleteGridData
    //     });

    //   })
    // );

    this.initialStoreDispatches();
  }

  initialStoreDispatches() {

    const store = getStore();
    store.dispatch({
      type: GET_GRID_UPDATE,
      payload: {
        filters: {
          type: 'edit'
        }
      }
    } as UpdateAction);

  }

  resetFilters = () => {
    
    const fieldList = this.state.filters.fields;

    for (let k in fieldList) {
      fieldList[k].value = null;
    }

    const form = this.state.filters;
    form.fields = fieldList;

    // form['page'] = 1;

    this.setState({
      filters: form
    });
  }

  applyFilters = () => {
    // let filters: Map<string, any> = new Map<string, any>();
    let filters = {};
    const fieldList = this.state.filters.fields;

    for (let k in fieldList) {
      if (fieldList[k].value) {
        // filters.set(k, fieldList[k].value);
        filters[k] = fieldList[k].value;
      }
    }

    // when new filters are applied, always set back to first page
    filters['page'] = 1

    const store = getStore();

    if (!this.state.isEditActive) {
      filters['type'] = 'delete';

      store.dispatch({
        type: GET_GRID_UPDATE,
        payload: {
          filters: filters,
        }
      } as UpdateAction);
    } else {
      filters['type'] = 'edit';

      store.dispatch({
        type: GET_GRID_UPDATE,
        payload: {
          filters: filters,
        }
      } as UpdateAction);
    }
    
  }

  getEditData(state: DashUpdatesState) {

    if (state.editGridData && state.isEditActive) {
      return (
        <div class="dash-updates__card-container row">
          {
            state.editGridData.map(member => 
              <div class="col-12 col-md-3 mb-3">
                <UpdateCard currentUser={state.currentUser}
                  request={member} 
                  isEdit={state.isEditActive} />
              </div>
            )
          }
        </div>
      );
    } else if (state.editGridData && !state.isEditActive) {
      return (
        <div class="dash-updates__card-container row">
          {
            state.editGridData.map(member => 
              <div class="col-12 col-md-3 mb-3">
                <UpdateCard currentUser={state.currentUser}
                  request={member} 
                  isEdit={state.isEditActive} />
              </div>
            )
          }
        </div>
      );
    }
  }

  // getDeleteData(state: DashUpdatesState) {

  //   if (state.deleteGridData && !state.isEditActive) {
  //     return (
  //       <div class="dash-updates__card-container row">
  //         {
  //           state.deleteGridData.map(member => 
  //             <div class="col-3">
  //               <UpdateCard request={member} />
  //             </div>
  //           )
  //         }
  //       </div>
  //     );
  //   }
  // }

  onEditClicked = () => {

    this.resetFilters();

    const store = getStore();
    store.dispatch({
      type: GET_GRID_UPDATE,
      payload: {
        filters: {
          type: 'edit'
        }
      }
    } as UpdateAction);

    this.setState({
      isEditActive: true
    })
  }

  onDeleteClicked = () => {

    this.resetFilters();

    const store = getStore();
    store.dispatch({
      type: GET_GRID_UPDATE,
      payload: {
        filters: {
          type: 'delete'
        }
      }
    } as UpdateAction);

    this.setState({
      isEditActive: false
    })
  }

  onFilterToggleClicked = () => {
    this.setState({
      isFilterVisible: !this.state.isFilterVisible
    });
  }

  onSupervisorSearchChange = (supervisor: User) => {
    updateFormFieldValue(
      this.form$,
      this.state.filters,
      'username',
      supervisor.username
    )
  }

  getFilterBlock(state: DashUpdatesState) {
    let form = state.filters;

    if (state.isFilterVisible && !state.isEditActive) {
      return (
        <div class="container-fluid">
          <div class="row mt-1 dash-vehicles__filters dash-remittance__filter-row__filters">

            <div class="col-12 col-md-6 mb-2">
              <label class="dash-vehicles__filters__label">Status</label>
              <select class="form-control"
                className={ cx('form-control text-capitalize', 
                  { 'invalid': cxValidateField(form.fields['status']) }
                )}
                placeholder="Status"
                value={form.fields['status'].value}
                onChange={linkform(this.form$, form, 'status')}>

                <option class="text-muted" value="" disabled={true} selected>Select Status</option>
                <option class="text-capitalize" value="">All</option>
                {
                  EDIT_REQUEST_STATUSES.map(status => {
                    return <option class="text-capitalize" value={status.value}>{ status.label }</option>
                  })
                }

              </select>
            </div>

            <div class="col-12 col-md-6 mb-2">
              <label class="dash-vehicles__filters__label">Supervisor</label>
              <AutocompleteSearch onResultSelected={this.onSupervisorSearchChange} />
            </div>

            <div class="col-12 col-lg-3 offset-lg-6 mt-3">
              <div class="mt-2">
                <button class="btn btn-outline-dark w-100" onClick={this.resetFilters}>
                  Reset Filters
                </button>
              </div>
            </div>

            <div class="col-12 col-lg-3 mt-3">
              <div class="mt-2">
                <button class="btn btn-secondary w-100" onClick={this.applyFilters}>
                  Apply Filters
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (state.isFilterVisible && state.isEditActive) {
      return (
        <div class="container-fluid">
          <div class="row mt-1 dash-vehicles__filters dash-remittance__filter-row__filters">

            <div class="col-12 col-md-6 mb-2">
              <label class="dash-vehicles__filters__label">Status</label>
              <select class="form-control"
                className={ cx('form-control text-capitalize', 
                  { 'invalid': cxValidateField(form.fields['status']) }
                )}
                placeholder="Status"
                value={form.fields['status'].value}
                onChange={linkform(this.form$, form, 'status')}>

                <option class="text-muted" value="" disabled={true} selected>Select Status</option>
                <option class="text-capitalize" value="">All</option>
                {
                  EDIT_REQUEST_STATUSES.map(status => {
                    return <option class="text-capitalize" value={status.value}>{ status.label }</option>
                  })
                }

              </select>
            </div>

            <div class="col-12 col-md-6">
              <label class="dash-vehicles__filters__label">Supervisor</label>
              <AutocompleteSearch onResultSelected={this.onSupervisorSearchChange} />
            </div>

            <div class="col-12 col-lg-3 offset-lg-6 mt-3">
              <div class="mt-2">
                <button class="btn btn-outline-dark w-100" onClick={this.resetFilters}>
                  Reset Filters
                </button>
              </div>
            </div>

            <div class="col-12 col-lg-3 mt-3">
              <div class="mt-2">
                <button class="btn btn-secondary w-100" onClick={this.applyFilters}>
                  Apply Filters
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  render(props: DashUpdatesProps, state: DashUpdatesState) {
    return (
      <div class="dash-updates">
        <div class="container-fluid">
          <div class="row">
            {/* cookie crumbs */}

            <div class="col-8 col-lg-4">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="#">Updates</a></li>
                  {/* <li class="breadcrumb-item active" aria-current="page">Remittance Updates</li> */}
                </ol>
              </nav>
            </div>
          </div>

          <div class="row dash-updates__type-row">
            <div class="col-12 col-md-4 offset-md-4">
              <button className={ cx(
                'w-50 dash-updates__type-row__button first', 
                {
                  'active': state.isEditActive
                }) 
              } 
              onClick={this.onEditClicked}>
                View for Edit
              </button>
              <button className={ cx(
                'w-50 dash-updates__type-row__button last', 
                {
                  'active': !state.isEditActive
                }) 
              }
              onClick={this.onDeleteClicked}>
                View for Delete
              </button>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col d-flex justify-content-end align-items-center">
              <a class="dash-remittance__filter-row__filter-btn mr-3" 
                onClick={ this.onFilterToggleClicked }>
                Filter <i class="fas fa-filter"></i>
              </a>
            </div>
          </div>

          {
            this.getFilterBlock(state)
          }

          <div class="mt-5">
            { this.getEditData(state) }
            {/* { this.getDeleteData(state) } */}
          </div>
        </div>

      </div>
    );
  }

}