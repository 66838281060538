import { h, Component } from "preact";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import moment from "moment-timezone";
import Portal from "preact-portal";

import { PurchaseDocumentGrid } from "./grids/purchase-document.grid";
import { ItemWithdrawalGrid } from "./grids/item-withdrawal.grid";
import { JobOrderGrid } from "./grids/job-order.grid";
import { ModalActionKey } from "../common/modal";
import { CreateJOModal } from "./modal/create-jo.modal";
import { CreateIWModal } from "./modal/create-iw.modal";
import { ViewIWModal } from "./modal/view-iw.modal";
import { SignIWModal } from "./modal/sign-iw.modal";
import { ViewJOModal } from "./modal/view-jo.modal";
import { UdpateJOModal } from "./modal/update-jo.modal";

export const JOB_ORDER_MODAL_VIEW_JO: string = "modal-view-jo";
export const JOB_ORDER_MODAL_CREATE_JO: string = "modal-create-jo";
export const JOB_ORDER_MODAL_EDIT_JO: string = "modal-edit-jo";
export const JOB_ORDER_MODAL_APPROVE_JO: string = "modal-approve-jo";
export const JOB_ORDER_MODAL_VIEW_WITHDRAWAL: string = "modal-view-iw";
export const JOB_ORDER_MODAL_CREATE_WITHDRAWAL: string = "modal-create-iw";
export const JOB_ORDER_MODAL_APPROVE_IW: string = "modal-approve-iw";

export interface DashboardState {
  modalAction: ModalActionKey;
}

export class JobOrderDashboard extends Component<any, DashboardState> {
  subs: Subscription[] = [];
  pdGridFilters: Map<string, any>;
  refreshPDGrid: Function;

  constructor(props) {
    super(props);

    this.pdGridFilters = new Map();
    this.setState({
      modalAction: {
        name: "",
        parameters: null,
      },
    });
  }

  onModalAction = (modalData: ModalActionKey) => {
    this.setState({
      modalAction: modalData,
    });
  };

  onModalClose = () => {
    console.log("close");
    this.setState({
      modalAction: {
        name: "",
        parameters: null,
      },
    });

    this.refreshPDGrid();
  };

  onCreateJO = () => {
    const modalData: ModalActionKey = {
      name: JOB_ORDER_MODAL_CREATE_JO,
      parameters: null,
    };

    this.setState({
      modalAction: modalData,
    });
  };

  onCreateIW = () => {
    const modalData: ModalActionKey = {
      name: JOB_ORDER_MODAL_CREATE_WITHDRAWAL,
      parameters: null,
    };

    this.setState({
      modalAction: modalData,
    });
  };

  passRefreshPDGrid = (returned: Function) => {
    this.refreshPDGrid = returned;
  };

  getPortalModal(state: DashboardState) {
    let modalState = state.modalAction;

    switch (modalState.name) {
      case JOB_ORDER_MODAL_CREATE_JO:
        return (
          <Portal into="body">
            <div class="anglo-modal-container" id="anglo-modal-124">
              <CreateJOModal
                close={this.onModalClose}
                isOutsideClickClose={true}
              />
            </div>
          </Portal>
        );
      case JOB_ORDER_MODAL_VIEW_JO:
        return (
          <Portal into="body">
            <div class="anglo-modal-container" id="anglo-modal-124">
              <ViewJOModal
                jo={modalState.parameters.get("jo")}
                close={this.onModalClose}
                isOutsideClickClose={true}
              />
            </div>
          </Portal>
        );
      case JOB_ORDER_MODAL_EDIT_JO:
        return (
          <Portal into="body">
            <div class="anglo-modal-container" id="anglo-modal-124">
              <UdpateJOModal
                jo={modalState.parameters.get("jo")}
                close={this.onModalClose}
                isOutsideClickClose={true}
              />
            </div>
          </Portal>
        );
      case JOB_ORDER_MODAL_CREATE_WITHDRAWAL:
        return (
          <Portal into="body">
            <div class="anglo-modal-container" id="anglo-modal-124">
              <CreateIWModal
                close={this.onModalClose}
                isOutsideClickClose={true}
              />
            </div>
          </Portal>
        );
      case JOB_ORDER_MODAL_VIEW_WITHDRAWAL:
        return (
          <Portal into="body">
            <div class="anglo-modal-container" id="anglo-modal-124">
              <ViewIWModal
                iw={modalState.parameters.get("iw")}
                close={this.onModalClose}
                isOutsideClickClose={true}
              />
            </div>
          </Portal>
        );
      case JOB_ORDER_MODAL_APPROVE_IW:
        return (
          <Portal into="body">
            <div class="anglo-modal-container" id="anglo-modal-124">
              <SignIWModal
                iw={modalState.parameters.get("iw")}
                isApproval={modalState.parameters.get("isApproval")}
                close={this.onModalClose}
                isOutsideClickClose={true}
              />
            </div>
          </Portal>
        );
      default:
        return null;
    }
  }

  getActionsBlock(state: DashboardState) {
    return (
      <div className="row mb-3">
        <div className="col-3">
          <button class="btn btn-outline-dark w-100" onClick={this.onCreateJO}>
            Create Job Order
          </button>
        </div>
        <div className="col-3">
          <button class="btn btn-outline-dark w-100" onClick={this.onCreateIW}>
            Create Withdrawal
          </button>
        </div>
      </div>
    );
  }

  render(props: any, state: DashboardState) {
    return (
      <div class="anglo-remittance">
        {this.getPortalModal(state)}

        <div class="container-fluid">
          <hr />
          <p>
            <b>Quick Actions</b>
          </p>
          {this.getActionsBlock(state)}
          <hr />
          <p>
            <b>Job Orders</b>
          </p>
          <JobOrderGrid
            initialFilters={this.pdGridFilters}
            onModalAction={this.onModalAction}
            refreshGrid={this.passRefreshPDGrid}
          />
          <hr />
          <p>
            <b>Item Withdrawals</b>
          </p>
          <ItemWithdrawalGrid
            initialFilters={this.pdGridFilters}
            onModalAction={this.onModalAction}
            refreshGrid={this.passRefreshPDGrid}
          />
        </div>
      </div>
    );
  }
}
