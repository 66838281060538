import * as Actions from './audit.actions';
import { AuditState, AuditAction } from './audit.actions';
import { REQUEST_SUCCESS, REQUEST_FAILED, REQUEST_IN_PROGRESS } from '../../../../types/request-state.model';

const initialState: AuditState = {

}

export default function AuditLog(state: AuditState = initialState, action: AuditAction) {
  switch (action.type) {

    case Actions.GET_GRID_AUDIT:
    case Actions.GET_GRID_AUDIT_SUCCESS:
    case Actions.GET_GRID_AUDIT_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
      );

    default:
      return state;
  }
}