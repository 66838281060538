import { h, Component } from 'preact';
import cx from 'classnames';
import linkState from 'linkstate';
import { Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { ModalProps, Modal } from '../common/modal';
import { FieldList, Field, Form } from '../../../../types/form.model';
import { validateForm, cxValidateField } from '../../../../types/validator.model';

import getStore, { appState$ } from '../../store';
import { RequestState, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../types/request-state.model';
import { Vehicle } from '../../../../types/vehicle.model';
import linkform from '../../../utils/linkform';

import { VEHICLE_FILE_TYPES } from '../../../../constants/vehicle-files';
import { DatePicker } from '../common/date-picker';
import { CREATE_VEHICLE_FILE } from '../../reducers/vehicle/vehicle.actions';
import { User } from '../../../../types/user.model';
import { Expense } from '../../../../types/expense.model';

export interface FileReportModalProps extends ModalProps {
  vehicle: Vehicle;
  currentUser?: User;
}

interface FileReportModalState  {

  reason?: string;
  isFormValid?: boolean;

  fileReportRequestState?: RequestState;
  vehicleForm?: Form;
  createVehicleFileState?: RequestState;

  expenses?: Expense[];
}

export class FileReportModal extends Modal<FileReportModalProps, FileReportModalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props: FileReportModalProps) {
    super(props);

    this.setState({
      expenses: []
    });

    this.createForm();
  }

  createForm() {
    let fields: FieldList = {
      'type': new Field([], null), // types: accident, maintenance/repair, breakdown
      'dateOfIncident': new Field([], null),
      'description': new Field([], null),
      'isMotorpool': new Field([], null),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      vehicleForm: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['vehicleForm'] = form;
      this.setState(state);
    });
  }

  componentDidMount() {
    super.componentDidMount();

    const vehicles$ = appState$.pipe(
      map(app => app.vehicles)
    );

    this.subs.push(
      vehicles$.pipe(
        map(vehicles => vehicles.createVehicleFileState)
      ).subscribe(reqState => {
        this.setState({
          createVehicleFileState: reqState
        });

        if (reqState === REQUEST_SUCCESS) {
          this.doModalClose();
        }
      })
    );
  }

  doModalClose = () => {
    this.props.close();
  }

  handleDatepickerValidation = (date: string, fieldName: string) => {
    const currentForm = this.state.vehicleForm; 

    // Manually do this because in order to follow the 
    // hopplerLinkstate validation system
    let clonedFields: FieldList = {};
    for (let k in currentForm.fields) {
      let prevField = currentForm.fields[k];
      clonedFields[k] = new Field(prevField.validators, prevField.customValidators, prevField.value);
      clonedFields[k].isValid = prevField.isValid;
    }

    let clone: Form = new Form(clonedFields);
    clone.fields[fieldName].value = date;
    clone.fields[fieldName].isPristine = false;
    // clone.fields[fieldName].customValidators.forEach(a => a(clone.fields[fieldName], clone));
    this.form$.next(clone);
  }

  onAddExpense = () => {
    let expense: Expense = {
      name: '',
      amount: 0
    };
    let expenses = this.state.expenses;
    expenses.push(expense);

    this.setState({
      expenses: expenses
    });
  }

  onRemoveExpense = (index) => {
    let expenses = this.state.expenses;
    expenses.splice(index, 1);

    this.setState({
      expenses: expenses
    });
  }

  onDateSelected = (date: string) => {
    this.handleDatepickerValidation(date, 'dateOfIncident');
  }

  onSubmit = () => {
    console.log(this.state.expenses);
    let form = this.state.vehicleForm;

    let expenses = this.state.expenses;
    expenses = expenses.map(expense => {
      return {
        name: expense.name,
        amount: Number.parseInt(expense.amount as any)
      }
    })

    const store = getStore();
    store.dispatch({
      type: CREATE_VEHICLE_FILE,
      payload: {
        vehicleFileToCreate: {
          supervisor: this.props.currentUser.username,
          vehicleId: this.props.vehicle.vehicleId,
          description: form.fields['description'].value,
          type: form.fields['type'].value,
          dateOfReport: form.fields['dateOfIncident'].value,
          isMotorpool: form.fields['isMotorpool'].value,
          expenses: this.state.expenses
        }
      }
    });
  }

  getActionBlock(state: FileReportModalState) {
    if (state.createVehicleFileState === REQUEST_IN_PROGRESS) {
      return (
        <div class="offset-lg-9 offset-md-6 col-12 col-md-6 col-lg-3 mt-3">
          <div class="spinner-border text-primary mt-3" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      )
    } else {
      return (
        <div class="offset-lg-9 offset-md-6 col-12 col-md-6 col-lg-3 mt-3">
          <button class="btn btn-primary w-100"
              onClick={this.onSubmit}>
            Submit
          </button>
        </div>
      );
    }
  }

  render(props: FileReportModalProps, state: FileReportModalState) {
    let form = state.vehicleForm;

    return (
      <div class="anglo-file-report-modal container anglo-modal"
        ref={ node => this.refNode = node }>
        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">
              File a report | <small>{ props.vehicle.plateNumber }</small>
            </h3>
          </div>
          <div class="col-3 col-md-2">
            <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div class="row anglo-modal__body py-3">

          <div class="col-12 col-md-6">
            <label class="anglo-remittance__submit-form__label">Type</label>

            <select
              className={ cx('form-control', 
                { 'invalid': cxValidateField(form.fields['type']) }
              )}
              placeholder="Type"
              value={form.fields['type'].value}
              onChange={linkform(this.form$, form, 'type')}>

                <option class="text-muted" value="" disabled={true} selected>Select a type</option>
                {
                  VEHICLE_FILE_TYPES.map(type => {
                    return <option value={type}>{ type }</option>
                  })
                }
            </select>
          </div>
          <div class="col-12 col-md-6 mt-3 mt-md-0">
            <label class="dash-remittance__filter-row__filters__label">Date of Incident/Event</label>
            <DatePicker
              onDateSelected={this.onDateSelected}
              initialValue={ form.fields['dateOfIncident'].value }
              isValid={form.fields['dateOfIncident'].isValid}
              isPristine={form.fields['dateOfIncident'].isPristine}>
            </DatePicker>
          </div>          

          <div class="col-12 mt-3 d-flex align-items-center">
            This happened in the Anglo motorpool <input class="ml-2" type="checkbox" onChange={linkform(this.form$, form, 'isMotorpool', true)} /> 
          </div>

          <div class="col-12 mt-3">
            <label class="anglo-remittance__submit-form__label">Description/Details</label>
            <textarea class="form-control anglo-request-edit-modal__reason"
              onInput={linkform(this.form$, form, 'description')}
              value={form.fields['description'].value}
              placeholder="Details of report"/>
          </div>

          <div class="col-12 mt-3">
            <div class="d-flex align-items-end justify-content-between">
              <label class="anglo-remittance__submit-form__label mb-0">Expense Breakdown</label>

              <button class="btn anglo-file-report-modal__expenses-list__add" 
                onClick={this.onAddExpense}>
                <i class="fas fa-plus"></i>
              </button>
            </div>

            <hr class="my-2"/>

            <div class="anglo-file-report-modal__expenses-list">
              {
                state.expenses.map((expense, index) => {
                  return (
                    <div class="anglo-file-report-modal__expense row mb-2 no-">
                      <div class="col-6 pr-0">
                        <input type="text" class="form-control" placeholder="Expense name"
                          onInput={linkState(this, `expenses.${index}.name`)}/>
                      </div>
                      <div class="col pr-0 pl-1">
                        <input type="number" class="form-control" placeholder="Amount"
                          onInput={linkState(this, `expenses.${index}.amount`)}/>
                      </div>
                      <div class="col-2 col-md-1 d-flex justify-content-end">

                        <button class="btn anglo-file-report-modal__expenses-list__add h-100" 
                          onClick={() => this.onRemoveExpense(index)}>
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>

          { this.getActionBlock(state) }
        </div>
      </div>
    );
  }
}