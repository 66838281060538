import * as Actions from './disposals.actions';
import { DisposalState, DisposalAction } from './disposals.actions';
import { REQUEST_SUCCESS, REQUEST_FAILED, REQUEST_IN_PROGRESS, REQUEST_UNTOUCHED } from '../../../../types/request-state.model';

const initialState: DisposalState = {

}

export default function Disposals(state: DisposalState = initialState, action: DisposalAction) {
  switch (action.type) {

    case Actions.GET_GRID_DISPOSAL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          gridRequestState: REQUEST_IN_PROGRESS
        }
      );
    case Actions.GET_GRID_DISPOSAL_SUCCESS:
    case Actions.GET_GRID_DISPOSAL_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
      );

    case Actions.CREATE_DISPOSAL:
      
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_IN_PROGRESS
        }
      );

    case Actions.CREATE_DISPOSAL_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_SUCCESS
        }
      );

    case Actions.CREATE_DISPOSAL_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_FAILED
        }
      );

    case Actions.CREATE_LOCATION:
      
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_IN_PROGRESS
        }
      );

    case Actions.CREATE_LOCATION_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_SUCCESS
        }
      );

    case Actions.CREATE_LOCATION_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_FAILED
        }
      );


    case Actions.RESET_DISPOSAL_STATE:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_UNTOUCHED
        }
      );

    default:
      return state;
  }
}