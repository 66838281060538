import * as Actions from './user.actions';
import { UserState, UserAction } from './user.actions';
import { REQUEST_IN_PROGRESS, REQUEST_SUCCESS, REQUEST_FAILED, REQUEST_UNTOUCHED } from '../../../../types/request-state.model';
import { Action } from 'rxjs/internal/scheduler/Action';

const initialState: UserState = {

}

export default function User(state: UserState = initialState, action: UserAction) {
  switch (action.type) {
    case Actions.GET_CURRENT_USER:
    case Actions.GET_CURRENT_USER_SUCCESS:
    case Actions.GET_CURRENT_USER_FAIL:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.GET_AGENTS:
    case Actions.GET_AGENTS_SUCCESS:
    case Actions.GET_AGENTS_FAIL:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.GET_SUPERVISORS:
    case Actions.GET_SUPERVISORS_SUCCESS:
    case Actions.GET_SUPERVISORS_FAIL:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.GET_ALL_USERS:
    case Actions.GET_ALL_USERS_SUCCESS:
    case Actions.GET_ALL_USERS_FAIL:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.GET_BANKS:
    case Actions.GET_BANKS_SUCCESS:
    case Actions.GET_BANKS_FAIL:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.CREATE_BANK_ACCOUNT:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          createBankAccountState: REQUEST_IN_PROGRESS
        }
      );
    case Actions.CREATE_BANK_ACCOUNT_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          createBankAccountState: REQUEST_SUCCESS
        }
      );
    case Actions.CREATE_BANK_ACCOUNT_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          createBankAccountState: REQUEST_FAILED
        }
      );

    case Actions.CREATE_USER:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          createUserState: REQUEST_IN_PROGRESS
        }
      );
    case Actions.CREATE_USER_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          createUserState: REQUEST_SUCCESS
        }
      );
    case Actions.CREATE_USER_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          createUserState: REQUEST_FAILED
        }
      );

    case Actions.CHANGE_PASSWORD:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          changePasswordStatus: REQUEST_IN_PROGRESS
        }
      );
    case Actions.CHANGE_PASSWORD_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          changePasswordStatus: REQUEST_SUCCESS
        }
      );
    case Actions.CHANGE_PASSWORD_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          changePasswordStatus: REQUEST_FAILED
        }
      );

    case Actions.ASSIGN_BANK_ACCOUNT:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          assignBankAccountStatus: REQUEST_IN_PROGRESS
        }
      );
    case Actions.ASSIGN_BANK_ACCOUNT_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          assignBankAccountStatus: REQUEST_SUCCESS
        }
      );
    case Actions.ASSIGN_BANK_ACCOUNT_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          assignBankAccountStatus: REQUEST_FAILED
        }
      );

    case Actions.CHANGE_STATUS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          changeActiveStatus: REQUEST_IN_PROGRESS
        }
      );
    case Actions.CHANGE_STATUS_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          changeActiveStatus: REQUEST_SUCCESS
        }
      );
    case Actions.CHANGE_STATUS_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          changeActiveStatus: REQUEST_FAILED
        }
      );

    case Actions.RESET_STATES:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          createBankAccountState: REQUEST_UNTOUCHED,
          createUserState: REQUEST_UNTOUCHED,
          changePasswordStatus: REQUEST_UNTOUCHED,
          assignBankAccountStatus: REQUEST_UNTOUCHED,
          changeActiveStatus: REQUEST_UNTOUCHED,
          postPutRequestStatus: REQUEST_UNTOUCHED,
          createEmployeeState: REQUEST_UNTOUCHED
        }
      );

    case Actions.LOGIN:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.LOGIN_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          authPassword: null,
          authUsername: null,
        } as UserState
      );

    case Actions.LOGIN_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          authPassword: null,
          authUsername: null,
        } as UserState
      );

    case Actions.LOGOUT:
    case Actions.LOGOUT_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.UPDATE_SUPERVISOR_STOCK:
    case Actions.CREATE_SUPERVISOR_DATA:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_IN_PROGRESS
        }
      );

    case Actions.UPDATE_SUPERVISOR_STOCK_SUCCESS:
    case Actions.CREATE_SUPERVISOR_DATA_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_SUCCESS
        }
      );

    case Actions.UPDATE_SUPERVISOR_STOCK_FAIL:
    case Actions.CREATE_SUPERVISOR_DATA_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_FAILED
        }
      );

    case Actions.CREATE_EMPLOYEE:
    case Actions.EDIT_EMPLOYEE:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          createEmployeeState: REQUEST_IN_PROGRESS
        }
      );
    case Actions.CREATE_EMPLOYEE_SUCCESS:
    case Actions.EDIT_EMPLOYEE_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          createEmployeeState: REQUEST_SUCCESS
        }
      );
    case Actions.CREATE_EMPLOYEE_FAIL:
    case Actions.EDIT_EMPLOYEE_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          createEmployeeState: REQUEST_FAILED
        }
      );

    default:
      return state;
  }
}