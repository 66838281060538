import { h, Component } from 'preact';
import linkState from 'linkstate';
import cx from 'classnames';

import { ModalProps, Modal } from '../common/modal';
import { Remittance } from '../../../../types/remittance.model';
import { Subject, Subscription } from 'rxjs';
import getStore, { appState$ } from '../../store';
import { SUBMIT_EDIT_REQUEST } from '../../reducers/remittance/remittance.actions';
import { EditRequest, DeleteRequest } from '../../../../types/edit-request.model';
import { map } from 'rxjs/operators';
import { RequestState, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../types/request-state.model';
import { FieldList, Field, Form } from '../../../../types/form.model';
import { validateForm, cxValidateField } from '../../../../types/validator.model';
import * as Validators from '../../../../types/validator.model';
import linkform from '../../../utils/linkform';
import { CREATE_BANK_ACCOUNT } from '../../reducers/user/user.actions';
import { BankAccount } from '../../../../types/bank-account.model';

export interface CreateBankAccountModalProps extends ModalProps {
}

interface CreateBankAccountModalState  {

  submitStatus?: RequestState;
  form?: Form;
}

export class CreateBankAccountModal extends Modal<CreateBankAccountModalProps, CreateBankAccountModalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props: CreateBankAccountModalProps) {
    super(props);

    this.createForm();
  }

  createForm() {
    let fields: FieldList = {
      'bankAccountName': new Field([Validators.required], null, ''),
      'bankAccountNumber': new Field([Validators.required] , null, ''),
      'bank': new Field([Validators.required] , null, ''),
      'accountBranch': new Field([Validators.required] , null, ''),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      form: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['form'] = form;
      this.setState(state);
    });
  }

  componentDidMount() {
    super.componentDidMount();

    const user$ = appState$.pipe(
      map(app => app.user)
    );

    this.subs.push(
      user$.pipe(
        map(user => user.createBankAccountState)
      ).subscribe(reqState => {
        this.setState({
          submitStatus: reqState
        });

        if (reqState === REQUEST_SUCCESS) {
          this.doModalClose();
        }
      })
    );
  }

  componentWillUnmount() {
    this.subs.forEach(s => s.unsubscribe());
  }

  doModalClose = () => {
    this.props.close();
  }

  getSpinnerBlock(state: CreateBankAccountModalState) {
    if (state.submitStatus === REQUEST_IN_PROGRESS) {
      return (
        <div class="col offset-md-9 d-flex justify-content-end">
          <div class="spinner-border text-primary mt-3" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }    
  }

  onSubmit = () => {

    let form = this.state.form;

    validateForm(form);

    if (!form.isValid) {
      return;
    }

    let bankAccount: BankAccount = {
      bankAccountName: form.fields['bankAccountName'].value,
      bankAccountNumber: form.fields['bankAccountNumber'].value,
      bank: form.fields['bank'].value,
      accountBranch: form.fields['accountBranch'].value
    }

    const store = getStore();
    store.dispatch({
      type: CREATE_BANK_ACCOUNT,
      payload: {
        bankAccount: bankAccount
      }
    });
  }

  render(props: CreateBankAccountModalProps, state: CreateBankAccountModalState) {
    let form = state.form;

    return (
      <div class="container anglo-modal"
          ref={ node => this.refNode = node }>
        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">
              Create bank account
            </h3>
          </div>
          <div class="col-3 col-md-2">
            <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        
        <div class="row anglo-modal__body py-3">

          <div class="col-12 col-md-6 mt-3">
            <label class="dash-remittance__filter-row__filters__label">Account Name*</label>
            <input type="text" className={ cx('form-control', 
              { 'invalid': cxValidateField(form.fields['bankAccountName']) }
            )}
            value={ form.fields['bankAccountName'].value }
            placeholder=""
            onChange={ linkform(this.form$, form, 'bankAccountName') }/>
          </div>

          <div class="col-12 col-md-6 mt-3 mt-md-0">
            <label class="dash-remittance__filter-row__filters__label">Account Number*</label>
            <input type="text" className={ cx('form-control', 
              { 'invalid': cxValidateField(form.fields['bankAccountNumber']) }
            )}
            value={ form.fields['bankAccountNumber'].value }
            placeholder=""
            onChange={ linkform(this.form$, form, 'bankAccountNumber') }/>
          </div>

          <div class="col-12 col-md-6 mt-3 mt-md-0 mt-3">
            <label class="dash-remittance__filter-row__filters__label">Bank*</label>
            <input type="text" className={ cx('form-control', 
              { 'invalid': cxValidateField(form.fields['bank']) }
            )}
            value={ form.fields['bank'].value }
            placeholder=""
            onChange={ linkform(this.form$, form, 'bank') }/>
          </div>

          <div class="col-12 col-md-6 mt-3 mt-md-0 mt-3">
            <label class="dash-remittance__filter-row__filters__label">Account Branch Location*</label>
            <input type="text" className={ cx('form-control', 
              { 'invalid': cxValidateField(form.fields['accountBranch']) }
            )}
            value={ form.fields['accountBranch'].value }
            placeholder=""
            onChange={ linkform(this.form$, form, 'accountBranch') }/>
          </div>

          <div class="col-2 offset-8 mt-4">
            {
              this.getSpinnerBlock(state)
            }
          </div>

          <div class="col-12 col-md-2 mt-4">

            <button class="btn btn-primary w-100" onClick={this.onSubmit}>
              Submit
            </button>
          </div>
        </div>

        
      </div>
    );
  }
}