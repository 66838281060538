export const ROLE_SUPERVISOR = 'role_supervisor';
export const ROLE_AUDITOR = 'role_auditor';
export const ROLE_VEHICLE_MANAGER = 'role_vehicle-manager';
export const ROLE_MANAGEMENT = 'role_management';
export const ROLE_SUPER_ADMIN = 'role_super_admin';
export const ROLE_ADMIN_SALES = 'role_admin-sales';

export const ROLES: string[] = [
  'role_agent',
  'role_supervisor',
  'role_auditor',
  'role_vehicle-manager',
  'role_management',
  'role_super_admin',
  ROLE_ADMIN_SALES
];

export const ROLES_FOR_CREATING: string[] = [
  'role_supervisor',
  'role_auditor',
  'role_vehicle-manager',
  'role_management',
  ROLE_ADMIN_SALES
];