import { Epic, ofType } from "redux-observable";

import * as VehicleActions from './vehicle.actions'
import { VehicleAction } from './vehicle.actions';

import { switchMap, mergeMap, catchError, withLatestFrom } from "rxjs/operators";
import { from, of } from "rxjs";

import { showToast } from '../../../utils/toast.service';
import Axios from "axios";
import { AuditLog } from "../../../../types/audit-log.model";

import { API_ENDPOINT } from '../../../../constants/api';
import { TotalCount } from "../../../../types/total-count.model";
import { Vehicle } from "../../../../types/vehicle.model";
import { REQUEST_SUCCESS, REQUEST_FAILED, REQUEST_UNTOUCHED } from "../../../../types/request-state.model";
import { appState$ } from "../../store";

let vehicleEffects: Epic[] = [];


const getGridData: Epic = getGridData$ => getGridData$.pipe(
  ofType<VehicleAction>(VehicleActions.GET_VEHICLES),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    let retrievedFilters;
    const stateFilters = state.vehicles.filters;
    let filters = '?';

    if (action.payload && action.payload.filters) {

      if (stateFilters) {
        retrievedFilters = {
          ...action.payload.filters,
          ...stateFilters
        };
      } else {
        retrievedFilters = action.payload.filters;
      }

      let index = 0;

      for (let k in retrievedFilters) {
        if (index > 0) {
          filters = filters.concat('&');
        }

        filters = filters.concat(`${k}=${retrievedFilters[k]}`);
        index++;
      }
      
    } else if (stateFilters) {
      let index = 0;
      for (let k in stateFilters) {
        if (index > 0) {
          filters = filters.concat('&');
        }

        filters = filters.concat(`${k}=${stateFilters[k]}`);
        index++;
      }
    }

    const promise = Axios.get<AuditLog[]>(
      `${API_ENDPOINT}/vehicle${filters}`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const gridData = JSON.parse(JSON.stringify(response.data['results']));

        const action: VehicleAction = {
          type: VehicleActions.GET_VEHICLES_SUCCESS,
          payload: {
            vehicles: gridData['data'],
            totalVehicles: gridData['totalCount'][0] ? gridData['totalCount'][0].count : 0
          }
        }

        return of(action);
      }),
      catchError((errResponse) => {
        const action: VehicleAction = {
          type: VehicleActions.GET_VEHICLES_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response && errResponse.response.status == 400) {
          showToast({
            title: 'Failed to get vehicle data',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to get vehicle data',
            message: 'Please contact the system admin.',
            duration: 3000
          });
        } 
        
        return of(action);
      })
    );
  })
);

const createVehicle: Epic = submitRemit$ => submitRemit$.pipe(
  ofType<VehicleAction>(VehicleActions.CREATE_VEHICLE),
  switchMap((action: VehicleAction) => {
    
    const promise = Axios.post<Vehicle>(
      `${API_ENDPOINT}/vehicle`,
      action.payload.vehicleToCreate, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: VehicleAction = {
          type: VehicleActions.CREATE_VEHICLE_SUCCESS,
          payload: {
            createVehicleState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Vehicle submit',
          message: 'You have successfully submitted a Vehicle',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit Vehicle',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to submit Vehicle',
            message: message,
            duration: 3000
          });
        } 

        const action: VehicleAction = {
          type: VehicleActions.CREATE_VEHICLE_FAIL,
          payload: {
            createVehicleState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const updateGrid: Epic = updateGrid$ => updateGrid$.pipe(
  ofType<VehicleAction>(VehicleActions.CREATE_VEHICLE_SUCCESS),
  switchMap(action => {
    return of({
      type: VehicleActions.GET_VEHICLES,
      payload: {
        createVehicleState: REQUEST_UNTOUCHED
      }
    });
  })
);

const editVehicle: Epic = editVehicle$ => editVehicle$.pipe(
  ofType<VehicleAction>(VehicleActions.EDIT_VEHICLE),
  switchMap((action: VehicleAction) => {
    
    const promise = Axios.put<Vehicle>(
      `${API_ENDPOINT}/vehicle`,
      action.payload.vehicleToUpdate, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: VehicleAction = {
          type: VehicleActions.EDIT_VEHICLE_SUCCESS,
          payload: {
            editVehicleState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Vehicle edit',
          message: 'You have successfully submitted a Vehicle',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to edit Vehicle',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to edit Vehicle',
            message: message,
            duration: 3000
          });
        } 

        const action: VehicleAction = {
          type: VehicleActions.EDIT_VEHICLE_FAIL,
          payload: {
            editVehicleState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const updateGridEdit: Epic = updateGrid$ => updateGrid$.pipe(
  ofType<VehicleAction>(VehicleActions.EDIT_VEHICLE_SUCCESS),
  switchMap(action => {
    return of({
      type: VehicleActions.GET_VEHICLES,
      payload: {
        editVehicleState: REQUEST_UNTOUCHED
      }
    });
  })
);

const createVehicleFile: Epic = createVehicleFile$ => createVehicleFile$.pipe(
  ofType<VehicleAction>(VehicleActions.CREATE_VEHICLE_FILE),
  switchMap((action: VehicleAction) => {
    
    const promise = Axios.post<Vehicle>(
      `${API_ENDPOINT}/vehicle-file`,
      action.payload.vehicleFileToCreate, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: VehicleAction = {
          type: VehicleActions.CREATE_VEHICLE_FILE_SUCCESS,
          payload: {
            createVehicleFileState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Vehicle File submit',
          message: 'You have successfully submitted a Vehicle Report',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit Vehicle File',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to submit Vehicle File',
            message: message,
            duration: 3000
          });
        } 

        const action: VehicleAction = {
          type: VehicleActions.CREATE_VEHICLE_FILE_FAIL,
          payload: {
            createVehicleFileState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const getVehicleFiles: Epic = getVehicleFiles$ => getVehicleFiles$.pipe(
  ofType<VehicleAction>(VehicleActions.GET_VEHICLE_FILES),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    let retrievedFilters;
    const stateFilters = state.vehicles.filters;
    let filters = '?';

    if (action.payload && action.payload.filters) {

      if (stateFilters) {
        retrievedFilters = {
          ...action.payload.filters,
          ...stateFilters
        };
      } else {
        retrievedFilters = action.payload.filters;
      }

      let index = 0;

      for (let k in retrievedFilters) {
        if (index > 0) {
          filters = filters.concat('&');
        }

        filters = filters.concat(`${k}=${retrievedFilters[k]}`);
        index++;
      }
      
    } else if (stateFilters) {
      let index = 0;
      for (let k in stateFilters) {
        if (index > 0) {
          filters = filters.concat('&');
        }

        filters = filters.concat(`${k}=${stateFilters[k]}`);
        index++;
      }
    }

    const promise = Axios.get<AuditLog[]>(
      `${API_ENDPOINT}/vehicle-file${filters}`,
      {
        // params: retrievedFilters,
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const gridData = JSON.parse(JSON.stringify(response.data['results']));

        const action: VehicleAction = {
          type: VehicleActions.GET_VEHICLE_FILES_SUCCESS,
          payload: {
            vehicleFiles: gridData['data'],
            totalVehicleFiles: gridData['totalCount'][0] ? gridData['totalCount'][0].count : 0
          }
        }

        return of(action);
      }),
      catchError((errResponse) => {
        const action: VehicleAction = {
          type: VehicleActions.GET_VEHICLE_FILES_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response && errResponse.response.status == 400) {
          showToast({
            title: 'Failed to get vehicle file data',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to get vehicle file data',
            message: 'Please contact the system admin.',
            duration: 3000
          });
        } 
        
        return of(action);
      })
    );
  })
);

const approveVehicleFile: Epic = approveVehicleFile$ => approveVehicleFile$.pipe(
  ofType<VehicleAction>(VehicleActions.APPROVE_VEHICLE_FILE),
  switchMap((action: VehicleAction) => {
    
    const promise = Axios.put<Vehicle>(
      `${API_ENDPOINT}/vehicle-file/approve`,
      {
        responseReason: action.payload.responseReason,
        _id: action.payload.vehicleFileToUpdate,
        vehicleId: action.payload.vehicleId
      }, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: VehicleAction = {
          type: VehicleActions.APPROVE_VEHICLE_FILE_SUCCESS,
          payload: {
            respondVehicleFileState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Vehicle File submit',
          message: 'You have successfully accepted the file',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to respond Vehicle File',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to respond Vehicle File',
            message: message,
            duration: 3000
          });
        } 

        const action: VehicleAction = {
          type: VehicleActions.APPROVE_VEHICLE_FILE_FAIL,
          payload: {
            respondVehicleFileState: REQUEST_FAILED
          }
        }
    
        return of(action);
      })
    );

  })
);

const rejectVehicleFile: Epic = rejectVehicleFile$ => rejectVehicleFile$.pipe(
  ofType<VehicleAction>(VehicleActions.REJECT_VEHICLE_FILE),
  switchMap((action: VehicleAction) => {
    
    const promise = Axios.put<Vehicle>(
      `${API_ENDPOINT}/vehicle-file/reject`,
      {
        responseReason: action.payload.responseReason,
        _id: action.payload.vehicleFileToUpdate,
        vehicleId: action.payload.vehicleId
      }, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: VehicleAction = {
          type: VehicleActions.REJECT_VEHICLE_FILE_SUCCESS,
          payload: {
            respondVehicleFileState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Vehicle File response',
          message: 'You have successfully rejected the file',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to respond Vehicle File',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to respond Vehicle File',
            message: message,
            duration: 3000
          });
        } 

        const action: VehicleAction = {
          type: VehicleActions.REJECT_VEHICLE_FILE_FAIL,
          payload: {
            respondVehicleFileState: REQUEST_FAILED
          }
        }
    
        return of(action);
      })
    );

  })
);

const getJobOrders: Epic = getVehicleFiles$ => getVehicleFiles$.pipe(
  ofType<VehicleAction>(VehicleActions.GET_JOB_ORDERS),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const promise = Axios.get<AuditLog[]>(
      `${API_ENDPOINT}/vehicle-job-order`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const gridData = JSON.parse(JSON.stringify(response.data['results']));

        const action: VehicleAction = {
          type: VehicleActions.GET_JOB_ORDERS_SUCCESS,
          payload: {
            jobOrders: gridData
          }
        }

        return of(action);
      }),
      catchError((errResponse) => {
        const action: VehicleAction = {
          type: VehicleActions.GET_JOB_ORDERS_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response && errResponse.response.status == 400) {
          showToast({
            title: 'Failed to get job order data',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to get order data',
            message: 'Please contact the system admin.',
            duration: 3000
          });
        } 
        
        return of(action);
      })
    );
  })
);

const createJobOrder: Epic = createVehicleFile$ => createVehicleFile$.pipe(
  ofType<VehicleAction>(VehicleActions.CREATE_JOB_ORDER),
  switchMap((action: VehicleAction) => {
    
    const promise = Axios.post<Vehicle>(
      `${API_ENDPOINT}/vehicle-job-order`,
      action.payload.jobOrderToCreate, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: VehicleAction = {
          type: VehicleActions.CREATE_JOB_ORDER_SUCCESS,
          payload: {
            createJobOrderState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success create job order',
          message: 'You have successfully created a job order.',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to create job order',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to create job order',
            message: message,
            duration: 3000
          });
        } 

        const action: VehicleAction = {
          type: VehicleActions.CREATE_JOB_ORDER_FAIL,
          payload: {
            createJobOrderState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const getMedicalHistory: Epic = getVehicleFiles$ => getVehicleFiles$.pipe(
  ofType<VehicleAction>(VehicleActions.GET_MEDICAL_HISTORY),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const promise = Axios.get<AuditLog[]>(
      `${API_ENDPOINT}/vehicle-medical-history`,
      {
        params: {
          vehicleId: action.payload.vehicleId
        },
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const gridData = JSON.parse(JSON.stringify(response.data['results']));

        const action: VehicleAction = {
          type: VehicleActions.GET_MEDICAL_HISTORY_SUCCESS,
          payload: {
            vehicleMedicalHistory: gridData
          }
        }

        return of(action);
      }),
      catchError((errResponse) => {
        const action: VehicleAction = {
          type: VehicleActions.GET_MEDICAL_HISTORY_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response && errResponse.response.status == 400) {
          showToast({
            title: 'Failed to get job order data',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to get order data',
            message: 'Please contact the system admin.',
            duration: 3000
          });
        } 
        
        return of(action);
      })
    );
  })
);

const createJobOrderCleanup: Epic = createVehicleFile$ => createVehicleFile$.pipe(
  ofType<VehicleAction>(VehicleActions.CREATE_JOB_ORDER_SUCCESS),
  switchMap((action: VehicleAction) => {
    const returnAction: VehicleAction = {
      type: VehicleActions.CREATE_JOB_ORDER_CLEANUP,
      payload: {
        createJobOrderState: REQUEST_UNTOUCHED
      }
    }

    return of(returnAction);
  })
);

const updateJobOrder: Epic = createVehicleFile$ => createVehicleFile$.pipe(
  ofType<VehicleAction>(VehicleActions.UPDATE_JOB_ORDER),
  switchMap((action: VehicleAction) => {
    
    const promise = Axios.put<Vehicle>(
      `${API_ENDPOINT}/vehicle-job-order`,
      action.payload.jobOrderToUpdate, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: VehicleAction = {
          type: VehicleActions.UPDATE_JOB_ORDER_SUCCESS,
          payload: {
            updateJobOrderState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success create job order',
          message: 'You have successfully updated a job order.',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to create job order',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to create job order',
            message: message,
            duration: 3000
          });
        } 

        const action: VehicleAction = {
          type: VehicleActions.UPDATE_JOB_ORDER_FAIL,
          payload: {
            updateJobOrderState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const getExpenseReport: Epic = getExpenseReport$ => getExpenseReport$.pipe(
  ofType<VehicleAction>(VehicleActions.GENERATE_VEHICLE_EXPENSE_REPORT),
  switchMap((action: VehicleAction) => {

    let filters = '?';

    if (action.payload && action.payload.filters) {
      let index = 0;
      let v;
      for (let k in action.payload.filters) {
        v = action.payload.filters[k];

        if (index > 0) {
          filters = filters.concat('&');
        }

        if (v) {
          filters = filters.concat(`${k}=${v}`);
        }

        index++;
      }
      // action.payload.filters.forEach((v, k) => {

      //   if (index > 0) {
      //     filters = filters.concat('&');
      //   }

      //   filters = filters.concat(`${k}=${v}`);

      //   index++;
      // });
    }

    const promise = Axios.get<any>(
      `${API_ENDPOINT}/vehicle-file/expenses${filters}`,
      {
        withCredentials: true,
        responseType: "blob",
        headers: {
          'Accept': 'application/pdf'
        }
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {

        const action: VehicleAction = {
          type: VehicleActions.GENERATE_VEHICLE_EXPENSE_REPORT_SUCCESS
        }
        if (response) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          window.open(URL.createObjectURL(blob));
        }

        return of(action);
      }),
      catchError((errResponse) => {
        const action: VehicleAction = {
          type: VehicleActions.GENERATE_VEHICLE_EXPENSE_REPORT_FAIL,
        };

        showToast({
          title: 'Failed to generate expense report',
          message: 'Please contact the system admin.',
          duration: 3000
        });
        
        return of(action);
      })
    );
  })
);

const resetChain: Epic = resetChain$ => resetChain$.pipe(
  ofType<VehicleAction>(VehicleActions.CREATE_VEHICLE_FILE_SUCCESS),
  switchMap(() => {
    const action: VehicleAction = {
      type: VehicleActions.RESET_VEHICLE_FILE_STATE
    }

    return of(action);
  })
);

// ! Make sure to add created epics to array 

vehicleEffects.push(getGridData);
vehicleEffects.push(createVehicle);
vehicleEffects.push(updateGrid);
vehicleEffects.push(editVehicle);
vehicleEffects.push(updateGridEdit);
vehicleEffects.push(createVehicleFile);

vehicleEffects.push(getVehicleFiles);
vehicleEffects.push(approveVehicleFile);
vehicleEffects.push(rejectVehicleFile);

vehicleEffects.push(getJobOrders);
vehicleEffects.push(createJobOrder);
vehicleEffects.push(getMedicalHistory);
vehicleEffects.push(createJobOrderCleanup);
vehicleEffects.push(updateJobOrder);

vehicleEffects.push(getExpenseReport);

vehicleEffects.push(resetChain);

export default vehicleEffects;
