import { Observable, pipe, of, EMPTY, from, Observer, throwError } from 'rxjs';
import { switchMap, catchError, mergeMap, map, withLatestFrom } from 'rxjs/operators';
import { Epic, ofType, ActionsObservable } from 'redux-observable';

import Axios, { AxiosPromise, AxiosResponse, AxiosError } from 'axios';

import * as UserActions from './user.actions'
import { UserAction } from './user.actions';
import { User } from '../../../../types/user.model';
import { REQUEST_SUCCESS, REQUEST_FAILED } from '../../../../types/request-state.model';

import { showToast } from '../../../utils/toast.service';

import { API_ENDPOINT } from '../../../../constants/api';
import { BankAccount } from '../../../../types/bank-account.model';
import { appState$ } from '../../store';
import { Agent } from '../../../../types/agent.model';

// should be something like api.carta.com
const userBasePath = `${API_ENDPOINT}/user`;

let userEffects: Epic[] = [];

const test: Epic = test$ => test$.pipe(
  ofType<UserAction>(UserActions.TEST),
  switchMap(() => {

    return of({
      type: UserActions.LOGOUT_SUCCESS
    } as UserAction);
  })
);

const login: Epic = login$ => login$.pipe(
  ofType<UserAction>(UserActions.LOGIN),
  switchMap((action: UserAction) => {
    const userData = {
      username: action.payload.authUsername,
      password: action.payload.authPassword,
    };

    const promise = Axios.post<User>(
      `${userBasePath}/login`,
      userData, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: UserAction = {
          type: UserActions.LOGIN_SUCCESS,
          payload: {
            loginState: REQUEST_SUCCESS,
          }
        }
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response && errResponse.response.status == 400) {
          showToast({
            title: 'Failed to login',
            message: message ? message : 'Invalid email or password.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to login',
            message: message,
            duration: 3000
          });
        } 

        const action: UserAction = {
          type: UserActions.LOGIN_FAIL,
          payload: {
            loginState: REQUEST_FAILED,
          }
        }
    
        return of(action);

      })
    );

  })
);

const loginSuccess: Epic = loginSuccess$ => loginSuccess$.pipe(
  ofType<UserAction>(UserActions.LOGIN_SUCCESS),
  switchMap(() => {

    const action: UserAction = {
      type: UserActions.GET_CURRENT_USER
    }

    setTimeout(() => {
      location.href = '/';
    }, 250);

    return of(action);
  })
);

const getCurrentUser: Epic = getCurrentUser$ => getCurrentUser$.pipe(
  ofType<UserAction>(UserActions.GET_CURRENT_USER),
  switchMap((action: UserAction) => {

    const promise = Axios.get<User>(
      `${userBasePath}/me`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        
        const currentUser: User = response.data[0];
        const action: UserAction = {
          type: UserActions.GET_CURRENT_USER_SUCCESS,
          payload: {
            currentUser: currentUser,
            getCurrentUserState: REQUEST_SUCCESS
          }
        }


        return of(action);
      }),
      catchError((errResp) => {
        const action: UserAction = {
          type: UserActions.GET_CURRENT_USER_FAIL,
          payload: {
            getCurrentUserState: REQUEST_FAILED
          }
        }
        
        return of(action);
      })
    );
  })
);

const logout: Epic = logout$ => logout$.pipe(
  ofType<UserAction>(UserActions.LOGOUT),
  switchMap(() => {

    const promise = Axios.get(
      `${userBasePath}/logout`,
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(() => {
        const action: UserAction = {
          type: UserActions.LOGOUT_SUCCESS, 
          payload: {
            currentUser: null
          }
        }

        setTimeout(() => {
          location.href = '/login';
        }, 250);

        return of(action);
      }),
      catchError(() => {
        const action: UserAction = {
          type: UserActions.LOGOUT_FAIL
        }
    
        return of(action);
      })
    );
  })
);

const getAgents: Epic = getOverallTotal$ => getOverallTotal$.pipe(
  ofType<UserAction>(UserActions.GET_AGENTS),
  switchMap((action: UserAction) => {

    let filters = '?';

    if (action.payload && action.payload.employeeFilters) {
      let index = 0;
      action.payload.employeeFilters.forEach((v, k) => {

        if (index > 0) {
          filters = filters.concat('&');
        }

        filters = filters.concat(`${k}=${v}`);

        index++;
      });
    }

    const promise = Axios.get<any>(
      `${API_ENDPOINT}/agent${filters}`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const agents = response.data['results'];

        const action: UserAction = {
          type: UserActions.GET_AGENTS_SUCCESS,
          payload: {
            agents: agents
          }
        }

        return of(action);
      }),
      catchError((errResponse) => {
        console.log(errResponse);
        const action: UserAction = {
          type: UserActions.GET_AGENTS_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        
        showToast({
          title: 'Failed to get agents',
          message: message ? message : 'Please contact the system admin.',
          duration: 3000
        })        
        
        return of(action);
      })
    );
  })
);

const getSupervisors: Epic = getSupervisors$ => getSupervisors$.pipe(
  ofType<UserAction>(UserActions.GET_SUPERVISORS),
  switchMap((action: UserAction) => {

    let filters = '?';

    if (action.payload && action.payload.filters) {
      let index = 0;
      action.payload.filters.forEach((v, k) => {

        if (index > 0) {
          filters = filters.concat('&');
        }

        filters = filters.concat(`${k}=${v}`);

        index++;
      });
    }

    const promise = Axios.get<any>(
      `${API_ENDPOINT}/supervisor${filters}`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const supervisors = response.data['results'];

        const action: UserAction = {
          type: UserActions.GET_SUPERVISORS_SUCCESS,
          payload: {
            supervisors: supervisors
          }
        }

        return of(action);
      }),
      catchError((errResponse) => {
        console.log(errResponse);
        const action: UserAction = {
          type: UserActions.GET_SUPERVISORS_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        
        showToast({
          title: 'Failed to get supervisors',
          message: message ? message : 'Please contact the system admin.',
          duration: 3000
        })        
        
        return of(action);
      })
    );
  })
);

const getAllUsers: Epic = getAllUsers$ => getAllUsers$.pipe(
  ofType<UserAction>(UserActions.GET_ALL_USERS),
  switchMap((action: UserAction) => {

    const promise = Axios.get<any>(
      `${API_ENDPOINT}/user/all`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const allUsers = response.data['results'];

        const action: UserAction = {
          type: UserActions.GET_ALL_USERS_SUCCESS,
          payload: {
            allUsers: allUsers
          }
        }

        return of(action);
      }),
      catchError((errResponse) => {
        console.log(errResponse);
        const action: UserAction = {
          type: UserActions.GET_ALL_USERS_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        
        showToast({
          title: 'Failed to get all users',
          message: message ? message : 'Please contact the system admin.',
          duration: 3000
        })        
        
        return of(action);
      })
    );
  })
);

const getBankAccounts: Epic = getBankAccounts$ => getBankAccounts$.pipe(
  ofType<UserAction>(UserActions.GET_BANKS),
  switchMap((action: UserAction) => {

    let promise;

    if (action.payload && action.payload.isSubmitRemit) {
      promise = Axios.get<any>(
        `${API_ENDPOINT}/banks/get-assignments`,
        {
          withCredentials: true
        }
      );
    } else {
      promise = Axios.get<any>(
        `${API_ENDPOINT}/banks`,
        {
          withCredentials: true
        }
      );
    }

    return from (promise).pipe(
      mergeMap((response: any) => {
        const allBankAccounts = response.data['results'];

        const action: UserAction = {
          type: UserActions.GET_BANKS_SUCCESS,
          payload: {
            allBankAccounts: allBankAccounts,
            isSubmitRemit: false
          }
        }

        return of(action);
      }),
      catchError((errResponse) => {
        console.log(errResponse);
        const action: UserAction = {
          type: UserActions.GET_BANKS_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        
        showToast({
          title: 'Failed to get all bank accounts',
          message: message ? message : 'Please contact the system admin.',
          duration: 3000
        })        
        
        return of(action);
      })
    );
  })
);

const createBankAccount: Epic = createBankAccount$ => createBankAccount$.pipe(
  ofType<UserAction>(UserActions.CREATE_BANK_ACCOUNT),
  switchMap((action: UserAction) => {
    const bankAccount: BankAccount = action.payload.bankAccount;

    const promise = Axios.post<any>(
      `${API_ENDPOINT}/banks/create-account`,
      bankAccount,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {

        const action: UserAction = {
          type: UserActions.CREATE_BANK_ACCOUNT_SUCCESS
        }

        showToast({
          title: 'Create Bank Account',
          message: 'Successfully created a new bank account',
          duration: 3000
        });   

        return of(action);
      }),
      catchError((errResponse) => {
        console.log(errResponse);
        const action: UserAction = {
          type: UserActions.CREATE_BANK_ACCOUNT_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        
        showToast({
          title: 'Failed to create bank account',
          message: message ? message : 'Please contact the system admin.',
          duration: 3000
        });        
        
        return of(action);
      })
    );
  })
);

const createUser: Epic = createUser$ => createUser$.pipe(
  ofType<UserAction>(UserActions.CREATE_USER),
  switchMap((action: UserAction) => {
    const userToCreate: User = action.payload.userToCreate;

    const promise = Axios.post<any>(
      `${API_ENDPOINT}/user/register`,
      userToCreate,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {

        const action: UserAction = {
          type: UserActions.CREATE_USER_SUCCESS
        }

        showToast({
          title: 'Create Bank Account',
          message: 'Successfully created a new user',
          duration: 3000
        });   

        return of(action);
      }),
      catchError((errResponse) => {
        console.log(errResponse);
        const action: UserAction = {
          type: UserActions.CREATE_USER_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        
        showToast({
          title: 'Failed to create user',
          message: message ? message : 'Please contact the system admin.',
          duration: 3000
        });        
        
        return of(action);
      })
    );
  })
);

const changePassword: Epic = changePassword$ => changePassword$.pipe(
  ofType<UserAction>(UserActions.CHANGE_PASSWORD),
  switchMap((action: UserAction) => {
    const userToUpdate: User = action.payload.userToUpdate;

    const promise = Axios.put<any>(
      `${API_ENDPOINT}/user/change-password`,
      userToUpdate,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {

        const action: UserAction = {
          type: UserActions.CHANGE_PASSWORD_SUCCESS
        }

        showToast({
          title: 'Changed password',
          message: 'Successfully changed user password',
          duration: 3000
        });   

        return of(action);
      }),
      catchError((errResponse) => {
        console.log(errResponse);
        const action: UserAction = {
          type: UserActions.CHANGE_PASSWORD_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        
        showToast({
          title: 'Failed to changed user password',
          message: message ? message : 'Please contact the system admin.',
          duration: 3000
        });        
        
        return of(action);
      })
    );
  })
);

const assignBankAccount: Epic = assignBankAccount$ => assignBankAccount$.pipe(
  ofType<UserAction>(UserActions.ASSIGN_BANK_ACCOUNT),
  switchMap((action: UserAction) => {

    const promise = Axios.post<any>(
      `${API_ENDPOINT}/banks/assign`,
      {
        accountNumber: action.payload.bankAccountNumber,
        username: action.payload.userToAssign
      },
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {

        const action: UserAction = {
          type: UserActions.ASSIGN_BANK_ACCOUNT_SUCCESS
        }

        showToast({
          title: 'Assign Bank Account',
          message: 'Successfully assigned a new bank account to user',
          duration: 3000
        });   

        return of(action);
      }),
      catchError((errResponse) => {
        console.log(errResponse);
        const action: UserAction = {
          type: UserActions.ASSIGN_BANK_ACCOUNT_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        
        showToast({
          title: 'Failed to assign bank account',
          message: message ? message : 'Please contact the system admin.',
          duration: 3000
        });        
        
        return of(action);
      })
    );
  })
);

const changeStatus: Epic = changeStatus$ => changeStatus$.pipe(
  ofType<UserAction>(UserActions.CHANGE_STATUS),
  switchMap((action: UserAction) => {
    const userToUpdate: User = action.payload.userToUpdate;

    const promise = Axios.put<any>(
      `${API_ENDPOINT}/user/change-status`,
      userToUpdate,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {

        const action: UserAction = {
          type: UserActions.CHANGE_STATUS_SUCCESS
        }

        showToast({
          title: 'Changed status',
          message: 'Successfully changed user status',
          duration: 3000
        });   

        return of(action);
      }),
      catchError((errResponse) => {
        console.log(errResponse);
        const action: UserAction = {
          type: UserActions.CHANGE_STATUS_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        
        showToast({
          title: 'Failed to changed user status',
          message: message ? message : 'Please contact the system admin.',
          duration: 3000
        });        
        
        return of(action);
      })
    );
  })
);

const updateStocks: Epic = updateStocks$ => updateStocks$.pipe(
  ofType<UserAction>(UserActions.UPDATE_SUPERVISOR_STOCK),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {
    
    const promise = Axios.put<any>(
      `${API_ENDPOINT}/supervisor/update-stocks`,
      {
        supervisorId: action.payload.supervisorToUpdate,
        brandBreakdown: action.payload.stocksToUpdate
      }, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: UserAction = {
          type: UserActions.UPDATE_SUPERVISOR_STOCK_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Supervisor Update to Stocks',
          message: 'You have successfully updated stocks',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed Supervisor Update',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed Supervisor Update',
            message: message,
            duration: 3000
          });
        } 

        const action: UserAction = {
          type: UserActions.UPDATE_SUPERVISOR_STOCK_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const createSupervisorRegData: Epic = createSupervisorRegData$ => createSupervisorRegData$.pipe(
  ofType<UserAction>(UserActions.CREATE_SUPERVISOR_DATA),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {
    
    const promise = Axios.post<any>(
      `${API_ENDPOINT}/supervisor`,
      action.payload.supervisorRegistrationData, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: UserAction = {
          type: UserActions.CREATE_SUPERVISOR_DATA_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Create Supervisor Data',
          message: 'You have successfully created supervisor data',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed Create Supervisor Data',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed Create Supervisor Data',
            message: message,
            duration: 3000
          });
        } 

        const action: UserAction = {
          type: UserActions.CREATE_SUPERVISOR_DATA_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const resetUserChain: Epic = resetUserChain$ => resetUserChain$.pipe(
  ofType<UserAction>(
    UserActions.CREATE_BANK_ACCOUNT_SUCCESS,
    UserActions.UPDATE_SUPERVISOR_STOCK_SUCCESS,
    UserActions.CREATE_USER_SUCCESS,
    UserActions.ASSIGN_BANK_ACCOUNT_SUCCESS,
    UserActions.CHANGE_STATUS_SUCCESS,
    UserActions.ASSIGN_BANK_ACCOUNT_SUCCESS
  ),
  switchMap(() => {
    const action: UserAction = {
      type: UserActions.GET_ALL_USERS
    }

    return of(action);
  })
);

const resetChainGrid: Epic = resetChainGrid$ => resetChainGrid$.pipe(
  ofType<UserAction>(UserActions.RESET_STATES),
  switchMap(() => {
    const action: UserAction = {
      type: UserActions.GET_ALL_USERS
    }

    return of(action);
  })
);

const createEmployee: Epic = createEmployee$ => createEmployee$.pipe(
  ofType<UserAction>(UserActions.CREATE_EMPLOYEE),
  switchMap((action: UserAction) => {
    const employee: Agent = action.payload.employeeToCreate;
    const supervisorUsername: string = action.payload.supervisorUsername;

    const promise = Axios.post<any>(
      `${API_ENDPOINT}/agent/mgmt`,
      {...employee, supervisorUsername},
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {

        const action: UserAction = {
          type: UserActions.CREATE_EMPLOYEE_SUCCESS
        }

        showToast({
          title: 'Create Employee',
          message: 'Successfully created a new employee',
          duration: 3000
        });   

        return of(action);
      }),
      catchError((errResponse) => {
        console.log(errResponse);
        const action: UserAction = {
          type: UserActions.CREATE_EMPLOYEE_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        
        showToast({
          title: 'Failed to create employee',
          message: message ? message : 'Please contact the system admin.',
          duration: 3000
        });        
        
        return of(action);
      })
    );
  })
);

const editEmployee: Epic = editEmployee$ => editEmployee$.pipe(
  ofType<UserAction>(UserActions.EDIT_EMPLOYEE),
  switchMap((action: UserAction) => {
    const employee: Agent = action.payload.employeeToCreate;
    const supervisorUsername: string = action.payload.supervisorUsername;

    const promise = Axios.put<any>(
      `${API_ENDPOINT}/agent/update-employee`,
      {...employee, supervisorUsername},
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {

        const action: UserAction = {
          type: UserActions.EDIT_EMPLOYEE_SUCCESS
        }

        showToast({
          title: 'Edit Employee',
          message: 'Successfully edit employee',
          duration: 3000
        });   

        return of(action);
      }),
      catchError((errResponse) => {
        console.log(errResponse);
        const action: UserAction = {
          type: UserActions.EDIT_EMPLOYEE_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        
        showToast({
          title: 'Failed to edit employee',
          message: message ? message : 'Please contact the system admin.',
          duration: 3000
        });        
        
        return of(action);
      })
    );
  })
);

// ! Make sure to add created epics to array 

userEffects.push(test);
userEffects.push(login);
userEffects.push(loginSuccess);
userEffects.push(getCurrentUser);
userEffects.push(logout);

userEffects.push(getAgents);
userEffects.push(getSupervisors);

userEffects.push(getAllUsers);
userEffects.push(createBankAccount);

userEffects.push(createUser);
userEffects.push(getBankAccounts);
userEffects.push(changePassword);
userEffects.push(assignBankAccount);
userEffects.push(changeStatus);
userEffects.push(updateStocks);
userEffects.push(createSupervisorRegData);

userEffects.push(createEmployee);
userEffects.push(editEmployee);

userEffects.push(resetChainGrid);
userEffects.push(resetUserChain);

export default userEffects;