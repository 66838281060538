import { h, Component } from 'preact';

import { DataDisplayWidget } from './common/data-display.widget';
import { Subscription } from 'rxjs';
import getStore, { appState$ } from '../store';
import { map } from 'rxjs/operators';
import { TotalCount } from '../../../types/total-count.model';
import { toDecimal } from '../../utils/decimal';
import { GET_GRID_REMITTANCE, GET_REMITTANCE_TOTAL, GET_REMITTANCE_TOTAL_MONTH, RemittanceAction } from '../reducers/remittance/remittance.actions';
import { GET_AGENTS, UserAction } from '../reducers/user/user.actions';
import { AgentListWidget } from './dashboard/agent-list.widget';
import { Agent } from '../../../types/agent.model';
import { GET_VEHICLES, VehicleAction } from '../reducers/vehicle/vehicle.actions';

import { DASHBOARD_TIPS } from '../../../constants/tips';

export interface AngloDashboardProps {
}

export interface AngloDashboardState {
  overallTotal?: TotalCount;
  currentMonthTotal?: TotalCount;
  previousMonthTotal?: TotalCount;

  agents?: Agent[];

  currentTipIndex?: number;
}

export class AngloDashboard extends Component<any, AngloDashboardState> {
  subs: Subscription[] = [];

  constructor(props) {
    super(props);

    this.setState({
      currentTipIndex: 0
    });

    this.setupTipInterval();
  }

  coinFlip(): boolean {
    const num = Math.floor(Math.random()* 10);
  
    return num % 2 == 0;
  }

  setupTipInterval() {
    setInterval(() => {
      let index = this.state.currentTipIndex;

      if (index < DASHBOARD_TIPS.length - 1) {
        this.setState({
          currentTipIndex: index + 1
        });
      } else {
        this.setState({
          currentTipIndex: 0
        });
      }

    }, 5000);
  }

  componentDidMount() {

    const user$ = appState$.pipe(
      map(appState => appState.user)
    );

    this.subs.push(user$.subscribe((user) => {
        if (user.agents) {
          this.setState({
            agents: user.agents
          });
        }
      })
    );

    const remittance$ = appState$.pipe(
      map(appState => appState.remittance)
    );
    
     // set overall Total
     this.subs.push(remittance$.pipe(
      map(remittance => remittance.overallTotal)
    ).subscribe((overallTotal) => {

        this.setState({
          overallTotal: overallTotal
        });

      })
    );

    // set curMonth Total
    this.subs.push(remittance$.pipe(
      map(remittance => remittance.currentMonthTotal)
    ).subscribe((currentMonthTotal) => {

        this.setState({
          currentMonthTotal: currentMonthTotal
        });

      })
    );

    // set previousMonthTotal Total
    this.subs.push(remittance$.pipe(
      map(remittance => remittance.previousMonthTotal)
    ).subscribe((previousMonthTotal) => {

        this.setState({
          previousMonthTotal: previousMonthTotal
        });

      })
    );

    this.initialStoreDispatches();
  }

  initialStoreDispatches() {

    const store = getStore();
    store.dispatch({
      type: GET_GRID_REMITTANCE,
      payload: {
        filters: {
          page: 1
        }
      }
    } as RemittanceAction);

    store.dispatch({
      type: GET_REMITTANCE_TOTAL
    } as RemittanceAction);

    store.dispatch({
      type: GET_REMITTANCE_TOTAL_MONTH
    } as RemittanceAction);

    store.dispatch({
      type: GET_AGENTS
    } as UserAction);

    store.dispatch({
      type: GET_VEHICLES,
      payload: {
        filters: {
          page: 1
        }
      }
    } as VehicleAction);

  }

  getOverallTotalBlock(state: AngloDashboardState) {
    if (state.overallTotal) {
      return (
        <div class="col-12 col-md-6">
          <DataDisplayWidget 
            name="Overall Total"
            data1={ `₱ ${ toDecimal(state.overallTotal.total) }` }
            data1Label="Total Remitted Amount (Overall)"
            data2={ state.overallTotal.count }
            data2Label="Number of Remittances Submitted (Overall)"   
          />
        </div>
      );
    }
  }

  getMonthAmountBlock(state: AngloDashboardState) {
    if (state.currentMonthTotal && state.previousMonthTotal) {
      let change = state.currentMonthTotal.total - state.previousMonthTotal.total;
      let isPos = state.currentMonthTotal.total > state.previousMonthTotal.total;

      return (
        <div class="col-12 col-md-6 col-md-6 mt-4 mt-md-0">
          <DataDisplayWidget 
            name="Monthly Amount"
            data1={ `₱ ${ toDecimal(state.currentMonthTotal.total) }` }
            data1Label="Total Remitted Amount (Current Month)"
            data2={ `₱ ${ toDecimal(state.previousMonthTotal.total) }` }
            data2Label="Total Remitted Amount (Previous Month)"
            changeAmount={change}  
            isChangePositive={isPos} 
          />
        </div>
      );
    }
  }

  getMonthCountBlock(state: AngloDashboardState) {
    if (state.currentMonthTotal && state.previousMonthTotal) {
      let change = state.currentMonthTotal.count - state.previousMonthTotal.count;
      let isPos = state.currentMonthTotal.count > state.previousMonthTotal.count;

      return (
        <div class="col-12 col-md-6 col-md-6 mt-4 mt-md-0">
          <DataDisplayWidget 
            name="Monthly Count"
            data1={ state.currentMonthTotal.count }
            data1Label="Number of Remittances Submitted (Current Month)"
            data2={ state.previousMonthTotal.count }
            data2Label="Number of Remittances Submitted (Previous Month)"  
            changeAmount={change}  
            isChangePositive={isPos}          
          />
        </div>
      );
    }
  }

  getSomethingBlock(state: AngloDashboardState) {
    if (state.currentMonthTotal && state.previousMonthTotal) {
      return (
        <div class="col-12 col-md-6 col-md-6 mt-4 mt-md-0">
          <DataDisplayWidget isComingSoon={true}
            name="Average Something"
            data1={ 123 }
            data1Label="Number of Average Something"
            data2={ 123 }
            data2Label="Number of Average Something Top 20"  
          />
        </div>
      );
    }
  }

  getAgentsBlock(state: AngloDashboardState) {
    if (state.agents && state.agents.length > 0) {
      return (
        <div class="col-12 col-md-6 col-lg-12">
          <AgentListWidget agents={ state.agents } />
        </div>
      );
    }
  }

  render(props: AngloDashboardProps, state: AngloDashboardState) {
    return (
      <div class="anglo__dashboard">
        <div class="container-fluid">
          <div class="row">
            {/* cookie crumbs */}

            <div class="col-8 col-lg-4">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
                  {/* <li class="breadcrumb-item active" aria-current="page">Remittance Updates</li> */}
                </ol>
              </nav>
            </div>


            <div class="col-4 col-lg-2 offset-lg-6 d-flex justify-content-end align-items-center">
              <a class="anglo__dashboard__faq" href="#">
                {/* FAQs <i class="fas fa-chevron-right"></i> */}
              </a>
            </div>
          </div>

          <div class="row mt-4">

            <div class="col-12">
              <div class="anglo__dashboard__tips mt-2">
                Tips: 
                <span class="ml-2">
                  { DASHBOARD_TIPS[state.currentTipIndex] }
                </span>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            {/* Left of dashboard content */}
            <div class="col-12 col-lg-12 anglo__dashboard__widget-column">

              <div class="row">
                { this.getOverallTotalBlock(state) }
                { this.getSomethingBlock(state) }
              </div>

              <div class="row mt-4 mt-xl-5">
                { this.getMonthAmountBlock(state) }
                { this.getMonthCountBlock(state) }
              </div>
            
            </div>

            {/* <div class="col-12 col-lg-4 mt-4 mt-lg-0">
              <div class="row">
                { this.getAgentsBlock(state) }
              </div>
              
            </div> */}
          </div>
        </div>

      </div>
    );
  }

}