// this function is copied from https://blog.flowandform.agency/debounce-in-react-fc5ed305a0e8

export function debounce(a: any, b: any, c?: any){
  var d: any, e: any;
  return function() {
    function h() { 
      d=null,c||(e=a.apply(f,g))
    }

    var f=this,g=arguments;
    return clearTimeout(d),d=setTimeout(h,b),c&&!d&&(e=a.apply(f,g)),e
  }
}