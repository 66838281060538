import { h, Component } from "preact";
import cx from "classnames";
import { Subscription, Subject } from "rxjs";
import moment from "moment-timezone";
import { map, filter, distinctUntilChanged } from "rxjs/operators";

import { ModalProps, Modal } from "../../common/modal";
import { Form, FieldList, Field } from "../../../../../types/form.model";
import * as Validators from "../../../../../types/validator.model";
import {
  validateForm,
  cxValidateField,
} from "../../../../../types/validator.model";
import linkform, { updateFormFieldValue } from "../../../../utils/linkform";
import getStore, { appState$ } from "../../../store";

import {
  PurchaseDocument,
  ReceivingReport,
} from "../../../../../types/purchase-document.model";
import {
  REQUEST_IN_PROGRESS,
  REQUEST_SUCCESS,
} from "../../../../../types/request-state.model";
import { Item, ItemPiece } from "../../../../../types/item.model";
import { ItemSearch } from "../../common/item-search.component";
import { toFixed } from "../../../../utils/decimal";
import { getSpinnerBlock } from "../../common/spinner-block";
import {
  APPROVE_IW,
  CREATE_PURCHASE_DOCUMENT,
  PROCESS_IW,
  SIGN_PURCHASE_DOCUMENT,
  UPDATE_IW,
  UPDATE_PURCHASE_DOCUMENT,
} from "../../../reducers/motorpool/motorpool.actions";
import { ItemWithdrawal } from "../../../../../types/item-withdrawal.model";
import { DatePicker } from "preact/components/common/date-picker";

interface SignIWProps extends ModalProps {
  iw: ItemWithdrawal;
  isApproval: boolean;
}

interface SignIWState {
  form?: Form;
  isPostInProgress?: boolean;
}

export class SignIWModal extends Modal<SignIWProps, SignIWState> {
  form$: Subject<Form>;
  subs: Subscription[] = [];

  constructor(props: SignIWProps) {
    super(props);

    this.createForm(props);
  }

  createForm(props: SignIWProps) {
    let fields: FieldList = {
      actionBy: new Field([Validators.required], null, null),
      dateAction: new Field([Validators.required], null, null),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      form: new Form(fields),
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state["form"] = form;
      this.setState(state);
    });
  }

  componentDidMount(): void {
    super.componentDidMount();

    const motorpool$ = appState$.pipe(map((appState) => appState.motorpool));

    this.subs.push(
      motorpool$
        .pipe(
          map((motorpool) => motorpool.postPutRequestState),
          distinctUntilChanged()
        )
        .subscribe((postPutRequestState) => {
          if (postPutRequestState === REQUEST_SUCCESS) {
            this.setState({
              isPostInProgress: false,
            });
            this.props.close();
          } else if (postPutRequestState === REQUEST_IN_PROGRESS) {
            this.setState({
              isPostInProgress: true,
            });
          }
        })
    );
  }

  doModalClose = () => {
    if (this.props.close) {
      this.props.close();
    }
  };

  onSubmit = () => {
    const form = this.state.form;
    validateForm(form);

    if (!form.isValid) {
      return;
    }

    let iw: ItemWithdrawal = this.props.iw;
    const actionByName: string = this.props.isApproval
      ? "approvedBy"
      : "processedBy";

    const dateName: string = this.props.isApproval
      ? "dateApproved"
      : "dateProcessed";

    const actionType = this.props.isApproval ? APPROVE_IW : PROCESS_IW;

    let iwSignPayload = {
      itemWithdrawalId: this.props.iw.itemWithdrawalId,
    };

    iwSignPayload[actionByName] = form.fields["actionBy"].value;
    iwSignPayload[dateName] = form.fields["dateAction"].value;

    const store = getStore();
    store.dispatch({
      type: actionType,
      payload: {
        iwSignPayload: iwSignPayload,
      },
    });
  };

  onDateSelected = (date: string, fieldName: string) => {
    updateFormFieldValue(this.form$, this.state.form, fieldName, date);
  };

  getFormBlock(props: SignIWProps, state: SignIWState) {
    const form = this.state.form;
    const actionByName: string = props.isApproval
      ? "Approved By"
      : "Processed By";

    const dateName: string = props.isApproval
      ? "Date of Approval"
      : "Date of Processed";

    return (
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div class="col-12 col-lg-6 mt-3">
              <label class="anglo-remittance__submit-form__label">
                {actionByName}*
              </label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["actionBy"]),
                })}
                type="text"
                value={form.fields["actionBy"].value}
                onChange={linkform(this.form$, form, "actionBy")}
                placeholder={actionByName}
              />
            </div>
            <div class="col-12 col-lg-6 mt-3">
              <label class="anglo-remittance__submit-form__label">
                {dateName}*
              </label>

              <DatePicker
                onDateSelected={(returned) =>
                  this.onDateSelected(returned, "dateAction")
                }
                initialValue={form.fields["dateAction"].value}
                isValid={form.fields["dateAction"].isValid}
                isPristine={form.fields["dateAction"].isPristine}
              ></DatePicker>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getActionsRow(props: SignIWProps, state: SignIWState) {
    if (state.isPostInProgress) {
      return getSpinnerBlock(state.isPostInProgress);
    } else {
      return (
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-3 offset-lg-9">
              <button
                class="btn btn-primary w-100"
                onClick={this.onSubmit}
                disabled={!state.form.isValid}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  render(props: SignIWProps, state: SignIWState) {
    return (
      <div
        class="container anglo-modal create-iw-modal"
        ref={(node) => (this.refNode = node)}
      >
        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">
              Approve Item Withdrawal | {props.iw.itemWithdrawalId}
            </h3>
          </div>
          <div class="col-3 col-md-2">
            <button
              class="btn btn-outline-light w-100"
              onClick={this.doModalClose}
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>

        <div class="row anglo-modal__body py-3">
          <div class="col-12 create-withdrawal-modal__body pb-3">
            {this.getFormBlock(props, state)}
          </div>
        </div>

        <div class="row vehicle-card__actions">
          {this.getActionsRow(props, state)}
        </div>
      </div>
    );
  }
}

export const getSignActionFieldName = (signAction: string) => {
  let fieldName: string = "";

  switch (signAction) {
    case SIGN_ACTION_NOTE:
      fieldName = "Noted By";
      break;

    case SIGN_ACTION_REQUEST_APPROVE:
      fieldName = "Request Approved By";
      break;
  }

  return fieldName;
};

export const SIGN_ACTION_NOTE: string = "notedBy";
export const SIGN_ACTION_REQUEST_APPROVE: string = "requestApprovedBy";
export const SIGN_ACTION_ORDER_APPROVED: string = "orderApprovedBy";
