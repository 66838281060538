import { Epic, ofType } from "redux-observable";

import { switchMap, mergeMap, catchError, withLatestFrom } from "rxjs/operators";
import { from, of } from "rxjs";
import Axios from "axios";

//^^ Non-app imports

import { appState$ } from "../../store";
import { API_ENDPOINT } from '../../../../constants/api';
import { REQUEST_SUCCESS, REQUEST_FAILED } from "../../../../types/request-state.model";
import { constructFilters, getGridCount, handleErrorToast } from '../../../utils/effect-utils';
import { PurchaseDocument } from "../../../../types/purchase-document.model";
import { showToast, TOAST_TYPE_ERROR } from "../../../utils/toast.service";

//^^ App-wide imports

import * as MotorpoolActions from './motorpool.actions';
import { MotorpoolAction } from './motorpool.actions';
import { SIGN_ACTION_NOTE, SIGN_ACTION_REQUEST_APPROVE } from "preact/components/motorpool/modal/sign-pd.modal";
import { Supplier } from "../../../../types/supplier.model";
import { Item } from "../../../../types/item.model";
import { V2_JobOrder } from "../../../../types/job-order.model";
import { ItemWithdrawal } from "../../../../types/item-withdrawal.model";

let motorpoolEffects: Epic[] = [];

const getPurchaseDocuments: Epic = getPurchaseDocuments$ => getPurchaseDocuments$.pipe(
  ofType<MotorpoolAction>(MotorpoolActions.GET_PURCHASE_DOCUMENTS),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const filters = constructFilters(action.payload?.filters);
    const promise = Axios.get<PurchaseDocument>(
      `${API_ENDPOINT}/motorpool/purchase${filters}`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const gridData = JSON.parse(JSON.stringify(response.data[0]));
        
        const action: MotorpoolAction = {
          type: MotorpoolActions.GET_PURCHASE_DOCUMENTS_SUCCESS,
          payload: {
            purchaseDocuments: gridData.data,
            pdTotal: getGridCount(gridData),
            pdRequestState: REQUEST_SUCCESS
          }
        };

        return of(action);
      }), 
      catchError((errResponse) => {
        console.log(errResponse);
        const action: MotorpoolAction = {
          type: MotorpoolActions.GET_PURCHASE_DOCUMENTS_FAIL,
          payload: {
            pdRequestState: REQUEST_FAILED
          }
        }

        handleErrorToast(errResponse, 'Failed to retrieve purchase documents');
        return of(action);
      })
    );
  })
);

const getSuppliers: Epic = getSuppliers$ => getSuppliers$.pipe(
  ofType<MotorpoolAction>(MotorpoolActions.GET_SUPPLIERS),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const filters = constructFilters(action.payload?.filters);
    const promise = Axios.get<PurchaseDocument>(
      `${API_ENDPOINT}/motorpool/supplier${filters}`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const gridData = JSON.parse(JSON.stringify(response.data[0]));
        
        const action: MotorpoolAction = {
          type: MotorpoolActions.GET_SUPPLIERS_SUCCESS,
          payload: {
            suppliers: gridData.data,
            total: getGridCount(gridData),
            pdRequestState: REQUEST_SUCCESS
          }
        };

        return of(action);
      }), 
      catchError((errResponse) => {
        console.log(errResponse);
        const action: MotorpoolAction = {
          type: MotorpoolActions.GET_SUPPLIERS_FAIL,
          payload: {
            pdRequestState: REQUEST_FAILED
          }
        }

        handleErrorToast(errResponse, 'Failed to retrieve suppliers');
        return of(action);
      })
    );
  })
);

const createSupplier: Epic = createSupplier$ => createSupplier$.pipe(
  ofType<MotorpoolAction>(MotorpoolActions.CREATE_SUPPLIER),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const supplier: Supplier = action.payload.supplierToCreate;
    
    const promise = Axios.post<Supplier>(
      `${API_ENDPOINT}/motorpool/supplier/create`,
      supplier, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: MotorpoolAction = {
          type: MotorpoolActions.CREATE_SUPPLIER_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Supplier submission',
          message: 'You have successfully submitted a Supplier',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit Supplier',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to submit Supplier',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        } 

        const action: MotorpoolAction = {
          type: MotorpoolActions.CREATE_PURCHASE_DOCUMENT_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const editSupplier: Epic = editSupplier$ => editSupplier$.pipe(
  ofType<MotorpoolAction>(MotorpoolActions.EDIT_SUPPLIER),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const supplier: Supplier = action.payload.supplierToEdit;
    
    const promise = Axios.put<Supplier>(
      `${API_ENDPOINT}/motorpool/supplier/update`,
      supplier, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: MotorpoolAction = {
          type: MotorpoolActions.EDIT_SUPPLIER_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Supplier submission',
          message: 'You have successfully submitted a Supplier',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit Supplier',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to submit Supplier',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        } 

        const action: MotorpoolAction = {
          type: MotorpoolActions.EDIT_SUPPLIER_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const createPd: Epic = createPd$ => createPd$.pipe(
  ofType<MotorpoolAction>(MotorpoolActions.CREATE_PURCHASE_DOCUMENT),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const pd: PurchaseDocument = action.payload.purchaseDocumentToCreate;
    
    const promise = Axios.post<PurchaseDocument>(
      `${API_ENDPOINT}/motorpool/purchase/create`,
      pd, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: MotorpoolAction = {
          type: MotorpoolActions.CREATE_PURCHASE_DOCUMENT_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Purchase Document submission',
          message: 'You have successfully submitted a Purchase Document',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit Purchase Document',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to submit Purchase Document',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        } 

        const action: MotorpoolAction = {
          type: MotorpoolActions.CREATE_PURCHASE_DOCUMENT_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const updatePd: Epic = updatePd$ => updatePd$.pipe(
  ofType<MotorpoolAction>(MotorpoolActions.UPDATE_PURCHASE_DOCUMENT),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const pd: PurchaseDocument = action.payload.purchaseDocumentToUpdate;
    
    const promise = Axios.put<PurchaseDocument>(
      `${API_ENDPOINT}/motorpool/purchase/update`,
      pd, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: MotorpoolAction = {
          type: MotorpoolActions.UPDATE_PURCHASE_DOCUMENT_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Purchase Document update',
          message: 'You have successfully updated a Purchase Document',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to update Purchase Document',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to update Purchase Document',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        } 

        const action: MotorpoolAction = {
          type: MotorpoolActions.UPDATE_PURCHASE_DOCUMENT_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const notePd: Epic = notePd$ => notePd$.pipe(
  ofType<MotorpoolAction>(MotorpoolActions.SIGN_PURCHASE_DOCUMENT),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const purchaseDocumentSignPayload = action.payload.purchaseDocumentSignPayload;
    
    const promise = Axios.put<PurchaseDocument>(
      `${API_ENDPOINT}/motorpool/purchase/${getSignPath(purchaseDocumentSignPayload.signAction)}`,
      purchaseDocumentSignPayload, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: MotorpoolAction = {
          type: MotorpoolActions.UPDATE_PURCHASE_DOCUMENT_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Purchase Document update',
          message: 'You have successfully updated a Purchase Document',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to update Purchase Document',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to update Purchase Document',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        } 

        const action: MotorpoolAction = {
          type: MotorpoolActions.UPDATE_PURCHASE_DOCUMENT_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const createRR: Epic = createRR$ => createRR$.pipe(
  ofType<MotorpoolAction>(MotorpoolActions.CREATE_RECEIVING_REPORT),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const purchaseDocumentId = action.payload.purchaseDocumentId;
    const receivingReport = action.payload.receivingReport;
    
    const promise = Axios.put<PurchaseDocument>(
      `${API_ENDPOINT}/motorpool/purchase/receive-report`,
      {purchaseDocumentId, receivingReport}, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: MotorpoolAction = {
          type: MotorpoolActions.CREATE_RECEIVING_REPORT_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Create Receiving Report',
          message: 'You have successfully updated a Receiving Report',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to Create Receiving Report',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to Create Receiving Report',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        } 

        const action: MotorpoolAction = {
          type: MotorpoolActions.CREATE_RECEIVING_REPORT_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const getItems: Epic = getItems$ => getItems$.pipe(
  ofType<MotorpoolAction>(MotorpoolActions.GET_ITEMS),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const filters = constructFilters(action.payload?.filters);
    const promise = Axios.get<PurchaseDocument>(
      `${API_ENDPOINT}/motorpool/item${filters}`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const gridData = JSON.parse(JSON.stringify(response.data[0]));
        
        const action: MotorpoolAction = {
          type: MotorpoolActions.GET_ITEMS_SUCCESS,
          payload: {
            items: gridData.data,
            total: getGridCount(gridData),
            pdRequestState: REQUEST_SUCCESS
          }
        };

        return of(action);
      }), 
      catchError((errResponse) => {
        console.log(errResponse);
        const action: MotorpoolAction = {
          type: MotorpoolActions.GET_ITEMS_FAIL,
          payload: {
            pdRequestState: REQUEST_FAILED
          }
        }

        handleErrorToast(errResponse, 'Failed to retrieve items');
        return of(action);
      })
    );
  })
);

const createItem: Epic = createItem$ => createItem$.pipe(
  ofType<MotorpoolAction>(MotorpoolActions.CREATE_ITEM),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const item: Item = action.payload.itemToCreate;
    
    const promise = Axios.post<Supplier>(
      `${API_ENDPOINT}/motorpool/item/create`,
      item, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: MotorpoolAction = {
          type: MotorpoolActions.CREATE_ITEM_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Item submission',
          message: 'You have successfully submitted a Item',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit Supplier',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to submit Item',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        } 

        const action: MotorpoolAction = {
          type: MotorpoolActions.CREATE_ITEM_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const getSignPath = (action: string) => {
  let path: string = "";

  switch (action) {
    case SIGN_ACTION_NOTE:
      path = "note";
      break;

    case SIGN_ACTION_REQUEST_APPROVE:
      path = "approve";
      break;
  }

  return path;
}

const createJO: Epic = createJO$ => createJO$.pipe(
  ofType<MotorpoolAction>(MotorpoolActions.CREATE_JO),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const jo: V2_JobOrder = action.payload.joToCreate;
    
    const promise = Axios.post<V2_JobOrder>(
      `${API_ENDPOINT}/motorpool/job-order/create`,
      jo, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: MotorpoolAction = {
          type: MotorpoolActions.CREATE_JO_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Job Order submission',
          message: 'You have successfully submitted a JO',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit Supplier',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to submit JO',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        } 

        const action: MotorpoolAction = {
          type: MotorpoolActions.CREATE_JO_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const updateJO: Epic = updateJO$ => updateJO$.pipe(
  ofType<MotorpoolAction>(MotorpoolActions.UPDATE_V2_JOB_ORDER),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const jo: V2_JobOrder = action.payload.joToUpdate;
    
    const promise = Axios.put<V2_JobOrder>(
      `${API_ENDPOINT}/motorpool/job-order/update`,
      jo, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: MotorpoolAction = {
          type: MotorpoolActions.UPDATE_V2_JOB_ORDER_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Job Order update',
          message: 'You have successfully submitted a JO',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {

        handleErrorToast(errResponse, 'Failed to update JO');

        const action: MotorpoolAction = {
          type: MotorpoolActions.UPDATE_V2_JOB_ORDER_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const getJOs: Epic = getJOs$ => getJOs$.pipe(
  ofType<MotorpoolAction>(MotorpoolActions.GET_JO),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const filters = constructFilters(action.payload?.filters);
    const promise = Axios.get<V2_JobOrder>(
      `${API_ENDPOINT}/motorpool/job-order${filters}`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const gridData = JSON.parse(JSON.stringify(response.data[0]));
        console.log(response.data);
        
        const action: MotorpoolAction = {
          type: MotorpoolActions.GET_JO_SUCCESS,
          payload: {
            jobOrders: gridData.data,
            total: getGridCount(gridData),
            joRequestState: REQUEST_SUCCESS
          }
        };

        return of(action);
      }), 
      catchError((errResponse) => {
        console.log(errResponse);
        const action: MotorpoolAction = {
          type: MotorpoolActions.GET_JO_FAIL,
          payload: {
            joRequestState: REQUEST_FAILED
          }
        }

        handleErrorToast(errResponse, 'Failed to retrieve items');
        return of(action);
      })
    );
  })
);

const createIW: Epic = createIW$ => createIW$.pipe(
  ofType<MotorpoolAction>(MotorpoolActions.CREATE_IW),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const iw: ItemWithdrawal = action.payload.iwToCreate;
    
    const promise = Axios.post<ItemWithdrawal>(
      `${API_ENDPOINT}/motorpool/withdrawal/create`,
      iw, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: MotorpoolAction = {
          type: MotorpoolActions.CREATE_IW_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Job Order submission',
          message: 'You have successfully submitted an Item Withdrawal',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit Item Withdrawal',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to submit Item Withdrawal',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        } 

        const action: MotorpoolAction = {
          type: MotorpoolActions.CREATE_IW_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const updateIW: Epic = updateIW$ => updateIW$.pipe(
  ofType<MotorpoolAction>(MotorpoolActions.UPDATE_IW),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const iw: ItemWithdrawal = action.payload.iwToUpdate;
    
    const promise = Axios.put<ItemWithdrawal>(
      `${API_ENDPOINT}/motorpool/withdrawal/update`,
      iw, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: MotorpoolAction = {
          type: MotorpoolActions.UPDATE_IW_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success withdrawal submission',
          message: 'You have successfully updated a withdrawal',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {

        handleErrorToast(errResponse, 'Failed to update withdrawal');

        const action: MotorpoolAction = {
          type: MotorpoolActions.UPDATE_IW_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const getIWs: Epic = getIWs$ => getIWs$.pipe(
  ofType<MotorpoolAction>(MotorpoolActions.GET_IW),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const filters = constructFilters(action.payload?.filters);
    const promise = Axios.get<ItemWithdrawal>(
      `${API_ENDPOINT}/motorpool/withdrawal${filters}`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const gridData = JSON.parse(JSON.stringify(response.data[0]));
        
        const action: MotorpoolAction = {
          type: MotorpoolActions.GET_IW_SUCCESS,
          payload: {
            itemWithdrawals: gridData.data,
            total: getGridCount(gridData),
            iwRequestState: REQUEST_SUCCESS
          }
        };

        return of(action);
      }), 
      catchError((errResponse) => {
        console.log(errResponse);
        const action: MotorpoolAction = {
          type: MotorpoolActions.GET_IW_FAIL,
          payload: {
            iwRequestState: REQUEST_FAILED
          }
        }

        handleErrorToast(errResponse, 'Failed to retrieve items');
        return of(action);
      })
    );
  })
);

const processIW: Epic = processIW$ => processIW$.pipe(
  ofType<MotorpoolAction>(MotorpoolActions.PROCESS_IW),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const iwSignPayload = action.payload.iwSignPayload;
    
    const promise = Axios.put<PurchaseDocument>(
      `${API_ENDPOINT}/motorpool/withdrawal/process`,
      iwSignPayload, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: MotorpoolAction = {
          type: MotorpoolActions.PROCESS_IW_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success withdrawal update',
          message: 'You have successfully updated an item withdrawal',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        handleErrorToast(errResponse, 'Failed to process withdrawal');

        const action: MotorpoolAction = {
          type: MotorpoolActions.PROCESS_IW_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const approveIW: Epic = approveIW$ => approveIW$.pipe(
  ofType<MotorpoolAction>(MotorpoolActions.APPROVE_IW),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const iwSignPayload = action.payload.iwSignPayload;
    
    const promise = Axios.put<PurchaseDocument>(
      `${API_ENDPOINT}/motorpool/withdrawal/approve`,
      iwSignPayload, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: MotorpoolAction = {
          type: MotorpoolActions.APPROVE_IW_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success withdrawal update',
          message: 'You have successfully updated an item withdrawal',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        handleErrorToast(errResponse, 'Failed to process withdrawal');

        const action: MotorpoolAction = {
          type: MotorpoolActions.APPROVE_IW_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const createPO: Epic = createPO$ => createPO$.pipe(
  ofType<MotorpoolAction>(MotorpoolActions.CREATE_PO),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const pd: PurchaseDocument = action.payload.purchaseDocumentSignPayload;
    
    const promise = Axios.put<PurchaseDocument>(
      `${API_ENDPOINT}/motorpool/purchase/order`,
      pd, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: MotorpoolAction = {
          type: MotorpoolActions.CREATE_PO_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success create Purchase Order',
          message: 'You have successfully created Purchase Order',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        handleErrorToast(errResponse, 'Failed to create Purchase Order');

        const action: MotorpoolAction = {
          type: MotorpoolActions.CREATE_PO_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const approvePO: Epic = approvePO$ => approvePO$.pipe(
  ofType<MotorpoolAction>(MotorpoolActions.APPROVE_PO),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const pd: PurchaseDocument = action.payload.purchaseDocumentSignPayload;
    
    const promise = Axios.put<PurchaseDocument>(
      `${API_ENDPOINT}/motorpool/purchase/approve-order`,
      pd, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: MotorpoolAction = {
          type: MotorpoolActions.APPROVE_PO_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success approve Purchase Order',
          message: 'You have successfully approved Purchase Order',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        handleErrorToast(errResponse, 'Failed to approve Purchase Order');

        const action: MotorpoolAction = {
          type: MotorpoolActions.APPROVE_PO_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

motorpoolEffects.push(getPurchaseDocuments);
motorpoolEffects.push(getSuppliers);
motorpoolEffects.push(createPd);
motorpoolEffects.push(updatePd);
motorpoolEffects.push(notePd);
motorpoolEffects.push(createRR);
motorpoolEffects.push(getItems);
motorpoolEffects.push(createSupplier);
motorpoolEffects.push(editSupplier);
motorpoolEffects.push(createItem);
motorpoolEffects.push(createJO);
motorpoolEffects.push(getJOs);
motorpoolEffects.push(updateJO);
motorpoolEffects.push(createPO);
motorpoolEffects.push(approvePO);

motorpoolEffects.push(createIW);
motorpoolEffects.push(getIWs);
motorpoolEffects.push(updateIW);
motorpoolEffects.push(processIW);
motorpoolEffects.push(approveIW);


export default motorpoolEffects;