import { Agent } from "agent.model";

export const LOAN_STATUS_UNPAID = 0; 
export const LOAN_STATUS_PENDING = 1;
export const LOAN_STATUS_FULLY_PAID = 2;
export const LOAN_STATUS_REJECTED = 3;

export function getLoanStatusString(status: number) {
  let statusAsString: string;

  switch (status) {
    case LOAN_STATUS_UNPAID:
      statusAsString = 'unpaid';
      break;

    case LOAN_STATUS_PENDING:
      statusAsString = 'pending';
      break;

    case LOAN_STATUS_FULLY_PAID:
      statusAsString = 'fully paid';
      break;

    case LOAN_STATUS_REJECTED:
      statusAsString = 'rejected';
      break;
  }

  return statusAsString;
}

export interface LoanStatusUpdate {
  loanId?: string;
  status?: string;
}

export interface Loan {
  loanId?: string;
  amount?: number;
  remaining?: number;
  agentId?: string;
  agent?: Agent;
  supervisorUsername?: string;
  sectorId?: string;

  status?: number;
  dateApproved?: string;

  description?: string;
  responseReason?: string;
  dateCreated?: string;
}

export interface LoanPayment {
  loanPaymentId?: string;
  loanId?: string;
  amount?: number;
  agent?: Agent;
  supervisor?: string;
  sectorId?: string;
  status?: string;
  
  receivingBankAccount?: string;
  dateOfTransaction?: string;
  receiptNumber?: string;
  dateCreated?: string;
}

export interface LoanPaymentUpdate {
  loanId?: string;
  paymentId?: string;
  amount?: number;
}