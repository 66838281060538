import { h, Component } from 'preact';
import cx from 'classnames';
import Portal from 'preact-portal';
import { Subscription, Subject } from 'rxjs';
import Axios, { AxiosPromise } from 'axios';

import { debounce } from '../../../utils/debounce';
import { API_ENDPOINT } from '../../../../constants/api';
import { showToast } from '../../../utils/toast.service';
import { Sector } from '../../../../types/sector.model';

export interface AutocompleteSectorProps {
  searchFunction?: Function; 
  // should be using this
  // but I'm making it a direct call for now

  searchUrl?: string;

  onResultSelected: Function;
  disabled?: boolean;
  initialValue?: string;
}

export interface AutocompleteSectorState {
  searchResults?: Sector[];
  search?: string;
  isSearchResultsVisible?: boolean;
}

export class AutocompleteSector extends Component<AutocompleteSectorProps, AutocompleteSectorState> {

  constructor(props: AutocompleteSectorProps) {
    super(props);

    this.setState({
      searchResults: [],
    });

    if (props.initialValue) {
      this.setState({
        search: props.initialValue
      });
    }
  }

  onSearchInputChange = debounce((event) => {

    const searchTerm = event.target.value;

    if (searchTerm && searchTerm.length >= 2) {
      this.getSearchResults(searchTerm);
    } 
    
    this.setState({
      search: searchTerm
    });

  }, 500);

  getSearchResults(searchTerm?: string) {
    let params = {
      sectorName: searchTerm
    };

    const axiosPromise = Axios.get(
      `${API_ENDPOINT}/sector`,
      {
        params: params,
        withCredentials: true
      }
    );

    axiosPromise.then((response) => {
      const results = response.data.results;

      this.setState({
        searchResults: results,
        isSearchResultsVisible: true
      });
    }).catch(errResp => {
      let message = errResp.response.data.msg ? errResp.response.data.msg : null;
      
      showToast({
        title: 'Failed to retrieve search',
        message: message,
        duration: 3000
      });
    })

  }

  onResultClick = (result: Sector) => {
    const label = result.name;

    this.setState({
      search: label,
      searchResults: [],
      isSearchResultsVisible: false,
    })

    this.props.onResultSelected(result);
  }

  render(props: AutocompleteSectorProps, state: AutocompleteSectorState) {
    const result = (member, index) => {
      return <p onClick={this.onResultClick.bind(this, member)}> 
       { member.name }
      </p>;
    }

    return (
      <div class="autocomplete-search">
        <input type="text"
          className={ cx('autocomplete-search__input form-control') }
          disabled={ props.disabled } 
          
          placeholder="Search for an area/sector"
          value={state.search}
          onInput={this.onSearchInputChange.bind(this)}
        />
        <span class="autocomplete-search__icon">
          <i class="fas fa-search"></i>
        </span>
        <div className={cx('autocomplete-search__results', {active : state.isSearchResultsVisible})}>
          { this.state.searchResults.map((member, i) => result(member, i)) }
        </div>
      </div>
    )
  }

}