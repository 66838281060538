import { h, Component } from 'preact';
import cx from 'classnames';
import getStore, { appState$ } from '../../store';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import moment from 'moment-timezone';

import { DatePicker } from '../common/date-picker';
import { AutocompleteSearch } from '../common/autocomplete-search.component';
import { User } from '../../../../types/user.model';
import { MicroscopeAction, RETRIEVE_GRAPH_DATA } from '../../reducers/microscope/microscope.actions';
import { RemittanceConfigOptions, RemittanceConfigParameters, RemittanceConfig } from '../../../../types/microscope.model';
import { REQUEST_SUCCESS } from '../../../../types/request-state.model';
import { toFixed } from '../../../utils/decimal';
import { AutocompleteSector } from '../common/autocomplete-sector.component';
import { Sector } from '../../../../types/sector.model';

const MONTH_SELECTION = 0;
const SUPERVISOR_SELECTION = 1;
const AREA_SELECTION = 2;
const AGENT_SELECTION = 3;
const STATUS_SELECTION = 4;

const MONTH_TYPE = 'month';
const SUPERVISOR_TYPE = 'supervisor';
const AREA_TYPE = 'area';
const AGENT_TYPE = 'agent';
const STATUS_TYPE = 'status';

const colors: string[] = [
  '#215B8A',
  '#f25b15',
  '#1b9b85',
  '#d7423e',
  '#0C8A38'
];

interface MicroscopeRemittanceState {
  gridType?: string;

  isByMonthSelected?: boolean;
  isBySupervisorSelected?: boolean;
  isByAreaSelected?: boolean;
  isByAgentSelected?: boolean;
  isByStatusSelected?: boolean;

  typesSelected?: number;

  startDate?: string;
  endDate?: string;
  selectedSupervisors?: User[];
  selectedAreas?: string[];

  isRetrieveDataSuccess?: boolean;
  graphData?: any[];
  graphConfig?: RemittanceConfig;
  dataChart?: Chart;
}

export class MicroscopeRemittance extends Component<any, MicroscopeRemittanceState> {
  chartNode: any;

  constructor(props) {
    super(props);

    this.setState({
      typesSelected: 0,
      selectedSupervisors: [],
      selectedAreas: []
    });
  }

  componentDidMount() {
    const microscope$ = appState$.pipe(
      map(appState => appState.microscope),
      distinctUntilChanged()
    );

    microscope$.pipe(
      map(microscope => microscope)
    ).subscribe((microscope) => {
      console.log(microscope.remittanceGraphData);

      if (microscope.retrieveGraphDataStatus === REQUEST_SUCCESS) {
        this.setState({
          isRetrieveDataSuccess: true,
          graphData: microscope.remittanceGraphData,
          graphConfig: microscope.remittanceConfig
        });

        this.createChart();
      }

    });
  }

  getMonthString(month: number, year: number) {
    switch(month) {
      case 1:
        return `January ${year}`;
      case 2: 
        return `February ${year}`;
      case 3: 
        return `March ${year}`;
      case 4: 
        return `April ${year}`;
      case 5: 
        return `May ${year}`;
      case 6: 
        return `June ${year}`;
      case 7: 
        return `July ${year}`;
      case 8: 
        return `August ${year}`;
      case 9: 
        return `September ${year}`;
      case 10: 
        return `October ${year}`;
      case 11: 
        return `November ${year}`;
      case 12: 
        return `December ${year}`;
    }
  }

  constructLabels() {
    const options = this.state.graphConfig.options;
    
    if (options.dataTypes.length == 2) {

      if (options.dataTypes.includes(MONTH_TYPE) && 
        options.dataTypes.includes(SUPERVISOR_TYPE)) {
        let labels = [];

        const firstSupervisor = this.state.graphData[0];
        const totals = firstSupervisor['totals']

        totals.forEach((m, index) => {
          console.log('building label ' + index, m);
          labels.push(this.getMonthString(m.month, m.year));
        });

        return labels;
      }

      if (options.dataTypes.includes(MONTH_TYPE) && 
        options.dataTypes.includes(AREA_TYPE)) {
        let labels = [];

        const firstArea = this.state.graphData[0];
        const totals = firstArea['totals']

        totals.forEach((m, index) => {
          console.log('building label ' + index, m);
          labels.push(this.getMonthString(m.month, m.year));
        });

        return labels;
      }

      if (options.dataTypes.includes(SUPERVISOR_TYPE) && 
        options.dataTypes.includes(AREA_TYPE)) {
        let labels = [];

        this.state.graphData.forEach((m, index) => {
          let totals: any[] = m['totals'];
          totals.forEach((total) => {
            labels.push(total.area);
          })
        });

        return labels;
      }

    } else {
      const dataType = options.dataTypes[0];

      if (dataType === MONTH_TYPE) {
        return this.state.graphData.map(m => {
          return this.getMonthString(m['_id'].month, m['_id'].year);
        });
      }

      if (dataType === SUPERVISOR_TYPE) {
        return this.state.graphData.map(m => {
          return m['_id'];
        });
      }

      if (dataType === AREA_TYPE) {
        return this.state.graphData.map(m => {
          return m['_id'];
        });
      }

    }
  }

  constructDatasets(labels?: any[]) {
    const options = this.state.graphConfig.options;

    if (options.dataTypes.length == 2) {

      if (options.dataTypes.includes(MONTH_TYPE) && 
        options.dataTypes.includes(SUPERVISOR_TYPE)) {
        let dataSets = [];

        this.state.graphData.forEach((m, index) => {
          let dataSet: any = {};
          const totals: any[] = m.totals;

          dataSet.data = totals.map(a => {
            // console.log(a);
            return a['totalAmount']
          });

          dataSet.label = m['_id'];
          dataSet.backgroundColor = colors[index];

          dataSets.push(dataSet);
        });

        console.log('dataSets', dataSets);

        return dataSets;
      }

      if (options.dataTypes.includes(MONTH_TYPE) && 
        options.dataTypes.includes(AREA_TYPE)) {
        let dataSets = [];

        this.state.graphData.forEach((m, index) => {
          let dataSet: any = {};
          const totals: any[] = m.totals;

          dataSet.data = totals.map(a => {
            return a['totalAmount']
          });

          dataSet.label = m['_id'];
          dataSet.backgroundColor = colors[index];

          dataSets.push(dataSet);
        });

        console.log('dataSets', dataSets);

        return dataSets;
      }

      if (options.dataTypes.includes(SUPERVISOR_TYPE) && 
        options.dataTypes.includes(AREA_TYPE)) {
        let dataSets = [];

        this.state.graphData.forEach((m, index) => {
          let dataSet: any = {};
          dataSet.data = [];
          const totals: any[] = m.totals;

          labels.forEach((label: string) => {
            const found = totals.find(total => {
              return total.area === label;
            });

            if (found) {
              dataSet.data.push(found['totalAmount']);
            } else {
              dataSet.data.push(0);
            }
          });

          dataSet.label = m['_id'];
          dataSet.backgroundColor = colors[index];

          dataSets.push(dataSet);
        });

        console.log('dataSets', dataSets);

        return dataSets;
      }

    } else {
      const dataType = options.dataTypes[0];
      let dataSet: any = {};

      if (dataType === MONTH_TYPE) {
        dataSet.data = this.state.graphData.map(m => {
          return m['totalAmount']
        });

        dataSet.backgroundColor = colors[0];
      }

      if (dataType === SUPERVISOR_TYPE) {
        dataSet.data = this.state.graphData.map(m => {
          return m['totalAmount']
        });

        dataSet.backgroundColor = colors[0];
      }

      if (dataType === AREA_TYPE) {
        dataSet.data = this.state.graphData.map(m => {
          return m['totalAmount']
        });

        dataSet.backgroundColor = colors[1];
      }


      return [dataSet];
    }
  }

  // We do this to ensure lazy loading.
  // If chartjs is imported directly, it will be part of the main module
  // and bloat the whole thing.
  createChart() {
    if (this.state.dataChart) {
      // this.state.dataChart.resize();
      this.state.dataChart.destroy();
      // this.chartNode
    }

    import('chart.js').then((Chart: any) => {
      import('chartjs-plugin-datalabels').then(() => {
        // let test = lol.map();
        const labels = this.constructLabels();
        const datasets = this.constructDatasets(labels);

        const chart = new Chart(this.chartNode, {
          type: 'bar', // default to bar graph
          data: {
            labels: labels,
            datasets: datasets
          },
          options: {
            responsive: false,
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  callback: (value, index, values) => {
                    return `₱ ${toFixed(value)}`;
                  }
                }                
              }]
            },
            legend: {
              display: false
            },
            plugins: {
              datalabels: {
                align: 'end',
                backgroundColor: function(context) {
                  return context.dataset.backgroundColor;
                },
                borderColor: 'white',
                borderRadius: 25,
                borderWidth: 2,
                color: 'white',
                font: {
                  weight: 'bold'
                },
                formatter: function(value) {
                  return `₱ ${toFixed(value)} `;
                }
              }
            }
          }
        });

        chart.resize();

        this,this.setState({
          dataChart: chart
        });
      })
    })
  }

  onDataSelectionChange = (type: number) => {

    switch(type) {
      case MONTH_SELECTION:
        if (this.state.isByMonthSelected) {
          // being deactivated
          this.setState({
            isByMonthSelected: !this.state.isByMonthSelected,
            typesSelected: this.state.typesSelected - 1,
            startDate: '',
            endDate: ''
          });
        } else if (this.state.typesSelected < 2) {
          // activating
          this.setState({
            isByMonthSelected: !this.state.isByMonthSelected,
            typesSelected: this.state.typesSelected + 1
          });
        }
        break;

      case AREA_SELECTION:
        if (this.state.isByAreaSelected) {
          // being deactivated
          this.setState({
            isByAreaSelected: !this.state.isByAreaSelected,
            typesSelected: this.state.typesSelected - 1
          });
        } else if (this.state.typesSelected < 2) {
          // activating
          this.setState({
            isByAreaSelected: !this.state.isByAreaSelected,
            typesSelected: this.state.typesSelected + 1
          });
        }
        break;

      case SUPERVISOR_SELECTION:
        if (this.state.isBySupervisorSelected) {
          // being deactivated
          this.setState({
            isBySupervisorSelected: !this.state.isBySupervisorSelected,
            typesSelected: this.state.typesSelected - 1,
            selectedSupervisors: []
          });
        } else if (this.state.typesSelected < 2) {
          // activating
          this.setState({
            isBySupervisorSelected: !this.state.isBySupervisorSelected,
            typesSelected: this.state.typesSelected + 1
          });
        }
        break;

      case AGENT_SELECTION:
        if (this.state.isByAgentSelected) {
          // being deactivated
          this.setState({
            isByAgentSelected: !this.state.isByAgentSelected,
            typesSelected: this.state.typesSelected - 1
          });
        } else if (this.state.typesSelected < 2) {
          // activating
          this.setState({
            isByAgentSelected: !this.state.isByAgentSelected,
            typesSelected: this.state.typesSelected + 1
          });
        }
        break;

      case STATUS_SELECTION:
        if (this.state.isByStatusSelected) {
          // being deactivated
          this.setState({
            isByStatusSelected: !this.state.isByStatusSelected,
            typesSelected: this.state.typesSelected - 1
          });
        } else if (this.state.typesSelected < 2) {
          // activating
          this.setState({
            isByStatusSelected: !this.state.isByStatusSelected,
            typesSelected: this.state.typesSelected + 1
          });
        }
        break;
    }
  }

  onStartDateSelected = (date: string) => {
    this.setState({
      startDate: date
    })
  }

  onEndDateSelected = (date: string) => {
    this.setState({
      endDate: date
    })
  }

  onSupervisorSearchChange = (user: User) => {
    let supervisors = this.state.selectedSupervisors;

    const found = supervisors.find((member) => {
      return member.username === user.username;
    });

    // make sure we don't have duplicates
    if (!found) {
      supervisors.push(user);
    }

    this.setState({
      selectedSupervisors: supervisors
    });
  }

  onRemoveSupervisor = (index: number) => {
    let supervisors = this.state.selectedSupervisors;
    supervisors.splice(index, 1);

    this.setState({
      selectedSupervisors: supervisors
    });
  }

  onAreaSearchChange = (sector: Sector) => {
    let areas = this.state.selectedAreas;
    const found = areas.includes(sector.name);

    // make sure we don't have duplicates
    if (!found) {
      areas.push(sector.name);
    }

    this.setState({
      selectedAreas: areas
    });
  }

  onRemoveArea = (index: number) => {
    let areas = this.state.selectedAreas;
    areas.splice(index, 1);

    this.setState({
      selectedAreas: areas
    });
  }
  
  getSupervisorUsernames(): string[] {
    return this.state.selectedSupervisors.map(user => user.username);
  }

  getGridDataTypes(): string[] {
    let types: string[] = [];

    if (this.state.isByMonthSelected) {
      types.push(MONTH_TYPE);
    }

    if (this.state.isBySupervisorSelected) {
      types.push(SUPERVISOR_TYPE);
    }

    if (this.state.isByAreaSelected) {
      types.push(AREA_TYPE);
    }

    if (this.state.isByAgentSelected) {
      types.push(AGENT_TYPE);
    }

    if (this.state.isByStatusSelected) {
      types.push(STATUS_TYPE);
    }

    return types;
  }

  onConstruct = () => {
    console.log('constructing data', this);
    const dataTypes = this.getGridDataTypes();

    let options: RemittanceConfigOptions = {
      dataTypes: dataTypes
    };

    const supervisors = this.getSupervisorUsernames();

    let parameters: RemittanceConfigParameters = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      supervisors: supervisors,
      areas: this.state.selectedAreas
    }

    const store = getStore();
    let action: MicroscopeAction = {
      type: RETRIEVE_GRAPH_DATA,
      payload: {
        remittanceConfig: {
          options: options,
          parameters: parameters
        }
      }
    }

    store.dispatch(action);
  }

  getMonthParametersBlock(state: MicroscopeRemittanceState) {
    if (state.isByMonthSelected) {
      return (
        <div class="microscope-remittance__controls__params__month param">
          <div class="row">
            <div class="col-12">
              <label class="microscope-remittance__controls__options__label">
                Start date
              </label>
              <DatePicker
                onDateSelected={this.onStartDateSelected}
                isValid={true}>
              </DatePicker>
            </div>

            <div class="col-12 mt-2">
              <label class="microscope-remittance__controls__options__label">
                End date
              </label>
              <DatePicker
                onDateSelected={this.onEndDateSelected}
                isValid={true}>
              </DatePicker>
            </div>
          </div>
        </div>
      );
    }
  }

  getSupervisorParametersBlock(state: MicroscopeRemittanceState) {
    if (state.isBySupervisorSelected) {
      return (
        <div class="microscope-remittance__controls__params__supervisor param">
          <label class="microscope-remittance__controls__options__label">
            Supervisors
          </label>
          <div class="microscope-remittance__controls__params__supervisor__selected">
            {
              state.selectedSupervisors.map((member, index) => {
                return (
                  <div class="anglo-chip">
                    { member.firstName } { member.lastName }
                    <button class="anglo-chip__icon-btn" onClick={() => this.onRemoveSupervisor(index)}>
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                );
              })
            }
          </div>
          <AutocompleteSearch onResultSelected={this.onSupervisorSearchChange} />
        </div>
      );
    }
  }

  getAreaParametersBlock(state: MicroscopeRemittanceState) {
    if (state.isByAreaSelected) {
      return (
        <div class="microscope-remittance__controls__params__area param">
          <label class="microscope-remittance__controls__options__label">
            Areas
          </label>
          <div class="microscope-remittance__controls__params__area__selected">
            {
              state.selectedAreas.map((member, index) => {
                return (
                  <div class="anglo-chip">
                    { member }
                    <button class="anglo-chip__icon-btn" onClick={() => this.onRemoveArea(index)}>
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                );
              })
            }
          </div>
          <AutocompleteSector onResultSelected={this.onAreaSearchChange}/>
        </div>
      );
    }
  }

  getDataRenderBlock(state: MicroscopeRemittanceState) {
    if (state.isRetrieveDataSuccess) {
      return (
        <div class="microscope-remittance__data">
          <canvas class="chart__canvas" id="mortgageChart"
            ref={node => this.chartNode = node}>
          </canvas>
        </div>
      );
    }
  }

  render(props, state: MicroscopeRemittanceState) {
    return (
      <div class="microscope-remittance">
        <div class="container-fluid">

          <div class="row">
            <div class="col-8 col-lg-4">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/remittance">Remittance</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Remittance Microscope</li>
                </ol>
              </nav>
            </div>
          </div>  

          <div class="row">
            <div class="col-12 col-lg-8 offset-lg-2 mb-3">
              <div class="microscope-remittance__controls row">
                <div class="microscope-remittance__controls__options col-12 col-lg-6">
                  <h2 class="microscope-remittance__controls__options__head">
                    Options
                  </h2>
                  <p class="microscope-remittance__controls__options__label text-center mb-4">
                    Select the options and data types for graph behavior
                  </p>

                  <div class="microscope-remittance__controls__options__grid-type">
                  </div>

                  <p class="microscope-remittance__controls__options__label">
                    Only a maximum of 2 data types can be selected.
                  </p>
                  <div class="microscope-remittance__controls__options__data-selection">
                    <button className={cx(
                      'microscope-remittance__controls__options__data-selection__btn',
                      {
                        'active': state.isByMonthSelected
                      }
                    )}
                        onClick={() => this.onDataSelectionChange(MONTH_SELECTION)}>
                      By Month
                    </button>

                    <button className={cx(
                      'microscope-remittance__controls__options__data-selection__btn',
                      {
                        'active': state.isBySupervisorSelected
                      }
                    )}
                        onClick={() => this.onDataSelectionChange(SUPERVISOR_SELECTION)}>
                      By Supervisor
                    </button>

                    <button className={cx(
                      'microscope-remittance__controls__options__data-selection__btn',
                      {
                        'active': state.isByAreaSelected
                      }
                    )}
                        onClick={() => this.onDataSelectionChange(AREA_SELECTION)}>
                      By Area
                    </button>

                    {/* <button className={cx(
                      'microscope-remittance__controls__options__data-selection__btn',
                      {
                        'active': state.isByAgentSelected
                      }
                    )}
                        onClick={() => this.onDataSelectionChange(AGENT_SELECTION)}>
                      By Agent
                    </button>

                    <button className={cx(
                      'microscope-remittance__controls__options__data-selection__btn',
                      {
                        'active': state.isByStatusSelected
                      }
                    )}
                        onClick={() => this.onDataSelectionChange(STATUS_SELECTION)}>
                      By Status
                    </button> */}
                  </div>
                </div>
                
                <div class="microscope-remittance__controls__params col-12 col-lg-6">
                  <h2 class="microscope-remittance__controls__params__head">
                    Parameters
                  </h2>
                  <p class="microscope-remittance__controls__options__label text-center mb-4">
                    Input parameters that help control data computation and graph
                    construction
                  </p>

                  { this.getMonthParametersBlock(state) }
                  { this.getSupervisorParametersBlock(state) }
                  { this.getAreaParametersBlock(state) }
                </div>
              </div>
            </div>
            <div class="col-8 offset-2 d-flex justify-content-end">
              <button class="btn btn-secondary" onClick={this.onConstruct}>
                Construct
              </button>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-12">
              { this.getDataRenderBlock(state) }
            </div>
          </div>

        </div> 
      </div>
    );
  }

}