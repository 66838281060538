import { User } from "user.model";
import { Expense } from "expense.model";

export const WITHDRAWAL_STATUS_PENDING = 'pending';
export const WITHDRAWAL_STATUS_APPROVED = 'approved';
export const WITHDRAWAL_STATUS_DENIED = 'denied';
export const WITHDRAWAL_STATUS_REEDIT = 'reedit';

// not sure if this status is needed
export const WITHDRAWAL_STATUS_REEDIT_ACCEPTED = 'reedit-accepted';

export const WITHDRAWAL_STATUS_COMPLETE = 'complete';

export const WITHDRAWAL_STAGE_APPROVAL_PENDING = 'approval-pending';
export const WITHDRAWAL_STAGE_STOCK_PREPARING = 'stock-preparing';

// walk-in stages
export const WITHDRAWAL_STAGE_READY_FOR_PICKUP = 'ready-for-pickup';
export const WITHDRAWAL_STAGE_PICKED_UP = 'picked-up';

// provincial stages
export const WITHDRAWAL_STAGE_AWAITING_SHIPMENT = 'awaiting-shipment';
export const WITHDRAWAL_STAGE_IN_TRANSIT = 'in-transit';
export const WITHDRAWAL_STAGE_SHIPMENT_RECEIVED = 'shipment-received';

export interface Withdrawal {
  withdrawalId: string;
  supervisor: string;

  dateCreated: Date,
  dateOfShipment?: Date,
  dateOfWithdrawal?: Date,
  maxDateOfReturn?: Date,

  status: string;
  stage: string;
  brands: WithdrawalBrandGroup[];
  totalAmount: number;
  response?: string;
  user?: User;
}

export interface WithdrawalBrand {
  name: string;
  brandPriceId?: string;
  numOfPacks?: number;
  numOfReams?: number;
  numOfCases?: number;

  pricePerReam?: number;
}

export interface WithdrawalBrandGroup {
  brands: WithdrawalBrand[];
  agentName?: string;
  agentId?: string;
}

