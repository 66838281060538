import { h, Component } from 'preact';
import Portal from 'preact-portal';

import { Subject, Subscription } from 'rxjs';

import { EDIT_REQUEST_PENDING, EDIT_REQUEST_APPROVED, EDIT_REQUEST_DECLINED } from '../../../../constants/edit-requests';

import { User } from '../../../../types/user.model';
import { Vehicle } from '../../../../types/vehicle.model';
import { EditVehicleModal } from './edit-vehicle.modal';
import { FileReportModal } from './file-report.modal';
import { CreateJobOrderModal } from '../motorpool/create-job-order.modal';
import { ViewHistoryModal } from '../motorpool/view-history.modal';
import { ROLE_MANAGEMENT, ROLE_VEHICLE_MANAGER } from '../../../../constants/role';

export interface VehicleCardProps {
  vehicle: Vehicle;
  currentUser: User;
  isDiagnostic?: boolean;
}

interface VehicleCardState  {

  // if set to -1, close all
  modalState?: number;

  isModalOpen?: boolean;
  isFileReportOpen?: boolean;
  isDiagosticOpen?: boolean;
  isJobOrderOpen?: boolean;
  modalId?: string;
}

export class VehicleCard extends Component<VehicleCardProps, VehicleCardState> {
  subs: Subscription[] = [];

  private MODAL_EDIT_VEHICLE: number = 0;
  private MODAL_FILE_REPORT: number = 1;
  private MODAL_DIAGNOSTIC: number = 2;
  private MODAL_CREATE_JOB_ORDER: number = 3;

  constructor(props: VehicleCardProps) {
    super(props);
  }

  componentDidMount() {
  }

  getStatusLabel(status: number) {
    switch(status) {
      case EDIT_REQUEST_PENDING:
        return 'Pending';

      case EDIT_REQUEST_APPROVED:
        return 'Approved';

      case EDIT_REQUEST_DECLINED:
        return 'Declined';
    }
  }

  getDiagnosticModal(state: VehicleCardState) {
    if (state.modalState === this.MODAL_DIAGNOSTIC) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container scrollable" id={state.modalId}>
            <ViewHistoryModal 
              currentUser={this.props.currentUser}
              vehicle={this.props.vehicle}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId={state.modalId}/>
          </div>
        </Portal>
      );
    }
  }

  getPortalModal(state: VehicleCardState) {
    if (state.modalState < 0) {
      return;
    }

    if (state.modalState === this.MODAL_EDIT_VEHICLE) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id={state.modalId}>
            <EditVehicleModal 
              currentUser={this.props.currentUser}
              vehicle={this.props.vehicle}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId={state.modalId}/>
          </div>
        </Portal>
      );
    } else if (state.modalState === this.MODAL_FILE_REPORT) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container scrollable" id={state.modalId}>
            <FileReportModal 
              currentUser={this.props.currentUser}
              vehicle={this.props.vehicle}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId={state.modalId}/>
          </div>
        </Portal>
      );
    } else if (state.modalState === this.MODAL_CREATE_JOB_ORDER) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container scrollable" id={state.modalId}>
            <CreateJobOrderModal
              vehicle={this.props.vehicle}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId="anglo-modal-111"/>
          </div>
        </Portal>
      );
    } 
  }

  onModalClose = () => {
    this.setState({
      modalState: -1
    });
  }

  onModalOpened = (paramState: number) => {
    const random = Math.floor(Math.random()*200);

    this.setState({
      modalState: paramState,
      modalId: `anglo-modal-${random}`
    });
  }

  onJobOrderModalOpen = () => {
    const random = Math.floor(Math.random()*200);

    this.setState({
      isModalOpen: false,
      isFileReportOpen: false,
      isDiagosticOpen: false,
      isJobOrderOpen: true,
      modalId: `anglo-modal-${random}`
    });
  }

  getActionsBlock(props: VehicleCardProps) {
    if (props.isDiagnostic) {
      return (
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <button class="btn btn-primary w-100" onClick={() => this.onModalOpened(this.MODAL_DIAGNOSTIC)}>
                View vehicle history
              </button>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <button class="btn btn-primary w-100" onClick={() => this.onModalOpened(this.MODAL_CREATE_JOB_ORDER)}>
                Create Job Order
              </button>
            </div>
          </div>
        </div>
      );
    }
    else if (props.currentUser.role === ROLE_MANAGEMENT || props.currentUser.role === ROLE_VEHICLE_MANAGER) {
      return (
        <div class="col-12">
          <button class="btn btn-primary w-100" onClick={() => this.onModalOpened(this.MODAL_EDIT_VEHICLE)}>
            Edit Vehicle
          </button>
        </div>
      );
    } else {
      return (
        <div class="col-12">
          <button class="btn btn-primary w-100"
            onClick={() => this.onModalOpened(this.MODAL_FILE_REPORT)}>
            File Update
          </button>
        </div>
      );
    }
  }

  render(props: VehicleCardProps, state: VehicleCardState) {
    return (
      <div class="vehicle-card">

        { this.getPortalModal(state) }
        { this.getDiagnosticModal(state) }

        <div class="vehicle-card__head pb-3">
          <div class="col-12">
            Vehicle ID { props.vehicle.vehicleId }
          </div>
        </div>

        <div class="row vehicle-card__body mt-3"> 
          <div class="col-6 mb-3">
            <div class="vehicle-card__body__label">
              Status
            </div>
            <div class="vehicle-card__body__value">
              { props.vehicle.status }
            </div>
          </div>

          <div class="col-6 mb-3">
            <div class="vehicle-card__body__label">
              Plate Number
            </div>
            <div class="vehicle-card__body__value">
              { props.vehicle.plateNumber }
            </div>
          </div>

          <div class="col-6 mb-3">
            <div class="vehicle-card__body__label">
              Model/Make
            </div>
            <div class="vehicle-card__body__value">
              { props.vehicle.modelMake }
            </div>
          </div>

          <div class="col-6 mb-3">
            <div class="vehicle-card__body__label">
              Lodging Location
            </div>
            <div class="vehicle-card__body__value">
              { props.vehicle.lodgingLocation ? props.vehicle.lodgingLocation : 'Not available' }
            </div>
          </div>
        </div>

        {
          props.currentUser.role === 'role_management' ?
            <div class="vehicle-card__body__assigned mt-3"> 
              <div class="vehicle-card__body__label">
                Assigned to
              </div>
              <div class="vehicle-card__body__value">
                { props.vehicle.user.firstName } { props.vehicle.user.lastName }
              </div>
            </div> : null
        }

        <div class="row vehicle-card__actions mt-3">
          {
            this.getActionsBlock(props)
          }
        </div>
      </div>
    );
  }
}