import { h, Component } from 'preact';
import cx from 'classnames';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import linkState from 'linkstate';
import moment from 'moment-timezone';

import { ModalProps, Modal } from '../../common/modal';
import { Form, FieldList, Field } from '../../../../../types/form.model';
import * as Validators from '../../../../../types/validator.model';
import { validateForm, cxValidateField } from '../../../../../types/validator.model';
import linkform, { updateFormFieldValue } from '../../../../utils/linkform';

import getStore, { appState$ } from '../../../store';
import { CREATE_LOAN_PAYMENT } from '../../../reducers/cashbond-loan/cashbond-loan.actions';
import { REQUEST_FAILED, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../../types/request-state.model';

import { Sector } from '../../../../../types/sector.model';
import { GET_BANKS, UserAction } from '../../../reducers/user/user.actions';
import { User } from '../../../../../types/user.model';
import { Loan, LoanPayment } from '../../../../../types/loan.model';
import { BankAccount } from '../../../../../types/bank-account.model';
import { DatePicker } from '../../common/date-picker';

export interface CreateLoanPaymentModalProps extends ModalProps {
    currentUser?: User;
    loan?: Loan;
}

interface CreateLoanPaymentModalState {
  loanPaymentForm?: Form;
  isFormVisible?: boolean;
  isEditing?: boolean;
  isPostInProgress?: boolean;
  
  allBankAccounts: BankAccount[];
}

export class CreateLoanPaymentModal extends Modal<CreateLoanPaymentModalProps, CreateLoanPaymentModalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props) {
    super(props);

    this.createForm();
  }

  createForm() {
    let fields: FieldList = {
      'dateOfTransaction': new Field([Validators.required], null, ''),
      'receivingBankAccount': new Field([Validators.required], null, ''),
      'amount': new Field([Validators.required], null, ''),
      'receiptNumber': new Field([], null, ''),
    }

    this.form$ = new Subject<Form>();
    this.setState({
      loanPaymentForm: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['loanPaymentForm'] = form;
      this.setState(state);
    });
  }

  componentDidMount() {
    super.componentDidMount();

    const cashbondloan$ = appState$.pipe(
      map(appState => appState.cashbondloan)
    );

    const user$ = appState$.pipe(
      map(appState => appState.user)
    );

    this.subs.push(
      cashbondloan$.pipe(
        map(cashbondloan => cashbondloan.postPutRequestState),
        distinctUntilChanged()
      ).subscribe(postPutRequestState => {
        if (postPutRequestState === REQUEST_SUCCESS) {

          this.setState({
            isPostInProgress: false
          });
          this.props.close();

        } else if (postPutRequestState === REQUEST_IN_PROGRESS) {
          this.setState({
            isPostInProgress: true
          });
        } else if (postPutRequestState === REQUEST_FAILED) {
          this.setState({
            isPostInProgress: false
          });
          this.props.close();
        }
      })
    );

    this.subs.push(user$.pipe(
      map(user => user.allBankAccounts),
      distinctUntilChanged()
    ).subscribe((allBankAccounts) => {
        if (allBankAccounts) {
          this.setState({
            allBankAccounts: allBankAccounts
          });
        }
      })
    );

    this.initialStoreDispatches();
  }

  initialStoreDispatches() {

    const store = getStore();
    store.dispatch({
      type: GET_BANKS,
      payload: {
        isSubmitRemit: true
      }
    } as UserAction);
  }

  doModalClose = () => {
    this.props.close();
  }

  onSubmit = () => {
    const form = this.state.loanPaymentForm;
    validateForm(form);

    this.setState({
      loanPaymentForm: form
    });

    if (!this.state.loanPaymentForm.isValid) {
      return;
    }

    let loanPayment: LoanPayment = {
      loanId: this.props.loan.loanId,
      agentId: this.props.loan.agentId,
      sectorId: this.props.loan.sectorId,
      dateOfTransaction: form.fields['dateOfTransaction'].value,
      receivingBankAccount: form.fields['receivingBankAccount'].value,
      amount: Number.parseFloat(form.fields['amount'].value),
      receiptNumber: form.fields['receiptNumber'].value,
    }

    const store = getStore();

    store.dispatch({
      type: CREATE_LOAN_PAYMENT,
      payload: {
        loanPaymentToCreate: loanPayment
      }
    });
  }

  onAgentSearchChange = (agentId: string) => {
    updateFormFieldValue(
      this.form$,
      this.state.loanPaymentForm,
      'agentId',
      agentId
    )
  }

  onLocationSearchChange = (sector: Sector) => {
    updateFormFieldValue(
      this.form$,
      this.state.loanPaymentForm,
      'location',
      sector.sectorId
    )
  }

  onDateSelected = (date: string) => {
    updateFormFieldValue(
      this.form$,
      this.state.loanPaymentForm,
      'dateOfTransaction',
      date
    );
  }

  getFormBlock(props: CreateLoanPaymentModalProps, state: CreateLoanPaymentModalState) {
    let form = state.loanPaymentForm;
    
    if (form && state.allBankAccounts) {
      return (
        <div class="row">
          <div class='col-12 create-loan-modal__form'>
              <div class="row">
                <div class="col-12 mt-3">
                  <label class="anglo-remittance__submit-form__label">Amount*</label>
                  <input className={ cx('form-control', 
                      { 'invalid': cxValidateField(form.fields['amount']) }
                    )}
                    type="text"
                    value={form.fields['amount'].value}
                    onChange={linkform(this.form$, form, 'amount')}
                    placeholder="Amount"/>
                </div>
              </div>

              <div class="row">
                <div class="col-12 mt-3">
                  <label class="anglo-remittance__submit-form__label">Date of Transaction*</label>
                  <DatePicker
                    onDateSelected={this.onDateSelected}
                    initialValue={ form.fields['dateOfTransaction'].value }
                    isValid={form.fields['dateOfTransaction'].isValid}
                    isPristine={form.fields['dateOfTransaction'].isPristine}>
                  </DatePicker>
                </div>
              </div>

              <div class="row">
                <div class="col-12 mt-3">
                  <label class="anglo-remittance__submit-form__label">Receiving Bank Account*</label>
                  <select
                    className={ cx('form-control', 
                      { 'invalid': cxValidateField(form.fields['receivingBankAccount']) }
                    )}
                    placeholder="Bank Account"
                    value={form.fields['receivingBankAccount'].value}
                    onChange={linkform(this.form$, form, 'receivingBankAccount')}>

                      <option class="text-muted" value="" disabled={true} selected>Select Account</option>
                      {
                        state.allBankAccounts.map(acc => {
                          return <option value={acc.bankAccountName}>{ acc.bankAccountName }</option>
                        })
                      }
                  </select>
                </div>
              </div>

              {/* <div class="row">
                <div class="col-12 mt-3">
                  <label class="anglo-remittance__submit-form__label">Receipt Number*</label>
                  <input className={ cx('form-control', 
                      { 'invalid': cxValidateField(form.fields['receiptNumber']) }
                    )}
                    type="text"
                    value={form.fields['receiptNumber'].value}
                    onChange={linkform(this.form$, form, 'receiptNumber')}
                    placeholder="Receipt Number"/>
                </div>
              </div> */}
  
  
          </div>
        </div>
      );
    }
  }

  getSpinnerBlock() {
    return (
      <div class="col offset-md-9 d-flex justify-content-end">
        <div class="spinner-border text-primary mt-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );  
  }

  getActionsRow(props: CreateLoanPaymentModalProps, state: CreateLoanPaymentModalState) {
    if (state.isPostInProgress) {
      return this.getSpinnerBlock();
    } else {
      return (
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-3 offset-lg-9">
              <button class="btn btn-primary w-100"
                onClick={this.onSubmit}
                disabled={!state.loanPaymentForm.isValid}>
                Submit Payment
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  render(props: CreateLoanPaymentModalProps, state: CreateLoanPaymentModalState) {
    return (
      <div class="container anglo-modal create-disposal-modal"
        ref={ node => this.refNode = node }>

          <div class="row anglo-modal__head align-items-center">
            <div class="col-9 col-md-10">
              <h3 class="mb-0">
                Create Loan Payment - {props.loan.loanId}
              </h3>
            </div>
            <div class="col-3 col-md-2">
              <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>

          <div class="row anglo-modal__body py-3">
            <div class="col-12 create-withdrawal-modal__body pb-3">
              { this.getFormBlock(props, state) }   
            </div>    
          </div>

          <div class="row vehicle-card__actions mt-3">
            { this.getActionsRow(props, state) }
          </div>

      </div>
    );
  }
}

/*



*/