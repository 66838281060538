import { h, Component } from 'preact';
import { RequestState } from '../../../types/request-state.model';
import { AuditLog } from '../../../types/audit-log.model';
import { Subscription } from 'rxjs';
import getStore, { appState$ } from '../store';
import { map } from 'rxjs/operators';
import { GET_GRID_AUDIT, AuditAction } from '../reducers/audit/audit.actions';

import moment from 'moment-timezone';

export interface AuditLogState {
  auditGridData?: AuditLog[];
  gridRequestState?: RequestState;
}

export class AuditLogDashboard extends Component<any, AuditLogState> {
  subs: Subscription[] = [];

  componentDidMount() {
    const audit$ = appState$.pipe(
      map(appState => appState.audit)
    );

    this.subs.push(audit$.pipe(
      map(audit => audit.auditGridData)
    ).subscribe((auditGridData) => {

        this.setState({
          auditGridData: auditGridData
        });

      })
    );

    this.initialStoreDispatches();
  }

  initialStoreDispatches() {

    const store = getStore();
    store.dispatch({
      type: GET_GRID_AUDIT
    } as AuditAction);

  }

  getGridBlock(props: any, state: AuditLogState) {

    if (state.auditGridData) {
      return (
        <div class="data-grid dash-remittance__grid-container__grid">
          <div class="data-grid__row header">
            <div class="data-grid__col col-short">
              Username
            </div>
            <div class="data-grid__col col-long">
              Action
            </div>
            <div class="data-grid__col">
              Date
            </div>
          </div>

          {
            state.auditGridData.map((row, index) => {

              return (
                <div class="data-grid__row">
                  <div class="data-grid__col col-short">
                    { row.username }
                  </div>
                  <div class="data-grid__col text-capitalize col-long">
                    { row.action }
                  </div>
                  <div class="data-grid__col ">
                    { moment(row.timestamp).format('MMM DD, YYYY @ hh:mm A') }
                  </div>
                </div>
              );
            })
          }
        </div>
      );
    }

  }

  render(props: any, state: AuditLogState) {
    return (
      <div class="anglo-remittance">
        <div class="container-fluid ">
          <div class="row">
            {/* cookie crumbs */}

            <div class="col-8 col-lg-4">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="#">History</a></li>
                  {/* <li class="breadcrumb-item active" aria-current="page">Remittance Updates</li> */}
                </ol>
              </nav>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12">
              <div class="dash-remittance__grid-container">
                { this.getGridBlock(props, state) }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}