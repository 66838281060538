import { showToast, TOAST_TYPE_ERROR } from "./toast.service";

export const constructFilters = (argFilters?: Map<string, any>) => {
  let filters: string = '?';
  const currentFilters = argFilters ? argFilters : [];
  let index = 0;
  currentFilters.forEach((value, key) => {
    if (index === 0) {
      filters = filters.concat(`${key}=${value}`);
    } else {
      filters = filters.concat(`&${key}=${value}`);
    }
    index++;
  });

  return filters;
}

export const getGridCount = (gridData: any) => {
  return gridData['totalCount'] && gridData['totalCount'][0] ? gridData['totalCount'][0].count : 0
}

export const handleErrorToast = (errResponse: any, templateMessage: string) => {
  console.log(errResponse);
  let message;

  if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
    message = errResponse.response.data.msg;
  }

  showToast({
    title: templateMessage,
    message: message ? message : 'Please contact the system admin.',
    duration: 3000,
    type: TOAST_TYPE_ERROR
  })

}