import { h, Component } from 'preact';
import cx from 'classnames';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import linkState from 'linkstate';
import moment from 'moment-timezone';

import { ModalProps, Modal } from '../../common/modal';
import { Form, FieldList, Field } from '../../../../../types/form.model';
import * as Validators from '../../../../../types/validator.model';
import { validateForm, cxValidateField } from '../../../../../types/validator.model';
import linkform, { updateFormFieldValue } from '../../../../utils/linkform';

import getStore, { appState$ } from '../../../store';
import { APPROVE_LOAN_PAYMENT, CREATE_LOAN_PAYMENT } from '../../../reducers/cashbond-loan/cashbond-loan.actions';
import { REQUEST_FAILED, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../../types/request-state.model';

import { Sector } from '../../../../../types/sector.model';
import { GET_BANKS, UserAction } from '../../../reducers/user/user.actions';
import { User } from '../../../../../types/user.model';
import { Loan, LoanPayment, LoanPaymentUpdate } from '../../../../../types/loan.model';
import { BankAccount } from '../../../../../types/bank-account.model';
import { DatePicker } from '../../common/date-picker';
import { toDecimal } from '../../../../utils/decimal';

export interface ApproveLoanPaymentModalProps extends ModalProps {
  currentUser?: User;
  loanPayment?: LoanPayment;
}

interface ApproveLoanPaymentModalState {
  loanPaymentForm?: Form;
  isFormVisible?: boolean;
  isEditing?: boolean;
  isPostInProgress?: boolean;

  allBankAccounts: BankAccount[];
}

export class ApproveLoanPaymentModal extends Modal<ApproveLoanPaymentModalProps, ApproveLoanPaymentModalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props) {
    super(props);
  }

  componentDidMount(): void {
    const cashbondloan$ = appState$.pipe(
      map(appState => appState.cashbondloan)
    );

    this.subs.push(
      cashbondloan$.pipe(
        map(cashbondloan => cashbondloan.postPutRequestState),
        distinctUntilChanged()
      ).subscribe(postPutRequestState => {
        if (postPutRequestState === REQUEST_SUCCESS) {

          this.setState({
            isPostInProgress: false
          });
          this.doModalClose();

        } else if (postPutRequestState === REQUEST_IN_PROGRESS) {
          this.setState({
            isPostInProgress: true
          });
        } else if (postPutRequestState === REQUEST_FAILED) {
          this.setState({
            isPostInProgress: false
          });
          this.doModalClose();
        }
      })
    );
  }

  doModalClose = () => {
    this.props.close();
  }

  onSubmit = () => {
    const loanPaymentUpdate: LoanPaymentUpdate = {
      loanId: this.props.loanPayment.loanId,
      paymentId: this.props.loanPayment?.loanPaymentId,
      amount: this.props.loanPayment?.amount,
    }

    const store = getStore();

    store.dispatch({
      type: APPROVE_LOAN_PAYMENT,
      payload: {
        loanPaymentToUpdate: loanPaymentUpdate
      }
    });
  }

  getPaymentDetailsBlock(props: ApproveLoanPaymentModalProps) {
    const payment: LoanPayment = props.loanPayment as LoanPayment;

    return (
      <div class="row">
        <div class="col-12">
          <div class='row'>
            <div class="col-12 col-md-4">
              <label class="loan-payment-modal__label text-bold">Agent</label>
              <p class='text-capitalize'>
                {payment.agent.firstName} {payment.agent.lastName}
              </p>
            </div>
            <div class="col-12 col-md-4">
              <label class="loan-payment-modal__label">Supervisor</label>
              <p>
                {payment.supervisor}
              </p>
            </div>
            <div class="col-12 col-md-4">
              <label class="loan-payment-modal__label">Date of Transaction</label>
              <p>
                { moment(payment.dateOfTransaction).format('MMMM DD, YYYY') }
              </p>
            </div>
          </div>
          <div class='row'>
            <div class="col-12 col-md-4">
              <label class="loan-payment-modal__label">Amount</label>
              <p>
                ₱ { toDecimal(payment.amount) }
              </p>
            </div>
            <div class="col-12 col-md-4">
              <label class="loan-payment-modal__label">Bank</label>
              <p>
                { payment.receivingBankAccount }
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getSpinnerBlock() {
    return (
      <div class="col offset-md-9 d-flex justify-content-end">
        <div class="spinner-border text-primary mt-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );  
  }

  getActionsRow(props: ApproveLoanPaymentModalProps, state: ApproveLoanPaymentModalState) {
    if (state.isPostInProgress) {
      return this.getSpinnerBlock();
    } else {
      return (
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-3 offset-lg-9">
              <button class="btn btn-primary w-100"
                onClick={this.onSubmit}>
                Approve Payment
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  render(props: ApproveLoanPaymentModalProps, state: ApproveLoanPaymentModalState) {

    return (
      <div class="container anglo-modal loan-payment-modal"
        ref={ node => this.refNode = node }>

          <div class="row anglo-modal__head align-items-center">
            <div class="col-9 col-md-10">
              <h3 class="mb-0">
                Approve Loan Payment - {props.loanPayment?.loanPaymentId}
              </h3>
            </div>
            <div class="col-3 col-md-2">
              <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>

          <div class="row anglo-modal__body py-3">
            <div class="col-12 pb-3">
              { this.getPaymentDetailsBlock(props) }   
            </div>    
          </div>

          <div class="row vehicle-card__actions mt-3">
            { this.getActionsRow(props, state) }
          </div>

      </div>
    );

  }
}