import { h, Component } from 'preact';
import cx from 'classnames';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import linkState from 'linkstate';

import { ModalProps, Modal } from '../../common/modal';
import { User } from '../../../../../types/user.model';
import { Withdrawal, WithdrawalBrand, WithdrawalBrandGroup } from '../../../../../types/withdrawal.model';
import moment from 'moment-timezone';

import { Formats, REAMS_PER_CASE, PACKS_PER_REAM } from '../../../../../constants/api';
import { toFixed } from '../../../../utils/decimal';
import { Form, FieldList, Field } from '../../../../../types/form.model';
import { validateForm, cxValidateField } from '../../../../../types/validator.model';
import getStore, { appState$ } from '../../../store';
import { GET_PRICES, WithdrawalAction, CREATE_WITHDRAWAL, REEDIT_WITHDRAWAL } from '../../../reducers/withdrawals/withdrawals.actions';
import { BrandPrice } from '../../../../../types/brand-price.model';
import linkform, { updateFormFieldValue } from '../../../../utils/linkform';
import { showToast, TOAST_TYPE_ERROR } from '../../../../utils/toast.service';
import * as Validators from '../../../../../types/validator.model';
import { REQUEST_SUCCESS, REQUEST_IN_PROGRESS } from '../../../../../types/request-state.model';
import { Disposal } from '../../../../../types/disposal.model';
import { CREATE_DISPOSAL } from '../../../reducers/disposals/disposals.actions';
import { AutocompleteLocation } from '../../common/autocomplete-location.component';
import { Location } from '../../../../../types/location.model';
import { MODAL_CHANGE_CREATE_LOCATION } from '../dash-disposal.component';
import { DatePicker } from '../../common/date-picker';

export interface CreateDisposalModalProps extends ModalProps {
  currentUser?: User;
  disposal?: Disposal;
  isReedit?: boolean;
}
 
interface CreateDisposalModalState {
  brandPrices?: BrandPrice[];
  disposalForm?: Form;
  brands: WithdrawalBrand[];  

  isFormVisible?: boolean;
  isEditing?: boolean;
  currentlySelectedBrandIndex?: number;

  isPostInProgress?: boolean;
}

export class CreateDisposalModal extends Modal<CreateDisposalModalProps, CreateDisposalModalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props) {
    super(props);

    this.setState({
      brands: []
    });

    this.createForm();
  }

  createForm() {
    let fields: FieldList = {
      'agentInvolved': new Field([Validators.required], null, ''),
      'location': new Field([Validators.required], null, ''),
      'name': new Field([Validators.required], null, ''),
      'numOfCases': new Field([] , null, ''),
      'numOfReams': new Field([] , null, ''),
      'numOfPacks': new Field([] , null, ''),
      'dateDisposed': new Field([Validators.required] , null, ''),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      disposalForm: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['disposalForm'] = form;
      this.setState(state);
    });
  }

  componentDidMount() {
    super.componentDidMount();

    const withdrawal$ = appState$.pipe(
      map(appState => appState.withdrawal)
    );

    this.subs.push(
      withdrawal$.pipe(
        map(withdrawal => withdrawal.brandPrices),
        distinctUntilChanged()
      ).subscribe(prices => {
        this.setState({
          brandPrices: prices
        });
      })
    );

    const disposal$ = appState$.pipe(
      map(appState => appState.disposal)
    );

    this.subs.push(
      disposal$.pipe(
        map(disposal => disposal.postPutRequestState),
        distinctUntilChanged()
      ).subscribe(postPutRequestState => {
        if (postPutRequestState === REQUEST_SUCCESS) {

          this.setState({
            isPostInProgress: false
          });
          this.props.close();

        } else if (postPutRequestState === REQUEST_IN_PROGRESS) {
          this.setState({
            isPostInProgress: true
          });
        }
      })
    );
    
    this.initialStoreDispatches();
  }

  initialStoreDispatches() {
    const store = getStore();

    store.dispatch({
      type: GET_PRICES
    } as WithdrawalAction);
  }

  doModalClose = () => {
    this.props.close();
  }

  onToggleForm = (toggleState?: boolean) => {
    this.setState({
      isFormVisible: toggleState ? toggleState : !this.state.isFormVisible
    });
  }

  onLocationSearchChange = (location: Location) => {
    updateFormFieldValue(
      this.form$,
      this.state.disposalForm,
      'location',
      location.locationId
    )
  }

  getPrice() {
    const form = this.state.disposalForm;

    const brand = this.state.brandPrices.find(member => {
      return member.brandName === form.fields['name'].value; 
    });

    return brand.pricePerReam;
  }

  onAddBrand = () => {
    const form = this.state.disposalForm;
    validateForm(form);

    if (!form.isValid) {
      return;
    }

    const selectedBrand = this.state.brandPrices.find(member => {
      return member.brandName === form.fields['name'].value;
    });

    const brand: WithdrawalBrand = {
      name: form.fields['name'].value,
      brandPriceId: selectedBrand.brandId,
      numOfCases: Number.parseInt(form.fields['numOfCases'].value),
      numOfReams: Number.parseInt(form.fields['numOfReams'].value),
      numOfPacks: Number.parseInt(form.fields['numOfPacks'].value),
      pricePerReam: this.getPrice(),
    };

    const found = this.state.brands.find((brandMember) => {
      return brand.name === brandMember.name;
    });

    if (found) {
      showToast({
        title: 'Failed to add brand',
        message: 'This brand already exists in current group.',
        duration: 3000,
        type: TOAST_TYPE_ERROR
      });
    } else {
      this.state.brands.push(brand);
    }

    this.forceUpdate();
  }

  onEditBrand = (brand: WithdrawalBrand, index: number) => {
    let form = this.state.disposalForm;

    form.fields['name'].value = brand.name;
    form.fields['numOfCases'].value = brand.numOfCases ? brand.numOfCases : 0;
    form.fields['numOfReams'].value = brand.numOfReams ? brand.numOfReams : 0;
    form.fields['numOfPacks'].value = brand.numOfPacks ? brand.numOfPacks : 0;
    
    this.setState({
      isEditing: true,
      currentlySelectedBrandIndex: index
    });
  }

  onRemoveBrand = (index: number) => {
    let brands = this.state.brands;
    brands.splice(index, 1);

    this.forceUpdate();
  }

  onCompleteEdit = () => {
    const form = this.state.disposalForm;
    validateForm(form);

    if (!form.isValid) {
      return;
    }

    const selectedBrand = this.state.brandPrices.find(member => {
      return member.brandName === form.fields['name'].value;
    });

    const brand: WithdrawalBrand = {
      name: form.fields['name'].value,
      brandPriceId: selectedBrand.brandId,
      numOfCases: Number.parseInt(form.fields['numOfCases'].value),
      numOfReams: Number.parseInt(form.fields['numOfReams'].value),
      numOfPacks: Number.parseInt(form.fields['numOfPacks'].value),
      pricePerReam: this.getPrice(),
    };

    const currentBrandIndex = this.state.currentlySelectedBrandIndex;
    this.state.brands[currentBrandIndex] = brand;

    this.setState({
      isEditing: false,
      currentlySelectedBrandIndex: -1
    });
  }

  onSubmit = () => {    
    const form = this.state.disposalForm;
    validateForm(form);

    this.setState({
      disposalForm: form
    });

    if (!this.state.disposalForm.isValid) {
      return;
    }

    let disposal: Disposal = {
      agentInvolved: form.fields['agentInvolved'].value,
      brandBreakdown: this.state.brands,
      supervisor: this.props.currentUser.supervisor.supervisorId,
      locationId: form.fields['location'].value,
      dateDisposed: form.fields['dateDisposed'].value
    }

    const store = getStore();

    store.dispatch({
      type: CREATE_DISPOSAL,
      payload: {
        disposalToCreate: disposal
      }
    });
  }

  onCreateLocation = () => {
    this.props.close({
      changeMode: MODAL_CHANGE_CREATE_LOCATION
    });
  }

  onDateSelected = (date: string) => {
    updateFormFieldValue(this.form$, this.state.disposalForm, 'dateDisposed', date)
  }

  getBrandCard(brand: WithdrawalBrand, index: number, length: number) {
    let value: number = 0;

    if (brand.numOfCases) {
      value += (brand.numOfCases * REAMS_PER_CASE) * brand.pricePerReam;
    }

    if (brand.numOfReams) {
      value += brand.numOfReams * brand.pricePerReam;
    }

    if (brand.numOfPacks) {
      value += (brand.numOfPacks / PACKS_PER_REAM) * brand.pricePerReam;
    }

    return (
      <div class="col-12 col-lg-4 mb-2">
        <div className={cx(
          'container-fluid create-withdrawal-modal__form__brand-card py-2',
          {
            'editing': index === this.state.currentlySelectedBrandIndex
          }
        )}>
          <div class="row mt-2">
            <div class="col-12">
              <div class="create-withdrawal-modal__form__brand-card__name">
                { brand.name }
              </div>
            </div>
            <div class="col-4">
              <div class="create-withdrawal-modal__form__brand-card__label">
                Cases
              </div>
              <div class="create-withdrawal-modal__form__brand-card__value">
                { brand.numOfCases ? brand.numOfCases : 0 }
              </div>
            </div>

            <div class="col-4">
              <div class="create-withdrawal-modal__form__brand-card__label">
                Reams
              </div>
              <div class="create-withdrawal-modal__form__brand-card__value">
                { brand.numOfReams ? brand.numOfReams : 0 }
              </div>
            </div>

            <div class="col-4">
              <div class="create-withdrawal-modal__form__brand-card__label">
                Packs
              </div>
              <div class="create-withdrawal-modal__form__brand-card__value">
                { brand.numOfPacks ? brand.numOfPacks : 0 }
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="create-withdrawal-modal__form__brand-card__label">
                Price per Ream
              </div>
              <div class="create-withdrawal-modal__form__brand-card__value">
                ₱ { toFixed(brand.pricePerReam) }
              </div>
            </div>
            <div class="col-6">
              <div class="create-withdrawal-modal__form__brand-card__label">
                Total Value
              </div>
              <div class="create-withdrawal-modal__form__brand-card__value">
                ₱ { toFixed(value) }
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-12 col-lg-6">
              <button class="btn btn-outline-dark w-100"
                onClick={() => this.onEditBrand(brand, index)}>
                Edit
              </button>
            </div>
            <div class="col-12 col-lg-6 mt-2 mt-lg-0">
              <button class="btn btn-outline-dark w-100"
                onClick={() => this.onRemoveBrand(index)}>
                Remove
              </button>
            </div>
          </div>
        </div>
          
      </div>
    )
  }

  getFormActionRow(state: CreateDisposalModalState) {
    if (state.isEditing) {
      return (
        <button class="btn btn-outline-primary w-100"
          onClick={this.onCompleteEdit}>
          Edit Brand
        </button>
      );
    } else {
      return (
        <button class="btn btn-outline-primary w-100"
          onClick={this.onAddBrand}>
          Add Brand
        </button>
      );
    }
  }

  getFormBlock(props: CreateDisposalModalProps, state: CreateDisposalModalState) {
    if (state.brandPrices) {
      let form = state.disposalForm;
      let brands: WithdrawalBrand[] = state.brands;

      return (
        <div class="row">
          <div class="col-12 create-withdrawal-modal__form">
            <div class="row">
              <div class="col-12 mt-3">
                <label class="anglo-remittance__submit-form__label">Sales Agent*</label>
                <input className={ cx('form-control', 
                    { 'invalid': cxValidateField(form.fields['agentInvolved']) }
                  )}
                  type="text"
                  value={form.fields['agentInvolved'].value}
                  onChange={linkform(this.form$, form, 'agentInvolved')}
                  placeholder="Agent name"/>
              </div>
            </div>

            <div class="row">
              <div class="col-12 mt-3">
                <label class="anglo-remittance__submit-form__label">Location*</label>
                <AutocompleteLocation onResultSelected={this.onLocationSearchChange}/>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12">
                <div class="create-disposal-modal__new-location">
                  <small>
                    Can't find the location of your disposal? 
                  </small>
                  <button class="create-disposal-modal__new-location-button"
                    onClick={this.onCreateLocation}>
                    Click here to add a new location
                  </button>
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12">
                <label class="anglo-remittance__submit-form__label">Date Disposed*</label>
                <DatePicker
                  onDateSelected={this.onDateSelected}
                  isValid={Validators.cxValidateField(form.fields['dateDisposed'])}
                  isPristine={form.fields['dateDisposed'].isPristine}>
                </DatePicker>
              </div>
            </div>

            <hr/>

            <div class="row">
              { brands.map((member, index) => this.getBrandCard(member, index, brands.length)) }
            </div>

            <div class="row create-withdrawal-modal__form__brand py-3">
              <div class="col-12">
                <label class="anglo-remittance__submit-form__label">Brand Name*</label>

                <select
                  className={ cx('form-control', 
                    { 'invalid': cxValidateField(form.fields['name']) }
                  )}
                  placeholder="Brand Name"
                  value={form.fields['name'].value}
                  onChange={linkform(this.form$, form, 'name')}>

                    <option class="text-muted" value="" disabled={true} selected>Select Brand</option>
                    {
                      props.currentUser.supervisor.currentStock.map(price => {
                      return <option value={price.name}>{ price.name } - ₱ { price.pricePerReam }</option>
                      })
                    }
                </select>
              </div>
              <div class="col-12 col-lg-4 mt-3">
                <label class="anglo-remittance__submit-form__label">Number of Cases</label>
                <input class="form-control" type="text"
                  onInput={linkform(this.form$, form, 'numOfCases')}
                  value={form.fields['numOfCases'].value}
                  placeholder="# of Cases"/>
              </div>

              <div class="col-12 col-lg-4 mt-3">
                <label class="anglo-remittance__submit-form__label">Number of Reams</label>
                <input class="form-control" type="text"
                  onInput={linkform(this.form$, form, 'numOfReams')}
                  value={form.fields['numOfReams'].value}
                  placeholder="# of Reams"/>
              </div>

              <div class="col-12 col-lg-4 mt-3">
                <label class="anglo-remittance__submit-form__label">Number of Packs</label>
                <input class="form-control" type="text"
                  onInput={linkform(this.form$, form, 'numOfPacks')}
                  value={form.fields['numOfPacks'].value}
                  placeholder="# of Packs"/>
              </div>

              <div class="col-6 col-lg-3 offset-6 offset-lg-9 mt-3">
                { this.getFormActionRow(state) }
              </div>
            </div>
            
          </div>
        </div>
      );
    }
  }

  getActionsRow(props: CreateDisposalModalProps, state: CreateDisposalModalState) {
    if (state.isPostInProgress) {
      return this.getSpinnerBlock();
    } else {
      return (
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-3 offset-lg-9">
              <button class="btn btn-primary w-100"
                onClick={this.onSubmit}
                disabled={!state.disposalForm.isValid}>
                Submit Disposal
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  getSpinnerBlock() {
    return (
      <div class="col offset-md-9 d-flex justify-content-end">
        <div class="spinner-border text-primary mt-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );  
  }

  render(props: CreateDisposalModalProps, state: CreateDisposalModalState) {
    return (
      <div class="container anglo-modal create-disposal-modal"
        ref={ node => this.refNode = node }>

          <div class="row anglo-modal__head align-items-center">
            <div class="col-9 col-md-10">
              <h3 class="mb-0">
                Create Disposal
              </h3>
            </div>
            <div class="col-3 col-md-2">
              <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>

          <div class="row anglo-modal__body py-3">
            <div class="col-12 create-withdrawal-modal__body pb-3">
              { this.getFormBlock(props, state) }   
            </div>    
          </div>

          <div class="row vehicle-card__actions mt-3">
            { this.getActionsRow(props, state) }
          </div>
      </div>
    );
  }

}