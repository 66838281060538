import { createStore, applyMiddleware, Store } from 'redux';
import { combineEpics, Epic, createEpicMiddleware, ofType } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';

import AppReducer from './reducers/reducer.module';

import { rootEpic } from './reducers/effects.module';
import { from, Observable, pipe, of } from 'rxjs';
import { AppState } from './reducers/app-state';

const epicMiddleware = createEpicMiddleware();

const appStore: Store<AppState> = createStore(
  AppReducer, 
  composeWithDevTools(
    applyMiddleware(epicMiddleware)
  )
);

export const appState$: Observable<AppState> = from(appStore as any) as Observable<AppState>;

// ! Important line here or else the rootEpic will never be used.
epicMiddleware.run(rootEpic);

export default function getStore() {
  return appStore;
}