import { RemittanceConfig } from '../../../../types/microscope.model';
import { RequestState } from '../../../../types/request-state.model';

const PREFIX = '[MICROSCOPE]';

export const RETRIEVE_GRAPH_DATA = `${PREFIX} RETRIEVE_GRAPH_DATA`;
export const RETRIEVE_GRAPH_DATA_SUCCESS = `${PREFIX} RETRIEVE_GRAPH_DATA_SUCCESS`;
export const RETRIEVE_GRAPH_DATA_FAIL = `${PREFIX} RETRIEVE_GRAPH_DATA_FAIL`;

export interface MicroscopeState {
  remittanceConfig?: RemittanceConfig;
  remittanceGraphData?: any; // ?? idk yet

  retrieveGraphDataStatus?: RequestState;
}

export interface MicroscopeAction {
  type: string;
  payload?: MicroscopeState;
}

