import { h, Component } from 'preact';
import cx from 'classnames';

import { ModalProps, Modal } from '../../common/modal';
import { User } from '../../../../../types/user.model';
import { Withdrawal, WithdrawalBrand, WITHDRAWAL_STATUS_REEDIT, WITHDRAWAL_STATUS_PENDING, WITHDRAWAL_STATUS_REEDIT_ACCEPTED, WITHDRAWAL_STATUS_APPROVED } from '../../../../../types/withdrawal.model';
import moment from 'moment-timezone';

import { Formats, REAMS_PER_CASE, PACKS_PER_REAM } from '../../../../../constants/api';
import { toFixed } from '../../../../utils/decimal';
import getStore from '../../../store';
import { REEDIT_ACCEPT_WITHDRAWAL, APPROVE_WITHDRAWAL, REJECT_WITHDRAWAL, REEDIT_DECLINE_WITHDRAWAL } from '../../../reducers/withdrawals/withdrawals.actions';
import { ROLE_ADMIN_SALES, ROLE_MANAGEMENT } from '../../../../../constants/role';
import { MODAL_CHANGE_WITHDRAWAL_REEDIT, MODAL_CHANGE_WITHDRAWAL_STAGE } from '../dash-withdrawal.component';

export interface ViewWithdrawalModalProps extends ModalProps {
  currentUser?: User;
  withdrawal?: Withdrawal;
}

interface ViewWithdrawalModalState  {
}

export class ViewWithdrawalModal extends Modal<ViewWithdrawalModalProps, ViewWithdrawalModalState> {
  
  doModalClose = () => {
    this.props.close();
  }

  getDataBlock(props: ViewWithdrawalModalProps) {
    return (
      <div class="row">
        <div class="col-12 col-lg-3 mb-3">
          <h4 class="view-withdrawal__data__label">
            Status
          </h4>
          <b class="view-withdrawal__data__value">
            {props.withdrawal.status}
          </b>
        </div>

        <div class="col-12 col-lg-3 mb-3">
          <h4 class="view-withdrawal__data__label">
            Stage
          </h4>
          <b class="view-withdrawal__data__value">
            {props.withdrawal.stage}
          </b>
        </div>

        <div class="col-12 col-lg-3 mb-3">
          <h4 class="view-withdrawal__data__label">
            Total Value
          </h4>
          <b class="view-withdrawal__data__value">
            ₱ {toFixed(props.withdrawal.totalAmount)}
          </b>
        </div>

        <div class="col-12 col-lg-3 mb-3">
          <h4 class="view-withdrawal__data__label">
            Date Created
          </h4>
          <b class="view-withdrawal__data__value">
            {props.withdrawal.dateCreated ? moment(props.withdrawal.dateCreated).format(Formats.dateWorded) : 'Not Available'}
          </b>
        </div>

        <div class="col-12 col-lg-3 mb-3">
          <h4 class="view-withdrawal__data__label">
            Date Withdrawn
          </h4>
          <b class="view-withdrawal__data__value">
            {props.withdrawal.dateOfWithdrawal ? moment(props.withdrawal.dateOfWithdrawal).format(Formats.dateWorded) : 'Not Available'}
          </b>
        </div>
      </div>
    );
  }

  getBrandRow(brand: WithdrawalBrand, index: number, length: number) {
    let value: number = 0;

    value += (brand.numOfCases * REAMS_PER_CASE) * brand.pricePerReam;

    if (brand.numOfReams) {
      value += brand.numOfReams * brand.pricePerReam;
    }
    // value += (brand.numOfPacks / PACKS_PER_REAM) * brand.pricePerReam;

    return (
      <div className={cx(
        'view-withdrawal__brands__grid__row',
        {
          'last': index === length - 1
        }
      )}>
        <div class="view-withdrawal__brands__grid__col">
          { brand.name }
        </div>
        <div class="view-withdrawal__brands__grid__col">
          { brand.numOfCases ? brand.numOfCases : 0 }
        </div>
        <div class="view-withdrawal__brands__grid__col">
          { brand.numOfReams ? brand.numOfReams : 0 }
        </div>
        {/* <div class="col view-withdrawal__brands__grid__col">
          { brand.numOfPacks ? brand.numOfPacks : 0 }
        </div> */}
        <div class="view-withdrawal__brands__grid__col">
          { brand.pricePerReam }
        </div>
        <div class="view-withdrawal__brands__grid__col last">
          ₱ { toFixed(value) }
        </div>
      </div>
    );
  }

  getBrandsBlock(props: ViewWithdrawalModalProps) {
    let brands = props.withdrawal.brands;

    return (
      <div class="row">
        <div class="col-12">
          {
            brands.map((brandGroup) => {
              return (
                <div class="row mb-4">
                  <div class="col-12">
                    <div class="view-withdrawal__brands__agent-name">
                      { brandGroup.agentName }
                    </div>
                  </div>
                  <div class="col-12 mt-3 view-withdrawal__brands__grid">
                    <div class="view-withdrawal__brands__grid__row header">
                      <div class="view-withdrawal__brands__grid__col view-withdrawal__brands__grid__header">
                        Brand
                      </div>
                      <div class="view-withdrawal__brands__grid__col view-withdrawal__brands__grid__header">
                        Number of Cases
                      </div>
                      <div class="view-withdrawal__brands__grid__col view-withdrawal__brands__grid__header">
                        Number of Reams
                      </div>
                      {/* <div class="col view-withdrawal__brands__grid__header">
                        Number of Packs
                      </div> */}
                      <div class="view-withdrawal__brands__grid__col view-withdrawal__brands__grid__header">
                        Price per Ream
                      </div>
                      <div class="view-withdrawal__brands__grid__col view-withdrawal__brands__grid__header">
                        Value
                      </div>
                    </div>
                    {
                      brandGroup.brands.map((brand, index) => this.getBrandRow(brand, index, brandGroup.brands.length))
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    );
  }

  onReeditDecline = () => {
    const store = getStore();

    store.dispatch({
      type: REEDIT_DECLINE_WITHDRAWAL,
      payload: {
        withdrawalId: this.props.withdrawal.withdrawalId
      }
    });

    this.props.close();
  }

  onReeditAccept = () => {
    const store = getStore();

    store.dispatch({
      type: REEDIT_ACCEPT_WITHDRAWAL,
      payload: {
        withdrawalId: this.props.withdrawal.withdrawalId
      }
    });

    this.props.close();
  }

  onApprove = () => {
    const store = getStore();

    store.dispatch({
      type: APPROVE_WITHDRAWAL,
      payload: {
        withdrawalId: this.props.withdrawal.withdrawalId
      }
    });

    this.props.close();
  }

  onReject = () => {
    const store = getStore();

    store.dispatch({
      type: REJECT_WITHDRAWAL,
      payload: {
        withdrawalId: this.props.withdrawal.withdrawalId
      }
    });

    this.props.close();
  }

  onManagementReedit = () => {
    this.props.close({
      changeMode: MODAL_CHANGE_WITHDRAWAL_REEDIT
    });
  }

  onChangeStage = () => {
    this.props.close({
      changeMode: MODAL_CHANGE_WITHDRAWAL_STAGE
    });
  }

  getActionsRow(props: ViewWithdrawalModalProps, state: ViewWithdrawalModalState) {
    if (props.withdrawal.status === WITHDRAWAL_STATUS_REEDIT) {
      return (
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-3 offset-lg-6">
              <button class="btn btn-danger w-100"
                onClick={this.onReeditDecline}>
                Decline
              </button>
            </div>
            <div class="col-12 col-lg-3">
              <button class="btn btn-primary w-100"
                onClick={this.onReeditAccept}>
                Accept
              </button>
            </div>
          </div>
        </div>
      );
    } else if (props.currentUser.role === ROLE_MANAGEMENT || props.currentUser.role === ROLE_ADMIN_SALES) {
      if (props.withdrawal.status === WITHDRAWAL_STATUS_PENDING || 
        props.withdrawal.status === WITHDRAWAL_STATUS_REEDIT_ACCEPTED) {
        return (
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-lg-3 offset-lg-3">
                <button class="btn btn-danger w-100"
                  onClick={this.onReject}>
                  Reject
                </button>
              </div>
              <div class="col-12 col-lg-3">
                <button class="btn btn-outline-dark w-100"
                  onClick={this.onManagementReedit}>
                  Re-Edit
                </button>
              </div>
              <div class="col-12 col-lg-3">
                <button class="btn btn-primary w-100"
                  onClick={this.onApprove}>
                  Approve
                </button>
              </div>
            </div>
          </div>
        );
      } else if (props.withdrawal.status === WITHDRAWAL_STATUS_APPROVED) {
        return (
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-lg-3 offset-lg-9">
                <button class="btn btn-primary w-100"
                  onClick={this.onChangeStage}>
                  Change Stage
                </button>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div>
          
        </div>
      );
    }

    
  }

  render(props: ViewWithdrawalModalProps, state: ViewWithdrawalModalState) {
    return (
      <div class="container anglo-modal"
        ref={ node => this.refNode = node }>

          <div class="row anglo-modal__head align-items-center">
            <div class="col-9 col-md-10">
              <h3 class="mb-0">
                Withdrawal Details
                <small class="anglo-modal__head__small ml-2">
                  {props.withdrawal.withdrawalId}
                </small>
              </h3>
            </div>
            <div class="col-3 col-md-2">
              <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>

          <div class="row anglo-modal__body py-3">
            <div class="col-12">
              { this.getDataBlock(props) } 
              <hr/>
              { this.getBrandsBlock(props) }      
            </div>    
          </div>

          <div class="row vehicle-card__actions mt-3">
            { this.getActionsRow(props, state) }
          </div>
      </div>
    );
  }

}