import * as Actions from './cashbond-loan.actions';
import { CashbondLoanState, CashbondLoanAction } from './cashbond-loan.actions';
import { REQUEST_SUCCESS, REQUEST_FAILED, REQUEST_IN_PROGRESS, REQUEST_UNTOUCHED } from '../../../../types/request-state.model';

const initialState: CashbondLoanState = {

}

export default function CashbondLoan(state: CashbondLoanState = initialState, action: CashbondLoanAction) {
  switch (action.type) {

    case Actions.GET_GRID_CASHBOND:
    case Actions.GET_GRID_LOAN:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          gridRequestState: REQUEST_IN_PROGRESS
        }
      );
    case Actions.GET_GRID_CASHBOND_SUCCESS:
    case Actions.GET_GRID_CASHBOND_FAIL:
    case Actions.GET_GRID_LOAN_SUCCESS:
    case Actions.GET_GRID_LOAN_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
      );

    case Actions.CREATE_CASHBOND:
    case Actions.CREATE_CASHBOND_PAYMENT:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_IN_PROGRESS
        }
      );

    case Actions.CREATE_CASHBOND_SUCCESS:
    case Actions.CREATE_CASHBOND_PAYMENT_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_SUCCESS
        }
      );

    case Actions.CREATE_CASHBOND_FAIL:
    case Actions.CREATE_CASHBOND_PAYMENT_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_FAILED
        }
      );

    case Actions.APPROVE_LOAN_PAYMENT:
    case Actions.REJECT_LOAN_PAYMENT:
    case Actions.APPROVE_CASHBOND_PAYMENT:
    case Actions.REJECT_BOND_PAYMENT:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_IN_PROGRESS
        }
      );

    case Actions.APPROVE_LOAN_PAYMENT_SUCCESS:
    case Actions.REJECT_LOAN_PAYMENT_SUCCESS:
    case Actions.APPROVE_CASHBOND_PAYMENT_SUCCESS:
    case Actions.REJECT_BOND_PAYMENT_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_SUCCESS
        }
      );

    case Actions.APPROVE_LOAN_PAYMENT_FAILURE:
    case Actions.REJECT_LOAN_PAYMENT_FAILURE:
    case Actions.APPROVE_CASHBOND_PAYMENT_FAILURE:
    case Actions.REJECT_BOND_PAYMENT_FAILURE:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_FAILED
        }
      );

    case Actions.CREATE_LOAN:
    case Actions.UPDATE_LOAN_STATUS:
    case Actions.CREATE_LOAN_PAYMENT:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_IN_PROGRESS
        }
      );

    case Actions.CREATE_LOAN_SUCCESS:
    case Actions.UPDATE_LOAN_STATUS_SUCCESS:
    case Actions.CREATE_LOAN_PAYMENT_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_SUCCESS
        }
      );

    case Actions.CREATE_LOAN_FAIL:
    case Actions.UPDATE_LOAN_STATUS_FAIL:
    case Actions.CREATE_LOAN_PAYMENT_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_FAILED
        }
      );

    case Actions.GET_LOAN_PAYMENTS:
    case Actions.GET_BOND_PAYMENTS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          paymentsRequestState: REQUEST_IN_PROGRESS,
          postPutRequestState: REQUEST_UNTOUCHED
        }
      );

    case Actions.GET_LOAN_PAYMENTS_SUCCESS:
    case Actions.GET_BOND_PAYMENTS_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          paymentsRequestState: REQUEST_SUCCESS
        }
      );

    case Actions.GET_LOAN_PAYMENTS_FAIL:
    case Actions.GET_BOND_PAYMENTS_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          paymentsRequestState: REQUEST_FAILED
        }
      );

    case Actions.GENERATE_SERVERSIDE_LOAN_REPORT:
    case Actions.GENERATE_SERVERSIDE_LOAN_PAYMENT_REPORT:
    case Actions.GENERATE_SERVERSIDE_CASHBOND_REPORT:
    case Actions.GENERATE_SERVERSIDE_BOND_PAYMENT_REPORT:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          reportRequestState: REQUEST_IN_PROGRESS
        }
      );

    case Actions.GENERATE_SERVERSIDE_LOAN_REPORT_SUCCESS:
    case Actions.GENERATE_SERVERSIDE_LOAN_PAYMENT_REPORT_SUCCESS:
    case Actions.GENERATE_SERVERSIDE_CASHBOND_REPORT_SUCCESS:
    case Actions.GENERATE_SERVERSIDE_BOND_PAYMENT_REPORT_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          reportRequestState: REQUEST_SUCCESS
        }
      );

    case Actions.GENERATE_SERVERSIDE_LOAN_REPORT_FAIL:
    case Actions.GENERATE_SERVERSIDE_LOAN_PAYMENT_REPORT_FAIL:
    case Actions.GENERATE_SERVERSIDE_CASHBOND_REPORT_FAIL:
    case Actions.GENERATE_SERVERSIDE_BOND_PAYMENT_REPORT_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          reportRequestState: REQUEST_FAILED
        }
      );

    case Actions.RESET_CASHBONDLOAN_STATE:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_UNTOUCHED
        }
      );

    default:
      return state;
  }
}