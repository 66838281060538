import { h, Component } from 'preact';

import cx from 'classnames';
import { toFixed } from '../../../utils/decimal';

export interface DataDisplayWidgetProps {
  name: string;

  externalLink?: string;

  data1: number | string;
  data1Label: string;

  data2?: number | string;
  data2Label?: string;

  changeAmount?: number;
  isChangePositive?: boolean; // Not sure if I should create a type or enum
  isComingSoon?: boolean;

}

export interface DataDisplayWidgetState {
  name: string;

  data1: number | string;
  data1Label: string;

  data2?: number | string;
  data2Label?: string;

  changeAmount?: number;
  isChangePositive?: boolean;
}

// It's called DataDisplay even though most of what is shows is comparison
// between the broker's average and the top 20 others
// because it's preferred to have this more usable
export class DataDisplayWidget extends Component<DataDisplayWidgetProps, DataDisplayWidgetState> {
  constructor(props: DataDisplayWidgetProps) {
    super(props);

    this.setState({
      name: props.name,
      data1: props.data1,
      data1Label: props.data1Label,
      data2: props.data2,
      data2Label: props.data2Label,
    });
  }

  getComingSoonBlock(props: DataDisplayWidgetProps) {
    if (props.isComingSoon) {
      return (
        <div class="anglo-data-display-widget__coming-soon">
          <h4 class="anglo-data-display-widget__coming-soon__h4">
            Feature coming soon
          </h4>
        </div>
      );
    }
  }

  getExternalLinkBlock(props: DataDisplayWidgetProps) {
    if (props.externalLink) {
      return (
        <a href={props.externalLink}
          class="anglo-data-display-widget__external-link">
          See details <i class="fas fa-chevron-right"></i>
        </a>
      );
    }
  }

  getChangeAmountBlock(props: DataDisplayWidgetProps) {
    if (props.changeAmount) {
      if (props.isChangePositive) {
        return (
          <span class="anglo-data-display-widget__change-amount green">
            + { toFixed(props.changeAmount) } <i class="fas fa-arrow-up"></i>
          </span>
        );
      } else {
        return (
          <span class="anglo-data-display-widget__change-amount red">
            { toFixed(props.changeAmount) } <i class="fas fa-arrow-down"></i>
          </span>
        );
      }
    }
  }

  getSecondDataBlock(state: DataDisplayWidgetState) {
    if (state.data2) {
      return (
        <div class="col-12 anglo-data-display-widget__data pt-3">
          <h4 class="anglo-data-display-widget__data__amount">
            { state.data2 }
          </h4>
          <p class="anglo-data-display-widget__data__label">
            { state.data2Label }
          </p>
        </div>
      );
    }
  }

  render(props: DataDisplayWidgetProps, state: DataDisplayWidgetState) {
    return (
      <div class="anglo-data-display-widget">
        { this.getComingSoonBlock(props) }
        <div class="row no-gutters">
          <div class="col-8 anglo-data-display-widget__name">
            { state.name } { this.getChangeAmountBlock(props) }
          </div>
          <div class="col-4 d-flex justify-content-end">
            { this.getExternalLinkBlock(props) }
          </div>
        </div>
        <div class="row mt-3">
          <div class="col anglo-data-display-widget__data first mb-3">
            <h4 class="anglo-data-display-widget__data__amount">
              { state.data1 }
            </h4>
            <p class="anglo-data-display-widget__data__label">
              { state.data1Label }
            </p>
          </div>
          { this.getSecondDataBlock(state) }
        </div>
      </div>
    ) 
  }

}