import { RequestState } from '../../../../types/request-state.model';
import { Supplier } from '../../../../types/supplier.model';
import { PurchaseDocument, ReceivingReport } from '../../../../types/purchase-document.model';
import { Item } from '../../../../types/item.model';
import { V2_JobOrder } from '../../../../types/job-order.model';
import { ItemWithdrawal } from '../../../../types/item-withdrawal.model';

const PREFIX = '[Motorpool]';

export const GET_SUPPLIERS = `${PREFIX} GET_SUPPLIERS`;
export const GET_SUPPLIERS_SUCCESS = `${PREFIX} GET_SUPPLIERS_SUCCESS`;
export const GET_SUPPLIERS_FAIL = `${PREFIX} GET_SUPPLIERS_FAIL`;  

export const CREATE_SUPPLIER = `${PREFIX} CREATE_SUPPLIER`;
export const CREATE_SUPPLIER_SUCCESS = `${PREFIX} CREATE_SUPPLIER_SUCCESS`;
export const CREATE_SUPPLIER_FAIL = `${PREFIX} CREATE_SUPPLIER_FAIL`;

export const EDIT_SUPPLIER = `${PREFIX} EDIT_SUPPLIER`;
export const EDIT_SUPPLIER_SUCCESS = `${PREFIX} EDIT_SUPPLIER_SUCCESS`;
export const EDIT_SUPPLIER_FAIL = `${PREFIX} EDIT_SUPPLIER_FAIL`;

export const GET_PURCHASE_DOCUMENTS = `${PREFIX} GET_PURCHASE_DOCUMENTS`;
export const GET_PURCHASE_DOCUMENTS_SUCCESS = `${PREFIX} GET_PURCHASE_DOCUMENTS_SUCCESS`;
export const GET_PURCHASE_DOCUMENTS_FAIL = `${PREFIX} GET_PURCHASE_DOCUMENTS_FAIL`;  

export const CREATE_PURCHASE_DOCUMENT = `${PREFIX} CREATE_PURCHASE_DOCUMENT`;
export const CREATE_PURCHASE_DOCUMENT_SUCCESS = `${PREFIX} CREATE_PURCHASE_DOCUMENT_SUCCESS`;
export const CREATE_PURCHASE_DOCUMENT_FAIL = `${PREFIX} CREATE_PURCHASE_DOCUMENT_FAIL`;

export const UPDATE_PURCHASE_DOCUMENT = `${PREFIX} UPDATE_PURCHASE_DOCUMENT`;
export const UPDATE_PURCHASE_DOCUMENT_SUCCESS = `${PREFIX} UPDATE_PURCHASE_DOCUMENT_SUCCESS`;
export const UPDATE_PURCHASE_DOCUMENT_FAIL = `${PREFIX} UPDATE_PURCHASE_DOCUMENT_FAIL`;

export const SIGN_PURCHASE_DOCUMENT = `${PREFIX} SIGN_PURCHASE_DOCUMENT`;
export const SIGN_PURCHASE_DOCUMENT_SUCCESS = `${PREFIX} SIGN_PURCHASE_DOCUMENT_SUCCESS`;
export const SIGN_PURCHASE_DOCUMENT_FAIL = `${PREFIX} SIGN_PURCHASE_DOCUMENT_FAIL`;

export const CREATE_RECEIVING_REPORT = `${PREFIX} CREATE_RECEIVING_REPORT`;
export const CREATE_RECEIVING_REPORT_SUCCESS = `${PREFIX} CREATE_RECEIVING_REPORT_SUCCESS`;
export const CREATE_RECEIVING_REPORT_FAIL = `${PREFIX} CREATE_RECEIVING_REPORT_FAIL`;

export const GET_ITEMS = `${PREFIX} GET_ITEMS`;
export const GET_ITEMS_SUCCESS = `${PREFIX} GET_ITEMS_SUCCESS`;
export const GET_ITEMS_FAIL = `${PREFIX} GET_ITEMS_FAIL`;  

export const CREATE_ITEM = `${PREFIX} CREATE_ITEM`;
export const CREATE_ITEM_SUCCESS = `${PREFIX} CREATE_ITEM_SUCCESS`;
export const CREATE_ITEM_FAIL = `${PREFIX} CREATE_ITEM_FAIL`;

export const GET_JO = `${PREFIX} GET_JO`;
export const GET_JO_SUCCESS = `${PREFIX} GET_JO_SUCCESS`;
export const GET_JO_FAIL = `${PREFIX} GET_JO_FAIL`;  

export const CREATE_JO = `${PREFIX} CREATE_JO`;
export const CREATE_JO_SUCCESS = `${PREFIX} CREATE_JO_SUCCESS`;
export const CREATE_JO_FAIL = `${PREFIX} CREATE_JO_FAIL`;  

export const GET_IW = `${PREFIX} GET_IW`;
export const GET_IW_SUCCESS = `${PREFIX} GET_IW_SUCCESS`;
export const GET_IW_FAIL = `${PREFIX} GET_IW_FAIL`;  

export const CREATE_IW = `${PREFIX} CREATE_IW`;
export const CREATE_IW_SUCCESS = `${PREFIX} CREATE_IW_SUCCESS`;
export const CREATE_IW_FAIL = `${PREFIX} CREATE_IW_FAIL`;  

export const UPDATE_IW = `${PREFIX} UPDATE_IW`;
export const UPDATE_IW_SUCCESS = `${PREFIX} UPDATE_IW_SUCCESS`;
export const UPDATE_IW_FAIL = `${PREFIX} UPDATE_IW_FAIL`;  

export const PROCESS_IW = `${PREFIX} PROCESS_IW`;
export const PROCESS_IW_SUCCESS = `${PREFIX} PROCESS_IW_SUCCESS`;
export const PROCESS_IW_FAIL = `${PREFIX} PROCESS_IW_FAIL`;  

export const APPROVE_IW = `${PREFIX} APPROVE_IW`;
export const APPROVE_IW_SUCCESS = `${PREFIX} APPROVE_IW_SUCCESS`;
export const APPROVE_IW_FAIL = `${PREFIX} APPROVE_IW_FAIL`;  

export const UPDATE_V2_JOB_ORDER = `${PREFIX} UPDATE_V2_JOB_ORDER`;
export const UPDATE_V2_JOB_ORDER_SUCCESS = `${PREFIX} UPDATE_V2_JOB_ORDER_SUCCESS`;
export const UPDATE_V2_JOB_ORDER_FAIL = `${PREFIX} UPDATE_V2_JOB_ORDER_FAIL`;  

export const CREATE_PO = `${PREFIX} CREATE_PO`;
export const CREATE_PO_SUCCESS = `${PREFIX} CREATE_PO_SUCCESS`;
export const CREATE_PO_FAIL = `${PREFIX} CREATE_PO_FAIL`;

export const APPROVE_PO = `${PREFIX} APPROVE_PO`;
export const APPROVE_PO_SUCCESS = `${PREFIX} APPROVE_PO_SUCCESS`;
export const APPROVE_PO_FAIL = `${PREFIX} APPROVE_PO_FAIL`;

export interface MotorpoolState {
  filters?: Map<string, any>;
  total?: number;
  pdTotal?: number;

  suppliers?: Supplier[];
  supplierRequestState?: RequestState;
  supplierToCreate?: Supplier;
  supplierToEdit?: Supplier;

  purchaseDocuments?: PurchaseDocument[];
  purchaseDocumentToCreate?: PurchaseDocument;
  purchaseDocumentToUpdate?: PurchaseDocument;
  purchaseDocumentSignPayload?: any;

  purchaseDocumentId?: string;
  receivingReport?: ReceivingReport;

  postPutRequestState?: RequestState;
  pdRequestState?: RequestState;

  items?: Item[];
  itemRequestState?: RequestState;
  itemToCreate?: Item;

  jobOrders?: V2_JobOrder[];
  joRequestState?: RequestState;
  joToCreate?: V2_JobOrder;
  joToUpdate?: V2_JobOrder;

  itemWithdrawals?: ItemWithdrawal[];
  iwRequestState?: RequestState;
  iwToCreate?: ItemWithdrawal;
  iwToUpdate?: ItemWithdrawal;
  iwSignPayload?: any;

}

export interface MotorpoolAction {
  type: string,
  payload?: MotorpoolState
}