import { h, Component } from 'preact';

export class PreactTester extends Component<any, any> {


  render(props, state) {
    return(
      <div>
        <h3>
          This is for testing preact components.

          <div class="mb-5"/>

        </h3>
      </div>
    );
  }
}