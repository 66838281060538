import * as Actions from './remittance.actions';
import { RemittanceState, RemittanceAction } from './remittance.actions';
import { REQUEST_SUCCESS, REQUEST_FAILED, REQUEST_IN_PROGRESS, REQUEST_UNTOUCHED } from '../../../../types/request-state.model';

const initialState: RemittanceState = {

}

export default function Remittance(state: RemittanceState = initialState, action: RemittanceAction) {
  switch (action.type) {
    case Actions.SUBMIT_REMITTANCE:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          submitRemitState: REQUEST_IN_PROGRESS
        }
      );

    case Actions.SUBMIT_REMITTANCE_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          submitRemitState: REQUEST_SUCCESS
        }
      );

    case Actions.SUBMIT_REMITTANCE_FAIL:
      return Object.assign(
        {},
        state,
        {
          submitRemitState: REQUEST_FAILED
        }
      );

    case Actions.GET_GRID_REMITTANCE:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          gridRequestState: REQUEST_IN_PROGRESS
        }
      );

    case Actions.GET_GRID_REMITTANCE_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          gridRequestState: REQUEST_SUCCESS
        }
      );

    case Actions.GET_GRID_REMITTANCE_FAIL:
      return Object.assign(
        {},
        state,
        {
          gridRequestState: REQUEST_FAILED
        }
      );

    case Actions.GET_REPORT_GRID_REMITTANCE:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          reportRequestState: REQUEST_IN_PROGRESS
        }
      );

    case Actions.GET_REPORT_GRID_REMITTANCE_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          reportRequestState: REQUEST_SUCCESS
        }
      );

    case Actions.GET_REPORT_GRID_REMITTANCE_FAIL:
      return Object.assign(
        {},
        state,
        {
          reportRequestState: REQUEST_FAILED
        }
      );

    case Actions.GET_REMITTANCE_TOTAL:
    case Actions.GET_REMITTANCE_TOTAL_SUCCESS:
    case Actions.GET_REMITTANCE_TOTAL_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
      );

    case Actions.GET_REMITTANCE_TOTAL_MONTH:
    case Actions.GET_REMITTANCE_TOTAL_MONTH_SUCCESS:
    case Actions.GET_REMITTANCE_TOTAL_MONTH_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
      );

    case Actions.SUBMIT_EDIT_REQUEST:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          editRequestState: REQUEST_IN_PROGRESS
        }
      );

    case Actions.SUBMIT_EDIT_REQUEST_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          editRequestState: REQUEST_SUCCESS
        }
      );

    case Actions.SUBMIT_EDIT_REQUEST_FAIL:
      return Object.assign(
        {},
        state,
        {
          editRequestState: REQUEST_FAILED
        }
      );

    case Actions.SUBMIT_DELETE_REQUEST:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          deleteRequestState: REQUEST_IN_PROGRESS
        }
      );

    case Actions.SUBMIT_DELETE_REQUEST_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          deleteRequestState: REQUEST_SUCCESS
        }
      );

    case Actions.SUBMIT_DELETE_REQUEST_FAIL:
      return Object.assign(
        {},
        state,
        {
          deleteRequestState: REQUEST_FAILED
        }
      );

    case Actions.APPROVE_REMITTANCE:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          approveRequestState: REQUEST_IN_PROGRESS
        }
      );

    case Actions.APPROVE_REMITTANCE_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          approveRequestState: REQUEST_SUCCESS
        }
      );

    case Actions.APPROVE_REMITTANCE_FAIL:
      return Object.assign(
        {},
        state,
        {
          approveRequestState: REQUEST_FAILED
        }
      );

    case Actions.RESET_REMITTANCE_STATES:
      return Object.assign(
        {},
        state,
        {
          approveRequestState: REQUEST_UNTOUCHED,
          editRequestState: REQUEST_UNTOUCHED,
          deleteRequestState: REQUEST_UNTOUCHED
        }
      );

    case Actions.GENERATE_SERVERSIDE_REPORT:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          reportRequestState: REQUEST_IN_PROGRESS
        }
      );

    case Actions.GENERATE_SERVERSIDE_REPORT_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          reportRequestState: REQUEST_SUCCESS
        }
      );

    case Actions.GENERATE_SERVERSIDE_REPORT_FAIL:
      return Object.assign(
        {},
        state,
        {
          reportRequestState: REQUEST_FAILED
        }
      );


    default:
      return state;
  }
}