import { h, render, ComponentChild } from 'preact';

import { PlatformUtility } from '../utils/platform-utility';
import { PreactApp } from './preact-app';
import { AngloLogin } from './components/login.component';

const PREACT_APP_SELECTOR = 'anglo-app';

const APP_SELECTORS = [
  {
    name: 'anglo-app',
    component: PreactApp
  },
  {
    name: 'anglo-login',
    component: AngloLogin
  },
];

export default document.addEventListener('DOMContentLoaded', () => {

  if (PlatformUtility.isOnClient()) {
    // console.log('Running on client');
    
    APP_SELECTORS.forEach((selector) => {
      let targets = document.querySelectorAll(selector.name);

      let componentVDOM = h(selector.component, {});

      targets.forEach((member) => {
        render(componentVDOM, member);
      });
    });

  }
  
});