import { SupervisorRegistrationData, User } from '../../../../types/user.model';
import { BankAccount } from '../../../../types/bank-account.model';
import { Agent } from '../../../../types/agent.model';
import { RequestState } from '../../../../types/request-state.model';
import { WithdrawalBrand } from '../../../../types/withdrawal.model';

const PREFIX = '[User]';

export const TEST = `${PREFIX} TEST`;

export const GET_CURRENT_USER = `${PREFIX} GET_CURRENT_USER`;
export const GET_CURRENT_USER_SUCCESS = `${PREFIX} GET_CURRENT_USER_SUCCESS`;
export const GET_CURRENT_USER_FAIL = `${PREFIX} GET_CURRENT_USER_FAIL`;

export const LOGIN = `${PREFIX} LOGIN`;
export const LOGIN_SUCCESS = `${PREFIX} LOGIN_SUCCESS`;
export const LOGIN_FAIL = `${PREFIX} LOGIN_FAIL`;

export const LOGOUT = `${PREFIX} LOGOUT`;
export const LOGOUT_SUCCESS = `${PREFIX} LOGOUT_SUCCESS`;
export const LOGOUT_FAIL = `${PREFIX} LOGOUT_FAIL`;

export const GET_AGENTS = `${PREFIX} GET_AGENTS`;
export const GET_AGENTS_SUCCESS = `${PREFIX} GET_AGENTS_SUCCESS`;
export const GET_AGENTS_FAIL = `${PREFIX} GET_AGENTS_FAIL`;

export const GET_SUPERVISORS = `${PREFIX} GET_SUPERVISORS`;
export const GET_SUPERVISORS_SUCCESS = `${PREFIX} GET_SUPERVISORS_SUCCESS`;
export const GET_SUPERVISORS_FAIL = `${PREFIX} GET_SUPERVISORS_FAIL`;

export const GET_ALL_USERS = `${PREFIX} GET_ALL_USERS`;
export const GET_ALL_USERS_SUCCESS = `${PREFIX} GET_ALL_USERS_SUCCESS`;
export const GET_ALL_USERS_FAIL = `${PREFIX} GET_ALL_USERS_FAIL`;

export const CREATE_BANK_ACCOUNT = `${PREFIX} CREATE_BANK_ACCOUNT`;
export const CREATE_BANK_ACCOUNT_SUCCESS = `${PREFIX} CREATE_BANK_ACCOUNT_SUCCESS`;
export const CREATE_BANK_ACCOUNT_FAIL = `${PREFIX} CREATE_BANK_ACCOUNT_FAIL`;

export const GET_BANKS = `${PREFIX} GET_BANKS`;
export const GET_BANKS_SUCCESS = `${PREFIX} GET_BANKS_SUCCESS`;
export const GET_BANKS_FAIL = `${PREFIX} GET_BANKS_FAIL`;

export const CREATE_USER = `${PREFIX} CREATE_USER`;
export const CREATE_USER_SUCCESS = `${PREFIX} CREATE_USER_SUCCESS`;
export const CREATE_USER_FAIL = `${PREFIX} CREATE_USER_FAIL`;

export const CREATE_EMPLOYEE = `${PREFIX} CREATE_EMPLOYEE`;
export const CREATE_EMPLOYEE_SUCCESS = `${PREFIX} CREATE_EMPLOYEE_SUCCESS`;
export const CREATE_EMPLOYEE_FAIL = `${PREFIX} CREATE_EMPLOYEE_FAIL`;

export const EDIT_EMPLOYEE = `${PREFIX} EDIT_EMPLOYEE`;
export const EDIT_EMPLOYEE_SUCCESS = `${PREFIX} EDIT_EMPLOYEE_SUCCESS`;
export const EDIT_EMPLOYEE_FAIL = `${PREFIX} EDIT_EMPLOYEE_FAIL`;

export const CHANGE_PASSWORD = `${PREFIX} CHANGE_PASSWORD`;
export const CHANGE_PASSWORD_SUCCESS = `${PREFIX} CHANGE_PASSWORD_SUCCESS`;
export const CHANGE_PASSWORD_FAIL = `${PREFIX} CHANGE_PASSWORD_FAIL`;

export const ASSIGN_BANK_ACCOUNT = `${PREFIX} ASSIGN_BANK_ACCOUNT`;
export const ASSIGN_BANK_ACCOUNT_SUCCESS = `${PREFIX} ASSIGN_BANK_ACCOUNT_SUCCESS`;
export const ASSIGN_BANK_ACCOUNT_FAIL = `${PREFIX} ASSIGN_BANK_ACCOUNT_FAIL`;

export const CHANGE_STATUS = `${PREFIX} CHANGE_STATUS`;
export const CHANGE_STATUS_SUCCESS = `${PREFIX} CHANGE_STATUS_SUCCESS`;
export const CHANGE_STATUS_FAIL = `${PREFIX} CHANGE_STATUS_FAIL`;

export const CREATE_SUPERVISOR_DATA = `${PREFIX} CREATE_SUPERVISOR_DATA`;
export const CREATE_SUPERVISOR_DATA_SUCCESS = `${PREFIX} CREATE_SUPERVISOR_DATA_SUCCESS`;
export const CREATE_SUPERVISOR_DATA_FAIL = `${PREFIX} CREATE_SUPERVISOR_DATA_FAIL`;

export const UPDATE_SUPERVISOR_STOCK = `${PREFIX} UPDATE_SUPERVISOR_STOCK`;
export const UPDATE_SUPERVISOR_STOCK_SUCCESS = `${PREFIX} UPDATE_SUPERVISOR_STOCK_SUCCESS`;
export const UPDATE_SUPERVISOR_STOCK_FAIL = `${PREFIX} UPDATE_SUPERVISOR_STOCK_FAIL`;

export const RESET_STATES = `${PREFIX} RESET_STATES`;

export interface UserState {
  filters?: Map<string, any>;
  employeeFilters?: Map<string, any>;
  currentUser?: User;

  loginState?: RequestState;
  getCurrentUserState?: RequestState;

  authUsername?: string;
  authPassword?: string;

  agents?: Agent[];
  supervisors?: User[];

  allUsers?: User[];
  allBankAccounts?: BankAccount[];
  isSubmitRemit?: boolean;

  bankAccount?: BankAccount;
  createBankAccountState?: RequestState;

  userToCreate?: User;
  createUserState?: RequestState;

  userToUpdate?: User;
  changePasswordStatus?: RequestState;
  changeActiveStatus?: RequestState;

  userToAssign?: string;
  bankAccountNumber?: string;
  assignBankAccountStatus?: RequestState;

  supervisorRegistrationData?: SupervisorRegistrationData;
  supervisorToUpdate?: string;
  stocksToUpdate?: WithdrawalBrand[];
  postPutRequestState?: RequestState;

  employeeToCreate?: Agent;
  createEmployeeState?: RequestState;
  supervisorUsername?: string;
}

export interface UserAction {
  type: string,
  payload?: UserState
}