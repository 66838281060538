import { Epic, ofType } from "redux-observable";

import * as UpdateActions from './updates.actions'
import { UpdateAction } from './updates.actions';

import { switchMap, mergeMap, catchError, withLatestFrom } from "rxjs/operators";
import { from, of } from "rxjs";

import { showToast } from '../../../utils/toast.service';
import Axios from "axios";
import { AuditLog } from "../../../../types/audit-log.model";

import { API_ENDPOINT } from '../../../../constants/api';
import { TotalCount } from "../../../../types/total-count.model";
import { appState$ } from "../../store";

let updateEffects: Epic[] = [];

const getEditGridData: Epic = getEditGridData$ => getEditGridData$.pipe(
  ofType<UpdateAction>(UpdateActions.GET_GRID_UPDATE),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    let retrievedFilters;
    const stateFilters = state.updates.filters;
    let filters = '?';

    if (action.payload && action.payload.filters) {

      if (stateFilters) {
        retrievedFilters = {
          ...action.payload.filters,
          ...stateFilters
        };
      } else {
        retrievedFilters = action.payload.filters;
      }

      let index = 0;

      for (let k in retrievedFilters) {
        if (index > 0) {
          filters = filters.concat('&');
        }

        filters = filters.concat(`${k}=${retrievedFilters[k]}`);
        index++;
      }
      
    } else if (stateFilters) {
      let index = 0;
      for (let k in stateFilters) {
        if (index > 0) {
          filters = filters.concat('&');
        }

        filters = filters.concat(`${k}=${stateFilters[k]}`);
        index++;
      }
    }

    const promise = Axios.get<AuditLog[]>(
      `${API_ENDPOINT}/update-request${filters}`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const gridData = JSON.parse(JSON.stringify(response.data['results']));

        const action: UpdateAction = {
          type: UpdateActions.GET_GRID_UPDATE_SUCCESS,
          payload: {
            editGridData: gridData
          }
        }

        return of(action);
      }),
      catchError((errResponse) => {
        const action: UpdateAction = {
          type: UpdateActions.GET_GRID_UPDATE_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to get grid data',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to get grid data',
            message: message,
            duration: 3000
          });
        } 
        
        return of(action);
      })
    );
  })
);

const approveEdit: Epic = approveEdit$ => approveEdit$.pipe(
  ofType<UpdateAction>(UpdateActions.APPROVE_REQUEST_EDIT),
  switchMap((action: UpdateAction) => {
    const promise = Axios.put(
      `${API_ENDPOINT}/update-request/edit`,
      {
        remittanceId: action.payload.remittanceId,
        responseReason: action.payload.responseReason
      },
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {

        const action: UpdateAction = {
          type: UpdateActions.APPROVE_REQUEST_EDIT_SUCCESS,
        }

        showToast({
          title: 'Success request approve',
          message: 'You have successfully approved this request',
          duration: 3000
        });

        setTimeout(() => {
          location.reload();
        }, 350);

        return of(action);
      }),
      catchError((errResponse) => {
        const action: UpdateAction = {
          type: UpdateActions.APPROVE_REQUEST_EDIT_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to approve request',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to approve request',
            message: message,
            duration: 3000
          });
        } 
        
        return of(action);
      })
    );
  })
);

const rejectEdit: Epic = approveEdit$ => approveEdit$.pipe(
  ofType<UpdateAction>(UpdateActions.REJECT_REQUEST_EDIT),
  switchMap((action: UpdateAction) => {
    const promise = Axios.put(
      `${API_ENDPOINT}/update-request/edit/reject`,
      {
        remittanceId: action.payload.remittanceId,
        responseReason: action.payload.responseReason
      },
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {

        const action: UpdateAction = {
          type: UpdateActions.REJECT_REQUEST_EDIT_SUCCESS,
        }

        showToast({
          title: 'Success request reject',
          message: 'You have successfully rejected this request',
          duration: 3000
        });

        setTimeout(() => {
          location.reload();
        }, 350);

        return of(action);
      }),
      catchError((errResponse) => {
        const action: UpdateAction = {
          type: UpdateActions.REJECT_REQUEST_EDIT_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to reject request',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to reject request',
            message: message,
            duration: 3000
          });
        } 
        
        return of(action);
      })
    );
  })
);

const approveDelete: Epic = approveEdit$ => approveEdit$.pipe(
  ofType<UpdateAction>(UpdateActions.APPROVE_REQUEST_DELETE),
  switchMap((action: UpdateAction) => {
    const promise = Axios.put(
      `${API_ENDPOINT}/update-request/delete`,
      {
        remittanceId: action.payload.remittanceId,
        responseReason: action.payload.responseReason
      },
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {

        const action: UpdateAction = {
          type: UpdateActions.APPROVE_REQUEST_DELETE_SUCCESS,
        }

        showToast({
          title: 'Success request approve',
          message: 'You have successfully approved this request',
          duration: 3000
        });

        setTimeout(() => {
          location.reload();
        }, 350);

        return of(action);
      }),
      catchError((errResponse) => {
        const action: UpdateAction = {
          type: UpdateActions.APPROVE_REQUEST_DELETE_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to approve delete',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to approve delete',
            message: message,
            duration: 3000
          });
        } 
        
        return of(action);
      })
    );
  })
);

const rejectDelete: Epic = approveEdit$ => approveEdit$.pipe(
  ofType<UpdateAction>(UpdateActions.REJECT_REQUEST_DELETE),
  switchMap((action: UpdateAction) => {
    const promise = Axios.put(
      `${API_ENDPOINT}/update-request/delete/reject`,
      {
        remittanceId: action.payload.remittanceId,
        responseReason: action.payload.responseReason
      },
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {

        const action: UpdateAction = {
          type: UpdateActions.REJECT_REQUEST_DELETE_SUCCESS,
        }

        showToast({
          title: 'Success request reject',
          message: 'You have successfully rejected this request',
          duration: 3000
        });

        setTimeout(() => {
          location.reload();
        }, 350);

        return of(action);
      }),
      catchError((errResponse) => {
        const action: UpdateAction = {
          type: UpdateActions.REJECT_REQUEST_DELETE_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to reject request',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to reject request',
            message: message,
            duration: 3000
          });
        } 
        
        return of(action);
      })
    );
  })
);

// ! Make sure to add created epics to array 

updateEffects.push(getEditGridData);

updateEffects.push(approveEdit);
updateEffects.push(approveDelete);
updateEffects.push(rejectEdit);
updateEffects.push(rejectDelete);

export default updateEffects;
