import { h, Component } from 'preact';
import cx from 'classnames';

import { ModalProps, Modal } from '../common/modal';
import { Subject, Subscription } from 'rxjs';
import { FieldList, Field, Form } from '../../../../types/form.model';
import { RequestState, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../types/request-state.model';
import { User } from '../../../../types/user.model';
import { Vehicle } from '../../../../types/vehicle.model';
import { validateForm, cxValidateField } from '../../../../types/validator.model';
import linkform from '../../../utils/linkform';
import { JobOrder } from '../../../../types/job-order.model';
import { CREATE_JOB_ORDER } from '../../reducers/vehicle/vehicle.actions';
import getStore, { appState$ } from '../../store';
import { map } from 'rxjs/operators';

export interface CreateJobOrderModalProps extends ModalProps {
  currentUser?: User;
  vehicle?: Vehicle;
}

interface CreateJobOrderModalState  {
  createJobOrderState?: RequestState;
  form?: Form;
}

export class CreateJobOrderModal extends Modal<CreateJobOrderModalProps, CreateJobOrderModalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props: CreateJobOrderModalProps) {
    super(props);

    this.createForm(props);
  }
  
  componentDidMount() {
    super.componentDidMount();

    const vehicles$ = appState$.pipe(
      map(app => app.vehicles)
    );

    this.subs.push(
      vehicles$.pipe(
        map(vehicles => vehicles.createJobOrderState)
      ).subscribe(reqState => {
        this.setState({
          createJobOrderState: reqState
        });

        if (reqState === REQUEST_SUCCESS) {
          this.doModalClose();
        }
      })
    );

  }

  createForm(props: CreateJobOrderModalProps) {
    let fields: FieldList;

    if (props.vehicle) {
      fields = {
        'vehicleId': new Field([], null, props.vehicle.vehicleId),
        'type': new Field(['required'], null, ''),
        'etaType': new Field(['required'], null, ''),
        'assignedPerson': new Field(['required'], null, ''),
        'notes': new Field([] , null, ''),
      };
    } else {
      fields = {
        'vehicleId': new Field(['required'], null, ''),
        'type': new Field(['required'], null, ''),
        'etaType': new Field(['required'], null, ''),
        'assignedPerson': new Field(['required'], null, ''),
        'notes': new Field([] , null, ''),
      };
    }

    this.form$ = new Subject<Form>();
    this.setState({
      form: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['form'] = form;
      this.setState(state);
    });
  }

  doModalClose = () => {
    this.props.close();
  }

  onSubmit = (e) => {

    e.preventDefault();
    const form = this.state.form;
    validateForm(form);

    if (!this.state.form.isValid) {

      for (let k in form.fields) {
        if (!form.fields[k].isValid) {
          console.log(k);
        }
      }

      return;
    }

    let jobOrder: JobOrder = {
      vehicleId: form.fields['vehicleId'].value,
      type: form.fields['type'].value,
      etaType: form.fields['etaType'].value,
      assignedPerson: form.fields['assignedPerson'].value, 
    }

    const store = getStore();
    store.dispatch({
      type: CREATE_JOB_ORDER,
      payload: {
        jobOrderToCreate: jobOrder
      }
    });

  }

  getSpinnerBlock(state: CreateJobOrderModalState) {
    if (state.createJobOrderState === REQUEST_IN_PROGRESS) {
      return (
        <div class="col offset-md-9 d-flex justify-content-end">
          <div class="spinner-border text-primary mt-3" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }    
  }

  getVehicleIdBlock(props: CreateJobOrderModalProps, state: CreateJobOrderModalState) {
    let form = state.form;
    
    if (props.vehicle) {
      return (
        <div class="col-12 col-md-6 mb-3">
          <label class="anglo-remittance__submit-form__label">Vehicle ID*</label>

          <input type="text" className={ cx('form-control', 
              { 'invalid': cxValidateField(form.fields['vehicleId']) }
            )}
            value={ form.fields['vehicleId'].value }
            placeholder="Vehicle ID"
            disabled={true}
            onChange={ linkform(this.form$, form, 'vehicleId') }/>
        </div>
      );
    } else {
      return (
        <div class="col-12 col-md-6 mb-3">
          <label class="anglo-remittance__submit-form__label">Vehicle ID*</label>

          <input type="text" className={ cx('form-control', 
              { 'invalid': cxValidateField(form.fields['vehicleId']) }
            )}
            value={ form.fields['vehicleId'].value }
            placeholder="Vehicle ID"
            onChange={ linkform(this.form$, form, 'vehicleId') }/>
        </div>
      );
    }
  }

  render(props: CreateJobOrderModalProps, state: CreateJobOrderModalState) {
    let form = state.form;
    
    return (
      <div class="container anglo-modal"
        ref={ node => this.refNode = node }>

        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">
              Create Job Order
            </h3>
          </div>
          <div class="col-3 col-md-2">
            <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>

        <div class="row anglo-modal__body py-3 m-0">
          
          { this.getVehicleIdBlock(props, state) }

          <div class="col-12 col-md-6 mb-3">
            <label class="anglo-remittance__submit-form__label">Type*</label>

            <select
              className={ cx('form-control', 
                { 'invalid': cxValidateField(form.fields['type']) }
              )}
              value={form.fields['type'].value}
              onChange={linkform(this.form$, form, 'type')}>
                <option value="electrician">Electrician</option>
                <option value="latero">Latero</option>
                <option value="mechanic">Mechanic</option>
            </select>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label class="anglo-remittance__submit-form__label">ETA*</label>

            <select
              className={ cx('form-control', 
                { 'invalid': cxValidateField(form.fields['etaType']) }
              )}
              value={form.fields['etaType'].value}
              onChange={linkform(this.form$, form, 'etaType')}>
                <option value="immediate">Immediate</option>
                <option value="long">Long</option>
            </select>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label class="anglo-remittance__submit-form__label">Assigned To*</label>

            <input type="text" className={ cx('form-control', 
                { 'invalid': cxValidateField(form.fields['assignedPerson']) }
              )}
              value={ form.fields['assignedPerson'].value }
              placeholder="Assigned Human Resource"
              onChange={ linkform(this.form$, form, 'assignedPerson') }/>
          </div>

          <div class="col-12">
            <div class="row">
              <div class="col-md-1 offset-md-9">
                {
                  this.getSpinnerBlock(state)
                }
              </div>

              <div class="col-12 col-md-2">

                <button class="btn btn-primary w-100" 
                  onClick={this.onSubmit}
                  disabled={state.createJobOrderState == REQUEST_IN_PROGRESS}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }

}