import { h, Component } from "preact";
import cx from "classnames";

export interface GridPaginationProps {
  pages: number[];
  currentPage: number;
  onPageClicked: Function;
}

interface GridPaginationState {}

export class GridPagination extends Component<
  GridPaginationProps,
  GridPaginationState
> {
  constructor(props) {
    super(props);
  }

  render(props: GridPaginationProps, state: GridPaginationState) {
    const pages = props.pages;
    const currentPage = props.currentPage;

    return (
      <div class="row mt-2">
        <div class="col-12">
          {pages ? (
            <div class="dash-remittance__pagination mb-3">
              <button
                class="btn dash-remittance__pagination__page arrow"
                disabled={currentPage === 1}
                onClick={() => props.onPageClicked(currentPage - 1)}
              >
                <i class="fas fa-chevron-left"></i>
              </button>
              {currentPage > 5 ? (
                <button
                  className={cx("btn dash-remittance__pagination__page", {
                    active: pages[0] === currentPage,
                  })}
                  onClick={() => props.onPageClicked(pages[0])}
                >
                  {pages[0]}
                </button>
              ) : null}
              {currentPage > 5 ? <span class="mx-2">...</span> : null}
              {pages
                ? pages.map((page) => {
                    if (page > currentPage - 5 && page < currentPage + 5) {
                      return (
                        <button
                          className={cx(
                            "btn dash-remittance__pagination__page",
                            { active: page === currentPage }
                          )}
                          onClick={() => props.onPageClicked(page)}
                        >
                          {page}
                        </button>
                      );
                    }
                  })
                : null}
              {currentPage < pages.length - 5 ? (
                <span class="mx-2">...</span>
              ) : null}
              {currentPage < pages.length - 5 ? (
                <button
                  className={cx("btn dash-remittance__pagination__page", {
                    active: pages[pages.length - 1] === currentPage,
                  })}
                  onClick={() => props.onPageClicked(pages[pages.length - 1])}
                >
                  {pages[pages.length - 1]}
                </button>
              ) : null}
              <button
                class="btn dash-remittance__pagination__page arrow"
                disabled={currentPage === pages[pages.length - 1]}
                onClick={() => props.onPageClicked(currentPage + 1)}
              >
                <i class="fas fa-chevron-right"></i>
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
