import { h, Component } from "preact";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import moment from "moment-timezone";
import Portal from "preact-portal";

import { PurchaseDocumentGrid } from "./grids/purchase-document.grid";
import { SupplierGrid } from "./grids/supplier.grid";
import { ItemGrid } from "./grids/item.grid";
import { ModalActionKey } from "../common/modal";
import { ViewPDModal } from "./modal/view-pd.modal";
import { CreatePDModal } from "./modal/create-pd.modal";
import { UpdatePDModal } from "./modal/update-pd.modal";
import { SignPDModal } from "./modal/sign-pd.modal";
import { CreateRRModal } from "./modal/create-rr.modal";
import { CreateSupplierModal } from "./modal/create-supplier.modal";
import { EditSupplierModal } from "./modal/edit-supplier.modal";
import { CreatePOModal } from "./modal/create-po.modal";
import { CreateItemModal } from "./modal/create-item.modal";
import { JOB_ORDER_MODAL_CREATE_JO } from "./job-order.dashboard";
import { CreateJOModal } from "./modal/create-jo.modal";
import { ApprovePOModal } from "./modal/approve-order.modal";

export const MOTORPOOL_MODAL_VIEW_PD: string = "modal-view-pd";
export const MOTORPOOL_MODAL_CREATE_PD: string = "modal-create-pd";
export const MOTORPOOL_MODAL_UPDATE_PD: string = "modal-update-pd";
export const MOTORPOOL_MODAL_SIGN_PD: string = "modal-sign-pd";
export const MOTORPOOL_MODAL_CREATE_PO: string = "modal-create-po";
export const MOTORPOOL_MODAL_APPROVE_PO: string = "modal-approve-po";
export const MOTORPOOL_MODAL_CREATE_RR: string = "modal-create-rr";
export const MOTORPOOL_MODAL_CREATE_SUPPLIER: string = "modal-create-supplier";
export const MOTORPOOL_MODAL_EDIT_SUPPLIER: string = "modal-edit-supplier";
export const MOTORPOOL_MODAL_CREATE_ITEM: string = "modal-create-item";

export interface DashboardState {
  modalAction: ModalActionKey;
}

export class MotorpoolDashboard extends Component<any, DashboardState> {
  subs: Subscription[] = [];
  pdGridFilters: Map<string, any>;
  refreshPDGrid: Function;

  constructor(props) {
    super(props);

    this.pdGridFilters = new Map();
    this.setState({
      modalAction: {
        name: "",
        parameters: null,
      },
    });
  }

  onModalAction = (modalData: ModalActionKey) => {
    this.setState({
      modalAction: modalData,
    });
  };

  onModalClose = () => {
    console.log("close");
    this.setState({
      modalAction: {
        name: "",
        parameters: null,
      },
    });

    this.refreshPDGrid();
  };

  onCreatePD = () => {
    const modalData: ModalActionKey = {
      name: MOTORPOOL_MODAL_CREATE_PD,
      parameters: null,
    };

    this.setState({
      modalAction: modalData,
    });
  };

  onCreateJO = () => {
    const modalData: ModalActionKey = {
      name: JOB_ORDER_MODAL_CREATE_JO,
      parameters: null,
    };

    this.setState({
      modalAction: modalData,
    });
  };

  onCreateItem = () => {
    const modalData: ModalActionKey = {
      name: MOTORPOOL_MODAL_CREATE_ITEM,
      parameters: null,
    };

    this.setState({
      modalAction: modalData,
    });
  };

  onCreateSupplier = () => {
    const modalData: ModalActionKey = {
      name: MOTORPOOL_MODAL_CREATE_SUPPLIER,
      parameters: null,
    };

    this.setState({
      modalAction: modalData,
    });
  };

  passRefreshPDGrid = (returned: Function) => {
    this.refreshPDGrid = returned;
  };

  getPortalModal(state: DashboardState) {
    let modalState = state.modalAction;

    switch (modalState.name) {
      case MOTORPOOL_MODAL_VIEW_PD:
        return (
          <Portal into="body">
            <div class="anglo-modal-container" id="anglo-modal-124">
              <ViewPDModal
                close={this.onModalClose}
                isOutsideClickClose={true}
                pd={modalState.parameters.get("pd")}
              />
            </div>
          </Portal>
        );
      case MOTORPOOL_MODAL_CREATE_PD:
        return (
          <Portal into="body">
            <div class="anglo-modal-container" id="anglo-modal-124">
              <CreatePDModal
                close={this.onModalClose}
                isOutsideClickClose={true}
              />
            </div>
          </Portal>
        );
      case MOTORPOOL_MODAL_UPDATE_PD:
        return (
          <Portal into="body">
            <div class="anglo-modal-container" id="anglo-modal-124">
              <UpdatePDModal
                pd={modalState.parameters.get("pd")}
                close={this.onModalClose}
                isOutsideClickClose={true}
              />
            </div>
          </Portal>
        );
      case MOTORPOOL_MODAL_SIGN_PD:
        return (
          <Portal into="body">
            <div class="anglo-modal-container" id="anglo-modal-124">
              <SignPDModal
                pd={modalState.parameters.get("pd")}
                signAction={modalState.parameters.get("signAction")}
                close={this.onModalClose}
                isOutsideClickClose={true}
              />
            </div>
          </Portal>
        );
      case MOTORPOOL_MODAL_CREATE_RR:
        return (
          <Portal into="body">
            <div class="anglo-modal-container" id="anglo-modal-124">
              <CreateRRModal
                pd={modalState.parameters.get("pd")}
                close={this.onModalClose}
                isOutsideClickClose={true}
              />
            </div>
          </Portal>
        );
      case MOTORPOOL_MODAL_CREATE_SUPPLIER:
        return (
          <Portal into="body">
            <div class="anglo-modal-container" id="anglo-modal-124">
              <CreateSupplierModal
                close={this.onModalClose}
                isOutsideClickClose={true}
              />
            </div>
          </Portal>
        );
      case MOTORPOOL_MODAL_EDIT_SUPPLIER:
        return (
          <Portal into="body">
            <div class="anglo-modal-container" id="anglo-modal-124">
              <EditSupplierModal
                supplier={modalState.parameters.get("supplier")}
                close={this.onModalClose}
                isOutsideClickClose={true}
              />
            </div>
          </Portal>
        );
      case MOTORPOOL_MODAL_CREATE_ITEM:
        return (
          <Portal into="body">
            <div class="anglo-modal-container" id="anglo-modal-124">
              <CreateItemModal
                close={this.onModalClose}
                isOutsideClickClose={true}
              />
            </div>
          </Portal>
        );
      case JOB_ORDER_MODAL_CREATE_JO:
        return (
          <Portal into="body">
            <div class="anglo-modal-container" id="anglo-modal-124">
              <CreateJOModal
                close={this.onModalClose}
                isOutsideClickClose={true}
              />
            </div>
          </Portal>
        );
      case MOTORPOOL_MODAL_CREATE_PO:
        return (
          <Portal into="body">
            <div class="anglo-modal-container" id="anglo-modal-124">
              <CreatePOModal
                pd={modalState.parameters.get("pd")}
                signAction={modalState.parameters.get("signAction")}
                close={this.onModalClose}
                isOutsideClickClose={true}
              />
            </div>
          </Portal>
        );
      case MOTORPOOL_MODAL_APPROVE_PO:
        return (
          <Portal into="body">
            <div class="anglo-modal-container" id="anglo-modal-124">
              <ApprovePOModal
                pd={modalState.parameters.get("pd")}
                close={this.onModalClose}
                isOutsideClickClose={true}
              />
            </div>
          </Portal>
        );
      default:
        return null;
    }
  }

  getActionsBlock(state: DashboardState) {
    return (
      <div className="row mb-3">
        <div className="col-3">
          <button class="btn btn-outline-dark w-100" onClick={this.onCreatePD}>
            Create Purchase Document
          </button>
        </div>
        <div className="col-3">
          <button
            class="btn btn-outline-dark w-100"
            onClick={this.onCreateItem}
          >
            Create New Item
          </button>
        </div>
        <div className="col-3">
          <button
            class="btn btn-outline-dark w-100"
            onClick={this.onCreateSupplier}
          >
            Create Supplier
          </button>
        </div>
        <div className="col-3">
          <button class="btn btn-outline-dark w-100" onClick={this.onCreateJO}>
            Create Job Order
          </button>
        </div>
      </div>
    );
  }

  render(props: any, state: DashboardState) {
    return (
      <div class="anglo-remittance">
        {this.getPortalModal(state)}

        <div class="container-fluid">
          <hr />
          <p>
            <b>Quick Actions</b>
          </p>
          {this.getActionsBlock(state)}
          <hr />
          <p>
            <b>Purchase Documents</b>
          </p>
          <PurchaseDocumentGrid
            initialFilters={this.pdGridFilters}
            onModalAction={this.onModalAction}
            refreshGrid={this.passRefreshPDGrid}
          />
          <hr />
          <p>
            <b>Inventory</b>
          </p>
          <ItemGrid
            initialFilters={this.pdGridFilters}
            onModalAction={this.onModalAction}
          />
          <hr />
          <p>
            <b>Suppliers</b>
          </p>
          <SupplierGrid
            initialFilters={this.pdGridFilters}
            onModalAction={this.onModalAction}
          />
        </div>
      </div>
    );
  }
}
