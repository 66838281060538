import { h, Component } from "preact";

export const getSpinnerBlock = (isVisible: boolean) => {
  if (isVisible) {
    return (
      <div class="col offset-md-9 d-flex justify-content-end">
        <div class="spinner-border text-primary mt-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
};
