import { Epic, ofType } from "redux-observable";

import * as RemittanceActions from './remittance.actions'
import { RemittanceAction } from './remittance.actions';

import { switchMap, mergeMap, catchError, map, withLatestFrom } from "rxjs/operators";
import { from, of } from "rxjs";

import { showToast } from '../../../utils/toast.service';
import Axios from "axios";
import { Remittance } from "../../../../types/remittance.model";

import { API_ENDPOINT } from '../../../../constants/api';
import { TotalCount } from "../../../../types/total-count.model";
import { appState$ } from "../../store";

let remittanceEffects: Epic[] = [];

const submitRemit: Epic = submitRemit$ => submitRemit$.pipe(
  ofType<RemittanceAction>(RemittanceActions.SUBMIT_REMITTANCE),
  switchMap((action: RemittanceAction) => {
    

    const promise = Axios.post<Remittance>(
      `${API_ENDPOINT}/remittance`,
      {
        data: action.payload.remittancesToUpload
      }, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: RemittanceAction = {
          type: RemittanceActions.SUBMIT_REMITTANCE_SUCCESS,
        }

        showToast({
          title: 'Success remittance submit',
          message: 'You have successfully uploaded your remittances',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit remittance',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to submit remittance',
            message: message,
            duration: 3000
          });
        } 

        const action: RemittanceAction = {
          type: RemittanceActions.SUBMIT_REMITTANCE_FAIL
        }
    
        return of(action);

      })
    );

  })
);

const getGridData: Epic = getGridData$ => getGridData$.pipe(
  ofType<RemittanceAction>(RemittanceActions.GET_GRID_REMITTANCE),
  withLatestFrom(appState$),
  switchMap(([action, state]) => {

    let retrievedFilters;
    const stateFilters = state.remittance.filters;
    let filters = '?';

    if (action.payload && action.payload.filters) {

      if (stateFilters) {
        retrievedFilters = {
          ...action.payload.filters,
          ...stateFilters
        };
      } else {
        retrievedFilters = action.payload.filters;
      }

      let index = 0;

      for (let k in retrievedFilters) {
        if (index > 0) {
          filters = filters.concat('&');
        }

        filters = filters.concat(`${k}=${retrievedFilters[k]}`);
        index++;
      }
      
    } else if (stateFilters) {
      let index = 0;
      for (let k in stateFilters) {
        if (index > 0) {
          filters = filters.concat('&');
        }

        filters = filters.concat(`${k}=${stateFilters[k]}`);
        index++;
      }
    }

    const promise = Axios.get<Remittance[]>(
      `${API_ENDPOINT}/remittance${filters}`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const gridData = JSON.parse(JSON.stringify(response.data['results']));

        let action: RemittanceAction;

        if (gridData['totalCount'] && gridData['totalCount'][0]) {
          action = {
            type: RemittanceActions.GET_GRID_REMITTANCE_SUCCESS,
            payload: {
              remittanceGridData: gridData['data'],
              total: gridData['totalCount'][0].count
            }
          }
        } else {
          action = {
            type: RemittanceActions.GET_GRID_REMITTANCE_SUCCESS,
            payload: {
              remittanceGridData: [],
              total: 0
            }
          }
        }

        if (retrievedFilters) {
          action.payload.filters = retrievedFilters;
        }
        
        return of(action);
      }),
      catchError((errResponse) => {
        const action: RemittanceAction = {
          type: RemittanceActions.GET_GRID_REMITTANCE_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to get grid data',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to get grid data',
            message: message,
            duration: 3000
          });
        } 
        
        return of(action);
      })
    );
  })
);

const getReportGridData: Epic = getReportGridData$ => getReportGridData$.pipe(
  ofType<RemittanceAction>(RemittanceActions.GET_REPORT_GRID_REMITTANCE),
  switchMap((action: RemittanceAction) => {

    let filters = '?';

    if (action.payload && action.payload.filters) {
      let index = 0;
      action.payload.filters.forEach((v, k) => {

        if (index > 0) {
          filters = filters.concat('&');
        }

        filters = filters.concat(`${k}=${v}`);

        index++;
      });
    }

    const promise = Axios.get<Remittance[]>(
      `${API_ENDPOINT}/remittance${filters}`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const gridData = JSON.parse(JSON.stringify(response.data['results']));

        const action: RemittanceAction = {
          type: RemittanceActions.GET_GRID_REMITTANCE_SUCCESS,
          payload: {
            reportGridData: gridData['data']
          }
        }

        return of(action);
      }),
      catchError((errResponse) => {
        const action: RemittanceAction = {
          type: RemittanceActions.GET_GRID_REMITTANCE_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to get grid data',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to get grid data',
            message: message,
            duration: 3000
          });
        } 
        
        return of(action);
      })
    );
  })
);

const getServersideReport: Epic = getReportGridData$ => getReportGridData$.pipe(
  ofType<RemittanceAction>(RemittanceActions.GENERATE_SERVERSIDE_REPORT),
  switchMap((action: RemittanceAction) => {

    let filters = '?';

    if (action.payload && action.payload.filters) {
      let index = 0;
      action.payload.filters.forEach((v, k) => {

        if (index > 0) {
          filters = filters.concat('&');
        }

        filters = filters.concat(`${k}=${v}`);

        index++;
      });
    }

    const promise = Axios.get<any>(
      `${API_ENDPOINT}/remittance/report${filters}`,
      {
        withCredentials: true,
        responseType: "blob",
        headers: {
          'Accept': 'application/pdf'
        }
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {

        const action: RemittanceAction = {
          type: RemittanceActions.GENERATE_SERVERSIDE_REPORT_SUCCESS
        }
        if (response) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          window.open(URL.createObjectURL(blob));
        }

        return of(action);
      }),
      catchError((errResponse) => {
        const action: RemittanceAction = {
          type: RemittanceActions.GENERATE_SERVERSIDE_REPORT_FAIL,
        };

        showToast({
          title: 'Failed to get grid data',
          message: 'Please contact the system admin.',
          duration: 3000
        });
        
        return of(action);
      })
    );
  })
);

const getOverallTotal: Epic = getOverallTotal$ => getOverallTotal$.pipe(
  ofType<RemittanceAction>(RemittanceActions.GET_REMITTANCE_TOTAL),
  switchMap((action: RemittanceAction) => {

    const promise = Axios.get<TotalCount>(
      `${API_ENDPOINT}/dashboard/remittance/total`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const total = response.data['results'][0];

        const action: RemittanceAction = {
          type: RemittanceActions.GET_REMITTANCE_TOTAL_SUCCESS,
          payload: {
            overallTotal: total
          }
        }

        return of(action);
      }),
      catchError((errResponse) => {
        console.log(errResponse);
        const action: RemittanceAction = {
          type: RemittanceActions.GET_REMITTANCE_TOTAL_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        
        showToast({
          title: 'Failed to get overall total',
          message: message ? message : 'Please contact the system admin.',
          duration: 3000
        })        
        
        return of(action);
      })
    );
  })
);

const getMonthTotal: Epic = getOverallTotal$ => getOverallTotal$.pipe(
  ofType<RemittanceAction>(RemittanceActions.GET_REMITTANCE_TOTAL_MONTH),
  switchMap((action: RemittanceAction) => {

    const promise = Axios.get<any>(
      `${API_ENDPOINT}/dashboard/remittance/month`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const current = response.data['results'].current[0];
        const previous = response.data['results'].previous[0];

        const action: RemittanceAction = {
          type: RemittanceActions.GET_REMITTANCE_TOTAL_MONTH_SUCCESS,
          payload: {
            currentMonthTotal: current,
            previousMonthTotal: previous
          }
        }

        return of(action);
      }),
      catchError((errResponse) => {
        console.log(errResponse);
        const action: RemittanceAction = {
          type: RemittanceActions.GET_REMITTANCE_TOTAL_MONTH_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        
        showToast({
          title: 'Failed to get month total',
          message: message ? message : 'Please contact the system admin.',
          duration: 3000
        })        
        
        return of(action);
      })
    );
  })
);

const getApprovedTotal: Epic = getApprovedTotal$ => getApprovedTotal$.pipe(
  ofType<RemittanceAction>(RemittanceActions.GET_REMITTANCE_TOTAL),
  switchMap((action: RemittanceAction) => {

    const promise = Axios.get<TotalCount>(
      `${API_ENDPOINT}/dashboard/remittance/approved-total`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const total = response.data['results'][0];

        const action: RemittanceAction = {
          type: RemittanceActions.GET_REMITTANCE_TOTAL_SUCCESS,
          payload: {
            overallTotal: total
          }
        }

        return of(action);
      }),
      catchError((errResponse) => {
        console.log(errResponse);
        const action: RemittanceAction = {
          type: RemittanceActions.GET_REMITTANCE_TOTAL_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        
        showToast({
          title: 'Failed to get overall total',
          message: message ? message : 'Please contact the system admin.',
          duration: 3000
        })        
        
        return of(action);
      })
    );
  })
);

const submitEditRequest: Epic = submitEditRequest$ => submitEditRequest$.pipe(
  ofType<RemittanceAction>(RemittanceActions.SUBMIT_EDIT_REQUEST),
  switchMap((action: RemittanceAction) => {
    
    const promise = Axios.post<Remittance>(
      `${API_ENDPOINT}/update-request/edit`,
      action.payload.editRequest, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: RemittanceAction = {
          type: RemittanceActions.SUBMIT_EDIT_REQUEST_SUCCESS,
        }

        showToast({
          title: 'Success remittance edit',
          message: 'You have successfully submitted an edit request',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit edit',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to submit remittance',
            message: message,
            duration: 3000
          });
        } 

        const action: RemittanceAction = {
          type: RemittanceActions.SUBMIT_EDIT_REQUEST_FAIL
        }
    
        return of(action);

      })
    );

  })
);

const submitDeleteRequest: Epic = submitDeleteRequest$ => submitDeleteRequest$.pipe(
  ofType<RemittanceAction>(RemittanceActions.SUBMIT_EDIT_REQUEST),
  switchMap((action: RemittanceAction) => {
    
    const promise = Axios.post<Remittance>(
      `${API_ENDPOINT}/update-request/delete`,
      action.payload.deleteRequest, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: RemittanceAction = {
          type: RemittanceActions.SUBMIT_DELETE_REQUEST_SUCCESS,
        }

        showToast({
          title: 'Success request remittance delete',
          message: 'You have successfully submitted delete edit request',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit delete request',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to submit delete request',
            message: message,
            duration: 3000
          });
        } 

        const action: RemittanceAction = {
          type: RemittanceActions.SUBMIT_DELETE_REQUEST_FAIL
        }
    
        return of(action);

      })
    );

  })
);

const approveRemittance: Epic = approveRemittance$ => approveRemittance$.pipe(
  ofType<RemittanceAction>(RemittanceActions.APPROVE_REMITTANCE),
  switchMap((action: RemittanceAction) => {
    
    const promise = Axios.put<Remittance>(
      `${API_ENDPOINT}/remittance/clear`,
      {
        remittanceId: action.payload.remittanceId,
        receiptNumber: action.payload.receiptNumber
      },
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: RemittanceAction = {
          type: RemittanceActions.APPROVE_REMITTANCE_SUCCESS,
        }

        showToast({
          title: 'Success cleared',
          message: 'You have successfully cleared this remittance',
          duration: 3000
        });

        // setTimeout(() => {
        //   location.reload();
        // }, 350);
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit remittance clearance',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to submit remittance clearance',
            message: message,
            duration: 3000
          });
        } 

        const action: RemittanceAction = {
          type: RemittanceActions.APPROVE_REMITTANCE_FAIL
        }
    
        return of(action);

      })
    );

  })
);

const resetChain: Epic = resetChain$ => resetChain$.pipe(
  ofType<RemittanceAction>(RemittanceActions.APPROVE_REMITTANCE_SUCCESS),
  switchMap(() => {
    const action: RemittanceAction = {
      type: RemittanceActions.RESET_REMITTANCE_STATES
    }

    return of(action);
  })
);

const clearChain: Epic = clearChain$ => clearChain$.pipe(
  ofType<RemittanceAction>(RemittanceActions.APPROVE_REMITTANCE_SUCCESS),
  switchMap(() => {
    const action: RemittanceAction = {
      type: RemittanceActions.GET_GRID_REMITTANCE
    }

    return of(action);
  })
);

// ! Make sure to add created epics to array 

remittanceEffects.push(submitRemit);
remittanceEffects.push(getOverallTotal);
remittanceEffects.push(getMonthTotal);
remittanceEffects.push(getGridData);
remittanceEffects.push(submitEditRequest);
remittanceEffects.push(submitDeleteRequest);
remittanceEffects.push(approveRemittance);
remittanceEffects.push(resetChain);
remittanceEffects.push(getReportGridData);
remittanceEffects.push(getServersideReport);

remittanceEffects.push(clearChain);

export default remittanceEffects;
