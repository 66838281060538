import { h, render } from 'preact';

export const TOAST_TYPE_DEFAULT = 0;
export const TOAST_TYPE_ERROR = 1;

export interface ToastParams {
  message?: string;
  title?: string;
  duration?: number;
  type?: number;
}

function createToastContainer() {
  let vdom, toastContainer;
  
  vdom = h(
    'div',
    {
      class: 'anglo-toast__container',
      id: 'angloToastContainer'
    }
  );

  toastContainer = render(vdom, document.body);

  return toastContainer;
}

function closeToast(id: string) {
  const element = document.getElementById(id);
  render(null, element, element);
}

const onToastClick = (id: string) => {
  closeToast(id);
}

function getToastTypeClass(type: number) {
  switch(type) {
    case TOAST_TYPE_ERROR:
      return 'toast-error';

    case TOAST_TYPE_DEFAULT:
    default:
      return '';
  }
}

export function showToast(params: ToastParams) {
  const id = `anglo-toast-${Math.floor(Math.random() * 1000)}`;
  let toast: any, vdom;
  let toastContainer: any = document.getElementById('angloToastContainer');

  if (!toastContainer) {
    toastContainer = createToastContainer();
  }

  const duration = params.duration ? params.duration : 3000;

  const toastTitleClasses = 'anglo-toast__title toast-header';
  const toastMessageClasses = 'anglo-toast__message toast-body';
  const toastType: number = params.type ? params.type : TOAST_TYPE_DEFAULT;

  vdom = h(
    'div',
    {
      class: `anglo-toast toast ${getToastTypeClass(toastType)}`,
      id: id,
      onClick: ((e) => onToastClick(id))
    },
    [
      h('span', { class: toastTitleClasses }, params.title),
      h('p', { class: toastMessageClasses }, params.message)
    ]
  );

  toast = render(vdom, toastContainer);

  setTimeout(() => {

    // console.log('unmounting toast');
    render(null, toast, toast);
    // call close

  }, duration);

}