import { Epic, ofType } from "redux-observable";

import * as DisposalActions from './disposals.actions'
import { DisposalAction } from './disposals.actions';

import { switchMap, mergeMap, catchError, withLatestFrom } from "rxjs/operators";
import { from, of } from "rxjs";

import { showToast, TOAST_TYPE_ERROR } from '../../../utils/toast.service';
import Axios from "axios";
import { AuditLog } from "../../../../types/audit-log.model";

import { API_ENDPOINT } from '../../../../constants/api';
import { TotalCount } from "../../../../types/total-count.model";
import { appState$ } from "../../store";
import { Withdrawal } from "../../../../types/withdrawal.model";
import { REQUEST_SUCCESS, REQUEST_FAILED } from "../../../../types/request-state.model";
import { BrandPrice } from "../../../../types/brand-price.model";
import { Disposal } from "../../../../types/disposal.model";
import { Location } from "../../../../types/location.model";

let withdrawalEffects: Epic[] = [];

const getGridData: Epic = getGridData$ => getGridData$.pipe(
  ofType<DisposalAction>(DisposalActions.GET_GRID_DISPOSAL),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    /*
      Let's make a rule that the filter that is passed in the action
      contains both old and new. Meaning, we will never need to do
      the combination of current + new filters.
    // */

    let filters: string = '?';
    const currentFilters = action.payload.filters;
    let index = 0;
    currentFilters.forEach((value, key) => {
      if (index === 0) {
        filters = filters.concat(`${key}=${value}`);
      } else {
        filters = filters.concat(`&${key}=${value}`);
      }
      index++;
    });

    const promise = Axios.get<Disposal[]>(
      `${API_ENDPOINT}/disposal${filters}`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const gridData = JSON.parse(JSON.stringify(response.data['results']));

        const action: DisposalAction = {
          type: DisposalActions.GET_GRID_DISPOSAL_SUCCESS,
          payload: {
            disposals: gridData.data,
            total: gridData['totalCount'] && gridData['totalCount'][0] ? gridData['totalCount'][0].count : 0,
            gridRequestState: REQUEST_SUCCESS
          }
        }

        return of(action);
      }),
      catchError((errResponse) => {
        console.log(errResponse);
        const action: DisposalAction = {
          type: DisposalActions.GET_GRID_DISPOSAL_FAIL,
          payload: {
            gridRequestState: REQUEST_FAILED
          }
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response && errResponse.response.status == 400) {
          showToast({
            title: 'Failed to get withdrawal grid data',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to get withdrawal grid data',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        } 
        
        return of(action);
      })
    );
  })
);

const createDisposal: Epic = createDisposal$ => createDisposal$.pipe(
  ofType<DisposalAction>(DisposalActions.CREATE_DISPOSAL),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const disposal: Disposal = action.payload.disposalToCreate;
    
    const promise = Axios.post<Withdrawal>(
      `${API_ENDPOINT}/disposal`,
      disposal, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: DisposalAction = {
          type: DisposalActions.CREATE_DISPOSAL_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Withdrawal submission',
          message: 'You have successfully submitted a Disposal',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit Disposal',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to submit Disposal',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        } 

        const action: DisposalAction = {
          type: DisposalActions.CREATE_DISPOSAL_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const resetDisposalChain: Epic = resetWithdrawalChain$ => resetWithdrawalChain$.pipe(
  ofType<DisposalAction>(
    DisposalActions.CREATE_DISPOSAL_SUCCESS,
    DisposalActions.CREATE_LOCATION_SUCCESS
  ),
  switchMap(() => {
    const action: DisposalAction = {
      type: DisposalActions.RESET_DISPOSAL_STATE
    }

    return of(action);
  })
);

const createLocation: Epic = createLocation$ => createLocation$.pipe(
  ofType<DisposalAction>(DisposalActions.CREATE_LOCATION),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const locationToCreate: Location = action.payload.locationToCreate;
    
    const promise = Axios.post<Location>(
      `${API_ENDPOINT}/location`,
      locationToCreate, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: DisposalAction = {
          type: DisposalActions.CREATE_LOCATION_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Location creation',
          message: 'You have successfully added a new location.',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to create Location',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to create Location',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        } 

        const action: DisposalAction = {
          type: DisposalActions.CREATE_LOCATION_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);


// ! Make sure to add created epics to array 

// withdrawalEffects.push(getBrandPrices);
withdrawalEffects.push(getGridData);
withdrawalEffects.push(createDisposal);
withdrawalEffects.push(createLocation);
// withdrawalEffects.push(rejectWithdrawal);
// withdrawalEffects.push(updateWithdrawal);
// withdrawalEffects.push(reeditWithdrawal);
// withdrawalEffects.push(reeditAcceptWithdrawal);
// withdrawalEffects.push(reeditDeclineWithdrawal);
withdrawalEffects.push(resetDisposalChain);

export default withdrawalEffects;
