import { MicroscopeState, MicroscopeAction } from "./microscope.actions";
import * as Actions from './microscope.actions';

import { REQUEST_SUCCESS, REQUEST_FAILED, REQUEST_IN_PROGRESS } from '../../../../types/request-state.model';


const initialState: MicroscopeState = {

}

export default function Microscope(
  state: MicroscopeState = initialState,
  action: MicroscopeAction
) {
  switch (action.type) {
    case Actions.RETRIEVE_GRAPH_DATA:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          retrieveGraphDataStatus: REQUEST_IN_PROGRESS
        }
      );

    case Actions.RETRIEVE_GRAPH_DATA_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          retrieveGraphDataStatus: REQUEST_SUCCESS
        }
      );

    case Actions.RETRIEVE_GRAPH_DATA_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          retrieveGraphDataStatus: REQUEST_FAILED
        }
      );

    default:
      return state;
  }
}