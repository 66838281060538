import { h, Component } from 'preact';
import { route } from 'preact-router';
import getStore from '../../store';
import { LOGOUT } from '../../reducers/user/user.actions';

export interface NavbarProps {
  sidenavMenuCallback: Function;
}

export interface NavbarState {
  
}

export class Navbar extends Component<NavbarProps, NavbarState> {
  constructor(props: NavbarProps) {
    super(props);
  }

  onMenuButtonClicked = () => {
    this.props.sidenavMenuCallback();
    
  }

  onLogout = () => {
    getStore().dispatch({
      type: LOGOUT
    });
  }  

  onSubmitRemit = () => {
    route('/remittance/submit');
  }

  render(props: NavbarProps, state: NavbarState) {
    return (
      <div class="anglo-navbar">
        <div class="anglo-navbar__contents">
          <div class="container-fluid h-100">
            <div class="row h-100 align-items-center">
              <div class="col-2 d-xl-none anglo-navbar__burger-menu d-flex justify-content-center">
                <button class="btn btn-icon" onClick={ this.onMenuButtonClicked }>
                  <i class="fas fa-bars"></i>
                </button>
              </div>

              <div class="col-4 col-xl-2 anglo-navbar__logo">
                <img src="/assets/aatcLogo.jpg" alt=""/>
              </div>
              
              <div class={`
                  col-6 col-xl-4 offset-xl-6 
                  anglo-navbar__post-property 
                  d-flex justify-content-center
                  d-md-flex justify-content-md-end
                `}>

                <button class="d-none d-md-block btn btn-light mr-3" onClick={ this.onLogout }>
                  Logout
                </button>

                <button class="btn btn-primary" onClick={ this.onSubmitRemit }>
                  Submit a Remittance
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="anglo-navbar__padding">

        </div>
      </div>
    );
  }
} 