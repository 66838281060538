import { h, Component } from 'preact';
import cx from 'classnames';

import { ModalProps, Modal } from './modal';
import { User } from '../../../../types/user.model';
import { RequestState, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../types/request-state.model';

import { FieldList, Field, Form } from '../../../../types/form.model';
import { Subscription, Subject } from 'rxjs';

import * as Validators from '../../../../types/validator.model';
import { LOCATION_TYPE_BUILDING, LOCATION_TYPE_AREA, LOCATION_TYPE_CITY, LOCATION_TYPE_REGION, LOCATION_TYPE_STREET, Location } from '../../../../types/location.model';
import linkform from '../../../utils/linkform';
import getStore, { appState$ } from '../../store';
import { CREATE_LOCATION } from '../../reducers/disposals/disposals.actions';
import { distinctUntilChanged, map } from 'rxjs/operators';

export interface CreateLocationModalProps extends ModalProps {
  currentUser?: User;
}

interface CreateLocationModalState  {
  createLocationState?: RequestState;
  form?: Form;
  isPostInProgress?: boolean;
}

export class CreateLocationModal extends Modal<CreateLocationModalProps, CreateLocationModalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor() {
    super();

    this.createForm();
  }

  componentDidMount() {
    super.componentDidMount();

    const disposal$ = appState$.pipe(
      map(appState => appState.disposal)
    );

    this.subs.push(
      disposal$.pipe(
        map(withdrawal => withdrawal.postPutRequestState),
        distinctUntilChanged()
      ).subscribe(postPutRequestState => {
        if (postPutRequestState === REQUEST_SUCCESS) {

          this.setState({
            isPostInProgress: false
          });
          this.props.close();

        } else if (postPutRequestState === REQUEST_IN_PROGRESS) {
          this.setState({
            isPostInProgress: true
          });
        } else {
          this.setState({
            isPostInProgress: false
          });
        }
      })
    );
  }

  doModalClose = () => {
    this.props.close();
  }

  isBuildingValidator: Validators.Validator = (field: Field, form: Form) => {
    if (form.fields['type'].value === LOCATION_TYPE_BUILDING) {
      return (field.value ? true : false);
    }
  }

  isStreetValidator: Validators.Validator = (field: Field, form: Form) => {
    if (form.fields['type'].value === LOCATION_TYPE_STREET) {
      return (field.value ? true : false);
    }
  }

  isAreaValidator: Validators.Validator = (field: Field, form: Form) => {
    if (
      form.fields['type'].value === LOCATION_TYPE_STREET ||
      form.fields['type'].value === LOCATION_TYPE_AREA
    ) {
      return (field.value ? true : false);
    }
  }

  isCityValidator: Validators.Validator = (field: Field, form: Form) => {
    if (
      form.fields['type'].value === LOCATION_TYPE_CITY 
    ) {
      return (field.value ? true : false);
    }
  }

  isRegionValidator: Validators.Validator = (field: Field, form: Form) => {
    if (
      form.fields['type'].value === LOCATION_TYPE_STREET ||
      form.fields['type'].value === LOCATION_TYPE_AREA ||
      form.fields['type'].value === LOCATION_TYPE_CITY ||
      form.fields['type'].value === LOCATION_TYPE_REGION
    ) {
      return (field.value ? true : false);
    }
  }

  createForm() {
    let fields: FieldList = {
      'type': new Field([Validators.required], null, ''),
      'region': new Field([], [this.isRegionValidator], ''),
      'city': new Field([], [this.isCityValidator], ''),
      'area': new Field([], [this.isAreaValidator], ''),
      'street': new Field([], [this.isStreetValidator], ''),

      'building': new Field([], [this.isBuildingValidator], ''),

      'name': new Field([Validators.required], null, ''),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      form: new Form(fields)
    });

    this.form$.subscribe((form) => {
      Validators.validateForm(form);

      let state = {};
      state['form'] = form;
      this.setState(state);
    });
  }

  isRegionVisible(form: Form) {
    let type: string = form.fields['type'].value;

    switch (type) {
      case LOCATION_TYPE_REGION:
      case LOCATION_TYPE_CITY:
      case LOCATION_TYPE_AREA:
      case LOCATION_TYPE_STREET:
      case LOCATION_TYPE_BUILDING:
        return true;

      default: 
        return false;
    }
  }

  isCityVisible(form: Form) {
    let type: string = form.fields['type'].value;

    switch (type) {
      case LOCATION_TYPE_CITY:
      case LOCATION_TYPE_AREA:
      case LOCATION_TYPE_STREET:
      case LOCATION_TYPE_BUILDING:
        return true;

      default: 
        return false;
    }
  }

  isAreaVisible(form: Form) {
    let type: string = form.fields['type'].value;

    switch (type) {
      case LOCATION_TYPE_AREA:
      case LOCATION_TYPE_STREET:
      case LOCATION_TYPE_BUILDING:
        return true;

      default: 
        return false;
    }
  }

  isStreetVisible(form: Form) {
    let type: string = form.fields['type'].value;

    switch (type) {
      case LOCATION_TYPE_STREET:
      case LOCATION_TYPE_BUILDING:
        return true;

      default: 
        return false;
    }
  }

  isBuildingVisible(form: Form) {
    let type: string = form.fields['type'].value;

    switch (type) {
      case LOCATION_TYPE_BUILDING:
        return true;

      default: 
        return false;
    }
  }

  isFormValid(form: Form) {
    Validators.validateForm(form);

    return form.isValid;
  }

  getLocationName(form: Form) {
    let type: string = form.fields['type'].value;

    switch (type) {
      case LOCATION_TYPE_REGION:
        return form.fields['region'].value;
      case LOCATION_TYPE_CITY:
        return form.fields['city'].value;
      case LOCATION_TYPE_AREA:
        return form.fields['area'].value;
      case LOCATION_TYPE_STREET:
        return form.fields['street'].value;
      case LOCATION_TYPE_BUILDING:
        return form.fields['building'].value;

      default: 
        return null;
    }
  }

  onSubmit = () => {
    let form: Form = this.state.form;

    if (!this.isFormValid(form)) {
      return;
    }

    const location: Location = {
      // name: this.getLocationName(form),
      name: form.fields['name'].value,

      region: form.fields['region'].value,
      city: form.fields['city'].value,
      area: form.fields['area'].value,
      street: form.fields['street'].value,
      building: form.fields['building'].value,

      type: form.fields['type'].value,
    }

    const store = getStore();
    store.dispatch({
      type: CREATE_LOCATION,
      payload: {
        locationToCreate: location
      }
    });
  }

  getLocationFieldSetsBlock(state: CreateLocationModalState) {
    let form = state.form;

    return (
      <div class="col-12">
        <div class="row">
          { this.getRegionBlock(form) }
          { this.getCityBlock(form) }
          { this.getAreaBlock(form) }
          { this.getStreetBlock(form) }
          { this.getBuildingBlock(form) }
          { this.getNameBlock(form) }
        </div>
      </div>
    );
  }

  getRegionBlock(form: Form) {
    if (this.isRegionVisible(form)) {
      return (
        <div class="col-12 col-md-6 col-lg-4 mb-2">
          <label class="anglo-remittance__submit-form__label">Region*</label>
          <input class="form-control" type="text"
            onInput={linkform(this.form$, form, 'region')}
            value={form.fields['region'].value}
            placeholder="Region Name"/>
        </div>
      );
    }
    return null;
  }

  getCityBlock(form: Form) {
    if (this.isCityVisible(form)) {
      return (
        <div class="col-12 col-md-6 col-lg-4 mb-2">
          <label class="anglo-remittance__submit-form__label">City</label>
          <input class="form-control" type="text"
            onInput={linkform(this.form$, form, 'city')}
            value={form.fields['city'].value}
            placeholder="City Name"/>
        </div>
      );
    }
    return null;
  }

  getAreaBlock(form: Form) {
    if (this.isAreaVisible(form)) {
      return (
        <div class="col-12 col-md-6 col-lg-4 mb-2">
          <label class="anglo-remittance__submit-form__label">Area/Barangay*</label>
          <input class="form-control" type="text"
            onInput={linkform(this.form$, form, 'area')}
            value={form.fields['area'].value}
            placeholder="Area or Barangay Name"/>
        </div>
      );
    }
    return null;
  }

  getStreetBlock(form: Form) {
    if (this.isStreetVisible(form)) {
      return (
        <div class="col-12 col-md-6 col-lg-4 mb-2">
          <label class="anglo-remittance__submit-form__label">Street Address*</label>
          <input class="form-control" type="text"
            onInput={linkform(this.form$, form, 'street')}
            value={form.fields['street'].value}
            placeholder="Lot or Street Address"/>
        </div>
      );
    }
    return null;
  }

  getBuildingBlock(form: Form) {
    if (this.isBuildingVisible(form)) {
      return (
        <div class="col-12 col-md-6 col-lg-4 mb-2">
          <label class="anglo-remittance__submit-form__label">Area/Barangay*</label>
          <input class="form-control" type="text"
            onInput={linkform(this.form$, form, 'city')}
            value={form.fields['city'].value}
            placeholder="Area or Barangay Name"/>
        </div>
      );
    }
    return null;
  }

  getNameBlock(form: Form) {
    if (form.fields['type'].value) {
      return (
        <div class="col-12 col-md-6 col-lg-4 mb-2">
          <label class="anglo-remittance__submit-form__label">Location Name*</label>
          <input class="form-control" type="text"
            onInput={linkform(this.form$, form, 'name')}
            value={form.fields['name'].value}
            placeholder="Location Name"/>
        </div>
      );
    }

    return null;
  }

  getSpinnerBlock() {
    return (
      <div class="col offset-md-9 d-flex justify-content-end">
        <div class="spinner-border text-primary mt-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );  
  }

  getActionsBlock(state: CreateLocationModalState) {
    let form = state.form;

    if (!state.isPostInProgress) {
      if (form.fields['type'].value) {
        return (
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-lg-3 offset-lg-9">
                <button class="btn btn-primary w-100"
                  onClick={this.onSubmit}
                  disabled={!this.isFormValid(form)}>
                  Submit Location
                </button>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return this.getSpinnerBlock();
    }

    return null;
  }

  render(props: CreateLocationModalProps, state: CreateLocationModalState) {
    return (
      <div class="container anglo-modal"
        ref={ node => this.refNode = node }>

        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">
              Create Location
            </h3>
          </div>
          <div class="col-3 col-md-2">
            <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>

        <div class="row anglo-modal__body py-3">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <i>
                  Note: As of February 2021, it is preferred to select <b>AREA/BARANGGAY</b> type when adding a new location.
                </i>
              </div>
            </div>
            <hr/>
            <div class="row">
              <div class="col-12">
                <label class="anglo-remittance__submit-form__label">Location Type*</label>
                  <select className={ cx( "form-control",
                    { 'invalid': Validators.cxValidateField(state.form.fields['type']) }
                  )}
                    placeholder="First select a location type"
                    value={state.form.fields['type'].value}
                    onChange={ linkform(this.form$, state.form, 'type') }>
                    <option value={LOCATION_TYPE_BUILDING}>Building</option>
                    <option value={LOCATION_TYPE_STREET}>Street Address</option>
                    <option value={LOCATION_TYPE_AREA}>Area/Barangay</option>
                    <option value={LOCATION_TYPE_CITY}>City</option>
                    <option value={LOCATION_TYPE_REGION}>Region</option>
                  </select>
              </div>
            </div>

            <div class="row mt-3">
              { this.getLocationFieldSetsBlock(state) }
            </div>

            <div class="row mt-3">
              { this.getActionsBlock(state) }
            </div>
            
          </div>
        </div>

      </div>
    );
  }
}
