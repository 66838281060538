import { Validator } from "./validator.model";
import { Subject } from 'rxjs';

export class Field {
  value?: any;
  isValid?: boolean;
  isPristine?: boolean;
  validators?: string[];
  customValidators?: Validator[];

  constructor(validators?: string[], customValidators?: Validator[], defaultValue?: any) {
    this.isPristine = true;
    this.validators = validators;
    this.customValidators = customValidators;
    this.value = defaultValue;
  }
}

export interface FieldList {
  [fieldName: string]: Field
}

export class Form {
  isValid?: boolean;
  fields: FieldList;

  constructor(fields: any) {
    this.fields = fields;
    this.isValid = false;
  }

  static cloneFrom(form: Form): Form {
    let clonedFields: FieldList = {};
    
    for (let k in form.fields) {
      let prevField = form.fields[k];
      clonedFields[k] = new Field(prevField.validators, prevField.customValidators, prevField.value);
      clonedFields[k].isValid = prevField.isValid;
    }

    let clone: Form = new Form(clonedFields);
    return clone;
  }
}

export const inputToField = (value: any, fieldName: string, form: Form, form$: Subject<Form>) => {

  // Manually do this because in order to follow the 
  // hopplerLinkstate validation system
  let clonedFields: FieldList = {};
  for (let k in form.fields) {
    let prevField = form.fields[k];
    clonedFields[k] = new Field(prevField.validators, prevField.customValidators, prevField.value);
    clonedFields[k].isValid = prevField.isValid;
  }

  let clone: Form = new Form(clonedFields);
  clone.fields[fieldName].value = value;
  clone.fields[fieldName].isPristine = false;
  // clone.fields[fieldName].customValidators.forEach(a => a(clone.fields[fieldName], clone));
  form$.next(clone);
}