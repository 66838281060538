import { h, Component } from "preact";
import cx from "classnames";
import { map, filter, distinctUntilChanged } from "rxjs/operators";
import { Subscription, Subject } from "rxjs";
import linkState from "linkstate";
import moment from "moment-timezone";

import { ModalProps, Modal } from "../../common/modal";
import { Form, FieldList, Field } from "../../../../../types/form.model";
import * as Validators from "../../../../../types/validator.model";
import {
  validateForm,
  cxValidateField,
} from "../../../../../types/validator.model";
import linkform, { updateFormFieldValue } from "../../../../utils/linkform";

import { User } from "../../../../../types/user.model";
import { Loan } from "../../../../../types/loan.model";
import getStore, { appState$ } from "../../../store";
import { AutocompleteSearch } from "../../common/autocomplete-search.component";
import { AutocompleteLocation } from "../../common/autocomplete-location.component";
import { CREATE_LOAN } from "../../../reducers/cashbond-loan/cashbond-loan.actions";
import {
  REQUEST_IN_PROGRESS,
  REQUEST_SUCCESS,
} from "../../../../../types/request-state.model";
import { AutocompleteSector } from "../../common/autocomplete-sector.component";
import { Sector } from "../../../../../types/sector.model";
import {
  ROLE_MANAGEMENT,
  ROLE_SUPERVISOR,
} from "../../../../../constants/role";
import { Agent } from "../../../../../types/agent.model";

export interface CreateLoanModalProps extends ModalProps {
  currentUser?: User;
  loan?: Loan;
}

interface CreateLoanModalState {
  loanForm?: Form;
  isFormVisible?: boolean;
  isEditing?: boolean;
  isPostInProgress?: boolean;
}

export class CreateLoanModal extends Modal<
  CreateLoanModalProps,
  CreateLoanModalState
> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props) {
    super(props);

    this.createForm();
  }

  createForm() {
    let fields: FieldList = {
      location: new Field([Validators.required], null, ""),
      agentId: new Field([Validators.required], null, ""),
      amount: new Field([Validators.required], null, ""),
      description: new Field([], null, ""),
      supervisorUsername: new Field([], null, ""),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      loanForm: new Form(fields),
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state["loanForm"] = form;
      this.setState(state);
    });
  }

  componentDidMount() {
    super.componentDidMount();

    const cashbondloan$ = appState$.pipe(
      map((appState) => appState.cashbondloan)
    );

    this.subs.push(
      cashbondloan$
        .pipe(
          map((cashbondloan) => cashbondloan.postPutRequestState),
          distinctUntilChanged()
        )
        .subscribe((postPutRequestState) => {
          if (postPutRequestState === REQUEST_SUCCESS) {
            this.setState({
              isPostInProgress: false,
            });
            this.props.close();
          } else if (postPutRequestState === REQUEST_IN_PROGRESS) {
            this.setState({
              isPostInProgress: true,
            });
          }
        })
    );
  }

  doModalClose = () => {
    this.props.close();
  };

  onSubmit = () => {
    const form = this.state.loanForm;
    validateForm(form);

    this.setState({
      loanForm: form,
    });

    if (!this.state.loanForm.isValid) {
      return;
    }

    let loan: Loan = {
      agentId: form.fields["agentId"].value,
      // supervisor: this.props.currentUser.supervisor.supervisorId,
      supervisorUsername: form.fields["agentId"].value["supervisorUsername"],
      sectorId: form.fields["location"].value,
      amount: form.fields["amount"].value,
      description: form.fields["description"].value,
    };

    const store = getStore();

    store.dispatch({
      type: CREATE_LOAN,
      payload: {
        loanToCreate: loan,
      },
    });
  };

  onAgentSearchChange = (agent: Agent) => {
    console.log("agent", agent);
    updateFormFieldValue(this.form$, this.state.loanForm, "agentId", agent._id);
  };

  onSupervisorSearchChange = (supervisor: User) => {
    updateFormFieldValue(
      this.form$,
      this.state.loanForm,
      "supervisorUsername",
      supervisor.username
    );
  };

  onLocationSearchChange = (sector: Sector) => {
    updateFormFieldValue(
      this.form$,
      this.state.loanForm,
      "location",
      sector.name
    );
  };

  getFormBlock(props: CreateLoanModalProps, state: CreateLoanModalState) {
    let form = state.loanForm;

    if (form) {
      return (
        <div class="row">
          <div class="col-12 create-loan-modal__form">
            <div class="row">
              <div class="col-12 col-md-6 mt-3">
                <label class="anglo-remittance__submit-form__label">
                  Agent*
                </label>
                <AutocompleteSearch
                  onResultSelected={this.onAgentSearchChange}
                  placeholder="Search by Agent"
                  searchUrl="agent"
                />
              </div>

              <div class="col-12 col-md-6  mt-3">
                <label class="anglo-remittance__submit-form__label">
                  Sector/Location*
                </label>
                <AutocompleteSector
                  onResultSelected={this.onLocationSearchChange}
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-3">
                <label class="anglo-remittance__submit-form__label">
                  Amount*
                </label>
                <input
                  className={cx("form-control", {
                    invalid: cxValidateField(form.fields["amount"]),
                  })}
                  type="text"
                  value={form.fields["amount"].value}
                  onChange={linkform(this.form$, form, "amount")}
                  placeholder="Amount"
                />
              </div>
            </div>

            {/* {
                props.currentUser?.role === ROLE_MANAGEMENT ? 
                <div class='row'>
                  <div class="col-12 col-md-6 mt-3">
                    <label class="anglo-remittance__submit-form__label">Supervisor</label>
                    <AutocompleteSearch 
                      onResultSelected={this.onSupervisorSearchChange}
                      placeholder='Search by Supervisor' />
                  </div>
                </div>
                : null
              } */}

            <div class="row">
              <div class="col-12 mt-3">
                <label class="anglo-remittance__submit-form__label">
                  Notes/Description
                </label>
                <input
                  className={cx("form-control", {
                    invalid: cxValidateField(form.fields["description"]),
                  })}
                  type="text"
                  value={form.fields["description"].value}
                  onChange={linkform(this.form$, form, "description")}
                  placeholder="Description"
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  getSpinnerBlock() {
    return (
      <div class="col offset-md-9 d-flex justify-content-end">
        <div class="spinner-border text-primary mt-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  getActionsRow(props: CreateLoanModalProps, state: CreateLoanModalState) {
    if (state.isPostInProgress) {
      return this.getSpinnerBlock();
    } else {
      return (
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-3 offset-lg-9">
              <button
                class="btn btn-primary w-100"
                onClick={this.onSubmit}
                disabled={!state.loanForm.isValid}
              >
                Submit Loan
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  render(props: CreateLoanModalProps, state: CreateLoanModalState) {
    return (
      <div
        class="container anglo-modal create-disposal-modal"
        ref={(node) => (this.refNode = node)}
      >
        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">Create Loan</h3>
          </div>
          <div class="col-3 col-md-2">
            <button
              class="btn btn-outline-light w-100"
              onClick={this.doModalClose}
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>

        <div class="row anglo-modal__body py-3">
          <div class="col-12 create-withdrawal-modal__body pb-3">
            {this.getFormBlock(props, state)}
          </div>
        </div>

        <div class="row vehicle-card__actions mt-3">
          {this.getActionsRow(props, state)}
        </div>
      </div>
    );
  }
}

/*



*/
