import { h, Component } from 'preact';
import linkState from 'linkstate';
import cx from 'classnames';

import { ModalProps, Modal } from '../common/modal';
import { Remittance } from '../../../../types/remittance.model';
import { Subject, Subscription } from 'rxjs';
import getStore, { appState$ } from '../../store';
import { map } from 'rxjs/operators';

import { RequestState, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../types/request-state.model';
import { FieldList, Field, Form } from '../../../../types/form.model';
import { validateForm, cxValidateField } from '../../../../types/validator.model';
import * as Validators from '../../../../types/validator.model';
import linkform from '../../../utils/linkform';
import { CREATE_BANK_ACCOUNT, UserAction, GET_BANKS, CREATE_USER, CHANGE_PASSWORD } from '../../reducers/user/user.actions';
import { User } from '../../../../types/user.model';
import { APPROVE_REMITTANCE } from '../../reducers/remittance/remittance.actions';

export interface ClearRemittanceModalProps extends ModalProps {
  remittance: Remittance;
}

interface ClearRemittanceModalState  {

  submitStatus?: RequestState;
  form?: Form;
}

export class ClearRemittanceModal extends Modal<ClearRemittanceModalProps, ClearRemittanceModalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props: ClearRemittanceModalProps) {
    super(props);

    this.createForm();
  }

  createForm() {
    let fields: FieldList = {
      'receiptNumber': new Field([Validators.required] , null, ''),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      form: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['form'] = form;
      this.setState(state);
    });
  }

  componentDidMount() {
    super.componentDidMount();

    const remittance$ = appState$.pipe(
      map(app => app.remittance)
    );

    this.subs.push(
      remittance$.pipe(
        map(remittance => remittance.approveRequestState)
      ).subscribe(reqState => {
        this.setState({
          submitStatus: reqState
        });

        if (reqState === REQUEST_SUCCESS) {
          this.doModalClose();
        }
      })
    );

    this.initialStoreDispatches();
  }

  initialStoreDispatches() {

    const store = getStore();

  }

  componentWillUnmount() {
    this.subs.forEach(s => s.unsubscribe());
  }

  doModalClose = () => {
    this.props.close();
  }

  getSpinnerBlock(state: ClearRemittanceModalState) {
    if (state.submitStatus === REQUEST_IN_PROGRESS) {
      return (
        <div class="col offset-md-9 d-flex justify-content-end">
          <div class="spinner-border text-primary mt-3" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }    
  }

  onSubmit = () => {

    let form = this.state.form;

    validateForm(form);

    if (!form.isValid) {
      return;
    }

    const store = getStore();
    store.dispatch({
      type: APPROVE_REMITTANCE,
      payload: {
        remittanceId: this.props.remittance.remittanceId,
        receiptNumber: form.fields['receiptNumber'].value
      }
    });
  }

  render(props: ClearRemittanceModalProps, state: ClearRemittanceModalState) {
    let form = state.form;

    return (
      <div class="container anglo-modal"
          ref={ node => this.refNode = node }>
        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">
              Clear Remittance | <small>{ props.remittance.remittanceId }</small>
            </h3>
          </div>
          <div class="col-3 col-md-2">
            <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        
        <div class="row anglo-modal__body py-3">

          <div class="col-12 mt-2">
            <label class="dash-remittance__filter-row__filters__label">Official Receipt Number*</label>
            <input type="text" className={ cx('form-control', 
              { 'invalid': cxValidateField(form.fields['receiptNumber']) }
            )}
            value={ form.fields['receiptNumber'].value }
            placeholder=""
            onChange={ linkform(this.form$, form, 'receiptNumber') }/>
          </div>

          <div class="col-2 offset-8 mt-4">
            {
              this.getSpinnerBlock(state)
            }
          </div>

          <div class="col-2 mt-4">

            <button class="btn btn-primary w-100" 
              onClick={this.onSubmit}
              disabled={!form.isValid}>
              Clear Remittance
            </button>
          </div>
        </div>

        
      </div>
    );
  }
}