import { h, Component } from 'preact';
import cx from 'classnames';

import { Agent } from '../../../../types/agent.model';

interface AgentListWidgetProps {
  agents: Agent[];
}

interface AgentListWidgetState {
  agents: Agent[];
  isCollapseVisible?: boolean;
}

export class AgentListWidget extends Component<AgentListWidgetProps, AgentListWidgetState> {
  constructor(props: AgentListWidgetProps) {
    super(props);

    this.setState({
      agents: props.agents
    });
  }

  toggleCollapsable = () => {
    this.setState({
      isCollapseVisible: !this.state.isCollapseVisible
    });
  }
  
  render(props: AgentListWidgetProps, state: AgentListWidgetState) {
    return (
      <div class="hi-broker-leaderboard-widget widget-collapsable">
        <div class="row widget-collapsable__toggle-row">
          <div class="col-10">
            <h4 class="hi-broker-leaderboard-widget__name">
              Agent List
            </h4>
          </div>
          <div class="col-2 d-md-none">
            <i className={ cx('fas fa-chevron-down', {
                'visible': state.isCollapseVisible
              })}
              onClick={ this.toggleCollapsable }>
            </i>
          </div>
        </div>

        <div className={ cx('widget-collapsable__body', {
          'visible': state.isCollapseVisible
        }) }>
          <div class="row">
            <div class="col-12">
              <p class="hi-broker-leaderboard-widget__desc">
                All your agents
              </p>
            </div>
          </div>

          <div class="row hi-broker-leaderboard-widget__broker-list mt-4">
            {
              state.agents.map((agent, index) => {
                return (
                  <div class="col-12 hi-broker-leaderboard-widget__broker mb-4">
                    <div class="hi-broker-leaderboard-widget__broker__img">

                    </div>
                    <div class="hi-broker-leaderboard-widget__broker__details">
                      <p class="hi-broker-leaderboard-widget__broker__name">
                        { agent.firstName } { agent.lastName }
                      </p>
                      <p class="hi-broker-leaderboard-widget__broker__counts"> 
                        <span class="hi-broker-leaderboard-widget__broker__counts__inquiry">
                          { agent.isActive ? 'Active' : 'Offline' }
                        </span>
                      </p>
                    </div>
                    <div class="hi-broker-leaderboard-widget__broker__placement">
                      {/* this should be an image but for now, using css magic */}
                      <div class={`hi-broker-leaderboard-widget__broker__placement__badge ${!agent.isActive ? 'offline' : ''}`}>
                        { index + 1 }
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>

        

      </div>
    );
  }
}