import { ItemWithdrawal } from "item-withdrawal.model";
import { ItemPiece } from "item.model";

export const JO_TYPE_LATERO = 'latero';
export const JO_TYPE_ELECTRONICS = 'electronics';
export const JO_TYPE_ENGINE_REPAIRS = 'engine-repairs';
export const JO_TYPE_PARTS_REPLACEMENT = 'parts-replacement';

export const JO_TYPES = [JO_TYPE_LATERO, JO_TYPE_ELECTRONICS, 
  JO_TYPE_ENGINE_REPAIRS, JO_TYPE_PARTS_REPLACEMENT
];

export const JO_STATUS_PENDING = 'pending';
export const JO_STATUS_FULFILLMENT = 'fulfillment';
export const JO_STATUS_ON_HOLD = 'on-hold';
export const JO_STATUS_COMPLETED = 'completed';
export const JO_STATUS_CANCELLED = 'cancelled';

export const JO_STATUSES = [
  JO_STATUS_PENDING, JO_STATUS_FULFILLMENT,
  JO_STATUS_ON_HOLD, JO_STATUS_CANCELLED,
  JO_STATUS_COMPLETED
];

export interface JobOrder {
  jobOrderId?: string;
  vehicleId?: string;

  type?: string;
  status?: string;
  etaType?: string;
  assignedPerson?: string;

  dateCreated?: Date;
  notes?: string;
}

export interface V2_JobOrder {
  jobOrderId?: string;
  itemWithdrawalId?: string;
  // itemList?: ItemPiece[];
  itemWithdrawal?: ItemWithdrawal;
  vehicleId: string;
  description: string;
  type: string;
  status?: string,
  assignedPerson?: string;
  
  approvedBy?: string;
  dateApproved?: Date;

  dateCreated?: Date;
  createdBy?: string;

  dateUpdated?: Date;
  updatedBy?: string;

  isDeleted?: boolean;
}