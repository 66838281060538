import { h, Component } from 'preact';
import cx from 'classnames';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import linkState from 'linkstate';

import { ModalProps, Modal } from '../../common/modal';
import { User } from '../../../../../types/user.model';
import { Withdrawal, WithdrawalBrand, WithdrawalBrandGroup } from '../../../../../types/withdrawal.model';
import moment from 'moment-timezone';

import { Formats, REAMS_PER_CASE, PACKS_PER_REAM } from '../../../../../constants/api';
import { toFixed } from '../../../../utils/decimal';
import { Form, FieldList, Field } from '../../../../../types/form.model';
import { validateForm, cxValidateField } from '../../../../../types/validator.model';
import getStore, { appState$ } from '../../../store';
import { GET_PRICES, WithdrawalAction, CREATE_WITHDRAWAL, REEDIT_WITHDRAWAL } from '../../../reducers/withdrawals/withdrawals.actions';
import { BrandPrice } from '../../../../../types/brand-price.model';
import linkform from '../../../../utils/linkform';
import { showToast, TOAST_TYPE_ERROR } from '../../../../utils/toast.service';
import * as Validators from '../../../../../types/validator.model';
import { REQUEST_SUCCESS, REQUEST_IN_PROGRESS } from '../../../../../types/request-state.model';

export interface CreateWithdrawalModalProps extends ModalProps {
  currentUser?: User;
  withdrawal?: Withdrawal;
  isReedit?: boolean;
}
 
interface CreateWithdrawalModalState {
  brandPrices?: BrandPrice[];
  withdrawalForm?: Form;

  isFormVisible?: boolean;
  brandGroups?: WithdrawalBrandGroup[];
  currentAgentName?: string;

  isEditing?: boolean;
  currentlySelectedGroupIndex?: number;
  currentlySelectedBrandIndex?: number;

  isPostInProgress?: boolean;
}

export class CreateWithdrawalModal extends Modal<CreateWithdrawalModalProps, CreateWithdrawalModalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props) {
    super(props);

    this.setState({
      brandGroups: []
    });

    this.createForm();
  }

  createForm() {
    let fields: FieldList = {
      'name': new Field([Validators.required], null, ''),
      'numOfCases': new Field([Validators.required] , null, ''),
      'numOfReams': new Field([] , null, ''),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      withdrawalForm: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['withdrawalForm'] = form;
      this.setState(state);
    });
  }

  componentDidMount() {
    super.componentDidMount();

    const withdrawal$ = appState$.pipe(
      map(appState => appState.withdrawal)
    );

    this.subs.push(
      withdrawal$.pipe(
        map(withdrawal => withdrawal.brandPrices),
        distinctUntilChanged()
      ).subscribe(prices => {
        this.setState({
          brandPrices: prices
        });
      })
    );

    this.subs.push(
      withdrawal$.pipe(
        map(withdrawal => withdrawal.postPutRequestState),
        distinctUntilChanged()
      ).subscribe(postPutRequestState => {
        if (postPutRequestState === REQUEST_SUCCESS) {

          this.setState({
            isPostInProgress: false
          });
          this.props.close();

        } else if (postPutRequestState === REQUEST_IN_PROGRESS) {
          this.setState({
            isPostInProgress: true
          });
        }
      })
    );
    
    this.initialStoreDispatches();
    this.handleExistingWithdrawal(this.props.withdrawal)
  }
  
  handleExistingWithdrawal(withdrawal: Withdrawal) {
    if (withdrawal && withdrawal.brands && withdrawal.brands.length > 0) {
      this.setState({
        brandGroups: withdrawal.brands
      });
    }
  }

  initialStoreDispatches() {
    const store = getStore();

    store.dispatch({
      type: GET_PRICES
    } as WithdrawalAction);
  }

  doModalClose = () => {
    this.props.close();
  }

  onToggleForm = (toggleState?: boolean) => {
    this.setState({
      isFormVisible: toggleState ? toggleState : !this.state.isFormVisible
    });
  }

  onAddBrandGroup = (state: CreateWithdrawalModalState) => {
    let brandGroups = state.brandGroups;
    let group: WithdrawalBrandGroup = {
      brands: []
    }

    let currentIndex = state.currentlySelectedGroupIndex ? state.currentlySelectedGroupIndex : 0;

    if (this.state.brandGroups.length > 0) {
      currentIndex++;
    }

    brandGroups.push(group);

    this.setState({
      brandGroups: brandGroups,
      currentlySelectedGroupIndex: currentIndex
    });
    this.onToggleForm();
  }

  onAddBrand = () => {
    validateForm(this.state.withdrawalForm);

    if (!this.state.withdrawalForm.isValid) {
      return;
    }

    let form: Form = this.state.withdrawalForm;
    const selectedBrand = this.state.brandPrices.find(member => {
      return member.brandId === form.fields['name'].value;
    });
    
    let brand: WithdrawalBrand = {
      name: selectedBrand.brandName,
      brandPriceId: selectedBrand.brandId,
      numOfCases: form.fields['numOfCases'].value,
      numOfReams: form.fields['numOfReams'].value,
      pricePerReam: selectedBrand.pricePerReam,
    };

    const currentIndex = this.state.currentlySelectedGroupIndex;
    let currentGroup = this.state.brandGroups[currentIndex];

    const found = currentGroup.brands.find((brandMember) => {
      return brand.name === brandMember.name;
    });

    if (found) {
      showToast({
        title: 'Failed to add brand',
        message: 'This brand already exists in current group.',
        duration: 3000,
        type: TOAST_TYPE_ERROR
      });
    } else {
      currentGroup.brands.push(brand);

      // need to force component to update to rerender
      this.forceUpdate();
    }
  }

  onEditBrand = (brand: WithdrawalBrand, index: number) => {
    let form = this.state.withdrawalForm;

    form.fields['name'].value = brand.brandPriceId;
    form.fields['numOfCases'].value = brand.numOfCases;
    form.fields['numOfReams'].value = brand.numOfReams;
    
    this.setState({
      isEditing: true,
      currentlySelectedBrandIndex: index
    });
  }

  onRemoveBrand = (index: number) => {
    const currentIndex = this.state.currentlySelectedGroupIndex;
    let currentGroup = this.state.brandGroups[currentIndex];
    currentGroup.brands.splice(index, 1);

    this.forceUpdate();
  }

  onRemoveGroup = (index: number) => {
    let groups = this.state.brandGroups;
    groups.splice(index, 1);

    this.forceUpdate();
  }

  onCompleteEdit = () => {
    validateForm(this.state.withdrawalForm);

    if (!this.state.withdrawalForm.isValid) {
      return;
    }

    let form: Form = this.state.withdrawalForm;
    const selectedBrand = this.state.brandPrices.find(member => {
      return member.brandId === form.fields['name'].value;
    });
    
    let brand: WithdrawalBrand = {
      name: selectedBrand.brandName,
      brandPriceId: selectedBrand.brandId,
      numOfCases: form.fields['numOfCases'].value,
      numOfReams: form.fields['numOfReams'].value,
      pricePerReam: selectedBrand.pricePerReam,
    };

    const currentIndex = this.state.currentlySelectedGroupIndex;
    let currentGroup = this.state.brandGroups[currentIndex];

    const currentBrandIndex = this.state.currentlySelectedBrandIndex;
    currentGroup.brands[currentBrandIndex] = brand;
    
    this.setState({
      isEditing: false,
      currentlySelectedBrandIndex: -1
    });
  }

  onCompleteBrandGroup = () => {

    let currentGroup = this.state.brandGroups[this.state.currentlySelectedGroupIndex];
    currentGroup.agentName = this.state.currentAgentName;

    this.setState({
      isFormVisible: false
    });

  }

  onEditGroup = (group: WithdrawalBrandGroup ,index: number) => {

    this.setState({
      currentlySelectedGroupIndex: index,
      isFormVisible: true,
      currentAgentName: group.agentName
    });
  }

  onSubmit = () => {
    const brandGroups = this.state.brandGroups;
    const store = getStore();
    store.dispatch({
      type: CREATE_WITHDRAWAL,
      payload: {
        brandGroupsToCreate: brandGroups
      }
    });
  }

  onSubmitReedit = () => {
    const brandGroups = this.state.brandGroups;
    const store = getStore();
    store.dispatch({
      type: REEDIT_WITHDRAWAL,
      payload: {
        updatedBrands: brandGroups,
        withdrawalId: this.props.withdrawal.withdrawalId
      }
    });
  }

  getListBlock(state: CreateWithdrawalModalState) {
    if (state.isEditing) {
      let currentGroup = state.brandGroups[state.currentlySelectedGroupIndex];

      return (
        <div>
          
        </div>
      );
    } else if (!state.isFormVisible) {

      if (state.brandGroups.length < 1) {
        return (
          <div class="row">
            <div class="col-12 text-center mt-3">
              No brands added. Please add at least 1 withdrawal group.
            </div>
          </div>
        );
      } else {
        return (
          <div class="row">
            <div class="col-12">
              {
                state.brandGroups.map((group, index) => {
                  return (
                    <div class="row my-3 create-withdrawal-modal__list__group">
                      <div class="col-12">
                        <div class="row align-items-center mb-3">
                          <div class="col-12 col-lg-6">
                            <div class="view-withdrawal__brands__agent-name">
                              { group.agentName }
                            </div>
                          </div>
                          <div class="col-12 col-lg-3">
                            <button class="btn btn-outline-danger w-100 mb-3 mb-lg-0"
                              onClick={() => this.onRemoveGroup(index)}>
                              Delete Group
                            </button>
                          </div>
                          <div class="col-12 col-lg-3">
                            <button class="btn btn-outline-primary w-100"
                              onClick={() => this.onEditGroup(group, index)}>
                              Edit Group
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row">
                          <div class="col view-withdrawal__brands__grid__header">
                            Brand
                          </div>
                          <div class="col view-withdrawal__brands__grid__header">
                            Number of Cases
                          </div>
                          <div class="col view-withdrawal__brands__grid__header">
                            Number of Reams
                          </div>
                          {/* <div class="col view-withdrawal__brands__grid__header">
                            Number of Packs
                          </div> */}
                          <div class="col view-withdrawal__brands__grid__header">
                            Price per Ream
                          </div>
                          <div class="col view-withdrawal__brands__grid__header">
                            Value
                          </div>
                        </div>
                        {
                          group.brands.map((brand, index) => this.getBrandRow(brand, index, group.brands.length))
                        }
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
        );
      }
    }
  }

  getBrandRow(brand: WithdrawalBrand, index: number, length: number) {
    let value: number = 0;

    value += (brand.numOfCases * REAMS_PER_CASE) * brand.pricePerReam;

    if (brand.numOfReams) {
      value += brand.numOfReams * brand.pricePerReam;
    }
    // value += (brand.numOfPacks / PACKS_PER_REAM) * brand.pricePerReam;

    return (
      <div className={cx(
        'row view-withdrawal__brands__grid__row',
        {
          'last': index === length - 1
        }
      )}>
        <div class="col view-withdrawal__brands__grid__col">
          { brand.name }
        </div>
        <div class="col view-withdrawal__brands__grid__col">
          { brand.numOfCases ? brand.numOfCases : 0 }
        </div>
        <div class="col view-withdrawal__brands__grid__col">
          { brand.numOfReams ? brand.numOfReams : 0 }
        </div>
        {/* <div class="col view-withdrawal__brands__grid__col">
          { brand.numOfPacks ? brand.numOfPacks : 0 }
        </div> */}
        <div class="col view-withdrawal__brands__grid__col last">
          { brand.pricePerReam }
        </div>
        <div class="col view-withdrawal__brands__grid__col last">
          ₱ { toFixed(value) }
        </div>
      </div>
    );
  }

  getFormBrandRow(brand: WithdrawalBrand, index: number, length: number) {
    let value: number = 0;

    value += (brand.numOfCases * REAMS_PER_CASE) * brand.pricePerReam;

    if (brand.numOfReams) {
      value += brand.numOfReams * brand.pricePerReam;
    }
    // value += (brand.numOfPacks / PACKS_PER_REAM) * brand.pricePerReam;

    return (
      <div class="col-12 col-lg-4 mb-2">
        <div className={cx(
          'container-fluid create-withdrawal-modal__form__brand-card py-2',
          {
            'editing': index === this.state.currentlySelectedBrandIndex
          }
        )}>
          <div class="row mt-2">
            <div class="col-12">
              <div class="create-withdrawal-modal__form__brand-card__name">
                { brand.name }
              </div>
            </div>
            <div class="col-6">
              <div class="create-withdrawal-modal__form__brand-card__label">
                Num of Cases
              </div>
              <div class="create-withdrawal-modal__form__brand-card__value">
                { brand.numOfCases ? brand.numOfCases : 0 }
              </div>
            </div>

            <div class="col-6">
              <div class="create-withdrawal-modal__form__brand-card__label">
                Num of Reams
              </div>
              <div class="create-withdrawal-modal__form__brand-card__value">
                { brand.numOfReams ? brand.numOfReams : 0 }
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="create-withdrawal-modal__form__brand-card__label">
                Price per Ream
              </div>
              <div class="create-withdrawal-modal__form__brand-card__value">
                ₱ { toFixed(brand.pricePerReam) }
              </div>
            </div>
            <div class="col-6">
              <div class="create-withdrawal-modal__form__brand-card__label">
                Total Value
              </div>
              <div class="create-withdrawal-modal__form__brand-card__value">
                ₱ { toFixed(value) }
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-12 col-lg-6">
              <button class="btn btn-outline-dark w-100"
                onClick={() => this.onEditBrand(brand, index)}>
                Edit
              </button>
            </div>
            <div class="col-12 col-lg-6 mt-2 mt-lg-0">
              <button class="btn btn-outline-dark w-100"
                onClick={() => this.onRemoveBrand(index)}>
                Remove
              </button>
            </div>
          </div>
        </div>
          
      </div>
    );
  }

  getFormBlock(props: CreateWithdrawalModalProps, state: CreateWithdrawalModalState) {
    if (state.isFormVisible) {
      let form = state.withdrawalForm;
      const currentIndex = state.currentlySelectedGroupIndex;
      let currentGroup = state.brandGroups[currentIndex];

      return (
        <div class="row">
          <div class="col-12 create-withdrawal-modal__form">
            <div class="row">
              <div class="col-12 mt-3">
                <label class="anglo-remittance__submit-form__label">Sales Agent*</label>
                <input class="form-control" type="text"
                  onInput={linkState(this, 'currentAgentName')}
                  value={state.currentAgentName}
                  placeholder="Agent name"/>
              </div>
            </div>

            <div class="container-fluid mt-3">
              <div class="row no-gutters">
                <div class="col-12">
                  <i>Brands Breakdown</i>
                </div>
              </div>

              <div class="row">
                { currentGroup.brands.map((member, index) => this.getFormBrandRow(member, index, currentGroup.brands.length)) }
              </div>
            </div>

            <div class="row create-withdrawal-modal__form__brand py-3">
              <div class="col-12">
                <label class="anglo-remittance__submit-form__label">Brand Name*</label>

                <select
                  className={ cx('form-control', 
                    { 'invalid': cxValidateField(form.fields['name']) }
                  )}
                  placeholder="Brand Name"
                  value={form.fields['name'].value}
                  onChange={linkform(this.form$, form, 'name')}>

                    <option class="text-muted" value="" disabled={true} selected>Select Brand</option>
                    {
                      state.brandPrices.map(price => {
                        return <option value={price.brandId}>{ price.brandName }</option>
                      })
                    }
                </select>
              </div>
              <div class="col-12 col-lg-6 mt-3">
                <label class="anglo-remittance__submit-form__label">Number of Cases*</label>
                <input class="form-control" type="text"
                  onInput={linkform(this.form$, form, 'numOfCases')}
                  value={form.fields['numOfCases'].value}
                  placeholder="Cases"/>
              </div>

              <div class="col-12 col-lg-6 mt-3">
                <label class="anglo-remittance__submit-form__label">Number of Reams</label>
                <input class="form-control" type="text"
                  onInput={linkform(this.form$, form, 'numOfReams')}
                  value={form.fields['numOfReams'].value}
                  placeholder="Reams"/>
              </div>

              <div class="col-6 col-lg-3 offset-6 offset-lg-9 mt-3">
                { this.getFormActionRow(state) }
              </div>
            </div>
          
            <div class="row mt-3">
              <div class="col-12 col-lg-3 offset-lg-9">
                <button class="btn btn-secondary w-100"
                  onClick={this.onCompleteBrandGroup}>
                  Complete Brand Group
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  getFormActionRow(state: CreateWithdrawalModalState) {
    if (state.isEditing) {
      return (
        <button class="btn btn-outline-primary w-100"
          onClick={this.onCompleteEdit}>
          Edit Brand
        </button>
      );
    } else {
      return (
        <button class="btn btn-outline-primary w-100"
          onClick={this.onAddBrand}>
          Add Brand
        </button>
      );
    }
  }

  getActionsRow(props: CreateWithdrawalModalProps, state: CreateWithdrawalModalState) {
    if (state.isPostInProgress) {
      return this.getSpinnerBlock();
    } else if (props.isReedit) {
      return (
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-3 offset-lg-9">
              <button class="btn btn-primary w-100"
                onClick={this.onSubmitReedit}
                disabled={state.brandGroups.length < 1}>
                Re-Edit Withdrawals
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-3 offset-lg-9">
              <button class="btn btn-primary w-100"
                onClick={this.onSubmit}
                disabled={state.brandGroups.length < 1}>
                Submit Withdrawals
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  getSpinnerBlock() {
    return (
      <div class="col offset-md-9 d-flex justify-content-end">
        <div class="spinner-border text-primary mt-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );  
  }

  render(props: CreateWithdrawalModalProps, state: CreateWithdrawalModalState) {
    return (
      <div class="container anglo-modal create-withdrawal-modal"
        ref={ node => this.refNode = node }>

          <div class="row anglo-modal__head align-items-center">
            <div class="col-9 col-md-10">
              <h3 class="mb-0">
                {props.isReedit ? 'Re-Edit Withdrawal Request' : 'Create Withdrawal Request'}
              </h3>
            </div>
            <div class="col-3 col-md-2">
              <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>

          <div class="row anglo-modal__body py-3">
            <div class="col-12 create-withdrawal-modal__body pb-3">
              {
                !state.isFormVisible ? 
                <div class="row">
                  <div class="col-12 col-lg-3 offset-lg-9">
                    <button class="btn btn-secondary w-100"
                      onClick={() => this.onAddBrandGroup(state)}>
                      Add Withdrawal Group
                    </button>
                  </div>
                </div> : null
              }

              { this.getListBlock(state) }

              { this.getFormBlock(props, state) }   
            </div>    
          </div>

          <div class="row vehicle-card__actions mt-3">
            { this.getActionsRow(props, state) }
          </div>
      </div>
    );
  }

}