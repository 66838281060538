import * as Actions from './updates.actions';
import { UpdateState, UpdateAction } from './updates.actions';
import { REQUEST_SUCCESS, REQUEST_FAILED, REQUEST_IN_PROGRESS } from '../../../../types/request-state.model';

const initialState: UpdateState = {

}

export default function AuditLog(state: UpdateState = initialState, action: UpdateAction) {
  switch (action.type) {

    case Actions.GET_GRID_UPDATE:
    case Actions.GET_GRID_UPDATE_SUCCESS:
    case Actions.GET_GRID_UPDATE_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
      );

    case Actions.APPROVE_REQUEST_EDIT:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          editRequestState: REQUEST_IN_PROGRESS
        }
      );
    case Actions.APPROVE_REQUEST_EDIT_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          editRequestState: REQUEST_SUCCESS
        }
      );
    case Actions.APPROVE_REQUEST_EDIT_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          editRequestState: REQUEST_FAILED
        }
      );

    case Actions.REJECT_REQUEST_EDIT:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          editRequestState: REQUEST_IN_PROGRESS
        }
      );
    case Actions.REJECT_REQUEST_EDIT_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          editRequestState: REQUEST_SUCCESS
        }
      );
    case Actions.REJECT_REQUEST_EDIT_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          editRequestState: REQUEST_FAILED
        }
      );

    case Actions.APPROVE_REQUEST_DELETE:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          editRequestState: REQUEST_IN_PROGRESS
        }
      );
    case Actions.APPROVE_REQUEST_DELETE_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          editRequestState: REQUEST_SUCCESS
        }
      );
    case Actions.APPROVE_REQUEST_DELETE_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          editRequestState: REQUEST_FAILED
        }
      );

    case Actions.REJECT_REQUEST_DELETE:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          editRequestState: REQUEST_IN_PROGRESS
        }
      );
    case Actions.REJECT_REQUEST_DELETE_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          editRequestState: REQUEST_SUCCESS
        }
      );
    case Actions.REJECT_REQUEST_DELETE_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          editRequestState: REQUEST_FAILED
        }
      );

    default:
      return state;
  }
}