import { h, Component } from 'preact';
import linkState from 'linkstate';
import cx from 'classnames';

import { ModalProps, Modal } from '../common/modal';
import { Remittance } from '../../../../types/remittance.model';
import { Subject, Subscription } from 'rxjs';
import getStore, { appState$ } from '../../store';
import { SUBMIT_EDIT_REQUEST } from '../../reducers/remittance/remittance.actions';
import { EditRequest, DeleteRequest } from '../../../../types/edit-request.model';
import { map } from 'rxjs/operators';

import { RequestState, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../types/request-state.model';
import { FieldList, Field, Form } from '../../../../types/form.model';
import { validateForm, cxValidateField } from '../../../../types/validator.model';
import * as Validators from '../../../../types/validator.model';
import linkform from '../../../utils/linkform';
import { CREATE_BANK_ACCOUNT, UserAction, GET_BANKS, CREATE_USER } from '../../reducers/user/user.actions';
import { BankAccount } from '../../../../types/bank-account.model';

import { ROLES_FOR_CREATING, ROLE_SUPERVISOR, ROLE_AUDITOR, ROLE_VEHICLE_MANAGER, ROLE_MANAGEMENT, ROLE_ADMIN_SALES } from '../../../../constants/role';
import { User } from '../../../../types/user.model';

export interface CreateUserModalProps extends ModalProps {
}

interface CreateUserModalState  {

  submitStatus?: RequestState;
  form?: Form;

  allBankAccounts?: BankAccount[];
}

export class CreateUserModal extends Modal<CreateUserModalProps, CreateUserModalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props: CreateUserModalProps) {
    super(props);

    this.createForm();
  }

  createForm() {
    let fields: FieldList = {
      'username': new Field([Validators.required], null, ''),
      'password': new Field([Validators.required] , null, ''),
      'firstName': new Field([Validators.required] , null, ''),
      'middleName': new Field([] , null, ''),
      'lastName': new Field([Validators.required] , null, ''),
      'role': new Field([Validators.required] , null, ''),
      'area': new Field([] , null, ''),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      form: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['form'] = form;
      this.setState(state);
    });
  }

  componentDidMount() {
    super.componentDidMount();

    const user$ = appState$.pipe(
      map(app => app.user)
    );

    this.subs.push(
      user$.pipe(
        map(user => user.createUserState)
      ).subscribe(reqState => {
        this.setState({
          submitStatus: reqState
        });

        if (reqState === REQUEST_SUCCESS) {
          this.doModalClose();
        }
      })
    );

    this.subs.push(
      user$.pipe(
        map(user => user.allBankAccounts)
      ).subscribe(allBankAccounts => {
        this.setState({
          allBankAccounts: allBankAccounts
        });
      })
    );

    this.initialStoreDispatches();
  }

  initialStoreDispatches() {

    const store = getStore();
    store.dispatch({
      type: GET_BANKS
    } as UserAction);

  }

  componentWillUnmount() {
    this.subs.forEach(s => s.unsubscribe());
  }

  doModalClose = () => {
    this.props.close();
  }

  getSpinnerBlock(state: CreateUserModalState) {
    if (state.submitStatus === REQUEST_IN_PROGRESS) {
      return (
        <div class="col offset-md-9 d-flex justify-content-end">
          <div class="spinner-border text-primary mt-3" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }    
  }

  onSubmit = () => {

    let form = this.state.form;

    validateForm(form);

    if (!form.isValid) {
      return;
    }

    let user: User = {
      username: form.fields['username'].value,
      password: form.fields['password'].value,
      firstName: form.fields['firstName'].value,
      middleName: form.fields['middleName'].value,
      lastName: form.fields['lastName'].value,
      role: form.fields['role'].value,
    }

    const store = getStore();
    store.dispatch({
      type: CREATE_USER,
      payload: {
        userToCreate: user
      }
    });
  }

  getRoleLabel(role: string) {
    if (role === ROLE_SUPERVISOR) {
      return 'Supervisor';
    }

    if (role === ROLE_AUDITOR) {
      return 'Auditor';
    }

    if (role === ROLE_VEHICLE_MANAGER) {
      return 'Vehicle Manager';
    }

    if (role === ROLE_MANAGEMENT) {
      return 'Management';
    }

    if (role === ROLE_ADMIN_SALES) {
      return 'Admin Sales';
    }
  }

  render(props: CreateUserModalProps, state: CreateUserModalState) {
    let form = state.form;

    if (!state.allBankAccounts) {
      return;
    }

    return (
      <div class="container anglo-modal"
          ref={ node => this.refNode = node }>
        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">
              Create new user
            </h3>
          </div>
          <div class="col-3 col-md-2">
            <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        
        <div class="row anglo-modal__body py-3">

          <div class="col-12 col-md-4">
            <label class="dash-remittance__filter-row__filters__label">Username*</label>
            <input type="text" className={ cx('form-control', 
              { 'invalid': cxValidateField(form.fields['username']) }
            )}
            value={ form.fields['username'].value }
            placeholder=""
            onChange={ linkform(this.form$, form, 'username') }/>
          </div>

          <div class="col-12 col-md-4">
            <label class="dash-remittance__filter-row__filters__label">Password*</label>
            <input type="text" className={ cx('form-control', 
              { 'invalid': cxValidateField(form.fields['password']) }
            )}
            value={ form.fields['password'].value }
            placeholder=""
            onChange={ linkform(this.form$, form, 'password') }/>
          </div>

          <div class="col-12 col-md-4">
            <label class="dash-remittance__filter-row__filters__label">Role*</label>
            <select
              className={ cx('form-control', 
                { 'invalid': cxValidateField(form.fields['role']) }
              )}
              value={form.fields['role'].value}
              onChange={linkform(this.form$, form, 'role')}>

                <option class="text-muted" value="" disabled={true} selected>Select Role</option>
                {
                  ROLES_FOR_CREATING.map(role => {
                    return <option value={role}>{ this.getRoleLabel(role) }</option>
                  })
                }
            </select>
          </div>

          <div class="col-12 col-md-4 mt-3">
            <label class="dash-remittance__filter-row__filters__label">Firstname*</label>
            <input type="text" className={ cx('form-control', 
              { 'invalid': cxValidateField(form.fields['firstName']) }
            )}
            value={ form.fields['firstName'].value }
            placeholder=""
            onChange={ linkform(this.form$, form, 'firstName') }/>
          </div>

          <div class="col-12 col-md-4 mt-3">
            <label class="dash-remittance__filter-row__filters__label">Middlename</label>
            <input type="text" className={ cx('form-control', 
              { 'invalid': cxValidateField(form.fields['middleName']) }
            )}
            value={ form.fields['middleName'].value }
            placeholder=""
            onChange={ linkform(this.form$, form, 'middleName') }/>
          </div>

          <div class="col-12 col-md-4 mt-3">
            <label class="dash-remittance__filter-row__filters__label">Lastname*</label>
            <input type="text" className={ cx('form-control', 
              { 'invalid': cxValidateField(form.fields['lastName']) }
            )}
            value={ form.fields['lastName'].value }
            placeholder=""
            onChange={ linkform(this.form$, form, 'lastName') }/>
          </div>

          <div class="col-2 offset-8 mt-4">
            {
              this.getSpinnerBlock(state)
            }
          </div>

          <div class="col-12 col-md-2 mt-4">

            <button class="btn btn-primary w-100" onClick={this.onSubmit}>
              Submit
            </button>
          </div>
        </div>

        
      </div>
    );
  }
}