import { h, Component } from 'preact';
import cx from 'classnames';
import linkState from 'linkstate';
import { Subject, Subscription } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { ModalProps, Modal } from '../common/modal';
import { FieldList, Field, Form } from '../../../../types/form.model';
import { validateForm, cxValidateField } from '../../../../types/validator.model';

import getStore, { appState$ } from '../../store';
import { RequestState, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../types/request-state.model';
import { VehicleFile } from '../../../../types/vehicle.model';
import linkform from '../../../utils/linkform';

import { VEHICLE_FILE_TYPES, FILE_STATUS_PENDING, FILE_STATUS_APPROVED, FILE_STATUS_DENIED } from '../../../../constants/vehicle-files';
import { DatePicker } from '../common/date-picker';
import { CREATE_VEHICLE_FILE, APPROVE_VEHICLE_FILE, REJECT_VEHICLE_FILE } from '../../reducers/vehicle/vehicle.actions';
import { User } from '../../../../types/user.model';
import { ROLE_MANAGEMENT, ROLE_VEHICLE_MANAGER } from '../../../../constants/role';

import moment from 'moment-timezone';
import { Expense } from '../../../../types/expense.model';
import { toFixed } from '../../../utils/decimal';

export interface RespondFileReportModalProps extends ModalProps {
  vehicleFile: VehicleFile;
  currentUser?: User;
}

interface RespondFileReportModalState  {

  reason?: string;
  isFormValid?: boolean;

  RespondFileReportRequestState?: RequestState;
  vehicleForm?: Form;
  respondVehicleFileState?: RequestState;
}

export class RespondFileReportModal extends Modal<RespondFileReportModalProps, RespondFileReportModalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props: RespondFileReportModalProps) {
    super(props);

    this.createForm();
  }

  createForm() {
    let fields: FieldList = {
      'responseReason': new Field([], null),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      vehicleForm: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['vehicleForm'] = form;
      this.setState(state);
    });
  }

  componentDidMount() {
    super.componentDidMount();

    const vehicles$ = appState$.pipe(
      map(app => app.vehicles)
    );

    this.subs.push(
      vehicles$.pipe(
        map(vehicles => vehicles.respondVehicleFileState),
        distinctUntilChanged()
      ).subscribe(reqState => {
        this.setState({
          respondVehicleFileState: reqState
        });

        if (reqState === REQUEST_SUCCESS) {
          this.doModalClose();
        }
      })
    );
  }

  doModalClose = () => {
    this.props.close();
  }

  handleDatepickerValidation = (date: string, fieldName: string) => {
    const currentForm = this.state.vehicleForm; 

    // Manually do this because in order to follow the 
    // hopplerLinkstate validation system
    let clonedFields: FieldList = {};
    for (let k in currentForm.fields) {
      let prevField = currentForm.fields[k];
      clonedFields[k] = new Field(prevField.validators, prevField.customValidators, prevField.value);
      clonedFields[k].isValid = prevField.isValid;
    }

    let clone: Form = new Form(clonedFields);
    clone.fields[fieldName].value = date;
    clone.fields[fieldName].isPristine = false;
    // clone.fields[fieldName].customValidators.forEach(a => a(clone.fields[fieldName], clone));
    this.form$.next(clone);
  }

  onDateSelected = (date: string) => {
    this.handleDatepickerValidation(date, 'dateOfIncident');
  }

  onApprove = () => {
    let form = this.state.vehicleForm;

    const store = getStore();
    store.dispatch({
      type: APPROVE_VEHICLE_FILE,
      payload: {
        responseReason: form.fields['responseReason'].value,
        vehicleFileToUpdate: this.props.vehicleFile._id,
        vehicleId: this.props.vehicleFile.vehicleId       
      }
    });
  }

  onReject = () => {
    let form = this.state.vehicleForm;

    const store = getStore();
    store.dispatch({
      type: REJECT_VEHICLE_FILE,
      payload: {
        responseReason: form.fields['responseReason'].value,
        vehicleFileToUpdate: this.props.vehicleFile._id,
        vehicleId: this.props.vehicleFile.vehicleId        
      }
    });
  }

  getStatusName(status: string) {
    if (status === FILE_STATUS_PENDING) {
      return 'Pending';
    }

    if (status === FILE_STATUS_APPROVED) {
      return 'Approved';
    }

    if (status === FILE_STATUS_DENIED) {
      return 'Denied';
    }
  }

  getExpenseTotal(props: RespondFileReportModalProps) {
    let total: number = 0;
    let expenses: Expense[] = props.vehicleFile.expenses;

    expenses.forEach(expense => { total+= Number.parseInt(expense.amount as any) });

    return `₱ ${toFixed(total)}`;
  }

  getExpensesBlock(props: RespondFileReportModalProps) {
    if (props.vehicleFile.expenses && props.vehicleFile.expenses.length > 0) {
      return (
        <div class="col-12 mt-3">
          <label class="anglo-remittance__submit-form__label">Expenses</label>
          <hr class="my-1"/>
          <div class="row">
            <div class="col-6">
              <b class="mb-1">
                Expense
              </b>
            </div>
            <div class="col-6">
              <b class="mb-1">
                Amount
              </b>
            </div>
          </div>
          {
            props.vehicleFile.expenses.map(member => {
              return (
                <div class="row mb-2">
                  <div class="col-6">
                    <input type="text" class="form-control"
                      value={member.name} disabled={true}/>
                  </div>
                  <div class="col-6">
                    <input type="text" class="form-control"
                      value={`₱ ${member.amount}`} disabled={true}/>
                  </div>
                </div>
              );
            })
          }

          <h4>
            Total: {this.getExpenseTotal(props)}
          </h4>

        </div>
      );
    }
  }

  getActionsBlock(props: RespondFileReportModalProps, state: RespondFileReportModalState) {
    if (props.currentUser.role === ROLE_MANAGEMENT || props.currentUser.role === ROLE_VEHICLE_MANAGER) {
      return (
        <div class="row mt-3">
          <div class="offset-4 col-4">
            <button class="btn btn-secondary w-100"
                onClick={this.onReject}>
              Reject
            </button>
          </div>

          <div class="col-4">
            <button class="btn btn-primary w-100"
                onClick={this.onApprove}>
              Approve
            </button>
          </div>
        </div>
      );
    }
  }
  
  getModalHeadingBlock(props: RespondFileReportModalProps) {
    if (props.currentUser.role === ROLE_MANAGEMENT || props.currentUser.role === ROLE_VEHICLE_MANAGER) {
      return (
        <h3 class="mb-0">
          Vehicle File | <small>{ props.vehicleFile.user.firstName } { props.vehicleFile.user.lastName }</small>
        </h3>
      );
    } else {
      return (
        <h3 class="mb-0">
          Vehicle File | <small>{ props.vehicleFile.vehicleId }</small>
        </h3>
      );
    }
  }

  getResponseReasonBlock(props: RespondFileReportModalProps, state: RespondFileReportModalState) {
    if (props.currentUser.role === ROLE_MANAGEMENT || props.currentUser.role === ROLE_VEHICLE_MANAGER) {
      let form = state.vehicleForm;

      return (
        <div class="col-12 mt-3">
          <label class="anglo-remittance__submit-form__label">Response Reason*</label>
          <textarea class="form-control anglo-request-edit-modal__reason"
            value={ form.fields['responseReason'].value }
            onInput={linkform(this.form$, form, 'responseReason')}
            disabled={props.currentUser.role !== ROLE_MANAGEMENT && props.currentUser.role !== ROLE_VEHICLE_MANAGER}
            placeholder="Response Reason"/>
        </div>
      );
    }
  }

  render(props: RespondFileReportModalProps, state: RespondFileReportModalState) {
    let form = state.vehicleForm;

    return (
      <div class="anglo-file-report-modal container anglo-modal"
        ref={ node => this.refNode = node }>
        <div class="row anglo-modal__head align-items-center">
          <div class="col-10">
            { this.getModalHeadingBlock(props) }
          </div>
          <div class="col-2">
            <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div class="row anglo-modal__body py-3">

          <div class="col-12 col-md-6">
            <label class="anglo-remittance__submit-form__label">Type</label>
            <input type="text" class="form-control" disabled={true}
              value={this.getStatusName(props.vehicleFile.status)} />
          </div>

          <div class="col-12 col-md-6 mt-3 mt-md-0">
            <label class="dash-remittance__filter-row__filters__label">Date of Incident</label>
            <input type="text" class="form-control" disabled={true}
              value={ moment(props.vehicleFile.dateOfReport).format('MM-DD-YYYY') } />
          </div>

          <div class="col-12 mt-3 d-flex align-items-center">
            This happened in the Anglo motorpool <input class="ml-2" type="checkbox" disabled={true} checked={props.vehicleFile.isMotorpool} /> 
          </div>

          <div class="col-12 mt-3">
            <label class="anglo-remittance__submit-form__label">Description/Details</label>
            <textarea class="form-control anglo-request-edit-modal__reason"
              value={props.vehicleFile.description}
              disabled={true}
              placeholder="Details of report"/>
          </div>

          { this.getExpensesBlock(props) }

          { this.getResponseReasonBlock(props, state) }

         <div class="col-12">
          { this.getActionsBlock(props, state) }
         </div>
         
        </div>
      </div>
    );
  }
}