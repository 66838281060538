import { h, Component } from 'preact';
import cx from 'classnames';
import Portal from 'preact-portal';
import { Subscription, Subject } from 'rxjs';
import Axios, { AxiosPromise } from 'axios';

import { debounce } from '../../../utils/debounce';
import { API_ENDPOINT } from '../../../../constants/api';
import { showToast } from '../../../utils/toast.service';
import { Sector } from '../../../../types/sector.model';
import { Location, LOCATION_TYPE_AREA, LOCATION_TYPE_BUILDING, LOCATION_TYPE_CITY, LOCATION_TYPE_REGION, LOCATION_TYPE_STREET } from '../../../../types/location.model';

export interface AutocompleteLocationProps {
  searchFunction?: Function; 
  // should be using this
  // but I'm making it a direct call for now

  searchUrl?: string;

  onResultSelected: Function;
  disabled?: boolean;
  initialValue?: string;
}

export interface AutocompleteLocationState {
  searchResults?: Sector[];
  search?: string;
  isSearchResultsVisible?: boolean;
}

export class AutocompleteLocation extends Component<AutocompleteLocationProps, AutocompleteLocationState> {
  refNode: any;

  constructor(props: AutocompleteLocationProps) {
    super(props);

    this.setState({
      searchResults: [],
    });

    if (props.initialValue) {
      this.setState({
        search: props.initialValue
      });
    }
  }

  componentDidMount() {
    document.body.addEventListener('mousedown', this.onOutsideClick, false);
  }

  componentWillUnmount() {
    document.body.removeEventListener('mousedown', this.onOutsideClick, false);
  }

  onOutsideClick = (ev) => {
    const target = ev.target;
    let doesContain: boolean = (this.refNode as HTMLElement).contains(target);
    
    if (!doesContain) {
      this.setState({
        isSearchResultsVisible: false
      });
    }
  }

  onSearchInputChange = debounce((event) => {

    const searchTerm = event.target.value;

    if (searchTerm && searchTerm.length >= 2) {
      this.getSearchResults(searchTerm);
    } 
    
    this.setState({
      search: searchTerm
    });

  }, 500);

  getSearchResults(searchTerm?: string) {
    let params = {
      searchString: searchTerm
    };

    const axiosPromise = Axios.get(
      `${API_ENDPOINT}/location/search`,
      {
        params: params,
        withCredentials: true
      }
    );

    axiosPromise.then((response) => {
      const results: any[] = response.data.results;

      if (results && results.length > 0) {
        this.setState({
          searchResults: results,
          isSearchResultsVisible: true
        });
      }
    }).catch(errResp => {
      let message = errResp.response.data.msg ? errResp.response.data.msg : null;
      
      showToast({
        title: 'Failed to retrieve search',
        message: message,
        duration: 3000
      });
    })

  }

  onResultClick = (result: Sector) => {
    const label = result.name;

    this.setState({
      search: label,
      searchResults: [],
      isSearchResultsVisible: false,
    })

    this.props.onResultSelected(result);
  }

  getLocationName(member: Location) {
    switch (member.type) {
      case LOCATION_TYPE_REGION:
        return member.region;
      case LOCATION_TYPE_CITY:
        return `${member.city}, ${member.region}`;
      case LOCATION_TYPE_AREA:
        return `${member.area}, ${member.city}, ${member.region}`;
      case LOCATION_TYPE_STREET:
        return `${member.street}, ${member.area}, ${member.city}, ${member.region}`;
      case LOCATION_TYPE_BUILDING:
        return `${member.building}, ${member.street}, ${member.area}, ${member.city}, ${member.region}`;

      default: 
        return null;
    }
  }

  render(props: AutocompleteLocationProps, state: AutocompleteLocationState) {
    const result = (member, index) => {
      return <p onClick={this.onResultClick.bind(this, member)}> 
       { this.getLocationName(member) }
      </p>;
    }

    return (
      <div class="autocomplete-search" 
        ref={node => this.refNode = node}>
        <input type="text"
          className={ cx('autocomplete-search__input form-control') }
          disabled={ props.disabled } 
          
          placeholder="Search for a location"
          value={state.search}
          onInput={this.onSearchInputChange.bind(this)}
        />
        <span class="autocomplete-search__icon">
          <i class="fas fa-search"></i>
        </span>
        <div className={cx('autocomplete-search__results', {active : state.isSearchResultsVisible})}>
          { this.state.searchResults.map((member, i) => result(member, i)) }
        </div>
      </div>
    )
  }

}