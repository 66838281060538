import { h, Component } from "preact";
import cx from "classnames";
import Portal from "preact-portal";
import { Subscription, Subject } from "rxjs";
import Axios, { AxiosPromise } from "axios";

import { debounce } from "../../../utils/debounce";
import { API_ENDPOINT } from "../../../../constants/api";
import { showToast } from "../../../utils/toast.service";
import { Sector } from "../../../../types/sector.model";
import {
  Location,
  LOCATION_TYPE_AREA,
  LOCATION_TYPE_BUILDING,
  LOCATION_TYPE_CITY,
  LOCATION_TYPE_REGION,
  LOCATION_TYPE_STREET,
} from "../../../../types/location.model";
import { Item } from "../../../../types/item.model";
import { Supplier } from "../../../../types/supplier.model";

export interface SupplierSearchProps {
  searchUrl?: string;

  onResultSelected: Function;
  disabled?: boolean;
  initialValue?: string;
}

export interface SupplierSearchState {
  searchResults?: Supplier[];
  search?: string;
  isSearchResultsVisible?: boolean;
}

export class SupplierSearch extends Component<
  SupplierSearchProps,
  SupplierSearchState
> {
  refNode: any;

  constructor(props: SupplierSearchProps) {
    super(props);

    this.setState({
      searchResults: [],
    });

    if (props.initialValue) {
      this.setState({
        search: props.initialValue,
      });
    }
  }

  componentDidMount() {
    document.body.addEventListener("mousedown", this.onOutsideClick, false);
  }

  componentWillUnmount() {
    document.body.removeEventListener("mousedown", this.onOutsideClick, false);
  }

  onOutsideClick = (ev) => {
    const target = ev.target;
    let doesContain: boolean = (this.refNode as HTMLElement).contains(target);

    if (!doesContain) {
      this.setState({
        isSearchResultsVisible: false,
      });
    }
  };

  onSearchInputChange = debounce((event) => {
    const searchTerm = event.target.value;

    if (searchTerm && searchTerm.length >= 2) {
      this.getSearchResults(searchTerm);
    }

    this.setState({
      search: searchTerm,
    });
  }, 500);

  getSearchResults(searchTerm?: string) {
    let params = {
      searchTerm: searchTerm,
      page: 1,
    };

    const axiosPromise = Axios.get(`${API_ENDPOINT}/motorpool/supplier`, {
      params: params,
      withCredentials: true,
    });

    axiosPromise
      .then((response) => {
        const results: any[] = response.data[0].data;

        if (results && results.length > 0) {
          this.setState({
            searchResults: results,
            isSearchResultsVisible: true,
          });
        }
      })
      .catch((errResp) => {
        let message = errResp.response.data.msg
          ? errResp.response.data.msg
          : null;

        showToast({
          title: "Failed to retrieve search",
          message: message,
          duration: 3000,
        });
      });
  }

  onResultClick = (result: Sector) => {
    const label = result.name;

    this.setState({
      search: label,
      searchResults: [],
      isSearchResultsVisible: false,
    });

    this.props.onResultSelected(result);
  };

  render(props: SupplierSearchProps, state: SupplierSearchState) {
    const result = (member: Supplier, index: number) => {
      return (
        <p onClick={this.onResultClick.bind(this, member)}>
          {member.supplierName} - {member.supplierId} - {member.tin}
        </p>
      );
    };

    return (
      <div class="autocomplete-search" ref={(node) => (this.refNode = node)}>
        <input
          type="text"
          className={cx("autocomplete-search__input form-control")}
          disabled={props.disabled}
          placeholder="Search for an supplier name"
          value={state.search}
          onInput={this.onSearchInputChange.bind(this)}
        />
        <span class="autocomplete-search__icon">
          <i class="fas fa-search"></i>
        </span>
        <div
          className={cx("autocomplete-search__results", {
            active: state.isSearchResultsVisible,
          })}
        >
          {this.state.searchResults.map((member, i) => result(member, i))}
        </div>
      </div>
    );
  }
}
