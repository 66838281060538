import { h, Component } from 'preact';
import Portal from 'preact-portal';
import cx from 'classnames';

import { Remittance } from '../../../../types/remittance.model';
import { Subject, Subscription } from 'rxjs';
import { EditRequest, DeleteRequest } from '../../../../types/edit-request.model';

import { EDIT_REQUEST_PENDING, EDIT_REQUEST_APPROVED, EDIT_REQUEST_DECLINED } from '../../../../constants/edit-requests';
import { ViewUpdateDetailModal } from './view-update-detail.modal';
import { User } from '../../../../types/user.model';

export interface UpdateCardProps {
  request: EditRequest;
  isEdit: boolean;
  currentUser?: User;
}

interface UpdateCardState  {
  isModalOpen?: boolean;
}

export class UpdateCard extends Component<UpdateCardProps, UpdateCardState> {
  subs: Subscription[] = [];

  constructor(props: UpdateCardProps) {
    super(props);
  }

  componentDidMount() {
  }

  getStatusLabel(status: number) {
    switch(status) {
      case EDIT_REQUEST_PENDING:
        return 'Pending';

      case EDIT_REQUEST_APPROVED:
        return 'Approved';

      case EDIT_REQUEST_DECLINED:
        return 'Declined';
    }
  }

  getPortalModal(state: UpdateCardState) {
    if (state.isModalOpen) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-111">
            <ViewUpdateDetailModal 
              currentUser={this.props.currentUser}
              request={this.props.request}
              isEdit={this.props.isEdit}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId="anglo-modal-111"/>
          </div>
        </Portal>
      );
    }
  }

  onModalClose = () => {
    this.setState({
      isModalOpen: false
    });
  }

  onModalOpen = () => {
    this.setState({
      isModalOpen: true
    });
  }

  render(props: UpdateCardProps, state: UpdateCardState) {
    return (
      <div class="update-card">

        { this.getPortalModal(state) }

        <div className={ cx(
          'update-card__head pb-3',
          {
            'pending': props.request.status === EDIT_REQUEST_PENDING,
            'approved': props.request.status === EDIT_REQUEST_APPROVED,
            'denied': props.request.status === EDIT_REQUEST_DECLINED
          }
        ) }>
          <div class="">
            { props.request.remittanceId }
          </div>
        </div>

        <div class="update-card__content">
          <div class="row update-card__body mt-3"> 
            <div class="col-3 update-card__body__label">
              Status
            </div>
            <div class="col-9 update-card__body__value">
              { this.getStatusLabel(props.request.status) }
            </div>
          </div>

          <div class="row update-card__body mt-3"> 
            <div class="col-3 update-card__body__label">
              Supervisor
            </div>
            <div class="col-9 update-card__body__value">
              { props.request.username }
            </div>
          </div>

          <div class="row update-card__body mt-3"> 
            <div class="col-3 update-card__body__label">
              Reason
            </div>
            <div class="col-9 update-card__body__value">
              <div class="update-card__body__value__reason">
                { props.request.reason }
              </div>
            </div>
          </div>
        </div>

        <div class="row update-card__actions mt-3">
          <div class="col-12">
            <button class="btn btn-primary w-100" onClick={this.onModalOpen}>
              View Details
            </button>
          </div>
        </div>
      </div>
    );
  }
}