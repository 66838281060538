import { h, Component } from 'preact';
import cx from 'classnames';
import Portal from 'preact-portal';

import moment, { Moment } from 'moment-timezone';
import { Formats } from '../../../constants/api';

import { FieldList, Field, Form } from '../../../types/form.model';
import { Subject, Subscription } from 'rxjs';
import { validateForm, cxValidateField } from '../../../types/validator.model';
import linkform, { updateFormFieldValue } from '../../utils/linkform';

import { DatePicker } from './common/date-picker';
import getStore, { appState$ } from '../store';
import { RemittanceState, SUBMIT_REMITTANCE, RemittanceAction } from '../reducers/remittance/remittance.actions';
import { RequestState, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../types/request-state.model';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { UserAction, GET_BANKS } from '../reducers/user/user.actions';
import { BankAccount } from '../../../types/bank-account.model';
import { Remittance } from '../../../types/remittance.model';
import { toFixed } from '../../utils/decimal';
import { AutocompleteSector } from './common/autocomplete-sector.component';
import { Sector } from '../../../types/sector.model';

interface SubmitRemittanceState {
  remittanceForm?: Form;

  now?: string;

  isSubmitInProgress?: boolean;
  allBankAccounts: BankAccount[];

  isFormVisible?: boolean;
  remittances: Remittance[];

  isEditing?: boolean;
  currentlySelectedIndex?: number;
  isSubmitLocked?: boolean;
  isSubmitSuccess?: boolean;
}

export class SubmitRemittance extends Component<any, SubmitRemittanceState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props) {
    super(props);

    this.setState({
      now: moment(new Date()).format(Formats.date),
      remittances: []
    });

    // if user exists in store, redirect
    this.createForm();
  }

  componentDidMount() {

    const remittance$ = appState$.pipe(
      map(appState => appState.remittance)
    );

    const user$ = appState$.pipe(
      map(appState => appState.user)
    );

    this.subs.push(remittance$.pipe(
      map(remittance => remittance.submitRemitState)
    ).subscribe((submitRemitState) => {
      
        if (submitRemitState == REQUEST_IN_PROGRESS) {
          this.setState({
            isSubmitInProgress: true
          });
        } else {
          if (submitRemitState == REQUEST_SUCCESS) {
            this.resetComponent();
          }

          this.setState({
            isSubmitInProgress: false
          });
        }
      })
    );

    this.subs.push(user$.pipe(
      map(user => user.allBankAccounts),
      distinctUntilChanged()
    ).subscribe((allBankAccounts) => {
        if (allBankAccounts) {
          this.setState({
            allBankAccounts: allBankAccounts
          });
        }
      })
    );

    this.initialStoreDispatches();
  }

  initialStoreDispatches() {

    const store = getStore();
    store.dispatch({
      type: GET_BANKS,
      payload: {
        isSubmitRemit: true
      }
    } as UserAction);
  }

  getStartOfMonth() {
    return (moment(new Date()).startOf('month')).format(Formats.date);
  }

  getEndOfPreviousMOnth() {
    const date = (
      moment(new Date()).subtract(1, 'M').endOf('month')
    ).format(Formats.date);
    return date;
  }

  getFormattedDate(date: string) {
    return moment(date).format('MMMM DD, YYYY');
  }

  resetComponent() {
    this.setState({
      isSubmitLocked: true,
      isSubmitSuccess: true
    });

    setTimeout(() => {
      window.location.reload();
    }, 4000);
    // this.setState({
    //   remittanceForm : null
    // });

    // let fields: FieldList = {
    //   'bankAccount': new Field(['required'], null, ''),
    //   'amount': new Field(['required'], null, ''),
    //   'dateOfTransaction': new Field(['required'], null, ''),
    //   'area': new Field([] , null, ''),
    //   'salesAgent': new Field([] , null, ''),
    // };

    // this.setState({
    //   remittanceForm: new Form(fields)
    // });
    
    // this.form$.next(this.state.remittanceForm);
  }

  createForm() {
    let fields: FieldList = {
      'bankAccount': new Field(['required'], null, ''),
      'amount': new Field(['required'], null, ''),
      'dateOfTransaction': new Field(['required'], null, ''),
      'area': new Field([] , null, ''),
      'salesAgent': new Field([] , null, ''),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      remittanceForm: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['remittanceForm'] = form;
      this.setState(state);
    });
  }

  handleDatepickerValidation = (date: string, fieldName: string) => {
    const currentForm = this.state.remittanceForm; 

    // Manually do this because in order to follow the 
    // hopplerLinkstate validation system
    let clonedFields: FieldList = {};
    for (let k in currentForm.fields) {
      let prevField = currentForm.fields[k];
      clonedFields[k] = new Field(prevField.validators, prevField.customValidators, prevField.value);
      clonedFields[k].isValid = prevField.isValid;
    }

    let clone: Form = new Form(clonedFields);
    clone.fields[fieldName].value = date;
    clone.fields[fieldName].isPristine = false;
    // clone.fields[fieldName].customValidators.forEach(a => a(clone.fields[fieldName], clone));
    this.form$.next(clone);
  }

  onDateSelected = (date: string) => {
    this.handleDatepickerValidation(date, 'dateOfTransaction');
  }

  onSubmit = () => {
    // e.preventDefault();

    validateForm(this.state.remittanceForm);

    if (!this.state.remittanceForm.isValid) {
      return;
    }
    
    let form: Form = this.state.remittanceForm;
    // console.log('valid form');

    const store = getStore();
    const payload: RemittanceState = {
      remittancesToUpload: this.state.remittances
    };

    store.dispatch({
      type: SUBMIT_REMITTANCE,
      payload: payload
    } as RemittanceAction);
  }

  onAddRemittance = () => {
    validateForm(this.state.remittanceForm);

    if (!this.state.remittanceForm.isValid) {
      return;
    }
    
    let form: Form = this.state.remittanceForm;

    let remittance: Remittance = {
      amount: Number.parseFloat(form.fields['amount'].value),
      receivingBankAccount: form.fields['bankAccount'].value,
      dateOfTransaction: form.fields['dateOfTransaction'].value,
      area: form.fields['area'].value,
      salesAgent: form.fields['salesAgent'].value
    }

    let remittances = this.state.remittances;
    remittances.push(remittance);

    this.setState({
      isFormVisible: false
    });
  }

  onToggleForm = () => {
    //remittanceForm

    this.setState({
      isFormVisible: !this.state.isFormVisible
    })
  }

  onEditRemittance = (remittance: Remittance, index: number) => {
    let form = this.state.remittanceForm;

    form.fields['dateOfTransaction'].value = remittance.dateOfTransaction;
    form.fields['amount'].value = remittance.amount;
    form.fields['bankAccount'].value = remittance.receivingBankAccount;
    form.fields['area'].value = remittance.area;
    form.fields['salesAgent'].value = remittance.salesAgent;

    this.setState({
      isFormVisible: true,
      isEditing: true,
      currentlySelectedIndex: index
    });
  }

  onRemoveRemittance = (index: number) => {
    let remittances = this.state.remittances;
    remittances.splice(index, 1);

    this.setState({
      remittances: remittances
    });
  }

  onCancelEdit = () => {
    this.setState({
      currentlySelectedIndex: -1,
      isFormVisible: false,
      isEditing: false
    })
  }

  onCompleteEdit = () => {
    validateForm(this.state.remittanceForm);

    if (!this.state.remittanceForm.isValid) {
      return;
    }
    
    let form: Form = this.state.remittanceForm;
    console.log('valid form');

    let remittance: Remittance = {
      amount: Number.parseFloat(form.fields['amount'].value),
      receivingBankAccount: form.fields['bankAccount'].value,
      dateOfTransaction: form.fields['dateOfTransaction'].value,
      area: form.fields['area'].value,
      salesAgent: form.fields['salesAgent'].value
    }

    let remittances = this.state.remittances;
    remittances[this.state.currentlySelectedIndex] = remittance;

    this.setState({
      currentlySelectedIndex: -1,
      isFormVisible: false,
      isEditing: false,
      remittances: remittances
    });
  }

  onSectorSearchChange = (sector: Sector) => {
    updateFormFieldValue(
      this.form$,
      this.state.remittanceForm,
      'area',
      sector.name
    )
  }

  getSpinnerBlock(state: SubmitRemittanceState) {
    if (state.isSubmitInProgress) {
      return (
        <div class="col offset-md-9 d-flex justify-content-end">
          <div class="spinner-border text-primary mt-3" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }    
  }

  getSubmitBlock(state: SubmitRemittanceState) {
    let form = state.remittanceForm;

    if (!state.isSubmitInProgress) {
      return (
        <div class="col offset-md-9 d-flex justify-content-end">
          <button class="btn btn-primary w-100" disabled={!form.isValid}
            onClick={this.onSubmit}>
            Submit
          </button>
        </div>
      );
    }

  }

  getOpenFormBlock(state: SubmitRemittanceState) {
    if (!state.isFormVisible) {
      return(
        <button class="anglo-remittance__open-form mb-3" 
          onClick={this.onToggleForm}>
          Add another remittance
        </button>
      );
    }
  }

  getEditRemittanceBlock(state: SubmitRemittanceState) {
    if (state.isEditing) {
      return (
        <div class="col offset-md-6 d-flex justify-content-end">
          <button class="btn btn-outline-dark w-50 mr-3"
            onClick={this.onCancelEdit}>
            Cancel
          </button>

          <button class="btn btn-primary w-50"
            onClick={this.onCompleteEdit}>
            Edit
          </button>
        </div>
      );
    }
  }

  getAddRemittanceBlock(state: SubmitRemittanceState) {
    if (!state.isEditing) {
      return (
        <div class="col offset-md-9 d-flex justify-content-end">
          <button class="btn btn-primary w-100"
            onClick={this.onAddRemittance}>
            Add Remittance
          </button>
        </div>
      );
    }
  }

  getListBlock(state: SubmitRemittanceState) {
    if (state.isEditing) {
      let current = state.remittances[state.currentlySelectedIndex];

      return (
        <div class="row anglo-remittance__list my-4">
          <div class="col-12 col-md-4 mb-3">
            <div className={ cx(
              'anglo-remittance__list__item selected'
            )}>
              <div class="anglo-remittance__list__item__upper">
                <div>
                  { current.receivingBankAccount }
                </div>
                <div>
                  { this.getFormattedDate(current.dateOfTransaction) }
                </div>
                <div>
                  ₱ { toFixed(current.amount) }
                </div>
              </div>
              <div class="anglo-remittance__list__item__lower">
                <div class="anglo-remittance__list__item__detail">
                  <div class="anglo-remittance__list__item__detail__label">
                    Area
                  </div>
                  <div class="anglo-remittance__list__item__detail__value">
                    { current.area ? current.area : 'N/A '}
                  </div>
                </div>

                <div class="anglo-remittance__list__item__detail">
                  <div class="anglo-remittance__list__item__detail__label">
                    Sales Agent
                  </div>
                  <div class="anglo-remittance__list__item__detail__value">
                    { current.salesAgent ? current.salesAgent : 'N/A '}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      );
    }

    return (
      <div class="row anglo-remittance__list">
        {
          state.remittances.map((member, index) => {
            return (
              <div class="col-12 col-md-6 col-lg-4 mb-3">
                <div className={ cx(
                  'anglo-remittance__list__item',
                  {
                    'selected': index === state.currentlySelectedIndex
                  }
                )}>
                  <div class="anglo-remittance__list__item__upper">
                    <button class="btn btn-outline-dark anglo-remittance__list__item__upper__close" 
                      onClick={(ev) => this.onRemoveRemittance(index)}>
                      <i class="fas fa-trash"></i>
                    </button>
                    <div>
                      { member.receivingBankAccount }
                    </div>
                    <div>
                      { this.getFormattedDate(member.dateOfTransaction) }
                    </div>
                    <div>
                      ₱ { toFixed(member.amount) }
                    </div>
                  </div>
                  <div class="anglo-remittance__list__item__lower">
                    <div class="anglo-remittance__list__item__detail">
                      <div class="anglo-remittance__list__item__detail__label">
                        Area
                      </div>
                      <div class="anglo-remittance__list__item__detail__value">
                        { member.area ? member.area : 'N/A '}
                      </div>
                    </div>

                    <div class="anglo-remittance__list__item__detail">
                      <div class="anglo-remittance__list__item__detail__label">
                        Sales Agent
                      </div>
                      <div class="anglo-remittance__list__item__detail__value">
                        { member.salesAgent ? member.salesAgent : 'N/A '}
                      </div>
                    </div>
                  </div>

                  <button class="anglo-remittance__list__item__action w-100"
                      onClick={(ev) => this.onEditRemittance(member, index)}>
                    Edit
                  </button>

                </div>
              </div>
            );
          })
        }

        {
          !state.isFormVisible ?
          <div class="col-12 col-md-4">
            <button class=" anglo-remittance__list__add"
              onClick={this.onToggleForm}>
                <i class="fas fa-plus"></i>
            </button>
          </div> : null
        }

        
      </div>
    );
  }

  getFormBlock(state: SubmitRemittanceState) {
    let form = state.remittanceForm;

    if (state.isFormVisible) {
      return (
        <div class="anglo-remittance__submit-form" id="remittanceForm">
          <div class="row">
            <div class="col-12">
              <label class="anglo-remittance__submit-form__label">Receiving Bank Account*</label>

              {/* <input type="text" className={ cx('form-control', 
                  { 'invalid': cxValidateField(form.fields['bankAccount']) }
                )}
                value={ state.remittanceForm.fields['bankAccount'].value }
                placeholder="Bank Account"
                onChange={ linkform(this.form$, state.remittanceForm, 'bankAccount') }/> */}

              <select
                className={ cx('form-control', 
                  { 'invalid': cxValidateField(form.fields['bankAccount']) }
                )}
                placeholder="Bank Account"
                value={form.fields['bankAccount'].value}
                onChange={linkform(this.form$, form, 'bankAccount')}>

                  <option class="text-muted" value="" disabled={true} selected>Select Account</option>
                  {
                    state.allBankAccounts.map(acc => {
                      return <option value={acc.bankAccountName}>{ acc.bankAccountName }</option>
                    })
                  }
              </select>
            </div>

            <div class="col-12 mt-3">
              <label class="anglo-remittance__submit-form__label">Date of Transaction*</label>

              <DatePicker
                onDateSelected={this.onDateSelected}
                initialValue={ form.fields['dateOfTransaction'].value }
                min={ this.getEndOfPreviousMOnth() }
                isValid={form.fields['dateOfTransaction'].isValid}
                isPristine={form.fields['dateOfTransaction'].isPristine}>

              </DatePicker>
            </div>
            
          </div>

          <div class="row mt-3">
            <div class="col">
              <label class="anglo-remittance__submit-form__label">Amount Remitted*</label>

              <input type="number" className={ cx('form-control', 
                  { 'invalid': cxValidateField(form.fields['amount']) }
                )}
                step="0.01"
                value={ state.remittanceForm.fields['amount'].value }
                placeholder="Amount Remitted"
                onChange={ linkform(this.form$, state.remittanceForm, 'amount') }/>
            </div>

            {/* <div class="col-12 col-lg-6 mt-3 mt-lg-0">
              <label class="anglo-remittance__submit-form__label">Area</label>

              <input type="text" class="form-control"
                value={ state.remittanceForm.fields['area'].value }
                placeholder="Area"
                onChange={ linkform(this.form$, state.remittanceForm, 'area') }/>
            </div> */}

            <div class="col-12 col-lg-6 mt-3 mt-lg-0">
              <label class="anglo-remittance__submit-form__label">Area</label>

              <AutocompleteSector onResultSelected={this.onSectorSearchChange}/>
            </div>

            <div class="col-12 mt-3">
              <label class="anglo-remittance__submit-form__label">Sales Agent</label>
              <input class="form-control" type="text"
                onInput={linkform(this.form$, form, 'salesAgent')}
                value={form.fields['salesAgent'].value}
                placeholder="Agent name"/>
            </div>
          </div>

          <div class="row mt-5 align-items-end">

            { this.getSpinnerBlock(state) }
            { this.getAddRemittanceBlock(state) }
            { this.getEditRemittanceBlock(state) }
            
          </div>
        </div>
      );
    }
  }

  getPortalBlock(state: SubmitRemittanceState) {
    if (state.isSubmitSuccess) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-111">
            <div class="container anglo-modal">
              <div class="row">
                <div class="col-12 text-center">
                  <h3 class="anglo-remittance__success-submit mb-3">
                    Successfully submitted remittances!
                  </h3>
                  <p>
                    Your remittances have been uploaded and processed in the system.
                  </p>
                  <p>
                    This page will auto reload in 3 seconds. Please wait.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      );
    }
  }

  render(props, state: SubmitRemittanceState) {
    let form = state.remittanceForm;

    if (!state.allBankAccounts) {
      return (
        <div class="col offset-md-9 d-flex justify-content-end">
          <div class="spinner-border text-primary mt-3" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    return(
      <div class="anglo-remittance">
        <div class="container-fluid ">
          <div class="row">
            {/* cookie crumbs */}

            <div class="col-8 col-lg-4">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/remittance">Remittance</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Submit Remittance</li>
                </ol>
              </nav>
            </div>
          </div>         

          <div class="row mt-4">
            <div class="col">

              { this.getPortalBlock(state) }

              { this.getListBlock(state) }

              <div class="row">
                <div class="col-12">
                  { this.getFormBlock(state) }
                </div>
              </div>

              <div class="row d-md-none mt-3">
                <div class="col-12">
                  <button class="btn btn-secondary w-100"
                      onClick={this.onSubmit}
                      disabled={state.remittances.length < 1}>
                    Upload Remittances
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 mt-3 mt-lg-0">
              <div class="anglo-remittance__right">
                <div class="anglo-remittance__reminders">
                  <h2 class="anglo-remittance__reminders__h2">
                    Reminders
                  </h2>

                  <ul class="anglo-remittance__reminders__ul">
                    <li class="anglo-remittance__reminders__p">
                      Always make sure the account number you're remitting
                      to is correct. Double checking with your receipt is best.
                    </li>
                    <li class="anglo-remittance__reminders__p">
                      You don't need to place commas in the amount field.
                    </li>
                    <li class="anglo-remittance__reminders__p">
                      Ensure that the Area you are remitting from is correct.
                    </li>
                    <li class="anglo-remittance__reminders__p">
                      The last day of the previous month is open for any late uploads.
                    </li>
                  </ul>
                </div>

                <div class="row mt-4">
                  <div class="col-12 d-none d-md-block">
                    <button class="btn btn-secondary w-100"
                        onClick={this.onSubmit}
                        disabled={state.remittances.length < 1 || state.isSubmitLocked}>
                      Upload Remittances
                    </button>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-12 d-none d-md-block">
                    { this.getOpenFormBlock(state) }
                  </div>
                </div>
                
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }

}