export const VEHICLE_FILE_TYPES: string[] = [
  'Accident',
  'Breakdown',
  'Maintenance',
  'ORCR Renewed',
  'Refuel'
];

export const FILE_STATUS_PENDING = 'vf-status-pending';
export const FILE_STATUS_APPROVED = 'vf-status-approved';
export const FILE_STATUS_DENIED = 'vf-status-denied';

export const VEHICLE_STATUSES: string[] = [
  'new',
  'reassigned',
  'orcr renewed',
  'updated',
  'in repair',
  'damaged',
  'decommissioned'
]