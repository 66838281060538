import { h, Component } from "preact";
import cx from "classnames";
import { Subscription, Subject } from "rxjs";
import moment from "moment-timezone";
import { map, filter, distinctUntilChanged } from "rxjs/operators";

import { ModalProps, Modal } from "../../common/modal";
import { Form, FieldList, Field } from "../../../../../types/form.model";
import * as Validators from "../../../../../types/validator.model";
import {
  validateForm,
  cxValidateField,
} from "../../../../../types/validator.model";
import linkform, { updateFormFieldValue } from "../../../../utils/linkform";
import getStore, { appState$ } from "../../../store";

import {
  PurchaseDocument,
  ReceivingReport,
} from "../../../../../types/purchase-document.model";
import {
  REQUEST_IN_PROGRESS,
  REQUEST_SUCCESS,
} from "../../../../../types/request-state.model";
import { Item, ItemPiece } from "../../../../../types/item.model";
import { ItemSearch } from "../../common/item-search.component";
import { toFixed } from "../../../../utils/decimal";
import { getSpinnerBlock } from "../../common/spinner-block";
import {
  CREATE_PURCHASE_DOCUMENT,
  CREATE_SUPPLIER,
  SIGN_PURCHASE_DOCUMENT,
  UPDATE_PURCHASE_DOCUMENT,
} from "../../../reducers/motorpool/motorpool.actions";
import { Supplier } from "../../../../../types/supplier.model";
import { EDIT_REQUEST_STATUSES } from "../../../../../constants/edit-requests";

interface EditSupplierProps extends ModalProps {
  supplier: Supplier;
}

interface EditSupplierState {
  form?: Form;
  isPostInProgress?: boolean;
}

export class EditSupplierModal extends Modal<
  EditSupplierProps,
  EditSupplierState
> {
  form$: Subject<Form>;
  subs: Subscription[] = [];

  constructor(props: EditSupplierProps) {
    super(props);

    this.createForm(props);
  }

  createForm(props: EditSupplierProps) {
    let fields: FieldList = {
      supplierName: new Field(
        [Validators.required],
        null,
        props.supplier.supplierName
      ),
      supplierId: new Field([], null, props.supplier.supplierId),
      address: new Field([Validators.required], null, props.supplier.address),
      tin: new Field([Validators.required], null, props.supplier.tin),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      form: new Form(fields),
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state["form"] = form;
      this.setState(state);
    });
  }

  componentDidMount(): void {
    super.componentDidMount();

    const motorpool$ = appState$.pipe(map((appState) => appState.motorpool));

    this.subs.push(
      motorpool$
        .pipe(
          map((motorpool) => motorpool.postPutRequestState),
          distinctUntilChanged()
        )
        .subscribe((postPutRequestState) => {
          if (postPutRequestState === REQUEST_SUCCESS) {
            this.setState({
              isPostInProgress: false,
            });
            this.props.close();
          } else if (postPutRequestState === REQUEST_IN_PROGRESS) {
            this.setState({
              isPostInProgress: true,
            });
          }
        })
    );
  }

  doModalClose = () => {
    if (this.props.close) {
      this.props.close();
    }
  };

  onSubmit = () => {
    const form = this.state.form;
    validateForm(form);

    if (!form.isValid) {
      return;
    }

    let supplier: Supplier = {
      supplierName: form.fields["supplierName"].value,
      supplierId: form.fields["supplierId"].value,
      address: form.fields["address"].value,
      tin: form.fields["tin"].value,
    };

    const store = getStore();
    store.dispatch({
      type: EDIT_REQUEST_STATUSES,
      payload: {
        supplierToEdit: supplier,
      },
    });
  };

  getFormBlock(props: EditSupplierProps, state: EditSupplierState) {
    const form = this.state.form;

    return (
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div class="col-12 col-lg-6 mt-3">
              <label class="anglo-remittance__submit-form__label">
                Supplier Name*
              </label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["supplierName"]),
                })}
                type="text"
                value={form.fields["supplierName"].value}
                onChange={linkform(this.form$, form, "supplierName")}
                placeholder="Supplier Name"
              />
            </div>
            <div class="col-12 col-lg-6 mt-3">
              <label class="anglo-remittance__submit-form__label">
                Suggested Supplier ID
              </label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["supplierId"]),
                })}
                type="text"
                disabled={true}
                value={form.fields["supplierId"].value}
                onChange={linkform(this.form$, form, "supplierId")}
                placeholder="Suggested Supplier ID"
              />
            </div>
            <div class="col-12 col-lg-6 mt-3">
              <label class="anglo-remittance__submit-form__label">
                Address*
              </label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["address"]),
                })}
                type="text"
                value={form.fields["address"].value}
                onChange={linkform(this.form$, form, "address")}
                placeholder="Address"
              />
            </div>
            <div class="col-12 col-lg-6 mt-3">
              <label class="anglo-remittance__submit-form__label">Tin*</label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["tin"]),
                })}
                type="text"
                value={form.fields["tin"].value}
                onChange={linkform(this.form$, form, "tin")}
                placeholder="Tin"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  getActionsRow(props: EditSupplierProps, state: EditSupplierState) {
    if (state.isPostInProgress) {
      return getSpinnerBlock(state.isPostInProgress);
    } else {
      return (
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-3 offset-lg-9">
              <button
                class="btn btn-primary w-100"
                onClick={this.onSubmit}
                disabled={!state.form.isValid}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  render(props: EditSupplierProps, state: EditSupplierState) {
    return (
      <div
        class="container anglo-modal create-pd-modal"
        ref={(node) => (this.refNode = node)}
      >
        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">Create Supplier</h3>
          </div>
          <div class="col-3 col-md-2">
            <button
              class="btn btn-outline-light w-100"
              onClick={this.doModalClose}
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>

        <div class="row anglo-modal__body py-3">
          <div class="col-12 create-withdrawal-modal__body pb-3">
            {this.getFormBlock(props, state)}
          </div>
        </div>

        <div class="row vehicle-card__actions">
          {this.getActionsRow(props, state)}
        </div>
      </div>
    );
  }
}
