import { h, Component } from "preact";
import cx from "classnames";
import { Subscription, Subject } from "rxjs";
import moment from "moment-timezone";

import { ModalProps, Modal } from "../../common/modal";
import { Form, FieldList, Field } from "../../../../../types/form.model";
import * as Validators from "../../../../../types/validator.model";
import {
  validateForm,
  cxValidateField,
} from "../../../../../types/validator.model";
import linkform, { updateFormFieldValue } from "../../../../utils/linkform";

import {
  PurchaseDocument,
  ReceivingReport,
} from "../../../../../types/purchase-document.model";
import { ItemPiece } from "../../../../../types/item.model";
import { V2_JobOrder } from "../../../../../types/job-order.model";

interface ViewJOProps extends ModalProps {
  jo: V2_JobOrder;
}

interface ViewJOState {
  form?: Form;
}

export class ViewJOModal extends Modal<ViewJOProps, ViewJOState> {
  form$: Subject<Form>;
  subs: Subscription[] = [];

  constructor(props) {
    super(props);

    this.createForm(props);
  }

  createForm(props: ViewJOProps) {
    let fields: FieldList = {
      type: new Field([Validators.required], null, props.jo.type),
      status: new Field([Validators.required], null, props.jo.status),
      vehicleId: new Field([Validators.required], null, props.jo.vehicleId),
      description: new Field([Validators.required], null, props.jo.description),
      assignedPerson: new Field([], null, props.jo.assignedPerson),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      form: new Form(fields),
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state["form"] = form;
      this.setState(state);
    });
  }

  doModalClose = () => {
    if (this.props.close) {
      this.props.close();
    }
  };

  getFormBlock(props: ViewJOProps, state: ViewJOState) {
    const form = this.state.form;

    return (
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div class="col-12 col-lg-4 mt-3">
              <label class="anglo-remittance__submit-form__label">
                Vehicle ID
              </label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["vehicleId"]),
                })}
                disabled={true}
                type="text"
                value={form.fields["vehicleId"].value}
                onChange={linkform(this.form$, form, "vehicleId")}
                placeholder="Vehicle ID"
              />
            </div>
            <div class="col-12 col-lg-4 mt-3">
              <label class="anglo-remittance__submit-form__label">Status</label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["status"]),
                })}
                type="text"
                disabled={true}
                value={form.fields["status"].value}
                onChange={linkform(this.form$, form, "status")}
                placeholder="Status"
              />
            </div>
            <div class="col-12 col-lg-4 mt-3">
              <label class="anglo-remittance__submit-form__label">Type</label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["type"]),
                })}
                type="text"
                disabled={true}
                value={form.fields["type"].value}
                onChange={linkform(this.form$, form, "type")}
                placeholder="Type"
              />
            </div>
            <div class="col-12 mt-3">
              <label class="anglo-remittance__submit-form__label">
                Description
              </label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["description"]),
                })}
                type="text"
                disabled={true}
                value={form.fields["description"].value}
                onChange={linkform(this.form$, form, "description")}
                placeholder="Description"
              />
            </div>
          </div>
          {props.jo.itemWithdrawal ? (
            <div className="row mt-3">
              <div className="col-12">
                {this.getItemList(props.jo.itemWithdrawal.itemCodes)}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  getItemList(itemList: ItemPiece[]) {
    return (
      <div class="item-list data-grid">
        <div className="data-grid__row header">
          <div class="data-grid__col">Name</div>
          <div class="data-grid__col">Item Code</div>
          {/* <div class="data-grid__col">Supplier</div> */}
          <div class="data-grid__col">Amount</div>
          <div class="data-grid__col">Unit Price</div>
          <div class="data-grid__col">Remark</div>
        </div>
        {itemList.map((row, index) => {
          return (
            <div className="data-grid__row">
              <div class="data-grid__col">{row.name}</div>
              <div class="data-grid__col">{row.itemCode}</div>
              {/* <div class="data-grid__col">{row.supplierId}</div> */}
              <div class="data-grid__col">{row.amount}</div>
              <div class="data-grid__col">{row.unitPrice}</div>
              <div class="data-grid__col">{row.remark}</div>
            </div>
          );
        })}
      </div>
    );
  }

  render(props: ViewJOProps, state: ViewJOState) {
    return (
      <div
        class="container anglo-modal create-disposal-modal"
        ref={(node) => (this.refNode = node)}
      >
        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">Job Order | {props.jo.jobOrderId}</h3>
          </div>
          <div class="col-3 col-md-2">
            <button
              class="btn btn-outline-light w-100"
              onClick={this.doModalClose}
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>

        <div class="row anglo-modal__body py-3">
          <div class="col-12 create-withdrawal-modal__body pb-3">
            {this.getFormBlock(props, state)}
          </div>
        </div>
      </div>
    );
  }
}
