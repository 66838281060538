import { h, Component, ComponentChild } from "preact";
import cx from "classnames";
import getStore, { appState$ } from "../../store";
import { map, distinctUntilChanged } from "rxjs/operators";
import { Subscription, Subject } from "rxjs";
import Portal from "preact-portal";
import { param } from "jquery";
import moment from "moment-timezone";

import { toDecimal } from "../../../utils/decimal";

import { User } from "../../../../types/user.model";
import {
  Loan,
  LoanPayment,
  getLoanStatusString,
} from "../../../../types/loan.model";
import {
  ROLE_AUDITOR,
  ROLE_MANAGEMENT,
  ROLE_SUPERVISOR,
  ROLE_SUPER_ADMIN,
} from "../../../../constants/role";
import {
  GENERATE_SERVERSIDE_CASHBOND_REPORT,
  GENERATE_SERVERSIDE_LOAN_REPORT,
  GET_GRID_CASHBOND,
  GET_GRID_LOAN,
} from "../../reducers/cashbond-loan/cashbond-loan.actions";

import { Form, FieldList, Field } from "../../../../types/form.model";
import { CashBond } from "../../../../types/cashbond.model";
import { CreateLoanModal } from "./modal/create-loan.modal";
import { CreateLoanPaymentModal } from "./modal/create-loan-payment.modal";
import { ChangeLoanStateModal } from "./modal/change-loan-state.modal";
import { ViewLoanModal } from "./modal/view-loan.modal";
import { CreateCashbondModal } from "./modal/create-cashbond.modal";
import { CreateCashbondPaymentModal } from "./modal/create-cashbond-payment.modal";
import { validateForm } from "../../../../types/validator.model";
import { REQUEST_UNTOUCHED } from "../../../../types/request-state.model";
import linkform, { updateFormFieldValue } from "../../../utils/linkform";
import { DatePicker } from "../common/date-picker";
import { AutocompleteSearch } from "../common/autocomplete-search.component";
import { showToast } from "../../../utils/toast.service";
import { PAGE_SIZE } from "../remittance/dash-remittance.component";
import { Agent } from "../../../../types/agent.model";

export interface DashCashbondLoansProps {}

export interface DashCashbondLoansState {
  currentUser?: User;

  allLoans?: Loan[];
  focusedLoan?: Loan;

  allCashbonds?: CashBond[];
  focusedCashbond?: CashBond;

  isLoansMode?: boolean;

  filters?: Map<string, any>;
  filterForm?: Form;
  isFilterVisible?: boolean;

  pages?: number[];
  currentPage?: number;

  // if set to -1, close all
  modalState?: number;
}

export class DashCashbondLoans extends Component<any, DashCashbondLoansState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  resetStartDate: Function;
  resetEndDate: Function;

  private MODAL_VIEW_LOAN: number = 0;
  private MODAL_VIEW_CASHBOND: number = 1;
  private MODAL_PAY_LOAN: number = 2;
  private MODAL_PAY_CASHBOND: number = 3;
  private MODAL_CREATE_LOAN: number = 4;
  private MODAL_CREATE_CASHBOND: number = 5;
  private MODAL_UPDATE_LOAN_STATUS: number = 6;

  constructor(props) {
    super(props);

    let filters: Map<string, any> = new Map();
    filters.set("page", 1);

    this.setState({
      modalState: -1,
      filters: filters,
      currentPage: 1,
      isLoansMode: true,
    });

    this.createForm();
  }

  createForm() {
    let fields: FieldList = {
      startDate: new Field(),
      endDate: new Field(),
      loanId: new Field(),
      status: new Field(),
      supervisorUsername: new Field(),
      agentId: new Field(),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      filterForm: new Form(fields),
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state["filterForm"] = form;
      this.setState(state);
    });
  }

  componentDidMount() {
    const user$ = appState$.pipe(map((appState) => appState.user));

    this.subs.push(
      user$
        .pipe(
          map((user) => user.currentUser),
          distinctUntilChanged()
        )
        .subscribe((currentUser) => {
          this.setState({
            currentUser: currentUser,
          });
        })
    );

    const cashbondLoans$ = appState$.pipe(
      map((appState) => appState.cashbondloan)
    );

    this.subs.push(
      cashbondLoans$
        .pipe(
          map((cashbondloan) => cashbondloan.loans),
          distinctUntilChanged()
        )
        .subscribe((loans) => {
          this.setState({
            allLoans: loans,
          });
        })
    );

    this.subs.push(
      cashbondLoans$
        .pipe(
          map((cashbondloan) => cashbondloan.cashbonds),
          distinctUntilChanged()
        )
        .subscribe((cashbonds) => {
          this.setState({
            allCashbonds: cashbonds,
          });
        })
    );

    this.subs.push(
      cashbondLoans$
        .pipe(map((cashbondloan) => cashbondloan.total))
        .subscribe((total) => {
          if (total) {
            let pages: number[] = [];
            let limit = Math.ceil(total / PAGE_SIZE);

            for (let i = 0; i < limit; i++) {
              pages.push(i + 1);
            }

            this.setState({
              pages: pages,
            });
          }
        })
    );

    this.initialStoreDispatches();
  }

  initialStoreDispatches() {
    const store = getStore();
    const action: string = this.state.isLoansMode
      ? GET_GRID_LOAN
      : GET_GRID_CASHBOND;

    store.dispatch({
      type: action,
      payload: {
        filters: this.state.filters,
      },
    });

    store.dispatch({
      type: GET_GRID_CASHBOND,
      payload: {
        postPutRequestState: REQUEST_UNTOUCHED,
        paymentsRequestState: REQUEST_UNTOUCHED,
        filters: this.state.filters,
      },
    });
  }

  onModalOpened = (paramState: number, row?: any) => {
    if (paramState === this.MODAL_VIEW_LOAN) {
      this.setState({
        modalState: paramState,
        focusedLoan: row as Loan,
      });
    } else if (paramState === this.MODAL_VIEW_CASHBOND) {
      this.setState({
        modalState: paramState,
        focusedCashbond: row as CashBond,
      });
    } else if (paramState === this.MODAL_PAY_CASHBOND) {
      this.setState({
        modalState: paramState,
        focusedCashbond: row as CashBond,
      });
    } else if (paramState === this.MODAL_CREATE_CASHBOND) {
      this.setState({
        modalState: paramState,
      });
    } else if (paramState === this.MODAL_CREATE_LOAN) {
      this.setState({
        modalState: paramState,
      });
    } else if (paramState === this.MODAL_PAY_LOAN) {
      this.setState({
        modalState: paramState,
        focusedLoan: row as Loan,
      });
    } else if (paramState === this.MODAL_UPDATE_LOAN_STATUS) {
      this.setState({
        modalState: paramState,
        focusedLoan: row as Loan,
      });
    } else {
    }
  };

  onModalClose = (returnParams: any) => {
    this.setState({
      modalState: -1,
    });

    this.initialStoreDispatches();
  };

  onSupervisorSearchChange = (supervisor: User) => {
    updateFormFieldValue(
      this.form$,
      this.state.filterForm,
      "supervisorUsername",
      supervisor.username
    );
  };

  onAgentSearchChange = (agent: Agent) => {
    console.log("agent", agent);
    updateFormFieldValue(
      this.form$,
      this.state.filterForm,
      "agentId",
      agent._id
    );
  };

  switchMode = () => {
    this.setState({
      isLoansMode: !this.state.isLoansMode,
    });
  };

  onPageClicked = (page: number) => {
    let filterForm = {};
    const fieldList = this.state.filterForm.fields;

    for (let k in fieldList) {
      if (fieldList[k].value) {
        filterForm[k] = fieldList[k].value;
      }
    }

    filterForm["page"] = page;

    let filters = this.state.filters;
    filters.set("page", page);

    const action: string = this.state.isLoansMode
      ? GET_GRID_LOAN
      : GET_GRID_CASHBOND;

    const store = getStore();
    store.dispatch({
      type: action,
      payload: {
        filters: filters,
      },
    });

    this.setState({
      currentPage: page,
    });
  };

  generateReport = () => {
    let filters: Map<string, any> = new Map<string, any>();
    const fieldList = this.state.filterForm.fields;

    for (let k in fieldList) {
      if (fieldList[k].value) {
        filters.set(k, fieldList[k].value);
      }
    }

    filters.set("pageSize", 5000);
    filters.set("page", 1);

    const store = getStore();
    store.dispatch({
      type: GENERATE_SERVERSIDE_LOAN_REPORT,
      payload: {
        filters: filters,
      },
    });
  };

  generateCashbondReport = () => {
    let filters: Map<string, any> = new Map<string, any>();
    const fieldList = this.state.filterForm.fields;

    for (let k in fieldList) {
      if (fieldList[k].value) {
        filters.set(k, fieldList[k].value);
      }
    }

    filters.set("pageSize", 5000);
    filters.set("page", 1);

    const store = getStore();
    store.dispatch({
      type: GENERATE_SERVERSIDE_CASHBOND_REPORT,
      payload: {
        filters: filters,
      },
    });
  };

  onFilterToggleClicked = () => {
    this.setState({
      isFilterVisible: !this.state.isFilterVisible,
    });
  };

  startDateSelected = (date: string) => {
    updateFormFieldValue(this.form$, this.state.filterForm, "startDate", date);
  };

  endDateSelected = (date: string) => {
    updateFormFieldValue(this.form$, this.state.filterForm, "endDate", date);
  };

  resetStartDates = (returned: Function) => {
    this.resetStartDate = returned;
  };

  resetEndDates = (returned: Function) => {
    this.resetEndDate = returned;
  };

  resetFilters = () => {
    const fieldList = this.state.filterForm.fields;

    for (let k in fieldList) {
      fieldList[k].value = null;
    }

    this.resetStartDate();
    this.resetEndDate();

    let filters: Map<string, any> = new Map();
    filters.set("page", 1);

    this.setState({
      filters: filters,
      currentPage: 1,
    });

    this.initialStoreDispatches();
  };

  applyFilters = () => {
    let filters = this.state.filters;
    const fieldList = this.state.filterForm.fields;

    console.log(fieldList);

    for (let k in fieldList) {
      if (fieldList[k].value) {
        filters.set(k, fieldList[k].value);
      }
    }

    const action: string = this.state.isLoansMode
      ? GET_GRID_LOAN
      : GET_GRID_CASHBOND;

    const store = getStore();
    store.dispatch({
      type: action,
      payload: {
        filters: filters,
      },
    });

    showToast({
      title: "Filters Applied",
      message: "Data filtered and new report may be generated",
      duration: 3000,
    });
  };

  getActionsBlock(state: DashCashbondLoansState) {
    let role: string;

    if (state && state.currentUser) {
      role = state.currentUser.role;
    }
    // if (state.currentUser && role && role !== ROLE_SUPERVISOR) {

    // } else {
    //   return null;
    // }

    if (state.isLoansMode) {
      if ((role && role === ROLE_MANAGEMENT) || role === ROLE_AUDITOR) {
        return (
          <div class="row">
            <div class="col-12 col-lg-2 mb-3 mb-lg-0">
              <button
                class="btn btn-secondary w-100"
                onClick={this.generateReport}
              >
                Generate Report
              </button>
            </div>

            <div class="col-12 offset-lg-6 col-lg-2">
              <button
                class="btn btn-outline-dark w-100"
                onClick={() => {
                  this.onModalOpened(this.MODAL_CREATE_LOAN);
                }}
              >
                Create Loan
              </button>
            </div>

            <div class="col-12 col-lg-2">
              <button class="btn btn-primary w-100" onClick={this.switchMode}>
                Switch to {this.state.isLoansMode ? "Cashbonds" : "Loans"}
              </button>
            </div>
          </div>
        );
      } else {
        return (
          <div class="row">
            <div class="col-12 offset-lg-10 col-lg-2">
              <button class="btn btn-primary w-100" onClick={this.switchMode}>
                Switch to {this.state.isLoansMode ? "Cashbonds" : "Loans"}
              </button>
            </div>
          </div>
        );
      }
    } else {
      if ((role && role === ROLE_MANAGEMENT) || role === ROLE_AUDITOR) {
        return (
          <div class="row">
            <div class="col-12 col-lg-2 mb-3 mb-lg-0">
              <button
                class="btn btn-secondary w-100"
                onClick={this.generateCashbondReport}
              >
                Generate Report
              </button>
            </div>

            <div class="col-12 offset-lg-6 col-lg-2 mb-3 mb-lg-0">
              <button
                class="btn btn-outline-dark w-100"
                onClick={() => {
                  this.onModalOpened(this.MODAL_CREATE_CASHBOND);
                }}
              >
                Create Cashbond
              </button>
            </div>
            <div class="col-12 col-lg-2">
              <button class="btn btn-primary w-100" onClick={this.switchMode}>
                Switch to {this.state.isLoansMode ? "Cashbonds" : "Loans"}
              </button>
            </div>
          </div>
        );
      } else {
        return (
          <div class="row">
            <div class="col-12 offset-lg-6 col-lg-2 mb-3 mb-lg-0">
              <button
                class="btn btn-outline-dark w-100"
                onClick={() => {
                  this.onModalOpened(this.MODAL_CREATE_CASHBOND);
                }}
              >
                Create Cashbond
              </button>
            </div>
            <div class="col-12 col-lg-2">
              <button class="btn btn-primary w-100" onClick={this.switchMode}>
                Switch to {this.state.isLoansMode ? "Cashbonds" : "Loans"}
              </button>
            </div>
          </div>
        );
      }
    }
  }

  getLoanActions(state: DashCashbondLoansState, row: Loan, index: number) {
    const role = state.currentUser.role;
    return (
      <div class="dropdown-menu" aria-labelledby={`actions${index}`}>
        <button
          class="dropdown-item"
          onClick={(e) => this.onModalOpened(this.MODAL_VIEW_LOAN, row)}
        >
          View Loan Details
        </button>
        {role === ROLE_SUPERVISOR ? (
          <button
            class="dropdown-item"
            onClick={(e) => this.onModalOpened(this.MODAL_PAY_LOAN, row)}
          >
            Make a Payment
          </button>
        ) : null}
        {role === ROLE_MANAGEMENT || role === ROLE_SUPER_ADMIN ? (
          <button
            class="dropdown-item"
            onClick={(e) =>
              this.onModalOpened(this.MODAL_UPDATE_LOAN_STATUS, row)
            }
          >
            Approve/Reject
          </button>
        ) : null}
      </div>
    );
  }

  getCashbondActions(
    state: DashCashbondLoansState,
    row: CashBond,
    index: number
  ) {
    const role = state.currentUser.role;
    return (
      <div class="dropdown-menu" aria-labelledby={`actions${index}`}>
        {/* <button class="dropdown-item" onClick={e => this.onModalOpened(this.MODAL_VIEW_CASHBOND, row)}>
          View Cashbond Details
        </button> */}
        <button
          class="dropdown-item"
          onClick={(e) => this.onModalOpened(this.MODAL_PAY_CASHBOND, row)}
        >
          Make a Payment
        </button>
        {/* {
          role === ROLE_SUPERVISOR ?
          <button class="dropdown-item" onClick={e => this.onModalOpened(this.MODAL_PAY_CASHBOND, row)}>
            Make a Payment
          </button> : null
        } */}
      </div>
    );
  }

  getLoansGrid(state: DashCashbondLoansState) {
    if (state.allLoans) {
      return (
        <div class="dash-cashbon-loan___grid-container">
          <div class="row mt-3">
            <div class="col-12">
              <div class="data-grid">
                <div class="data-grid__row header">
                  <div class="data-grid__col">Actions</div>
                  <div class="data-grid__col">Loan ID</div>
                  <div class="data-grid__col">Supervisor</div>
                  <div class="data-grid__col">Agent</div>
                  <div class="data-grid__col">Status</div>
                  <div class="data-grid__col">Amount Loaned</div>
                  <div class="data-grid__col">Remaining</div>
                  <div class="data-grid__col">Sector</div>
                  {/* <div class="data-grid__col">
                  Notes
                </div> */}
                </div>

                {state.allLoans.map((row, index) => {
                  return (
                    <div class="data-grid__row">
                      <div class="data-grid__col">
                        <div class="dropright">
                          <button
                            class="btn btn-outline-primary dropdown-toggle"
                            type="button"
                            id={`actions${index}`}
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Select action
                          </button>
                          {this.getLoanActions(state, row, index)}
                        </div>
                      </div>
                      <div class="data-grid__col">{row.loanId}</div>
                      <div class="data-grid__col">{row.supervisorUsername}</div>
                      <div class="data-grid__col">
                        {row.agent.firstName} {row.agent.lastName}
                      </div>
                      <div class="data-grid__col text-capitalize">
                        {getLoanStatusString(row.status)}
                      </div>
                      <div class="data-grid__col">
                        <i>₱ {toDecimal(row.amount)}</i>
                      </div>
                      <div class="data-grid__col">
                        <b>₱ {toDecimal(row.remaining)}</b>
                      </div>
                      <div class="data-grid__col">{row.sectorId}</div>
                      {/* <div class="data-grid__col">
                        { row.description }
                      </div> */}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  getCashbondGrid(state: DashCashbondLoansState) {
    if (state.allCashbonds) {
      return (
        <div class="dash-cashbon-loan___grid-container">
          <div class="row mt-3">
            <div class="col-12">
              <div class="data-grid">
                <div class="data-grid__row header">
                  <div class="data-grid__col">Actions</div>
                  <div class="data-grid__col">Cashbond ID</div>
                  <div class="data-grid__col">Supervisor</div>
                  <div class="data-grid__col">Agent</div>
                  <div class="data-grid__col">Amount Total</div>
                  <div class="data-grid__col">Sector</div>
                  <div class="data-grid__col">Date Created</div>
                </div>

                {state.allCashbonds.map((row, index) => {
                  return (
                    <div class="data-grid__row">
                      <div class="data-grid__col">
                        <div class="dropright">
                          <button
                            class="btn btn-outline-primary dropdown-toggle"
                            type="button"
                            id={`actions${index}`}
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Select action
                          </button>
                          {this.getCashbondActions(state, row, index)}
                        </div>
                      </div>
                      <div class="data-grid__col">{row.cashbondId}</div>
                      <div class="data-grid__col">{row.supervisorUsername}</div>
                      <div class="data-grid__col">
                        {row.agent.firstName} {row.agent.lastName}
                      </div>
                      <div class="data-grid__col">
                        ₱ {toDecimal(row.amount)}
                      </div>
                      <div class="data-grid__col">{row.sectorId}</div>
                      <div class="data-grid__col">
                        {moment(row.dateCreated).format("MMMM DD, YYYY")}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  // we show loans by default. cashbonds can be switched to
  getGridBlock(state: DashCashbondLoansState) {
    if (state.isLoansMode) {
      return this.getLoansGrid(state);
    } else {
      return this.getCashbondGrid(state);
    }
  }

  getPortalModal = (state: DashCashbondLoansState) => {
    let modalState = state.modalState;

    if (modalState < 0) {
      return null;
    } else if (modalState === this.MODAL_CREATE_LOAN) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-124">
            <CreateLoanModal
              currentUser={state.currentUser}
              close={this.onModalClose}
              isOutsideClickClose={false}
              containerId="anglo-modal-123"
            />
          </div>
        </Portal>
      );
    } else if (modalState === this.MODAL_PAY_LOAN) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-124">
            <CreateLoanPaymentModal
              currentUser={state.currentUser}
              loan={state.focusedLoan}
              close={this.onModalClose}
              isOutsideClickClose={false}
              containerId="anglo-modal-123"
            />
          </div>
        </Portal>
      );
    } else if (modalState === this.MODAL_UPDATE_LOAN_STATUS) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-124">
            <ChangeLoanStateModal
              currentUser={state.currentUser}
              loan={state.focusedLoan}
              close={this.onModalClose}
              isOutsideClickClose={false}
              containerId="anglo-modal-123"
            />
          </div>
        </Portal>
      );
    } else if (modalState === this.MODAL_VIEW_LOAN) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-124">
            <ViewLoanModal
              currentUser={state.currentUser}
              loan={state.focusedLoan}
              close={this.onModalClose}
              isOutsideClickClose={false}
              containerId="anglo-modal-123"
            />
          </div>
        </Portal>
      );
    } else if (modalState === this.MODAL_CREATE_CASHBOND) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-124">
            <CreateCashbondModal
              currentUser={state.currentUser}
              close={this.onModalClose}
              isOutsideClickClose={false}
              containerId="anglo-modal-123"
            />
          </div>
        </Portal>
      );
    } else if (modalState === this.MODAL_PAY_CASHBOND) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-124">
            <CreateCashbondPaymentModal
              currentUser={state.currentUser}
              cashbond={state.focusedCashbond}
              close={this.onModalClose}
              isOutsideClickClose={false}
              containerId="anglo-modal-123"
            />
          </div>
        </Portal>
      );
    }
  };

  getPaginationBlock(state: DashCashbondLoansState) {
    return (
      <div class="row">
        <div class="col-12">
          {state.pages ? (
            <div class="dash-remittance__pagination mb-3">
              <button
                class="btn dash-remittance__pagination__page arrow"
                disabled={state.currentPage === 1}
                onClick={() => this.onPageClicked(state.currentPage - 1)}
              >
                <i class="fas fa-chevron-left"></i>
              </button>
              {state.currentPage > 5 ? (
                <button
                  className={cx("btn dash-remittance__pagination__page", {
                    active: state.pages[0] === state.currentPage,
                  })}
                  onClick={() => this.onPageClicked(state.pages[0])}
                >
                  {state.pages[0]}
                </button>
              ) : null}
              {state.currentPage > 5 ? <span class="mx-2">...</span> : null}
              {state.pages
                ? state.pages.map((page) => {
                    if (
                      page > state.currentPage - 5 &&
                      page < state.currentPage + 5
                    ) {
                      return (
                        <button
                          className={cx(
                            "btn dash-remittance__pagination__page",
                            { active: page === state.currentPage }
                          )}
                          onClick={() => this.onPageClicked(page)}
                        >
                          {page}
                        </button>
                      );
                    }
                  })
                : null}
              {state.currentPage < state.pages.length - 5 ? (
                <span class="mx-2">...</span>
              ) : null}
              {state.currentPage < state.pages.length - 5 ? (
                <button
                  className={cx("btn dash-remittance__pagination__page", {
                    active:
                      state.pages[state.pages.length - 1] === state.currentPage,
                  })}
                  onClick={() =>
                    this.onPageClicked(state.pages[state.pages.length - 1])
                  }
                >
                  {state.pages[state.pages.length - 1]}
                </button>
              ) : null}
              <button
                class="btn dash-remittance__pagination__page arrow"
                disabled={
                  state.currentPage === state.pages[state.pages.length - 1]
                }
                onClick={() => this.onPageClicked(state.currentPage + 1)}
              >
                <i class="fas fa-chevron-right"></i>
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  getFilterBlock(state: DashCashbondLoansState) {
    return (
      <div class="container-fluid px-0 mt-3">
        <div class="row justify-content-end">
          <div class="col-12 col-lg-3 offset-9 d-flex justify-content-end">
            <a
              class="dash-remittance__filter-row__filter-btn"
              onClick={this.onFilterToggleClicked}
            >
              Filter <i class="fas fa-filter"></i>
            </a>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-12">
            {state.isFilterVisible ? (
              <div class="dash-remittance__filter-row__filters mt-2">
                {this.getFilterForm(state)}
                {this.getFilterActions(state)}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  getFilterForm(state: DashCashbondLoansState) {
    let form = state.filterForm;

    if (form && state.isFilterVisible) {
      return (
        <div class="row mt-3">
          {state.currentUser.role === ROLE_MANAGEMENT ||
          state.currentUser.role === ROLE_AUDITOR ? (
            <div class="col-12 col-lg-4 mt-1">
              <label class="dash-remittance__filter-row__filters__label">
                Supervisor
              </label>
              <AutocompleteSearch
                onResultSelected={this.onSupervisorSearchChange}
              />
            </div>
          ) : null}

          <div class="col-12 col-lg-4 mt-1">
            <label class="anglo-remittance__submit-form__label">Agent*</label>
            <AutocompleteSearch
              onResultSelected={this.onAgentSearchChange}
              placeholder="Search by Agent"
              searchUrl="agent"
            />
          </div>

          <div class="col-12 col-lg-4 mt-1">
            <label class="dash-remittance__filter-row__filters__label">
              Loan ID
            </label>
            <input
              className={cx("form-control")}
              type="text"
              value={form.fields["loanId"].value}
              onChange={linkform(this.form$, form, "loanId")}
              placeholder="Loan ID"
            />
          </div>

          {/* <div class="col-12 col-lg-3">
            <label class="dash-remittance__filter-row__filters__label">Location</label>
            <AutocompleteLocation onResultSelected={this.onLocationSearchChange} />
          </div> */}

          <div class="col-12 col-lg-4 mt-1">
            <label class="dash-remittance__filter-row__filters__label">
              Start date
            </label>
            <DatePicker
              onDateSelected={this.startDateSelected}
              initialValue={form.fields["startDate"].value}
              isValid={form.fields["startDate"].isValid}
              isPristine={form.fields["startDate"].isPristine}
              reset={this.resetStartDates}
            ></DatePicker>
          </div>

          <div class="col-12 col-lg-4 mt-1">
            <label class="dash-remittance__filter-row__filters__label">
              End date
            </label>
            <DatePicker
              onDateSelected={this.endDateSelected}
              initialValue={form.fields["endDate"].value}
              isValid={form.fields["endDate"].isValid}
              isPristine={form.fields["endDate"].isPristine}
              reset={this.resetEndDates}
            ></DatePicker>
          </div>
        </div>
      );
    }
  }

  getFilterActions(state: DashCashbondLoansState) {
    if (state.isFilterVisible) {
      return (
        <div class="row mt-3">
          <div class="col-12 col-lg-2 offset-lg-8">
            <button
              class="btn btn-outline-dark w-100"
              onClick={this.resetFilters}
            >
              Reset Filters
            </button>
          </div>
          <div class="col-12 col-lg-2">
            <button class="btn btn-secondary w-100" onClick={this.applyFilters}>
              Apply Filters
            </button>
          </div>
        </div>
      );
    }
  }

  render(props, state: DashCashbondLoansState) {
    return (
      <div class="dash-cashbond-loans  anglo__dashboard">
        {this.getPortalModal(state)}

        <div class="container-fluid">
          <div class="row">
            <div class="col-8 col-lg-4">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/disposal">Cashbonds / Loans</a>
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          {this.getActionsBlock(state)}

          {this.getFilterBlock(state)}
          {this.getGridBlock(state)}

          {this.getPaginationBlock(state)}

          {/* { this.getInsightBlock(props, state) }

           */}
        </div>
      </div>
    );
  }
}
