import { h, Component } from "preact";
import cx from "classnames";
import { Subscription, Subject } from "rxjs";
import moment from "moment-timezone";
import { map, filter, distinctUntilChanged } from "rxjs/operators";

import { ModalProps, Modal } from "../../common/modal";
import { Form, FieldList, Field } from "../../../../../types/form.model";
import * as Validators from "../../../../../types/validator.model";
import {
  validateForm,
  cxValidateField,
} from "../../../../../types/validator.model";
import linkform, { updateFormFieldValue } from "../../../../utils/linkform";
import getStore, { appState$ } from "../../../store";

import {
  PurchaseDocument,
  ReceivingReport,
} from "../../../../../types/purchase-document.model";
import {
  REQUEST_IN_PROGRESS,
  REQUEST_SUCCESS,
} from "../../../../../types/request-state.model";
import { Item, ItemPiece } from "../../../../../types/item.model";
import { ItemSearch } from "../../common/item-search.component";
import { toFixed } from "../../../../utils/decimal";
import { getSpinnerBlock } from "../../common/spinner-block";
import {
  CREATE_PURCHASE_DOCUMENT,
  UPDATE_PURCHASE_DOCUMENT,
} from "../../../reducers/motorpool/motorpool.actions";

interface UpdatePDProps extends ModalProps {
  pd: PurchaseDocument;
}

interface UpdatePDState {
  form?: Form;
  itemList: Item[];
  isPostInProgress?: boolean;
}

export class UpdatePDModal extends Modal<UpdatePDProps, UpdatePDState> {
  form$: Subject<Form>;
  subs: Subscription[] = [];

  constructor(props: UpdatePDProps) {
    super(props);

    this.createForm(props);
    this.setState({
      itemList: props.pd.itemList as Item[],
    });
  }

  createForm(props: UpdatePDProps) {
    let fields: FieldList = {
      status: new Field([Validators.required], null, props.pd.status),
      requestedBy: new Field([Validators.required], null, props.pd.requestedBy),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      form: new Form(fields),
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state["form"] = form;
      this.setState(state);
    });
  }

  componentDidMount(): void {
    super.componentDidMount();

    const motorpool$ = appState$.pipe(map((appState) => appState.motorpool));

    this.subs.push(
      motorpool$
        .pipe(
          map((motorpool) => motorpool.postPutRequestState),
          distinctUntilChanged()
        )
        .subscribe((postPutRequestState) => {
          if (postPutRequestState === REQUEST_SUCCESS) {
            this.setState({
              isPostInProgress: false,
            });
            this.props.close();
          } else if (postPutRequestState === REQUEST_IN_PROGRESS) {
            this.setState({
              isPostInProgress: true,
            });
          }
        })
    );
  }

  doModalClose = () => {
    if (this.props.close) {
      this.props.close();
    }
  };

  onSubmit = () => {
    const form = this.state.form;
    validateForm(form);

    if (!form.isValid) {
      return;
    }

    let pd: PurchaseDocument = {
      requestedBy: form.fields["requestedBy"].value,
      itemList: this.state.itemList,
    };

    const store = getStore();
    store.dispatch({
      type: UPDATE_PURCHASE_DOCUMENT,
      payload: {
        purchaseDocumentToUpdate: pd,
      },
    });
  };

  onItemSelected = (a: Item) => {
    let itemList = this.state.itemList;
    const existing = itemList.find((member) => member.itemCode === a.itemCode);

    if (!existing) {
      // brute force setting amount to 1 (we don't set the amount in system)
      a.amount = 1;
      itemList.push(a);

      this.setState({
        itemList: itemList,
      });
    }
  };

  getFormBlock(props: UpdatePDProps, state: UpdatePDState) {
    const form = this.state.form;

    if (!form) {
      return;
    }

    return (
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div class="col-12 col-lg-4 mt-3">
              <label class="anglo-remittance__submit-form__label">
                Requested By
              </label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["requestedBy"]),
                })}
                type="text"
                value={form.fields["requestedBy"].value}
                onChange={linkform(this.form$, form, "requestedBy")}
                placeholder="Requested By"
              />
            </div>
            <div class="col-12 col-lg-4 mt-3">
              <label class="anglo-remittance__submit-form__label">Status</label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["status"]),
                })}
                type="text"
                value={form.fields["status"].value}
                onChange={linkform(this.form$, form, "status")}
                placeholder="Status"
              />
            </div>
            <div class="col-12 mt-3">
              <ItemSearch onResultSelected={this.onItemSelected} />
            </div>
          </div>
          <div className="row my-2">
            <div className="col-12 col-lg-4 offset-lg-8 text-right">
              <p class="mb-0">
                <b>Running Total: ₱ {toFixed(this.getRunningTotal())}</b>
              </p>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              {state.itemList.length > 0
                ? this.getItemList(state.itemList)
                : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  getRunningTotal = () => {
    let runningTotal: number = 0;
    const itemList = this.state.itemList;

    itemList.forEach((i) => {
      runningTotal += i.amount * i.unitPrice;
    });

    return runningTotal;
  };

  removeItem = (index: number) => {
    let itemList = this.state.itemList;
    itemList.splice(index, 1);
    this.setState({
      itemList: itemList,
    });
  };

  onItemAmountChange = (event, index: number) => {
    const target = event.target;
    const value = target.value;
    let itemList = this.state.itemList;
    itemList[index].amount = value;

    this.setState({
      itemList: itemList,
    });
  };

  getItemList(itemList: Item[]) {
    return (
      <div class="item-list data-grid">
        <div className="data-grid__row header">
          <div class="data-grid__col">Name</div>
          <div class="data-grid__col">Item Code</div>
          <div class="data-grid__col">Supplier</div>
          <div class="data-grid__col">Unit Price</div>
          <div class="data-grid__col col-short">Amount</div>
          <div class="data-grid__col">Total</div>
          <div class="data-grid__col col-short">Remove</div>
        </div>
        {itemList.map((row, index) => {
          return (
            <div className="data-grid__row">
              <div class="data-grid__col">{row.name}</div>
              <div class="data-grid__col">{row.itemCode}</div>
              <div class="data-grid__col">{row.supplierId}</div>
              <div class="data-grid__col">₱ {toFixed(row.unitPrice)}</div>
              <div class="data-grid__col col-short">
                <input
                  className={"form-control"}
                  type="number"
                  value={row.amount}
                  onChange={(ev) => this.onItemAmountChange(ev, index)}
                  placeholder="Requested By"
                />
              </div>
              <div class="data-grid__col">
                ₱ {toFixed(row.unitPrice * row.amount)}
              </div>
              <div class="data-grid__col col-short">
                <button
                  class="btn btn-outline-dark"
                  onClick={(ev) => this.removeItem(index)}
                >
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  getActionsRow(props: UpdatePDProps, state: UpdatePDState) {
    if (state.isPostInProgress) {
      return getSpinnerBlock(state.isPostInProgress);
    } else {
      return (
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-3 offset-lg-9">
              <button
                class="btn btn-primary w-100"
                onClick={this.onSubmit}
                disabled={!state.form.isValid}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  render(props: UpdatePDProps, state: UpdatePDState) {
    return (
      <div
        class="container anglo-modal create-pd-modal"
        ref={(node) => (this.refNode = node)}
      >
        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">
              Update Purchase Document | {props.pd.purchaseDocumentId}
            </h3>
          </div>
          <div class="col-3 col-md-2">
            <button
              class="btn btn-outline-light w-100"
              onClick={this.doModalClose}
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>

        <div class="row anglo-modal__body py-3">
          <div class="col-12 create-withdrawal-modal__body pb-3">
            {this.getFormBlock(props, state)}
          </div>
        </div>

        <div class="row vehicle-card__actions">
          {this.getActionsRow(props, state)}
        </div>
      </div>
    );
  }
}
