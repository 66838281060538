import { h, Component } from "preact";
import cx from "classnames";
import { map, filter, distinctUntilChanged } from "rxjs/operators";
import { Subscription, Subject } from "rxjs";

import { ModalProps, Modal } from "../../common/modal";
import getStore, { appState$ } from "../../../store";
import { Form, FieldList, Field } from "../../../../../types/form.model";
import * as Validators from "../../../../../types/validator.model";
import {
  validateForm,
  cxValidateField,
} from "../../../../../types/validator.model";
import linkform, { updateFormFieldValue } from "../../../../utils/linkform";
import {
  REQUEST_FAILED,
  REQUEST_IN_PROGRESS,
  REQUEST_SUCCESS,
} from "../../../../../types/request-state.model";

import { User } from "../../../../../types/user.model";
import {
  CREATE_EMPLOYEE,
  EDIT_EMPLOYEE,
} from "../../../reducers/user/user.actions";
import { Agent } from "../../../../../types/agent.model";

export interface EditEmployeeModalProps extends ModalProps {
  currentUser?: User;
  selectedSupervisor?: User;
  selectedEmployee?: Agent;
}

interface EditEmployeeModalState {
  employeeForm?: Form;
  isPostInProgress?: boolean;
}

export class EditEmployeeModal extends Modal<
  EditEmployeeModalProps,
  EditEmployeeModalState
> {
  form$: Subject<Form>;
  subs: Subscription[] = [];

  constructor(props) {
    super(props);

    this.createForm(props);
  }

  createForm(props: EditEmployeeModalProps) {
    let fields: FieldList = {
      firstName: new Field(
        [Validators.required],
        null,
        props.selectedEmployee?.firstName
      ),
      lastName: new Field(
        [Validators.required],
        null,
        props.selectedEmployee?.lastName
      ),
      employeeType: new Field(
        [Validators.required],
        null,
        props.selectedEmployee?.employeeType
      ),
      // isActive: new Field([Validators.required], null, ""),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      employeeForm: new Form(fields),
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state["employeeForm"] = form;
      this.setState(state);
    });
  }

  componentDidMount(): void {
    super.componentDidMount();

    const user$ = appState$.pipe(map((appState) => appState.user));

    this.subs.push(
      user$
        .pipe(
          map((user) => user.createEmployeeState),
          distinctUntilChanged()
        )
        .subscribe((postPutRequestState) => {
          if (postPutRequestState === REQUEST_SUCCESS) {
            this.setState({
              isPostInProgress: false,
            });
            this.props.close();
          } else if (postPutRequestState === REQUEST_IN_PROGRESS) {
            this.setState({
              isPostInProgress: true,
            });
          } else if (postPutRequestState === REQUEST_FAILED) {
            this.setState({
              isPostInProgress: false,
            });
            this.props.close();
          }
        })
    );
  }

  doModalClose = () => {
    this.props.close();
  };

  onSubmit = () => {
    const form = this.state.employeeForm;
    validateForm(form);

    this.setState({
      employeeForm: form,
    });

    if (!this.state.employeeForm.isValid) {
      return;
    }

    let employee: Agent = {
      _id: this.props.selectedEmployee._id,
      firstName: form.fields["firstName"].value,
      lastName: form.fields["lastName"].value,
      employeeType: form.fields["employeeType"].value,
      isActive: true,
    };

    const store = getStore();

    store.dispatch({
      type: EDIT_EMPLOYEE,
      payload: {
        employeeToCreate: employee,
        supervisorUsername: this.props.selectedSupervisor.username,
      },
    });
  };

  getFormBlock(props: EditEmployeeModalProps, state: EditEmployeeModalState) {
    const form = this.state.employeeForm;

    if (form) {
      return (
        <div className="row">
          <div className="col-12 create-employee-modal__form">
            <div className="row">
              <div class="col-12 col-lg-4 mt-3">
                <label class="anglo-remittance__submit-form__label">
                  First Name*
                </label>
                <input
                  className={cx("form-control", {
                    invalid: cxValidateField(form.fields["firstName"]),
                  })}
                  type="text"
                  value={form.fields["firstName"].value}
                  onChange={linkform(this.form$, form, "firstName")}
                  placeholder="First Name"
                />
              </div>
              <div class="col-12 col-lg-4 mt-3">
                <label class="anglo-remittance__submit-form__label">
                  Last Name*
                </label>
                <input
                  className={cx("form-control", {
                    invalid: cxValidateField(form.fields["lastName"]),
                  })}
                  type="text"
                  value={form.fields["lastName"].value}
                  onChange={linkform(this.form$, form, "lastName")}
                  placeholder="Last Name"
                />
              </div>
              <div class="col-12 col-lg-4 mt-3">
                <label class="anglo-remittance__submit-form__label">
                  Employee Type*
                </label>
                <select
                  className={cx("form-control", {
                    invalid: cxValidateField(form.fields["employeeType"]),
                  })}
                  placeholder="Employee Type"
                  value={form.fields["employeeType"].value}
                  onChange={linkform(this.form$, form, "employeeType")}
                >
                  <option class="text-muted" value="" disabled={true} selected>
                    Select Type
                  </option>
                  {/* <option class="text-muted" value="supervisor" selected>
                    Supervisor
                  </option> */}
                  <option class="text-muted" value="agent" selected>
                    Agent
                  </option>
                  <option class="text-muted" value="secretary" selected>
                    Secretary
                  </option>
                  <option class="text-muted" value="driver" selected>
                    Driver
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div class="row align-center">{this.getSpinnerBlock()}</div>;
    }
  }

  getSpinnerBlock() {
    return (
      <div class="col offset-md-9 d-flex justify-content-end">
        <div class="spinner-border text-primary mt-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  getActionsRow(props: EditEmployeeModalProps, state: EditEmployeeModalState) {
    if (state.isPostInProgress) {
      return this.getSpinnerBlock();
    } else {
      return (
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-3 offset-lg-9">
              <button
                class="btn btn-primary w-100"
                onClick={this.onSubmit}
                disabled={!state.employeeForm.isValid}
              >
                Submit Edit
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  render(props: EditEmployeeModalProps, state: EditEmployeeModalState) {
    return (
      <div
        class="container anglo-modal create-disposal-modal"
        ref={(node) => (this.refNode = node)}
      >
        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">
              Edit Employee - {props.selectedEmployee?.firstName} {}
            </h3>
          </div>
          <div class="col-3 col-md-2">
            <button
              class="btn btn-outline-light w-100"
              onClick={this.doModalClose}
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>

        <div class="row anglo-modal__body py-3">
          <div class="col-12 create-withdrawal-modal__body pb-3">
            {this.getFormBlock(props, state)}
          </div>
        </div>

        <div class="row vehicle-card__actions mt-3">
          {this.getActionsRow(props, state)}
        </div>
      </div>
    );
  }
}
