export const API_ENDPOINT = '//api.cartaatc.com';
// export const API_ENDPOINT = '//localhost:8080';

// alert('YOU ARE IN DEV MODE.');

export const Formats = {
  dateTime: 'YYYY-MM-DD HH:mm:ss',
  date: 'YYYY-MM-DD',
  dateWorded: 'MMMM DD, YYYY'
}

export const PACKS_PER_REAM: number = 10;
export const REAMS_PER_CASE: number = 50;