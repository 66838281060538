import { h, Component } from 'preact';
import Portal from 'preact-portal';
import cx from 'classnames';

import { Subject, Subscription } from 'rxjs';

import { EDIT_REQUEST_PENDING, EDIT_REQUEST_APPROVED, EDIT_REQUEST_DECLINED } from '../../../../constants/edit-requests';

import { User } from '../../../../types/user.model';
import { VehicleFile } from '../../../../types/vehicle.model';
import { EditVehicleModal } from './edit-vehicle.modal';
import { FileReportModal } from './file-report.modal';

import moment from 'moment-timezone';
import { RespondFileReportModal } from './respond-file-report.modal';
import { FILE_STATUS_PENDING, FILE_STATUS_APPROVED, FILE_STATUS_DENIED } from '../../../../constants/vehicle-files';
import getStore from '../../store';
import { GET_VEHICLE_FILES, VehicleAction, GET_VEHICLES } from '../../reducers/vehicle/vehicle.actions';

export interface VehicleFileCardProps {
  vehicleFile: VehicleFile;
  currentUser: User;
}

interface VehicleFileCardState  {
  isModalOpen?: boolean;
  
  modalId?: string;
}

export class VehicleFileCard extends Component<VehicleFileCardProps, VehicleFileCardState> {
  subs: Subscription[] = [];

  constructor(props: VehicleFileCardProps) {
    super(props);
  }

  componentDidMount() {
  }

  getStatusLabel(status: number) {
    switch(status) {
      case EDIT_REQUEST_PENDING:
        return 'Pending';

      case EDIT_REQUEST_APPROVED:
        return 'Approved';

      case EDIT_REQUEST_DECLINED:
        return 'Declined';
    }
  }

  getPortalModal(state: VehicleFileCardState) {
    if (state.isModalOpen) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container scrollable" id={state.modalId}>
            <RespondFileReportModal 
              currentUser={this.props.currentUser}
              vehicleFile={this.props.vehicleFile}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId={state.modalId}/>
          </div>
        </Portal>
      );
    }
  }

  update() {

    const store = getStore();
    store.dispatch({
      type: GET_VEHICLE_FILES,
      payload: {
        type: 'edit'
        // filters: {
        //   page: 1
        // }
      }
    } as VehicleAction);

    store.dispatch({
      type: GET_VEHICLES,
      payload: {
        // filters: {
        //   page: 1
        // }
      }
    } as VehicleAction);

  }

  onModalClose = () => {
    this.setState({
      isModalOpen: false
    });

    this.update();
  }
  

  onModalOpen = () => {
    const random = Math.floor(Math.random() *200) ;
    console.log(this.state);

    this.setState({
      isModalOpen: true,
      modalId: `anglo-modal-${random}`
    });
  }

  getStatusName(status: string) {
    if (status === FILE_STATUS_PENDING) {
      return 'Pending';
    }

    if (status === FILE_STATUS_APPROVED) {
      return 'Approved';
    }

    if (status === FILE_STATUS_DENIED) {
      return 'Denied';
    }
  }

  getActionsBlock(props: VehicleFileCardProps) {

    // if (props.vehicleFile.status !== FILE_STATUS_PENDING) {
    //   return;
    // }

    if (props.currentUser.role === 'role_management') {
      return (
        <div class="col-12">
          <button class="btn btn-primary w-100" onClick={this.onModalOpen}>
            Respond to File
          </button>
        </div>
      );
    } 
    else {
      return (
        <div class="col-12">
          <button class="btn btn-primary w-100"
            onClick={this.onModalOpen}>
            View Details
          </button>
        </div>
      );
    }
  }

  getResponseBlock(props: VehicleFileCardProps) {
    if (props.vehicleFile.responseReason) {
      return (
        <div class="col-12 mb-3">
          <div class="vehicle-card__body__label">
            Response
          </div>
          <div class="vehicle-card__body__value">
            { props.vehicleFile.responseReason }
          </div>
        </div>
      );
    }
  }

  render(props: VehicleFileCardProps, state: VehicleFileCardState) {
    return (
      <div class="vehicle-card file">

        { this.getPortalModal(state) }

        <div className={ cx(
          'vehicle-card__head pb-3',
          {
            'pending': props.vehicleFile.status === FILE_STATUS_PENDING,
            'approved': props.vehicleFile.status === FILE_STATUS_APPROVED,
            'denied': props.vehicleFile.status === FILE_STATUS_DENIED
          }
        ) }>
          <div class="">
            Vehicle ID { props.vehicleFile.vehicleId }
          </div>
          <div class=" text-right">
            { props.vehicleFile.vehicle.plateNumber }
          </div>
        </div>

        <div class="row vehicle-card__body mt-3"> 
          <div class="col-6 mb-3">
            <div class="vehicle-card__body__label">
              File Type
            </div>
            <div class="vehicle-card__body__value">
              { props.vehicleFile.type }
            </div>
          </div>

          <div class="col-6 mb-3">
            <div class="vehicle-card__body__label">
              Status
            </div>
            <div class="vehicle-card__body__value">
              {this.getStatusName(props.vehicleFile.status)}
            </div>
          </div>

          <div class="col mb-3">
            <div class="vehicle-card__body__label">
              Date of Report
            </div>
            <div class="vehicle-card__body__value">
              { moment(props.vehicleFile.dateOfReport).format('MMM DD, YYYY') }
            </div>
          </div>

          {
            props.currentUser.role === 'role_management' ?
            <div class="col mb-3">
              <div class="vehicle-card__body__label">
                Supervisor
              </div>
              <div class="vehicle-card__body__value">
                { props.vehicleFile.user.firstName } { props.vehicleFile.user.lastName }
              </div>
            </div> : null
          }

          <div class="col-12 mb-3">
            <div class="vehicle-card__body__label">
              Details
            </div>
            <div class="vehicle-card__body__value">
              { props.vehicleFile.description }
            </div>
          </div>

          { this.getResponseBlock(props) }

        </div>

        <div class="row vehicle-card__actions mt-3">
          {
            this.getActionsBlock(props)
          }
        </div>
      </div>
    );
  }
}