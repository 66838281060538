import { h, Component } from 'preact';
import cx from 'classnames';
import getStore, { appState$ } from '../../store';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import Portal from 'preact-portal';
import moment from 'moment-timezone';

import { RequestState, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../types/request-state.model';
import { GET_GRID_WITHDRAWAL } from '../../reducers/withdrawals/withdrawals.actions';
import { toFixed } from '../../../utils/decimal';
import { Form, FieldList, Field } from '../../../../types/form.model';
import { validateForm, cxValidateField } from '../../../../types/validator.model';

import { User } from '../../../../types/user.model';
import { ROLE_ADMIN_SALES, ROLE_MANAGEMENT, ROLE_SUPERVISOR } from '../../../../constants/role';
import { 
  Withdrawal,
  WITHDRAWAL_STAGE_APPROVAL_PENDING,
  WITHDRAWAL_STAGE_STOCK_PREPARING,
  WITHDRAWAL_STAGE_READY_FOR_PICKUP,
  WITHDRAWAL_STAGE_PICKED_UP,
  WITHDRAWAL_STAGE_AWAITING_SHIPMENT,
  WITHDRAWAL_STAGE_IN_TRANSIT,
  WITHDRAWAL_STAGE_SHIPMENT_RECEIVED
} from '../../../../types/withdrawal.model';
import { ViewWithdrawalModal } from './modal/view-withdrawal.modal';
import { CreateWithdrawalModal } from './modal/create-withdrawal.modal';
import { DataDisplayWidget } from '../common/data-display.widget';
import { ReviewStocksModal } from './modal/review-stocks.modal';
import { PAGE_SIZE } from '../remittance/dash-remittance.component';
import { ChangeStageModal } from './modal/change-stage.modal';
import { AutocompleteSearch } from '../common/autocomplete-search.component';
import linkform, { updateFormFieldValue } from '../../../utils/linkform';
import { DatePicker } from '../common/date-picker';

export const MODAL_CHANGE_WITHDRAWAL_REEDIT: string = 'management-reedit';
export const MODAL_CHANGE_WITHDRAWAL_STAGE: string = 'change-stage';


export interface DashWithdrawalsProps {

}

export interface DashWithdrawalsState {
  gridData?: Withdrawal[];
  gridRequestState?: RequestState;
  pages?: number[];
  currentPage?: number;

  selectedWithdrawal?: Withdrawal;

  filters?: Map<string, any>;
  filterForm?: Form;

  // if set to -1, close all
  modalState?: number;

  currentUser?: User;
  isFilterVisible?: boolean;
}

export class DashWithdrawals extends Component<DashWithdrawalsProps, DashWithdrawalsState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  resetStartDate: Function;
  resetEndDate: Function;

  private MODAL_CREATE_WITHDRAWAL: number = 0;
  private MODAL_VIEW_DETAILS: number = 1;
  private MODAL_REVIEW_CURRENT_STOCKS: number = 2;
  private MODAL_REEDIT: number = 3;
  private MODAL_CHANGE_STAGE: number = 4;
  

  constructor(props: DashWithdrawalsProps) {
    super(props);

    let filters: Map<string, any> = new Map();
    filters.set('page', 1);

    this.setState({
      modalState: -1,
      filters: filters,
      currentPage: 1
    });

    this.createForm();
  }

  createForm() {
    let fields: FieldList = {
      'startDate': new Field([], null, ''),
      'endDate': new Field([] , null, ''),
      'minAmount': new Field([] , null, ''),
      'maxAmount': new Field([] , null, ''),
      'status': new Field([] , null, ''),
      'stage': new Field([] , null, ''),
      'username': new Field([] , null, ''),
      'withdrawalId': new Field([], null, ''),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      filterForm: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['filterForm'] = form;
      this.setState(state);
    });
  }

  componentDidMount() {

    const user$ = appState$.pipe(
      map(appState => appState.user)
    );

    this.subs.push(user$.subscribe((user) => {
        if (user.currentUser) {
          this.setState({
            currentUser: user.currentUser
          });
        }
      })
    );

    const withdrawal$ = appState$.pipe(
      map(appState => appState.withdrawal)
    );

    this.subs.push(
      withdrawal$.pipe(
        map(withdrawal => withdrawal.gridRequestState)
      ).subscribe((gridRequestState) => {
        this.setState({
          gridRequestState: gridRequestState
        });
      })
    );

    this.subs.push(
      withdrawal$.pipe(
        map(withdrawal => withdrawal.withdrawals),
        distinctUntilChanged()
      ).subscribe((withdrawals) => {
        this.setState({
          gridData: withdrawals
        });
      })
    );

    this.subs.push(withdrawal$.pipe(
      map(withdrawal => withdrawal.total),
      distinctUntilChanged()
    ).subscribe((total) => {
        if (total) {
          let pages: number[] = [];
          let limit = Math.ceil(total / PAGE_SIZE);

          for(let i = 0; i < limit; i++) {
            pages.push(i + 1);
          }

          this.setState({
            pages: pages
          });
        }
      })
    );

    this.initialStoreDispatches();
  }

  initialStoreDispatches() {
    const store = getStore();

    store.dispatch({
      type: GET_GRID_WITHDRAWAL,
      payload: {
        filters: this.state.filters
      }
    });
  }

  onViewDetails = (withdrawal: Withdrawal) => {
    this.setState({
      selectedWithdrawal: withdrawal
    });

    this.onModalOpened(this.MODAL_VIEW_DETAILS);
  }

  onModalOpened = (paramState: number) => {
    this.setState({
      modalState: paramState
    });
  }

  onModalClose = (returnParms: any) => {
    this.setState({
      modalState: -1
    });

    if (returnParms) {
      if (returnParms.changeMode && returnParms.changeMode === MODAL_CHANGE_WITHDRAWAL_REEDIT) {
        this.onModalOpened(this.MODAL_REEDIT);
      } else if (returnParms.changeMode && returnParms.changeMode === MODAL_CHANGE_WITHDRAWAL_STAGE) {
        this.onModalOpened(this.MODAL_CHANGE_STAGE);
      }
    }

    this.initialStoreDispatches();
  }

  onPageClicked = (page: number) => {
    let filterForm = {};
    const fieldList = this.state.filterForm.fields;

    for (let k in fieldList) {
      if (fieldList[k].value) {
        // filters.set(k, fieldList[k].value);
        filterForm[k] = fieldList[k].value;
      }
    }

    filterForm['page'] = page;

    let filters = this.state.filters;
    filters.set('page', page);

    const store = getStore();
    store.dispatch({
      type: GET_GRID_WITHDRAWAL,
      payload: {
        filters: filters
      }
    });

    this.setState({
      currentPage: page
    })
  }

  onSupervisorSearchChange = (supervisor: User) => {
    updateFormFieldValue(
      this.form$,
      this.state.filterForm,
      'username',
      supervisor.username
    );
  }

  onFilterToggleClicked = () => {
    this.setState({
      isFilterVisible: !this.state.isFilterVisible
    });
  }

  startDateSelected = (date: string) => {
    updateFormFieldValue(
      this.form$,
      this.state.filterForm,
      'startDate',
      date
    );
  }

  resetStartDates = (returned: Function) => {
    this.resetStartDate = returned;
  }

  resetEndDates = (returned: Function) => {
    this.resetEndDate = returned;
  }

  applyFilters = () => {
    let filters = this.state.filters;
    const fieldList = this.state.filterForm.fields;

    for (let k in fieldList) {
      if (fieldList[k].value) {
        filters.set(k, fieldList[k].value);
      }
    }

    const store = getStore();
    store.dispatch({
      type: GET_GRID_WITHDRAWAL,
      payload: {
        filters: filters
      }
    });
  }

  resetFilters = () => {
    const fieldList = this.state.filterForm.fields;

    for (let k in fieldList) {
      fieldList[k].value = null;
    }

    this.resetStartDate();
    this.resetEndDate();

    let filters: Map<string, any> = new Map();
    filters.set('page', 1);

    this.setState({
      filters: filters,
      currentPage: 1
    });

    const store = getStore();
    store.dispatch({
      type: GET_GRID_WITHDRAWAL,
      payload: {
        filters: filters
      }
    });
  }

  getWithdrawalStage(stage: string) {
    switch (stage) {
      case WITHDRAWAL_STAGE_APPROVAL_PENDING:
        return 'Approval Pending';
        
      case WITHDRAWAL_STAGE_STOCK_PREPARING:
        return 'Stock Preparing';

      case WITHDRAWAL_STAGE_READY_FOR_PICKUP:
        return 'Ready for Pickup';

      case WITHDRAWAL_STAGE_PICKED_UP:
        return 'Picked Up';

      case WITHDRAWAL_STAGE_AWAITING_SHIPMENT:
        return 'Awaiting Shipment';

      case WITHDRAWAL_STAGE_IN_TRANSIT:
        return 'In Transit';

      case WITHDRAWAL_STAGE_SHIPMENT_RECEIVED:
        return 'Shipment Received';
    }
  }

  getPortalModal = (state: DashWithdrawalsState) => {
    let modalState = state.modalState;

    if (modalState < 0) {
      return null;
    } else if (modalState === this.MODAL_CREATE_WITHDRAWAL) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-124">
            <CreateWithdrawalModal 
              currentUser={state.currentUser}
              withdrawal={state.selectedWithdrawal}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId="anglo-modal-123"/>
          </div>
        </Portal>
      );
    } else if (modalState === this.MODAL_VIEW_DETAILS) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-123">
            <ViewWithdrawalModal 
              currentUser={state.currentUser}
              withdrawal={state.selectedWithdrawal}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId="anglo-modal-123"/>
          </div>
        </Portal>
      );
    } else if (modalState === this.MODAL_REVIEW_CURRENT_STOCKS) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-123">
            <ReviewStocksModal 
              currentUser={state.currentUser}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId="anglo-modal-123"/>
          </div>
        </Portal>
      );
    } else if (modalState === this.MODAL_REEDIT) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-123">
            <CreateWithdrawalModal 
              currentUser={state.currentUser}
              withdrawal={state.selectedWithdrawal}
              isReedit={true}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId="anglo-modal-123"/>
          </div>
        </Portal>
      );
    } else if (modalState === this.MODAL_CHANGE_STAGE) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-123">
            <ChangeStageModal 
              currentUser={state.currentUser}
              withdrawal={state.selectedWithdrawal}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId="anglo-modal-123"/>
          </div>
        </Portal>
      );
    } else {
      return null;
    }
    
  }

  getInsightBlock(props: DashWithdrawalsProps, state: DashWithdrawalsState) {
    return (
      <div class="row">
        {/* <div class="col-12 col-md-6 col-xl-4 mt-4 mt-xl-0">
          <DataDisplayWidget 
            name="Monthly Count"
            data1={ state.currentMonthTotal.count }
            data1Label="Number of Remittances Submitted (Current Month)"
            data2={ state.previousMonthTotal.count }
            data2Label="Number of Remittances Submitted (Previous Month)"
            changeAmount={change}  
            isChangePositive={isPos}
          />
        </div> */}
      </div>
    );
  }

  getActionsBlock(props: DashWithdrawalsProps, state: DashWithdrawalsState) {
    if (state.currentUser && state.currentUser.role === ROLE_SUPERVISOR) {
      return (
        <div class="row">
          <div class="col-12 offset-lg-8 col-lg-2 mb-lg-0">
            <button class="btn btn-outline-dark w-100"
              onClick={() => {this.onModalOpened(this.MODAL_REVIEW_CURRENT_STOCKS)}}>
              Review Current Stocks
            </button>
          </div>
          <div class="col-12 col-lg-2">
            <button class="btn btn-secondary w-100"
              onClick={() => {this.onModalOpened(this.MODAL_CREATE_WITHDRAWAL)}}>
              Create Withdrawal
            </button>
          </div>
        </div>
      );
    }
  }

  getFilterForm(props: DashWithdrawalsProps, state: DashWithdrawalsState) {
    let form = state.filterForm;
    
    if (state.currentUser && state.isFilterVisible) {
      if (state.currentUser.role === ROLE_MANAGEMENT) {
        return (
          <div class="row mt-3">
            <div class="col-12 col-lg-3">
              <label class="dash-remittance__filter-row__filters__label">Supervisor</label>
              <AutocompleteSearch onResultSelected={this.onSupervisorSearchChange} />
            </div>

            <div class="col-12 col-lg-3">
              <label class="dash-remittance__filter-row__filters__label">Withdrawal Id</label>
              <input type="text" className={ cx('form-control', 
                { 'invalid': cxValidateField(form.fields['withdrawalId']) }
              )}
              value={ form.fields['withdrawalId'].value }
              placeholder=""
              onChange={ linkform(this.form$, form, 'withdrawalId') }/>
            </div>

            <div class="col-12 col-lg-3">
              <label class="dash-remittance__filter-row__filters__label">Start Creation date</label>
              <DatePicker
                onDateSelected={this.startDateSelected}
                initialValue={ form.fields['startDate'].value }
                isValid={form.fields['startDate'].isValid}
                isPristine={form.fields['startDate'].isPristine}
                reset={this.resetStartDates}>
              </DatePicker>
            </div>

            <div class="col-12 col-lg-3">
              <label class="dash-remittance__filter-row__filters__label">End Creation date</label>
              <DatePicker
                onDateSelected={this.startDateSelected}
                initialValue={ form.fields['endDate'].value }
                isValid={form.fields['endDate'].isValid}
                isPristine={form.fields['endDate'].isPristine}
                reset={this.resetEndDates}>
              </DatePicker>
            </div>
          </div>
        );
      } else {
        return (
          <div class="row mt-3">
            <div class="col-12 col-lg-3">
              <label class="dash-remittance__filter-row__filters__label">Withdrawal Id</label>
              <input type="text" className={ cx('form-control', 
                { 'invalid': cxValidateField(form.fields['withdrawalId']) }
              )}
              value={ form.fields['withdrawalId'].value }
              placeholder=""
              onChange={ linkform(this.form$, form, 'withdrawalId') }/>
            </div>

            <div class="col-12 col-lg-3">
              <label class="dash-remittance__filter-row__filters__label">Start Creation date</label>
              <DatePicker
                onDateSelected={this.startDateSelected}
                initialValue={ form.fields['startDate'].value }
                isValid={form.fields['startDate'].isValid}
                isPristine={form.fields['startDate'].isPristine}
                reset={this.resetStartDates}>
              </DatePicker>
            </div>

            <div class="col-12 col-lg-3">
              <label class="dash-remittance__filter-row__filters__label">End Creation date</label>
              <DatePicker
                onDateSelected={this.startDateSelected}
                initialValue={ form.fields['endDate'].value }
                isValid={form.fields['endDate'].isValid}
                isPristine={form.fields['endDate'].isPristine}
                reset={this.resetEndDates}>
              </DatePicker>
            </div>
          </div>
        );
      }
    }
  }

  getFilterActions(props: DashWithdrawalsProps, state: DashWithdrawalsState) {
    if (state.isFilterVisible) {
      return (
        <div class="row mt-3">
          <div class="col-12 col-lg-2 offset-lg-8">
            <button class="btn btn-outline-dark w-100"
              onClick={this.resetFilters}>
              Reset Filters
            </button>
          </div>
          <div class="col-12 col-lg-2">
            <button class="btn btn-secondary w-100"
              onClick={this.applyFilters}>
              Apply Filters
            </button>
          </div>
        </div>
      );
    }
  }

  getFilterBlock(props: DashWithdrawalsProps, state: DashWithdrawalsState) {
    return (
      <div class="container-fluid px-0 mt-3">
        <div class="row justify-content-end">
          <div class="col-12 col-lg-3 offset-9 d-flex justify-content-end">
            <a class="dash-remittance__filter-row__filter-btn" onClick={ this.onFilterToggleClicked }>
              Filter <i class="fas fa-filter"></i>
            </a>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-12">
            {
              state.isFilterVisible ?
              <div class="dash-remittance__filter-row__filters mt-2">
                { this.getFilterForm(props, state) }
                { this.getFilterActions(props, state) }
              </div> : null
            }
          </div>
        </div>
      </div>
    )
    
  }

  getGridBlock(props: DashWithdrawalsProps, state: DashWithdrawalsState) {
    if (state.gridRequestState === REQUEST_IN_PROGRESS) {
      return (
        <div class="row mt-3">
          <div class="col-12">
            <div class="spinner-border text-primary mt-3" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      );
    } else if (state.gridRequestState === REQUEST_SUCCESS) {

      // in the grid, we only show 4-5 columns
      // should the user wish to see the full breakdown
      // a modal will be used instead

      return (
        <div class="dash-remittance__grid-container">
          <div class="row mt-3">
            <div class="col-12">
              <div class="data-grid dash-withdrawal__grid">
                <div class="data-grid__row header">
                  <div class="data-grid__col col-short">
                    Actions
                  </div>
                  <div class="data-grid__col">
                    Withdrawal ID
                  </div>
                  {
                    state.currentUser.role === ROLE_MANAGEMENT || state.currentUser.role === ROLE_ADMIN_SALES? 
                    <div class="data-grid__col">
                      Supervisor
                    </div>
                    : null
                  }
                  <div class="data-grid__col">
                    Status
                  </div>
                  <div class="data-grid__col">
                    Stage
                  </div>
                  <div class="data-grid__col">
                    Calculated Value
                  </div>
                  <div class="data-grid__col">
                    Date Created
                  </div>
                  <div class="data-grid__col">
                    Date Withdrawn
                  </div>
                </div>

                {
                  state.gridData.map((row, index) => {
                    return (
                      <div class="data-grid__row" id={row.withdrawalId}>
                        <div class="data-grid__col col-short">
                          <button class="btn btn-outline-primary w-100"
                            onClick={() => {this.onViewDetails(row)}}>
                            View
                          </button>
                        </div>
                        <div class="data-grid__col">
                          {row.withdrawalId}
                        </div>
                        {
                          state.currentUser.role === ROLE_MANAGEMENT || state.currentUser.role === ROLE_ADMIN_SALES ? 
                          <div class="data-grid__col">
                            {row.user.firstName} {row.user.lastName}
                          </div>
                          : null
                        }
                        
                        <div class="data-grid__col text-capitalize">
                          {row.status}
                        </div>
                        <div class="data-grid__col">
                          {this.getWithdrawalStage(row.stage)}
                        </div>
                        <div class="data-grid__col">
                        ₱ {toFixed(row.totalAmount)}
                        </div>
                        <div class="data-grid__col">
                          {moment(row.dateCreated).format("MMMM DD, YYYY")}
                        </div>
                        <div class="data-grid__col">
                          {row.dateOfWithdrawal ? moment(row.dateOfWithdrawal).format("MMMM DD, YYYY") : 'Not Available'}
                        </div>
                      </div>
                    );
                  })
                }

              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div class="row">
          <div class="col-12">
            Failed to load grid data.
          </div>
        </div>
      );
    }
  }

  getPaginationBlock(props: DashWithdrawalsProps, state: DashWithdrawalsState) {
    return (
      <div class="row">
        <div class="col-12">
          {
            state.pages ?
            <div class="dash-remittance__pagination mb-3">
              <button class="btn dash-remittance__pagination__page arrow"
                  disabled={ state.currentPage === 1 } 
                  onClick={() => this.onPageClicked(state.currentPage - 1)}>
                <i class="fas fa-chevron-left"></i>
              </button>
              {
                state.currentPage > 5 ?
                <button className={ cx('btn dash-remittance__pagination__page', 
                  { 'active': state.pages[0] === state.currentPage }) }
                  onClick={() => this.onPageClicked(state.pages[0])}>
                  { state.pages[0] }
                </button> : null
              }
              {
                state.currentPage > 5 ?
                <span class="mx-2">
                  ...
                </span> : null
              }
              {
                state.pages ? state.pages.map(page => {
                  if (page > state.currentPage - 5 && page < state.currentPage + 5) {
                    return (
                      <button className={ cx('btn dash-remittance__pagination__page', 
                        { 'active': page === state.currentPage }) }
                        onClick={() => this.onPageClicked(page)}>
                        { page }
                      </button>
                    );
                  }
                }) : null
              }
              {
                state.currentPage < (state.pages.length) - 5 ?
                <span class="mx-2">
                  ...
                </span> : null
              }
              {
                state.currentPage < (state.pages.length) - 5 ?
                <button className={ cx('btn dash-remittance__pagination__page', 
                  { 'active': state.pages[state.pages.length - 1] === state.currentPage }) }
                  onClick={() => this.onPageClicked(state.pages[state.pages.length - 1])}>
                  { state.pages[state.pages.length - 1] }
                </button> : null
              }
              <button class="btn dash-remittance__pagination__page arrow"
                  disabled={ state.currentPage === state.pages[state.pages.length - 1] } 
                  onClick={() => this.onPageClicked(state.currentPage + 1)}>
                <i class="fas fa-chevron-right"></i>
              </button>
            </div> : null
          }
        </div>
      </div>
    );
  }

  render(props: DashWithdrawalsProps, state: DashWithdrawalsState) {
    return (
      <div class="dash-withdrawal anglo__dashboard">

        { this.getPortalModal(state) }

        <div class="container-fluid">
          <div class="row">
            <div class="col-8 col-lg-4">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/withdrawal">Withdrawals</a></li>
                </ol>
              </nav>
            </div>
          </div>

          { this.getInsightBlock(props, state) }

          { this.getActionsBlock(props, state) }

          { this.getFilterBlock(props, state) }
          { this.getGridBlock(props, state) }

          { this.getPaginationBlock(props, state) }
        </div>

      </div>
    );
  }

}