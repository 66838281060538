import { Epic, ofType } from "redux-observable";

import { switchMap, mergeMap, catchError, withLatestFrom } from "rxjs/operators";
import { from, of } from "rxjs";
import Axios from "axios";

//^^ Non-app imports

import { appState$ } from "../../store";
import { API_ENDPOINT } from '../../../../constants/api';
import { REQUEST_SUCCESS, REQUEST_FAILED } from "../../../../types/request-state.model";

//^^ App-wide imports

import * as CashbondLoanActions from './cashbond-loan.actions'
import { CashbondLoanAction } from './cashbond-loan.actions';
import { BondPayment, BondPaymentUpdate, CashBond } from "../../../../types/cashbond.model";
import { Loan, LoanPayment, LoanPaymentUpdate, LoanStatusUpdate } from "../../../../types/loan.model";
import { showToast, TOAST_TYPE_ERROR } from "../../../utils/toast.service";

//^^ Literally others locally

let cashbondLoanEffects: Epic[] = [];

const getCashBondGridData: Epic = getGridData$ => getGridData$.pipe(
  ofType<CashbondLoanAction>(CashbondLoanActions.GET_GRID_CASHBOND),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    let filters: string = '?';
    const currentFilters = action.payload.filters ? action.payload.filters : [];
    let index = 0;
    currentFilters.forEach((value, key) => {
      if (index === 0) {
        filters = filters.concat(`${key}=${value}`);
      } else {
        filters = filters.concat(`&${key}=${value}`);
      }
      index++;
    });

    const promise = Axios.get<CashBond[]>(
      `${API_ENDPOINT}/cashbond${filters}`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const gridData = JSON.parse(JSON.stringify(response.data['results']));

        const action: CashbondLoanAction = {
          type: CashbondLoanActions.GET_GRID_CASHBOND_SUCCESS,
          payload: {
            cashbonds: gridData.data,
            total: gridData['totalCount'] && gridData['totalCount'][0] ? gridData['totalCount'][0].count : 0,
            gridRequestState: REQUEST_SUCCESS
          }
        };

        
        return of(action);
      }), 
      catchError((errResponse) => {
        const action: CashbondLoanAction = {
          type: CashbondLoanActions.GET_GRID_CASHBOND_FAIL,
          payload: {
            gridRequestState: REQUEST_FAILED
          }
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }

        if (errResponse.response && errResponse.response.status == 400) {
          showToast({
            title: 'Failed to get cashbond grid data',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to get cashbond grid data',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        }
        
        return of(action);
      })
    );
  })
);

const createCashBond: Epic = createCashBond$ => createCashBond$.pipe(
  ofType<CashbondLoanAction>(CashbondLoanActions.CREATE_CASHBOND),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const cashbond: CashBond = action.payload.cashbondToCreate;
    
    const promise = Axios.post<CashBond>(
      `${API_ENDPOINT}/cashbond`,
      cashbond, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: CashbondLoanAction = {
          type: CashbondLoanActions.CREATE_CASHBOND_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Cashbond submission',
          message: 'You have successfully submitted a Cashbond',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit Cashbond',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to submit Cashbond',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        } 

        const action: CashbondLoanAction = {
          type: CashbondLoanActions.CREATE_CASHBOND_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const createCashbondPayment: Epic = createCashBond$ => createCashBond$.pipe(
  ofType<CashbondLoanAction>(CashbondLoanActions.CREATE_CASHBOND_PAYMENT),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const bondPayment: BondPayment = action.payload.cashbondPaymentToCreate;
    
    const promise = Axios.post<BondPayment>(
      `${API_ENDPOINT}/bond-payment`,
      bondPayment, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: CashbondLoanAction = {
          type: CashbondLoanActions.CREATE_LOAN_PAYMENT_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Cashbond Payment submission',
          message: 'You have successfully submitted a Cashbond Payment',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit Cashbond Payment',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to submit Cashbond Payment',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        } 

        const action: CashbondLoanAction = {
          type: CashbondLoanActions.CREATE_LOAN_PAYMENT_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const getLoanGridData: Epic = getLoanGridData$ => getLoanGridData$.pipe(
  ofType<CashbondLoanAction>(CashbondLoanActions.GET_GRID_LOAN),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    let filters: string = '?';
    const currentFilters = action.payload.filters ? action.payload.filters : [];
    let index = 0;
    currentFilters.forEach((value, key) => {
      if (index === 0) {
        filters = filters.concat(`${key}=${value}`);
      } else {
        filters = filters.concat(`&${key}=${value}`);
      }
      index++;
    });

    const promise = Axios.get<Loan>(
      `${API_ENDPOINT}/loan${filters}`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const gridData = JSON.parse(JSON.stringify(response.data['results']));

        const action: CashbondLoanAction = {
          type: CashbondLoanActions.GET_GRID_LOAN_SUCCESS,
          payload: {
            loans: gridData.data,
            total: gridData['totalCount'] && gridData['totalCount'][0] ? gridData['totalCount'][0].count : 0,
            gridRequestState: REQUEST_SUCCESS
          }
        };

        
        return of(action);
      }), 
      catchError((errResponse) => {
        console.log(errResponse);
        const action: CashbondLoanAction = {
          type: CashbondLoanActions.GET_GRID_LOAN_FAIL,
          payload: {
            gridRequestState: REQUEST_FAILED
          }
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }

        if (errResponse.response && errResponse.response.status == 400) {
          showToast({
            title: 'Failed to get loan grid data',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to get loan grid data',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        }
        
        return of(action);
      })
    );
  })
);

const createLoan: Epic = createCashBond$ => createCashBond$.pipe(
  ofType<CashbondLoanAction>(CashbondLoanActions.CREATE_LOAN),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const loan: Loan = action.payload?.loanToCreate;
    
    const promise = Axios.post<Loan>(
      `${API_ENDPOINT}/loan`,
      loan, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: CashbondLoanAction = {
          type: CashbondLoanActions.CREATE_LOAN_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Loan submission',
          message: 'You have successfully submitted a Loan',
          duration: 3000
        });
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit Loan',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to submit Loan',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        } 

        const action: CashbondLoanAction = {
          type: CashbondLoanActions.CREATE_LOAN_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const createLoanPayment: Epic = createCashBond$ => createCashBond$.pipe(
  ofType<CashbondLoanAction>(CashbondLoanActions.CREATE_LOAN_PAYMENT),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const loanPayment: LoanPayment = action.payload?.loanPaymentToCreate;
    
    const promise = Axios.post<LoanPayment>(
      `${API_ENDPOINT}/loan-payment`,
      loanPayment, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: CashbondLoanAction = {
          type: CashbondLoanActions.CREATE_LOAN_PAYMENT_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Loan Payment submission',
          message: 'You have successfully submitted a Loan Payment',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit Loan Payment',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to submit Loan Payment',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        } 

        const action: CashbondLoanAction = {
          type: CashbondLoanActions.CREATE_LOAN_PAYMENT_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const updateLoanStatus: Epic = createCashBond$ => createCashBond$.pipe(
  ofType<CashbondLoanAction>(CashbondLoanActions.UPDATE_LOAN_STATUS),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const loanStatus: LoanStatusUpdate = action.payload.loanStatusToUpdate;
    
    const promise = Axios.put<LoanStatusUpdate>(
      `${API_ENDPOINT}/loan`,
      loanStatus, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: CashbondLoanAction = {
          type: CashbondLoanActions.UPDATE_LOAN_STATUS_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Loan Status Update',
          message: 'You have successfully submitted a Loan Status Update',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit Loan Status Update',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to submit Loan Status Update',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        } 

        const action: CashbondLoanAction = {
          type: CashbondLoanActions.UPDATE_LOAN_STATUS_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const approveLoanPaymentStatus: Epic = approveLoanPaymentStatus$ => approveLoanPaymentStatus$.pipe(
  ofType<CashbondLoanAction>(CashbondLoanActions.APPROVE_LOAN_PAYMENT),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const loanPaymentUpdate: LoanPaymentUpdate = action.payload?.loanPaymentToUpdate;
    
    const promise = Axios.put<LoanStatusUpdate>(
      `${API_ENDPOINT}/loan-payment/approve`,
      loanPaymentUpdate, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: CashbondLoanAction = {
          type: CashbondLoanActions.APPROVE_LOAN_PAYMENT_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Loan Status Update',
          message: 'You have successfully submitted a Loan Status Update',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit Loan Status Update',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to submit Loan Status Update',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        } 

        const action: CashbondLoanAction = {
          type: CashbondLoanActions.APPROVE_LOAN_PAYMENT_FAILURE,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const approveCashbondPaymentStatus: Epic = approveLoanPaymentStatus$ => approveLoanPaymentStatus$.pipe(
  ofType<CashbondLoanAction>(CashbondLoanActions.APPROVE_CASHBOND_PAYMENT),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const bondPaymentUpdate: BondPaymentUpdate = action.payload?.cashbondPaymentToUpdate;
    
    const promise = Axios.put<LoanStatusUpdate>(
      `${API_ENDPOINT}/bond-payment/approve`,
      bondPaymentUpdate, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: CashbondLoanAction = {
          type: CashbondLoanActions.APPROVE_CASHBOND_PAYMENT_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Cashbond Status Update',
          message: 'You have successfully submitted a Cashbond Status Update',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit Cashbond Status Update',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to submit Cashbond Status Update',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        } 

        const action: CashbondLoanAction = {
          type: CashbondLoanActions.APPROVE_CASHBOND_PAYMENT_FAILURE,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const getLoanPaymentsData: Epic = getLoanPaymentsData$ => getLoanPaymentsData$.pipe(
  ofType<CashbondLoanAction>(CashbondLoanActions.GET_LOAN_PAYMENTS),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    let filters: string = '?';
    const currentFilters = action.payload.filters ? action.payload.filters : [];
    let index = 0;
    currentFilters.forEach((value, key) => {
      if (index === 0) {
        filters = filters.concat(`${key}=${value}`);
      } else {
        filters = filters.concat(`&${key}=${value}`);
      }
      index++;
    });

    const promise = Axios.get<LoanPayment>(
      `${API_ENDPOINT}/loan-payment${filters}`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const gridData = JSON.parse(JSON.stringify(response.data['results']));

        const action: CashbondLoanAction = {
          type: CashbondLoanActions.GET_GRID_LOAN_SUCCESS,
          payload: {
            loanPayments: gridData.data,
            total: gridData['totalCount'] && gridData['totalCount'][0] ? gridData['totalCount'][0].count : 0,
            paymentsRequestState: REQUEST_SUCCESS
          }
        };

        
        return of(action);
      }), 
      catchError((errResponse) => {
        console.log(errResponse);
        const action: CashbondLoanAction = {
          type: CashbondLoanActions.GET_GRID_LOAN_FAIL,
          payload: {
            paymentsRequestState: REQUEST_FAILED
          }
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }

        if (errResponse.response && errResponse.response.status == 400) {
          showToast({
            title: 'Failed to get loan payments data',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to get loan payments data',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        }
        
        return of(action);
      })
    );
  })
);

const getBondPaymentsData: Epic = getBondPaymentsData$ => getBondPaymentsData$.pipe(
  ofType<CashbondLoanAction>(CashbondLoanActions.GET_BOND_PAYMENTS),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    let filters: string = '?';
    const currentFilters = action.payload.filters ? action.payload.filters : [];
    let index = 0;
    currentFilters.forEach((value, key) => {
      if (index === 0) {
        filters = filters.concat(`${key}=${value}`);
      } else {
        filters = filters.concat(`&${key}=${value}`);
      }
      index++;
    });

    const promise = Axios.get<LoanPayment>(
      `${API_ENDPOINT}/bond-payment${filters}`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const gridData = JSON.parse(JSON.stringify(response.data['results']));

        const action: CashbondLoanAction = {
          type: CashbondLoanActions.GET_BOND_PAYMENTS_SUCCESS,
          payload: {
            bondPayment: gridData.data,
            total: gridData['totalCount'] && gridData['totalCount'][0] ? gridData['totalCount'][0].count : 0,
            paymentsRequestState: REQUEST_SUCCESS
          }
        };

        
        return of(action);
      }), 
      catchError((errResponse) => {
        console.log(errResponse);
        const action: CashbondLoanAction = {
          type: CashbondLoanActions.GET_BOND_PAYMENTS_FAIL,
          payload: {
            paymentsRequestState: REQUEST_FAILED
          }
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }

        if (errResponse.response && errResponse.response.status == 400) {
          showToast({
            title: 'Failed to get loan payments data',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to get loan payments data',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        }
        
        return of(action);
      })
    );
  })
);

const getServersideLoanReport: Epic = getReportGridData$ => getReportGridData$.pipe(
  ofType<CashbondLoanAction>(CashbondLoanActions.GENERATE_SERVERSIDE_LOAN_REPORT),
  switchMap((action: CashbondLoanAction) => {

    let filters = '?';

    if (action.payload && action.payload.filters) {
      let index = 0;
      action.payload.filters.forEach((v, k) => {

        if (index > 0) {
          filters = filters.concat('&');
        }

        filters = filters.concat(`${k}=${v}`);

        index++;
      });
    }

    const promise = Axios.get<any>(
      `${API_ENDPOINT}/loan/report${filters}`,
      {
        withCredentials: true,
        responseType: "blob",
        headers: {
          'Accept': 'application/pdf'
        }
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {

        const action: CashbondLoanAction = {
          type: CashbondLoanActions.GENERATE_SERVERSIDE_LOAN_REPORT_SUCCESS
        }
        if (response) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          window.open(URL.createObjectURL(blob));
        }

        return of(action);
      }),
      catchError((errResponse) => {
        const action: CashbondLoanAction = {
          type: CashbondLoanActions.GENERATE_SERVERSIDE_LOAN_REPORT_FAIL,
        };

        showToast({
          title: 'Failed to get grid data',
          message: 'Please contact the system admin.',
          duration: 3000
        });
        
        return of(action);
      })
    );
  })
);

const getServersideCashbondReport: Epic = getReportGridData$ => getReportGridData$.pipe(
  ofType<CashbondLoanAction>(CashbondLoanActions.GENERATE_SERVERSIDE_CASHBOND_REPORT),
  switchMap((action: CashbondLoanAction) => {

    let filters = '?';

    if (action.payload && action.payload.filters) {
      let index = 0;
      action.payload.filters.forEach((v, k) => {

        if (index > 0) {
          filters = filters.concat('&');
        }

        filters = filters.concat(`${k}=${v}`);

        index++;
      });
    }

    const promise = Axios.get<any>(
      `${API_ENDPOINT}/cashbond/report${filters}`,
      {
        withCredentials: true,
        responseType: "blob",
        headers: {
          'Accept': 'application/pdf'
        }
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {

        const action: CashbondLoanAction = {
          type: CashbondLoanActions.GENERATE_SERVERSIDE_CASHBOND_REPORT_SUCCESS
        }
        if (response) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          window.open(URL.createObjectURL(blob));
        }

        return of(action);
      }),
      catchError((errResponse) => {
        const action: CashbondLoanAction = {
          type: CashbondLoanActions.GENERATE_SERVERSIDE_CASHBOND_REPORT_FAIL,
        };

        showToast({
          title: 'Failed to get grid data',
          message: 'Please contact the system admin.',
          duration: 3000
        });
        
        return of(action);
      })
    );
  })
);

const getServersideLoanPaymentReport: Epic = getReportGridData$ => getReportGridData$.pipe(
  ofType<CashbondLoanAction>(CashbondLoanActions.GENERATE_SERVERSIDE_LOAN_PAYMENT_REPORT),
  switchMap((action: CashbondLoanAction) => {

    let filters = '?';

    if (action.payload && action.payload.filters) {
      let index = 0;
      action.payload.filters.forEach((v, k) => {

        if (index > 0) {
          filters = filters.concat('&');
        }

        filters = filters.concat(`${k}=${v}`);

        index++;
      });
    }

    const promise = Axios.get<any>(
      `${API_ENDPOINT}/loan-payment/report${filters}`,
      {
        withCredentials: true,
        responseType: "blob",
        headers: {
          'Accept': 'application/pdf'
        }
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {

        const action: CashbondLoanAction = {
          type: CashbondLoanActions.GENERATE_SERVERSIDE_LOAN_PAYMENT_REPORT_SUCCESS
        }
        if (response) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          window.open(URL.createObjectURL(blob));
        }

        return of(action);
      }),
      catchError((errResponse) => {
        const action: CashbondLoanAction = {
          type: CashbondLoanActions.GENERATE_SERVERSIDE_LOAN_PAYMENT_REPORT_FAIL,
        };

        showToast({
          title: 'Failed to get grid data',
          message: 'Please contact the system admin.',
          duration: 3000
        });
        
        return of(action);
      })
    );
  })
);

const getServersideCashbondPaymentReport: Epic = getReportGridData$ => getReportGridData$.pipe(
  ofType<CashbondLoanAction>(CashbondLoanActions.GENERATE_SERVERSIDE_BOND_PAYMENT_REPORT),
  switchMap((action: CashbondLoanAction) => {

    let filters = '?';

    if (action.payload && action.payload.filters) {
      let index = 0;
      action.payload.filters.forEach((v, k) => {

        if (index > 0) {
          filters = filters.concat('&');
        }

        filters = filters.concat(`${k}=${v}`);

        index++;
      });
    }

    const promise = Axios.get<any>(
      `${API_ENDPOINT}/bond-payment/report${filters}`,
      {
        withCredentials: true,
        responseType: "blob",
        headers: {
          'Accept': 'application/pdf'
        }
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {

        const action: CashbondLoanAction = {
          type: CashbondLoanActions.GENERATE_SERVERSIDE_BOND_PAYMENT_REPORT_SUCCESS
        }
        if (response) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          window.open(URL.createObjectURL(blob));
        }

        return of(action);
      }),
      catchError((errResponse) => {
        const action: CashbondLoanAction = {
          type: CashbondLoanActions.GENERATE_SERVERSIDE_BOND_PAYMENT_REPORT_FAIL,
        };

        showToast({
          title: 'Failed to get grid data',
          message: 'Please contact the system admin.',
          duration: 3000
        });
        
        return of(action);
      })
    );
  })
);

const rejectLoanPaymentStatus: Epic = rejectLoanPaymentStatus$ => rejectLoanPaymentStatus$.pipe(
  ofType<CashbondLoanAction>(CashbondLoanActions.REJECT_LOAN_PAYMENT),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const loanPaymentUpdate: LoanPaymentUpdate = action.payload?.loanPaymentToUpdate;
    
    const promise = Axios.put<LoanStatusUpdate>(
      `${API_ENDPOINT}/loan-payment/reject`,
      loanPaymentUpdate, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: CashbondLoanAction = {
          type: CashbondLoanActions.REJECT_LOAN_PAYMENT_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Loan Status Update',
          message: 'You have successfully submitted a Loan Status Update',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit Loan Status Update',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to submit Loan Status Update',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        } 

        const action: CashbondLoanAction = {
          type: CashbondLoanActions.REJECT_LOAN_PAYMENT_FAILURE,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const rejectCashbondPaymentStatus: Epic = rejectCashbondPaymentStatus$ => rejectCashbondPaymentStatus$.pipe(
  ofType<CashbondLoanAction>(CashbondLoanActions.REJECT_BOND_PAYMENT),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    const bondPaymentUpdate: BondPaymentUpdate = action.payload?.cashbondPaymentToUpdate;
    
    const promise = Axios.put<LoanStatusUpdate>(
      `${API_ENDPOINT}/bond-payment/reject`,
      bondPaymentUpdate, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: CashbondLoanAction = {
          type: CashbondLoanActions.REJECT_BOND_PAYMENT_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Cashbond Status Update',
          message: 'You have successfully submitted a Cashbond Status Update',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit Cashbond Status Update',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000,
            type: TOAST_TYPE_ERROR
          })
        } else {
          showToast({
            title: 'Failed to submit Cashbond Status Update',
            message: message,
            duration: 3000,
            type: TOAST_TYPE_ERROR
          });
        } 

        const action: CashbondLoanAction = {
          type: CashbondLoanActions.REJECT_BOND_PAYMENT_FAILURE,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

cashbondLoanEffects.push(getCashBondGridData);
cashbondLoanEffects.push(createCashBond);
cashbondLoanEffects.push(createCashbondPayment);
cashbondLoanEffects.push(getServersideCashbondReport);
cashbondLoanEffects.push(getBondPaymentsData);

cashbondLoanEffects.push(getLoanGridData);
cashbondLoanEffects.push(createLoan);
cashbondLoanEffects.push(createLoanPayment);
cashbondLoanEffects.push(updateLoanStatus);
cashbondLoanEffects.push(getLoanPaymentsData);
cashbondLoanEffects.push(getServersideLoanReport);

cashbondLoanEffects.push(approveLoanPaymentStatus);
cashbondLoanEffects.push(approveCashbondPaymentStatus);
cashbondLoanEffects.push(rejectLoanPaymentStatus);
cashbondLoanEffects.push(rejectCashbondPaymentStatus);

cashbondLoanEffects.push(getServersideLoanPaymentReport);
cashbondLoanEffects.push(getServersideCashbondPaymentReport);

export default cashbondLoanEffects;