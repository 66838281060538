import { Subject } from "rxjs";
import { Form, FieldList, Field } from "../../types/form.model";

/** 
 * This function is a drop-in replacement for preact's linkState.
 * Please only use when a form requires heavy lifting and validation
*/
export default function linkform(
  subject: Subject<any>, 
  form: Form, 
  fieldName: string,
  toggleable?: boolean
  ) {
  return (event) => {
    let clone = Form.cloneFrom(form);

    let target = event.target;
    let value = target.value

    if (toggleable) {
      if (target.nodeName && target.type.match(/^che|rad/)) {
        value = target.checked
      }
    }

    clone.fields[fieldName].value = value;
    clone.fields[fieldName].isPristine = false;

    subject.next(clone);
  }
}

/**
 * This is an extended version of the linkState that does not rely
 * on an event or target. It simply receives a new value then updates
 * the subject form. 
 * 
 * @param subject 
 * @param form 
 * @param fieldName 
 * @param value 
 */
// */
export function updateFormFieldValue(
  subject: Subject<any>, 
  form: Form, 
  fieldName: string,
  value: any
) {
  let clone = Form.cloneFrom(form);

  clone.fields[fieldName].value = value;
  clone.fields[fieldName].isPristine = false;

  subject.next(clone);
}