import { h, Component } from 'preact';
import cx from 'classnames';
import Portal from 'preact-portal';
import { Subscription, Subject } from 'rxjs';

import getStore, { appState$ } from '../../store';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { User } from '../../../../types/user.model';
import { Vehicle, VehicleFile } from '../../../../types/vehicle.model';
import { GET_VEHICLES, VehicleAction, GET_VEHICLE_FILES } from '../../reducers/vehicle/vehicle.actions';
import { VehicleCard } from './vehicle-card.component';
import { CreateVehicleModal } from './modals/create-vehicle.modal';
import { VehicleFileCard } from './vehicle-file-card.component';

import { validateForm, cxValidateField } from '../../../../types/validator.model';
import { FieldList, Field, Form } from '../../../../types/form.model';
import linkform, { updateFormFieldValue } from '../../../utils/linkform';
import { VEHICLE_STATUSES } from '../../../../constants/vehicle-files';
import { REQUEST_IN_PROGRESS } from '../../../../types/request-state.model';
import { AutocompleteSearch } from '../common/autocomplete-search.component';
import { PAGE_SIZE } from '../remittance/dash-remittance.component';
import { GenerateExpenseReportModal } from './modals/generate-expense-report.modal';

export interface DashVehiclesProps {
}

export interface DashVehiclesState {
  vehicles?: Vehicle[];
  vehicleFiles?: VehicleFile[];

  // new prototype Modal Control Approach.
  // This will prevent stacking modals though.
  // if set to -1, close all
  modalState?: number;
  
  currentUser?: User;

  isViewingFiles?: boolean;

  filters?: Form;
  isFilterVisible?: boolean;

  vehiclesPages?: number[];
  vehiclesCurrentPage?: number;

  filesPages?: number[];
  filesCurrentPage?: number;
}

export class DashVehicles extends Component<any, DashVehiclesState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  private MODAL_CREATE_VEHICLE: number = 0;
  private MODAL_GENERATE_EXPENSE_REPORT: number = 1;

  constructor(props) {
    super(props);

    this.setState({
      filesCurrentPage: 1,
      vehiclesCurrentPage: 1,
      modalState: -1
    });

    this.createForm();
  }

  componentDidMount() {

    const user$ = appState$.pipe(
      map(appState => appState.user)
    );

    this.subs.push(user$.subscribe((user) => {
        if (user.currentUser) {
          this.setState({
            currentUser: user.currentUser
          });
        }
      })
    );

    const vehicles$ = appState$.pipe(
      map(appState => appState.vehicles)
    );
    
    this.subs.push(vehicles$.pipe(
      map(vehicles => vehicles.vehicles),
      distinctUntilChanged()
    ).subscribe((vehicles) => {

        this.setState({
          vehicles: vehicles
        });

      })
    );

    this.subs.push(vehicles$.pipe(
      map(vehicles => vehicles.totalVehicles)
    ).subscribe((total) => {
        if (total) {
          
          let pages: number[] = [];
          let limit = Math.ceil(total / PAGE_SIZE);

          for(let i = 0; i < limit; i++) {
            pages.push(i + 1);
          }

          this.setState({
            vehiclesPages: pages
          });
        }
      })
    );

    this.subs.push(vehicles$.pipe(
      map(vehicles => vehicles.vehicleFiles),
      distinctUntilChanged()
    ).subscribe((vehicleFiles) => {

        this.setState({
          vehicleFiles: vehicleFiles
        });

      })
    );

    this.subs.push(vehicles$.pipe(
      map(vehicles => vehicles.totalVehicleFiles)
    ).subscribe((total) => {
        if (total) {
          
          let pages: number[] = [];
          let limit = Math.ceil(total / PAGE_SIZE);

          for(let i = 0; i < limit; i++) {
            pages.push(i + 1);
          }

          this.setState({
            filesPages: pages
          });
        }
      })
    );

    this.initialStoreDispatches();
  }

  createForm() {
    let fields: FieldList = {
      'status': new Field([], null, ''),
      'plateNumber': new Field([] , null, ''),
      'lodgingLocation': new Field([] , null, ''),
      'modelMake': new Field([] , null, ''),
      'username': new Field([] , null, ''),
      'vehicleId': new Field([] , null, ''),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      filters: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['filters'] = form;
      this.setState(state);
    });
  }

  initialStoreDispatches() {

    const store = getStore();
    store.dispatch({
      type: GET_VEHICLES,
      payload: {
        type: 'edit',
        filters: {
          page: 1
        }
      }
    } as VehicleAction);

    store.dispatch({
      type: GET_VEHICLE_FILES,
      payload: {
        type: 'edit',
        filters: {
          page: 1
        }
      }
    } as VehicleAction);

  }

  resetFilters = () => {
    
    const fieldList = this.state.filters.fields;

    for (let k in fieldList) {
      fieldList[k].value = null;
    }

    const form = this.state.filters;
    form.fields = fieldList;

    form['page'] = 1;

    this.setState({
      filters: form
    });
  }

  applyFilters = () => {
    // let filters: Map<string, any> = new Map<string, any>();
    let filters = {};
    const fieldList = this.state.filters.fields;

    for (let k in fieldList) {
      if (fieldList[k].value) {
        // filters.set(k, fieldList[k].value);
        filters[k] = fieldList[k].value;
      }
    }

    // when new filters are applied, always set back to first page
    filters['page'] = 1

    const store = getStore();

    if (!this.state.isViewingFiles) {
      store.dispatch({
        type: GET_VEHICLES,
        payload: {
          filters: filters,
          gridRequestState: REQUEST_IN_PROGRESS
        }
      });
    } else {
      store.dispatch({
        type: GET_VEHICLE_FILES,
        payload: {
          filters: filters,
          gridRequestState: REQUEST_IN_PROGRESS
        }
      });
    }
    
  }

  onFilesPageClicked = (page: number) => {
    let filters = {};
    const fieldList = this.state.filters.fields;

    for (let k in fieldList) {
      if (fieldList[k].value) {
        // filters.set(k, fieldList[k].value);
        filters[k] = fieldList[k].value;
      }
    }

    filters['page'] = page;

    const store = getStore();
    store.dispatch({
      type: GET_VEHICLE_FILES,
      payload: {
        filters: filters
      }
    });

    this.setState({
      filesCurrentPage: page
    })
  }

  onVehiclesPageClicked = (page: number) => {
    let filters = {};
    const fieldList = this.state.filters.fields;

    for (let k in fieldList) {
      if (fieldList[k].value) {
        // filters.set(k, fieldList[k].value);
        filters[k] = fieldList[k].value;
      }
    }

    filters['page'] = page;

    const store = getStore();
    store.dispatch({
      type: GET_VEHICLES,
      payload: {
        filters: filters
      }
    });

    this.setState({
      vehiclesCurrentPage: page
    })
  }

  onSupervisorSearchChange = (supervisor: User) => {
    updateFormFieldValue(
      this.form$,
      this.state.filters,
      'username',
      supervisor.username
    )
  }

  getVehicleData(state: DashVehiclesState) {
    if (!state.isViewingFiles) {
      if (state.vehicles) {
        return (
          <div class="dash-updates__card-container row">
            {
              state.vehicles.map(member => {
                  // if (state.currentUser.role === 'role_supervisor' && member.status !== 'decommissioned') {
                  //   return (
                  //     <div class="col-3">
                  //       <VehicleCard currentUser={state.currentUser}
                  //         vehicle={member} />
                  //     </div>
                  //   );
                  // } else {
                  //   return (
                  //     <div class="col-3">
                  //       <VehicleCard currentUser={state.currentUser}
                  //         vehicle={member} />
                  //     </div>
                  //   );
                  // }
                  return (
                    <div class="col-12 col-md-6 col-lg-3 mb-3">
                      <VehicleCard currentUser={state.currentUser}
                        vehicle={member} />
                    </div>
                  );
                }
              )
            }

            <div class="col-12 mt-4">
              {
                state.vehiclesPages ?
                <div class="dash-remittance__pagination mb-3">
                  <button class="btn dash-remittance__pagination__page arrow"
                      disabled={ state.vehiclesCurrentPage === 1 } 
                      onClick={() => this.onVehiclesPageClicked(state.vehiclesCurrentPage - 1)}>
                    <i class="fas fa-chevron-left"></i>
                  </button>
                  {
                    state.vehiclesCurrentPage > 5 ?
                    <button className={ cx('btn dash-remittance__pagination__page', 
                      { 'active': state.vehiclesPages[0] === state.vehiclesCurrentPage }) }
                      onClick={() => this.onVehiclesPageClicked(state.vehiclesPages[0])}>
                      { state.vehiclesPages[0] }
                    </button> : null
                  }
                  {
                    state.vehiclesCurrentPage > 5 ?
                    <span class="mx-2">
                      ...
                    </span> : null
                  }
                  {
                    state.vehiclesPages ? state.vehiclesPages.map(page => {
                      if (page > state.vehiclesCurrentPage - 5 && page < state.vehiclesCurrentPage + 5) {
                        return (
                          <button className={ cx('btn dash-remittance__pagination__page', 
                            { 'active': page === state.vehiclesCurrentPage }) }
                            onClick={() => this.onVehiclesPageClicked(page)}>
                            { page }
                          </button>
                        );
                      }
                    }) : null
                  }
                  {
                    state.vehiclesCurrentPage < (state.vehiclesPages.length) - 5 ?
                    <span class="mx-2">
                      ...
                    </span> : null
                  }
                  {
                    state.vehiclesCurrentPage < (state.vehiclesPages.length) - 5 ?
                    <button className={ cx('btn dash-remittance__pagination__page', 
                      { 'active': state.vehiclesPages[state.vehiclesPages.length - 1] === state.vehiclesCurrentPage }) }
                      onClick={() => this.onVehiclesPageClicked(state.vehiclesPages[state.vehiclesPages.length - 1])}>
                      { state.vehiclesPages[state.vehiclesPages.length - 1] }
                    </button> : null
                  }
                  <button class="btn dash-remittance__pagination__page arrow"
                      disabled={ state.vehiclesCurrentPage === state.vehiclesPages[state.vehiclesPages.length - 1] } 
                      onClick={() => this.onVehiclesPageClicked(state.vehiclesCurrentPage + 1)}>
                    <i class="fas fa-chevron-right"></i>
                  </button>
                </div> : null
              }
            </div>
          </div>
        );
      }
    }
  }

  getVehicleFilesData(state: DashVehiclesState) {

    if (state.isViewingFiles) {
      if (state.vehicleFiles) {
        return (
          <div class="dash-updates__card-container row">
            {
              state.vehicleFiles.map(member => 
                <div class="col-12 col-md-6 col-lg-3 mb-3">
                  <VehicleFileCard 
                    currentUser={state.currentUser}
                    vehicleFile={member} />
                </div>
              )
            }

            <div class="col-12 mt-4">
              {
                state.filesPages ?
                <div class="dash-remittance__pagination mb-3">
                  <button class="btn dash-remittance__pagination__page arrow"
                      disabled={ state.filesCurrentPage === 1 } 
                      onClick={() => this.onFilesPageClicked(state.filesCurrentPage - 1)}>
                    <i class="fas fa-chevron-left"></i>
                  </button>
                  {
                    state.filesCurrentPage > 5 ?
                    <button className={ cx('btn dash-remittance__pagination__page', 
                      { 'active': state.filesPages[0] === state.filesCurrentPage }) }
                      onClick={() => this.onFilesPageClicked(state.filesPages[0])}>
                      { state.filesPages[0] }
                    </button> : null
                  }
                  {
                    state.filesCurrentPage > 5 ?
                    <span class="mx-2">
                      ...
                    </span> : null
                  }
                  {
                    state.filesPages ? state.filesPages.map(page => {
                      if (page > state.filesCurrentPage - 5 && page < state.filesCurrentPage + 5) {
                        return (
                          <button className={ cx('btn dash-remittance__pagination__page', 
                            { 'active': page === state.filesCurrentPage }) }
                            onClick={() => this.onFilesPageClicked(page)}>
                            { page }
                          </button>
                        );
                      }
                    }) : null
                  }
                  {
                    state.filesCurrentPage < (state.filesPages.length) - 5 ?
                    <span class="mx-2">
                      ...
                    </span> : null
                  }
                  {
                    state.filesCurrentPage < (state.filesPages.length) - 5 ?
                    <button className={ cx('btn dash-remittance__pagination__page', 
                      { 'active': state.filesPages[state.filesPages.length - 1] === state.filesCurrentPage }) }
                      onClick={() => this.onFilesPageClicked(state.filesPages[state.filesPages.length - 1])}>
                      { state.filesPages[state.filesPages.length - 1] }
                    </button> : null
                  }
                  <button class="btn dash-remittance__pagination__page arrow"
                      disabled={ state.filesCurrentPage === state.filesPages[state.filesPages.length - 1] } 
                      onClick={() => this.onFilesPageClicked(state.filesCurrentPage + 1)}>
                    <i class="fas fa-chevron-right"></i>
                  </button>
                </div> : null
              }
            </div>
          </div>
        );
      }
    }
  }

  getFilterBlock(state: DashVehiclesState) {
    let form = state.filters;

    if (state.isFilterVisible && !state.isViewingFiles) {
      return (
        <div class="container-fluid">
          <div class="row mt-1 dash-vehicles__filters dash-remittance__filter-row__filters">
            <div class="col-12 col-md-6 mb-2">
              <label class="dash-vehicles__filters__label">Plate Number</label>
              <input type="text" className={ cx('form-control', 
                { 'invalid': cxValidateField(form.fields['plateNumber']) }
              )}
              value={ form.fields['plateNumber'].value }
              placeholder=""
              onChange={ linkform(this.form$, form, 'plateNumber') }/>
            </div>


            <div class="col-12 col-md-6 mb-2">
              <label class="dash-vehicles__filters__label">Vehicle ID</label>
              <input type="text" className={ cx('form-control', 
                  { 'invalid': cxValidateField(form.fields['vehicleId']) }
                )}
                value={ form.fields['vehicleId'].value }
                placeholder=""
                onChange={ linkform(this.form$, form, 'vehicleId') }/>
            </div>

            <div class="col-12 col-md-6 mb-2">
              <label class="dash-vehicles__filters__label">Model / Make</label>
              <input type="text" className={ cx('form-control', 
                { 'invalid': cxValidateField(form.fields['modelMake']) }
              )}
              value={ form.fields['modelMake'].value }
              placeholder=""
              onChange={ linkform(this.form$, form, 'modelMake') }/>
            </div>

            <div class="col-12 col-md-6 mb-2">
              <label class="dash-vehicles__filters__label">Status</label>
              <select class="form-control"
                className={ cx('form-control text-capitalize', 
                  { 'invalid': cxValidateField(form.fields['status']) }
                )}
                placeholder="Status"
                value={form.fields['status'].value}
                onChange={linkform(this.form$, form, 'status')}>

                <option class="text-muted" value="" disabled={true} selected>Select Status</option>
                <option class="text-capitalize" value="">All</option>
                {
                  VEHICLE_STATUSES.map(status => {
                    return <option class="text-capitalize" value={status}>{ status }</option>
                  })
                }

              </select>
            </div>

            <div class="col-12 col-md-6 mb-2">
              <label class="dash-vehicles__filters__label">Supervisor</label>
              <AutocompleteSearch onResultSelected={this.onSupervisorSearchChange} />
            </div>

            <div class="col-12 col-md-6 mb-2">
              <label class="dash-vehicles__filters__label">Lodging Location</label>
              <input type="text" className={ cx('form-control', 
                { 'invalid': cxValidateField(form.fields['lodgingLocation']) }
              )}
              value={ form.fields['lodgingLocation'].value }
              placeholder=""
              onChange={ linkform(this.form$, form, 'lodgingLocation') }/>
            </div>
            

            <div class="col-12 col-lg-3 mt-3">
              <div class="mt-2">
                <button class="btn btn-outline-dark w-100" onClick={this.resetFilters}>
                  Reset Filters
                </button>
              </div>
            </div>

            <div class="col-12 col-lg-3 mt-3">
              <div class="mt-2">
                <button class="btn btn-secondary w-100" onClick={this.applyFilters}>
                  Apply Filters
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (state.isFilterVisible && state.isViewingFiles) {
      return (
        <div class="container-fluid">
          <div class="row mt-1 dash-vehicles__filters dash-remittance__filter-row__filters">
            <div class="col-12 col-md-6">
              <label class="dash-vehicles__filters__label">Supervisor</label>
              <AutocompleteSearch onResultSelected={this.onSupervisorSearchChange} />
            </div>

            <div class="col-12 col-md-6">
              <label class="dash-vehicles__filters__label">Vehicle ID</label>
              <input type="text" className={ cx('form-control', 
                  { 'invalid': cxValidateField(form.fields['vehicleId']) }
                )}
                value={ form.fields['vehicleId'].value }
                placeholder=""
                onChange={ linkform(this.form$, form, 'vehicleId') }/>
            </div>

            <div class="col-12 col-lg-3 mt-3">
              <div class="mt-2">
                <button class="btn btn-outline-dark w-100" onClick={this.resetFilters}>
                  Reset Filters
                </button>
              </div>
            </div>

            <div class="col-12 col-lg-3 mt-3">
              <div class="mt-2">
                <button class="btn btn-secondary w-100" onClick={this.applyFilters}>
                  Apply Filters
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    
  }

  onVehiclesClicked = () => {
    this.setState({
      isViewingFiles: false
    })
  }

  onFilesClicked = () => {
    this.setState({
      isViewingFiles: true
    })
  }

  onFilterToggleClicked = () => {
    this.setState({
      isFilterVisible: !this.state.isFilterVisible
    });
  }

  onModalOpened = (paramState: number) => {
    this.setState({
      modalState: paramState
    });
  }

  onModalClose = () => {
    this.setState({
      modalState: -1
    });
  }

  getPortalModal = (state: DashVehiclesState) => {
    if (state.modalState < 0) {
      return;
    }

    if (state.modalState === this.MODAL_CREATE_VEHICLE) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-111">
            <CreateVehicleModal 
              currentUser={state.currentUser}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId="anglo-modal-111"/>
          </div>
        </Portal>
      );
    } else if (state.modalState === this.MODAL_GENERATE_EXPENSE_REPORT) {
        return (
          <Portal into="body">
            <div class="anglo-modal-container" id="anglo-modal-111">
              <GenerateExpenseReportModal 
                currentUser={state.currentUser}
                close={this.onModalClose} 
                isOutsideClickClose={false} 
                containerId="anglo-modal-111"/>
            </div>
          </Portal>
        );
    }
  }

  render(props: DashVehiclesProps, state: DashVehiclesState) {
    return (
      <div class="dash-updates">

        { this.getPortalModal(state) }
        <div class="container-fluid">
          <div class="row">
            {/* cookie crumbs */}

            <div class="col-8 col-lg-4">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="#">Vehicles</a></li>
                  {/* <li class="breadcrumb-item active" aria-current="page">Remittance Updates</li> */}
                </ol>
              </nav>
            </div>
          </div>
          <div class="row dash-updates__type-row">
            
            <div class="col-12 col-md-4 offset-md-4">
              <button className={ cx(
                'w-50 dash-updates__type-row__button first', 
                {
                  'active': !state.isViewingFiles
                }) 
              } 
              onClick={this.onVehiclesClicked}>
                View Vehicles
              </button>
              <button className={ cx(
                'w-50 dash-updates__type-row__button last', 
                {
                  'active': state.isViewingFiles
                }) 
              }
              onClick={this.onFilesClicked}>
                View Vehicle Files
              </button>
            </div>
          </div>

          <hr/>

          {
            state.currentUser && (state.currentUser.role === 'role_management' || state.currentUser.role === 'role_vehicle-manager') ?
            <div class="row mt-4 mb-4 mb-md-0">
              <div class="col-12 col-md-4 col-lg-2 offset-md-2 offset-lg-8 d-flex mb-3 mb-md-0 justify-content-end align-items-center">
                <button class="btn btn-outline-dark w-100" onClick={() => this.onModalOpened(this.MODAL_GENERATE_EXPENSE_REPORT)}>
                  Generate Expense Report
                </button>
              </div>
              <div class="col-12 col-md-4 col-lg-2 d-flex justify-content-end align-items-center">
                <button class="btn btn-secondary w-100" onClick={() => this.onModalOpened(this.MODAL_CREATE_VEHICLE)}>
                  Create Vehicle Entry
                </button>
              </div>
            </div> : null
          }

          <div class="row mt-3">
            <div class="col d-flex justify-content-end align-items-center">
              <a class="dash-remittance__filter-row__filter-btn mr-3" 
                onClick={ this.onFilterToggleClicked }>
                Filter <i class="fas fa-filter"></i>
              </a>
            </div>
          </div>

          {
            this.getFilterBlock(state)
          }

          <div class="mt-5">
            { this.getVehicleData(state) }
            {/* { this.getDeleteData(state) } */}
            { this.getVehicleFilesData(state) }
          </div>
        </div>

      </div>
    );
  }

}