import { h, Component } from 'preact';
import cx from 'classnames';

import { ModalProps, Modal } from '../common/modal';
import { Subject, Subscription } from 'rxjs';
import { FieldList, Field, Form } from '../../../../types/form.model';
import { RequestState, REQUEST_IN_PROGRESS } from '../../../../types/request-state.model';
import { User } from '../../../../types/user.model';
import { Vehicle, VehicleFile } from '../../../../types/vehicle.model';
import { JobOrder } from '../../../../types/job-order.model';
import { MedicalHistoryData } from '../../../../types/medical-history.model';
import getStore, { appState$ } from '../../store';
import { GET_MEDICAL_HISTORY, VehicleAction } from '../../reducers/vehicle/vehicle.actions';
import { map, distinctUntilChanged } from 'rxjs/operators';
import moment from 'moment-timezone';

export interface ViewHistoryModalProps extends ModalProps {
  currentUser?: User;
  vehicle?: Vehicle;
}

interface ViewHistoryModalState  {
  createJobOrderState?: RequestState;

  vehicleFiles?: VehicleFile[];
  jobOrders?: JobOrder[];

  data?: MedicalHistoryData[];
}

export class ViewHistoryModal extends Modal<ViewHistoryModalProps, ViewHistoryModalState> {
  subs: Subscription[] = [];

  constructor(props) {
    super(props);

    this.setState({
      data: []
    });
  }

  doModalClose = () => {
    this.props.close();
  }

  onSubmit = (e) => {

  }

  componentDidMount() {
    super.componentDidMount();

    const vehicles$ = appState$.pipe(
      map(app => app.vehicles)
    );

    this.subs.push(
      vehicles$.pipe(
        map(vehicles => vehicles.vehicleMedicalHistory),
        distinctUntilChanged()
      ).subscribe(medicalHistory => {
        if (medicalHistory) {
          let data: MedicalHistoryData[] = medicalHistory.jobOrders.concat(
            medicalHistory.vehicleFiles
          );
  
          data.sort((a, b) => 
            new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime());
        
          this.setState({
            data: data
          });
        }
      })
    );

    this.initialStoreDispatches();
  }

  initialStoreDispatches() {
    const store = getStore();
    store.dispatch({
      type: GET_MEDICAL_HISTORY,
      payload: {
        vehicleId: this.props.vehicle.vehicleId
      }
    } as VehicleAction);
  }

  getSpinnerBlock(state: ViewHistoryModalState) {
    if (state.createJobOrderState === REQUEST_IN_PROGRESS) {
      return (
        <div class="col offset-md-9 d-flex justify-content-end">
          <div class="spinner-border text-primary mt-3" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }    
  }

  getListItem(data: any) {
    if (data.jobOrderId) {
      const jobOrder: JobOrder = data as JobOrder;
      return (
        <div class="vehicle-history-item">
          <div class="vehicle-history-item__head">
            Job Order
          </div>
          <div class="vehicle-history-item__body">
            <div class="vehicle-history-item__body__detail">
              <p class="vehicle-history-item__body__detail__title">
                Job Type
              </p>
              <p class="text-capitalize">
                {jobOrder.type}
              </p>
            </div>

            <div class="vehicle-history-item__body__detail">
              <p class="vehicle-history-item__body__detail__title">
                Assigned To
              </p>
              <p class="text-capitalize">
                {jobOrder.assignedPerson}
              </p>
            </div>

            <div class="vehicle-history-item__body__detail">
              <p class="vehicle-history-item__body__detail__title">
                Status
              </p>
              <p class="text-capitalize">
                {jobOrder.status}
              </p>
              
            </div>                  

            <div class="vehicle-history-item__body__detail">
              <p class="vehicle-history-item__body__detail__title">
                Eta Type
              </p>
              <p class="text-capitalize">
                {jobOrder.etaType}
              </p>
              
            </div>

            <div class="vehicle-history-item__body__detail">
              <p class="vehicle-history-item__body__detail__title">
                Date Filed
              </p>
              <p>
                {moment(jobOrder.dateCreated).format("MMMM DD, YYYY")}
              </p>
              
            </div>

            <button class="btn btn-secondary w-100">
              View Details
            </button>
            
          </div>
        </div>
      );
    } else {
      const vehicleFile: VehicleFile = data as VehicleFile;
      return (
        <div class="vehicle-history-item">
          <div class="vehicle-history-item__head">
            Vehicle File 
            <i class="vehicle-history-item__head__small">
              ({vehicleFile.type})
            </i>
          </div>
          <div class="vehicle-history-item__body">
            {/* <div class="vehicle-history-item__body__detail">
              <p class="vehicle-history-item__body__detail__title">
                Job Type
              </p>
              <p>
                Mechanic
              </p>
            </div> */}

            <div class="vehicle-history-item__body__detail">
              <p class="vehicle-history-item__body__detail__title">
                Supervisor in-charge
              </p>
              <p class="text-capitalize">
                {vehicleFile.username}
              </p>
            </div>

            <div class="vehicle-history-item__body__detail">
              <p class="vehicle-history-item__body__detail__title">
                Status
              </p>
              <p class="text-capitalize">
                {vehicleFile.status}
              </p>
              
            </div>                  

            <div class="vehicle-history-item__body__detail">
              <p class="vehicle-history-item__body__detail__title">
                Date Filed
              </p>
              <p>
                {moment(vehicleFile.dateOfReport).format('MMMM DD, YYYY')}
              </p>
              
            </div>

            <button class="btn btn-secondary w-100">
              View Details
            </button>
            
          </div>
        </div>
      );
    }
  }

  getDataBlock(state: ViewHistoryModalState) {
    // if (state.vehicleFiles && state.jobOrders) {
      return (
        <div class="col-12">
          <div class="row">
            {
              state.data.map(member => {
                return (
                  <div class="col-12 mb-3">
                    {this.getListItem(member)}
                  </div>
                )
              })
            }
          </div>
        </div>
      );
    // }
  }

  render(props: ViewHistoryModalProps, state: ViewHistoryModalState) {
    return (
      <div class="container anglo-modal"
        ref={ node => this.refNode = node }>

        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">
              View Vehicle History
            </h3>
          </div>
          <div class="col-3 col-md-2">
            <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>

        <div class="row anglo-modal__body py-3">
          { this.getDataBlock(state) }          
        </div>

      </div>
    );
  }

}