import { RequestState } from '../../../../types/request-state.model';
import { EditRequest, DeleteRequest } from '../../../../types/edit-request.model';
import { Withdrawal, WithdrawalBrandGroup } from '../../../../types/withdrawal.model';
import { BrandPrice } from '../../../../types/brand-price.model';

const PREFIX = '[Withdrawal]';

export const GET_PRICES = `${PREFIX} GET_PRICES`;
export const GET_PRICES_SUCCESS = `${PREFIX} GET_PRICES_SUCCESS`;
export const GET_PRICES_FAIL = `${PREFIX} GET_PRICES_FAIL`;

export const GET_GRID_WITHDRAWAL = `${PREFIX} GET_GRID_WITHDRAWAL`;
export const GET_GRID_WITHDRAWAL_SUCCESS = `${PREFIX} GET_GRID_WITHDRAWAL_SUCCESS`;
export const GET_GRID_WITHDRAWAL_FAIL = `${PREFIX} GET_GRID_WITHDRAWAL_FAIL`;

export const CREATE_WITHDRAWAL = `${PREFIX} CREATE_WITHDRAWAL`;
export const CREATE_WITHDRAWAL_SUCCESS = `${PREFIX} CREATE_WITHDRAWAL_SUCCESS`;
export const CREATE_WITHDRAWAL_FAIL = `${PREFIX} CREATE_WITHDRAWAL_FAIL`;

export const APPROVE_WITHDRAWAL = `${PREFIX} APPROVE_WITHDRAWAL`;
export const APPROVE_WITHDRAWAL_SUCCESS = `${PREFIX} APPROVE_WITHDRAWAL_SUCCESS`;
export const APPROVE_WITHDRAWAL_FAIL = `${PREFIX} APPROVE_WITHDRAWAL_FAIL`;

export const REJECT_WITHDRAWAL = `${PREFIX} REJECT_WITHDRAWAL`;
export const REJECT_WITHDRAWAL_SUCCESS = `${PREFIX} REJECT_WITHDRAWAL_SUCCESS`;
export const REJECT_WITHDRAWAL_FAIL = `${PREFIX} REJECT_WITHDRAWAL_FAIL`;

export const REEDIT_WITHDRAWAL = `${PREFIX} REEDIT_WITHDRAWAL`;
export const REEDIT_WITHDRAWAL_SUCCESS = `${PREFIX} REEDIT_WITHDRAWAL_SUCCESS`;
export const REEDIT_WITHDRAWAL_FAIL = `${PREFIX} REEDIT_WITHDRAWAL_FAIL`;

export const UPDATE_WITHDRAWAL = `${PREFIX} UPDATE_WITHDRAWAL`;
export const UPDATE_WITHDRAWAL_SUCCESS = `${PREFIX} UPDATE_WITHDRAWAL_SUCCESS`;
export const UPDATE_WITHDRAWAL_FAIL = `${PREFIX} UPDATE_WITHDRAWAL_FAIL`;

export const REEDIT_ACCEPT_WITHDRAWAL = `${PREFIX} REEDIT_ACCEPT_WITHDRAWAL`;
export const REEDIT_ACCEPT_WITHDRAWAL_SUCCESS = `${PREFIX} REEDIT_ACCEPT_WITHDRAWAL_SUCCESS`;
export const REEDIT_ACCEPT_WITHDRAWAL_FAIL = `${PREFIX} REEDIT_ACCEPT_WITHDRAWAL_FAIL`;

export const REEDIT_DECLINE_WITHDRAWAL = `${PREFIX} REEDIT_DECLINE_WITHDRAWAL`;
export const REEDIT_DECLINE_WITHDRAWAL_SUCCESS = `${PREFIX} REEDIT_DECLINE_WITHDRAWAL_SUCCESS`;
export const REEDIT_DECLINE_WITHDRAWAL_FAIL = `${PREFIX} REEDIT_DECLINE_WITHDRAWAL_FAIL`;

export const RESET_WITHDRAWAL_STATE = `${PREFIX} RESET_WITHDRAWAL_STATE`;

export interface WithdrawalState {
  filters?: Map<string, any>;
  withdrawals?: Withdrawal[];
  total?: number;

  gridRequestState?: RequestState;

  withdrawalToCreate?: Withdrawal;
  postPutRequestState?: RequestState;
  withdrawalId?: string;
  currentStage?: string;
  targetStage?: string;

  brandPrices?: BrandPrice[];
  priceRequestState?: RequestState;
  
  brandGroupsToCreate?: WithdrawalBrandGroup[];
  updatedBrands?: WithdrawalBrandGroup[];

  dateOfWithdrawal?: string;
  dateOfShipment?: string;
  supervisor?: string;
}

export interface WithdrawalAction {
  type: string;
  payload?: WithdrawalState;
}