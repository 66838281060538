export const LOCATION_TYPE_REGION: string = 'region';
export const LOCATION_TYPE_CITY: string = 'city';
export const LOCATION_TYPE_AREA: string = 'area';
export const LOCATION_TYPE_STREET: string = 'street';
export const LOCATION_TYPE_BUILDING: string = 'building';

export interface Location {
  locationId?: string;

  region?: string;
  city?: string;
  area?: string;
  street?: string;
  building?: string; // treated as building address EX: Unit 1005, 10th floor

  type?: string; // is it a Region, city, area?
  name?: string;
}