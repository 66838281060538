import { h, Component } from 'preact';
import cx from 'classnames';

import { ModalProps, Modal } from '../../common/modal';

import { Subject, Subscription } from 'rxjs';
import { FieldList, Field, Form } from '../../../../../types/form.model';

import { validateForm, cxValidateField } from '../../../../../types/validator.model';
import getStore, { appState$ } from '../../../store';
import { SUBMIT_EDIT_REQUEST } from '../../../reducers/remittance/remittance.actions';

import { map } from 'rxjs/operators';
import { RequestState, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../../types/request-state.model';
import { User } from '../../../../../types/user.model';
import linkform, { updateFormFieldValue } from '../../../../utils/linkform';
import { GET_SUPERVISORS, UserAction } from '../../../reducers/user/user.actions';
import { Vehicle } from '../../../../../types/vehicle.model';
import { CREATE_VEHICLE } from '../../../reducers/vehicle/vehicle.actions';
import { AutocompleteSearch } from '../../common/autocomplete-search.component';

export interface CreateVehicleModalProps extends ModalProps {
  currentUser?: User;
}

interface CreateVehicleModalState  {
  createVehicleState?: RequestState;
  vehicleForm?: Form;
  supervisors?: User[];
}

export class CreateVehicleModal extends Modal<CreateVehicleModalProps, CreateVehicleModalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props: CreateVehicleModalProps) {
    super(props);

    this.createForm();
  }

  componentDidMount() {
    super.componentDidMount();

    const vehicles$ = appState$.pipe(
      map(app => app.vehicles)
    );

    this.subs.push(
      vehicles$.pipe(
        map(vehicles => vehicles.createVehicleState)
      ).subscribe(reqState => {
        this.setState({
          createVehicleState: reqState
        });

        if (reqState === REQUEST_SUCCESS) {
          this.doModalClose();
        }
      })
    );

    const user$ = appState$.pipe(
      map(app => app.user)
    );

    this.subs.push(
      user$.pipe(
        map(user => user.supervisors)
      ).subscribe(supervisors => {
        this.setState({
          supervisors: supervisors
        });
      })
    );

    this.initialStoreDispatches();
  }

  initialStoreDispatches() {

    const store = getStore();
    store.dispatch({
      type: GET_SUPERVISORS
    } as UserAction);

  }

  componentWillUnmount() {
    console.log('unmounting');
  }

  createForm() {
    let fields: FieldList = {
      'supervisor': new Field(['required'], null, ''),
      'modelMake': new Field(['required'], null, ''),
      'plateNumber': new Field(['required'], null, ''),
      'lodgingLocation': new Field([] , null, ''),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      vehicleForm: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['vehicleForm'] = form;
      this.setState(state);
    });
  }

  onSupervisorSearchChange = (supervisor: User) => {
    updateFormFieldValue(
      this.form$,
      this.state.vehicleForm,
      'supervisor',
      supervisor.username
    )
  }

  doModalClose = () => {
    this.props.close();
  }

  getSpinnerBlock(state: CreateVehicleModalState) {
    if (state.createVehicleState === REQUEST_IN_PROGRESS) {
      return (
        <div class="col offset-md-9 d-flex justify-content-end">
          <div class="spinner-border text-primary mt-3" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }    
  }

  onSubmit = (e) => {

    e.preventDefault();
    
    const form = this.state.vehicleForm;
    validateForm(form);

    this.setState({
      vehicleForm: form
    });

    if (!this.state.vehicleForm.isValid) {
      return;
    }

    let vehicle: Vehicle = {
      supervisor: form.fields['supervisor'].value,
      modelMake: form.fields['modelMake'].value,
      plateNumber: form.fields['plateNumber'].value,
      lodgingLocation: form.fields['lodgingLocation'].value,
    }

    const store = getStore();
    store.dispatch({
      type: CREATE_VEHICLE,
      payload: {
        vehicleToCreate: vehicle
      }
    });
  }

  render(props: CreateVehicleModalProps, state: CreateVehicleModalState) {
    let form = state.vehicleForm;

    if (!state.supervisors) {
      return;
    }

    return (
      <div class="anglo-request-edit-modal container anglo-modal"
          ref={ node => this.refNode = node }>
        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">
              Create Vehicle Entry
            </h3>
          </div>
          <div class="col-3 col-md-2">
            <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        
        <div class="row anglo-modal__body py-3">
          <div class="col-12 col-md-6 mb-3">
            <label class="anglo-remittance__submit-form__label">Supervisor*</label>

            {/* <select
              className={ cx('form-control', 
                { 'invalid': cxValidateField(form.fields['supervisor']) }
              )}
              placeholder="Supervisor"
              value={form.fields['supervisor'].value}
              onChange={linkform(this.form$, form, 'supervisor')}>

                <option class="text-muted" value="" disabled={true} selected>Supervisor</option>
                {
                  state.supervisors.map(supervisor => {
                    return <option value={supervisor.username}>{ supervisor.firstName } { supervisor.lastName }</option>
                  })
                }
            </select> */}
            <AutocompleteSearch onResultSelected={this.onSupervisorSearchChange} />

          </div>
          <div class="col-12 col-md-6 mb-3">
            <label class="anglo-remittance__submit-form__label">Plate Number*</label>

            <input type="text" className={ cx('form-control', 
                { 'invalid': cxValidateField(form.fields['plateNumber']) }
              )}
              value={ form.fields['plateNumber'].value }
              placeholder="Plate Number"
              onChange={ linkform(this.form$, form, 'plateNumber') }/>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label class="anglo-remittance__submit-form__label">Model/Make*</label>

            <input type="text" className={ cx('form-control', 
                { 'invalid': cxValidateField(form.fields['modelMake']) }
              )}
              value={ form.fields['modelMake'].value }
              placeholder="Model/Make"
              onChange={ linkform(this.form$, form, 'modelMake') }/>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label class="anglo-remittance__submit-form__label">Lodging Location</label>

            <input type="text" className={ cx('form-control', 
                { 'invalid': cxValidateField(form.fields['lodgingLocation']) }
              )}
              value={ form.fields['lodgingLocation'].value }
              placeholder="Lodging Location"
              onChange={ linkform(this.form$, form, 'lodgingLocation') }/>
          </div>

          <div class="col-12">
            <div class="row">
              <div class="col-md-1 offset-md-9">
                {
                  this.getSpinnerBlock(state)
                }
              </div>

              <div class="col-12 col-md-2">

                <button class="btn btn-primary w-100" 
                  onClick={this.onSubmit}
                  disabled={state.createVehicleState == REQUEST_IN_PROGRESS}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}