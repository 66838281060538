import { h, Component } from "preact";
import cx from "classnames";
import { Subscription, Subject } from "rxjs";
import moment from "moment-timezone";
import { map, filter, distinctUntilChanged } from "rxjs/operators";

import { ModalProps, Modal } from "../../common/modal";
import { Form, FieldList, Field } from "../../../../../types/form.model";
import * as Validators from "../../../../../types/validator.model";
import {
  validateForm,
  cxValidateField,
} from "../../../../../types/validator.model";
import linkform, { updateFormFieldValue } from "../../../../utils/linkform";
import getStore, { appState$ } from "../../../store";

import {
  PurchaseDocument,
  ReceivingReport,
} from "../../../../../types/purchase-document.model";
import {
  REQUEST_IN_PROGRESS,
  REQUEST_SUCCESS,
  REQUEST_UNTOUCHED,
} from "../../../../../types/request-state.model";
import { Item, ItemPiece } from "../../../../../types/item.model";
import { ItemSearch } from "../../common/item-search.component";
import { toFixed } from "../../../../utils/decimal";
import { getSpinnerBlock } from "../../common/spinner-block";
import {
  CREATE_PURCHASE_DOCUMENT,
  CREATE_RECEIVING_REPORT,
  UPDATE_PURCHASE_DOCUMENT,
} from "../../../reducers/motorpool/motorpool.actions";
import { DatePicker } from "preact/components/common/date-picker";

interface CreateRRProps extends ModalProps {
  pd: PurchaseDocument;
}

interface CreateRRState {
  form?: Form;
  itemList: Item[];
  isPostInProgress?: boolean;
}

export class CreateRRModal extends Modal<CreateRRProps, CreateRRState> {
  form$: Subject<Form>;
  subs: Subscription[] = [];

  constructor(props: CreateRRProps) {
    super(props);

    this.createForm(props);
    this.setState({
      itemList: [],
    });
  }

  createForm(props: CreateRRProps) {
    let fields: FieldList = {
      checkedBy: new Field([Validators.required], null, null),
      dateChecked: new Field([Validators.required], null, null),
      receivedBy: new Field([Validators.required], null, null),
      dateReceived: new Field([Validators.required], null, null),
      receivingRemarks: new Field([], null, null),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      form: new Form(fields),
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state["form"] = form;
      this.setState(state);
    });
  }

  componentDidMount(): void {
    super.componentDidMount();

    const motorpool$ = appState$.pipe(map((appState) => appState.motorpool));

    this.subs.push(
      motorpool$
        .pipe(
          map((motorpool) => motorpool.postPutRequestState),
          distinctUntilChanged()
        )
        .subscribe((postPutRequestState) => {
          if (postPutRequestState === REQUEST_SUCCESS) {
            this.setState({
              isPostInProgress: false,
            });
            this.props.close();
          } else if (postPutRequestState === REQUEST_IN_PROGRESS) {
            this.setState({
              isPostInProgress: true,
            });
          }
        })
    );
  }

  doModalClose = () => {
    if (this.props.close) {
      this.props.close();
    }
  };

  onSubmit = () => {
    const form = this.state.form;
    validateForm(form);

    if (!form.isValid) {
      return;
    }

    let receivingReport: ReceivingReport = {
      checkedBy: form.fields["checkedBy"].value,
      dateChecked: form.fields["dateChecked"].value,
      receivedBy: form.fields["receivedBy"].value,
      dateReceived: form.fields["dateReceived"].value,
      itemList: this.state.itemList,
    };

    const store = getStore();
    store.dispatch({
      type: CREATE_RECEIVING_REPORT,
      payload: {
        purchaseDocumentId: this.props.pd.purchaseDocumentId,
        receivingReport,
      },
    });
  };

  onItemSelected = (a: Item) => {
    let itemList = this.state.itemList;
    const existing = itemList.find((member) => member.itemCode === a.itemCode);

    if (!existing) {
      // brute force setting amount to 1 (we don't set the amount in system)
      a.amount = 1;
      itemList.push(a);

      this.setState({
        itemList: itemList,
      });
    }
  };

  onDateSelected = (date: string, fieldName: string) => {
    updateFormFieldValue(this.form$, this.state.form, fieldName, date);
  };

  getFormBlock(props: CreateRRProps, state: CreateRRState) {
    const form = this.state.form;

    if (!form) {
      return;
    }

    return (
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div class="col-12 col-lg-6 mt-3">
              <label class="anglo-remittance__submit-form__label">
                Received By*
              </label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["receivedBy"]),
                })}
                type="text"
                value={form.fields["receivedBy"].value}
                onChange={linkform(this.form$, form, "receivedBy")}
                placeholder="Received By"
              />
            </div>
            <div class="col-12 col-lg-6 mt-3">
              <label class="anglo-remittance__submit-form__label">
                Date of Checking*
              </label>

              <DatePicker
                onDateSelected={(returned) =>
                  this.onDateSelected(returned, "dateReceived")
                }
                initialValue={form.fields["dateReceived"].value}
                isValid={form.fields["dateReceived"].isValid}
                isPristine={form.fields["dateReceived"].isPristine}
              ></DatePicker>
            </div>
            <div class="col-12 col-lg-6 mt-3">
              <label class="anglo-remittance__submit-form__label">
                Checked By*
              </label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["checkedBy"]),
                })}
                type="text"
                value={form.fields["checkedBy"].value}
                onChange={linkform(this.form$, form, "checkedBy")}
                placeholder="Checked By"
              />
            </div>
            <div class="col-12 col-lg-6 mt-3">
              <label class="anglo-remittance__submit-form__label">
                Date of Checking*
              </label>

              <DatePicker
                onDateSelected={(returned) =>
                  this.onDateSelected(returned, "dateChecked")
                }
                initialValue={form.fields["dateChecked"].value}
                isValid={form.fields["dateChecked"].isValid}
                isPristine={form.fields["dateChecked"].isPristine}
              ></DatePicker>
            </div>
          </div>
          <hr />
          <p class="m-0">
            <b>Receiving Report Item List</b>
          </p>
          <div className="row">
            <div class="col-12 mt-3">
              <ItemSearch onResultSelected={this.onItemSelected} />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              {state.itemList.length > 0
                ? this.getItemList(state.itemList)
                : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  removeItem = (index: number) => {
    let itemList = this.state.itemList;
    itemList.splice(index, 1);
    this.setState({
      itemList: itemList,
    });
  };

  onItemAmountChange = (event, index: number) => {
    const target = event.target;
    const value = target.value;
    let itemList = this.state.itemList;
    itemList[index].amount = value;

    this.setState({
      itemList: itemList,
    });
  };

  getItemList(itemList: Item[]) {
    return (
      <div class="item-list data-grid">
        <div className="data-grid__row header">
          <div class="data-grid__col">Name</div>
          <div class="data-grid__col">Item Code</div>
          <div class="data-grid__col">Supplier</div>
          <div class="data-grid__col">Unit Price</div>
          <div class="data-grid__col col-short">Amount</div>
          <div class="data-grid__col">Total</div>
          <div class="data-grid__col col-short">Remove</div>
        </div>
        {itemList.map((row, index) => {
          return (
            <div className="data-grid__row">
              <div class="data-grid__col">{row.name}</div>
              <div class="data-grid__col">{row.itemCode}</div>
              <div class="data-grid__col">{row.supplierId}</div>
              <div class="data-grid__col">₱ {toFixed(row.unitPrice)}</div>
              <div class="data-grid__col col-short">
                <input
                  className={"form-control"}
                  type="number"
                  value={row.amount}
                  onChange={(ev) => this.onItemAmountChange(ev, index)}
                  placeholder="Requested By"
                />
              </div>
              <div class="data-grid__col">
                ₱ {toFixed(row.unitPrice * row.amount)}
              </div>
              <div class="data-grid__col col-short">
                <button
                  class="btn btn-outline-dark"
                  onClick={(ev) => this.removeItem(index)}
                >
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  getActionsRow(props: CreateRRProps, state: CreateRRState) {
    if (state.isPostInProgress) {
      return getSpinnerBlock(state.isPostInProgress);
    } else {
      return (
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-3 offset-lg-9">
              <button
                class="btn btn-primary w-100"
                onClick={this.onSubmit}
                disabled={!state.form.isValid}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  render(props: CreateRRProps, state: CreateRRState) {
    return (
      <div
        class="container anglo-modal create-pd-modal"
        ref={(node) => (this.refNode = node)}
      >
        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">
              Create Receiving Report | {props.pd.purchaseDocumentId}
            </h3>
          </div>
          <div class="col-3 col-md-2">
            <button
              class="btn btn-outline-light w-100"
              onClick={this.doModalClose}
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>

        <div class="row anglo-modal__body py-3">
          <div class="col-12 create-withdrawal-modal__body pb-3">
            {this.getFormBlock(props, state)}
          </div>
        </div>

        <div class="row vehicle-card__actions">
          {this.getActionsRow(props, state)}
        </div>
      </div>
    );
  }
}
