export const EDIT_REQUEST_DECLINED = 1;
export const EDIT_REQUEST_APPROVED = 2;
export const EDIT_REQUEST_PENDING = 0;

export const EDIT_REQUEST_STATUSES: any[] = [
  {
    value: EDIT_REQUEST_PENDING,
    label: 'Pending'
  },
  {
    value: EDIT_REQUEST_APPROVED,
    label: 'Approved'
  },
  {
    value: EDIT_REQUEST_DECLINED,
    label: 'Declined'
  }
];