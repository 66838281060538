import { h, Component } from 'preact';
import linkState from 'linkstate';
import cx from 'classnames';

import { ModalProps, Modal } from '../common/modal';
import { Remittance } from '../../../../types/remittance.model';
import { Subject, Subscription } from 'rxjs';
import getStore, { appState$ } from '../../store';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { RequestState, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../types/request-state.model';
import { FieldList, Field, Form } from '../../../../types/form.model';
import { validateForm, cxValidateField } from '../../../../types/validator.model';
import * as Validators from '../../../../types/validator.model';
import linkform from '../../../utils/linkform';
import { CREATE_BANK_ACCOUNT, UserAction, GET_BANKS, CREATE_USER, CHANGE_PASSWORD, ASSIGN_BANK_ACCOUNT } from '../../reducers/user/user.actions';
import { User } from '../../../../types/user.model';
import { BankAccount } from '../../../../types/bank-account.model';

export interface AssignBankAccountModalProps extends ModalProps {
  selectedUser: User;
}

interface AssignBankAccountModalState  {

  submitStatus?: RequestState;
  form?: Form;

  assignableBankAccounts?: BankAccount[];
}

export class AssignBankAccountModal extends Modal<AssignBankAccountModalProps, AssignBankAccountModalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props: AssignBankAccountModalProps) {
    super(props);

    this.createForm();
  }

  createForm() {
    let fields: FieldList = {
      'accountNumber': new Field([Validators.required] , null, ''),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      form: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['form'] = form;
      this.setState(state);
    });
  }

  componentDidMount() {
    super.componentDidMount();

    const user$ = appState$.pipe(
      map(app => app.user)
    );

    this.subs.push(
      user$.pipe(
        map(user => user.assignBankAccountStatus)
      ).subscribe(reqState => {
        this.setState({
          submitStatus: reqState
        });

        if (reqState === REQUEST_SUCCESS) {
          this.doModalClose();
        }
      })
    );

    this.subs.push(
      user$.pipe(
        map(user => user.allBankAccounts),
        distinctUntilChanged()
      ).subscribe(allBankAccounts => {

        if (allBankAccounts) {
          let accounts: BankAccount[] = [];
          
          allBankAccounts.forEach(member => {
            let found = this.props.selectedUser.assignedBankAccounts.find(
              (m) => { return member.bankAccountNumber === m.accountNumber }
            );

            if (!found) {
              accounts.push(member);
            }
          });

          this.setState({
            assignableBankAccounts: accounts
          });
        }
      })
    );

    this.initialStoreDispatches();
  }

  initialStoreDispatches() {

    const store = getStore();
    store.dispatch({
      type: GET_BANKS
    } as UserAction);

  }

  componentWillUnmount() {
    this.subs.forEach(s => s.unsubscribe());
  }

  doModalClose = () => {
    this.props.close();
  }

  getSpinnerBlock(state: AssignBankAccountModalState) {
    if (state.submitStatus === REQUEST_IN_PROGRESS) {
      return (
        <div class="col offset-md-9 d-flex justify-content-end">
          <div class="spinner-border text-primary mt-3" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }    
  }

  onSubmit = () => {

    let form = this.state.form;

    validateForm(form);

    if (!form.isValid) {
      return;
    }

    const store = getStore();
    store.dispatch({
      type: ASSIGN_BANK_ACCOUNT,
      payload: {
        userToAssign: this.props.selectedUser.username,
        bankAccountNumber: form.fields['accountNumber'].value,
      }
    });
  }

  render(props: AssignBankAccountModalProps, state: AssignBankAccountModalState) {
    let form = state.form;

    if (!state.assignableBankAccounts) {
      return;
    }

    return (
      <div class="container anglo-modal"
          ref={ node => this.refNode = node }>
        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">
              Assign bank account | <small>{ props.selectedUser.username }</small>
            </h3>
          </div>
          <div class="col-3 col-md-2">
            <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        
        <div class="row anglo-modal__body py-3">

          {/* <div class="col-12 mt-2">
            <label class="dash-remittance__filter-row__filters__label">Assigned accounts</label>
            <div class="d-flex">
              {
                props.selectedUser.assignedBankAccounts.map(account => {

                })
              }
            </div>
          </div> */}

          <div class="col-12 mt-3">
            <label class="dash-remittance__filter-row__filters__label">Bank account*</label>
            <select
              className={ cx('form-control', 
                { 'invalid': cxValidateField(form.fields['accountNumber']) }
              )}
              value={form.fields['accountNumber'].value}
              onChange={linkform(this.form$, form, 'accountNumber')}>

                <option class="text-muted" value="" disabled={true} selected>Select account</option>
                {
                  state.assignableBankAccounts.map(bankAccount => {
                    return <option value={bankAccount.bankAccountNumber}>{ bankAccount.bankAccountName } - { bankAccount.bankAccountNumber } </option>
                  })
                }
            </select>
          </div>

          <div class="col-2 offset-8 mt-4">
            {
              this.getSpinnerBlock(state)
            }
          </div>

          <div class="col-2 mt-4">

            <button class="btn btn-primary w-100" onClick={this.onSubmit}>
              Submit
            </button>
          </div>
        </div>

        
      </div>
    );
  }
}