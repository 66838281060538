import * as Actions from './vehicle.actions';
import { VehicleState, VehicleAction } from './vehicle.actions';
import { REQUEST_SUCCESS, REQUEST_FAILED, REQUEST_IN_PROGRESS, REQUEST_UNTOUCHED } from '../../../../types/request-state.model';

const initialState: VehicleState = {

}

export default function Vehicle(state: VehicleState = initialState, action: VehicleAction) {
  switch (action.type) {

    case Actions.GET_VEHICLES:
    case Actions.GET_VEHICLES_SUCCESS:
    case Actions.GET_VEHICLES_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          respondVehicleFileState: REQUEST_UNTOUCHED
        }
      );

    case Actions.GET_VEHICLE_FILES:
    case Actions.GET_VEHICLE_FILES_SUCCESS:
    case Actions.GET_VEHICLE_FILES_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
      );

    case Actions.CREATE_VEHICLE:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          createVehicleState: REQUEST_IN_PROGRESS
        }
      );
        
    case Actions.CREATE_VEHICLE_SUCCESS:
    case Actions.CREATE_VEHICLE_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
      );

    case Actions.EDIT_VEHICLE:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          editVehicleState: REQUEST_IN_PROGRESS
        }
      );
        
    case Actions.EDIT_VEHICLE_SUCCESS:
    case Actions.EDIT_VEHICLE_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
      );

    case Actions.CREATE_VEHICLE_FILE:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          createVehicleFileState: REQUEST_IN_PROGRESS
        }
      );
        
    case Actions.CREATE_VEHICLE_FILE_SUCCESS:
    case Actions.CREATE_VEHICLE_FILE_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
      );

    case Actions.APPROVE_VEHICLE_FILE:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          respondVehicleFileState: REQUEST_IN_PROGRESS
        }
      );
        
    case Actions.APPROVE_VEHICLE_FILE_SUCCESS:
    case Actions.APPROVE_VEHICLE_FILE_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
      );

    case Actions.REJECT_VEHICLE_FILE:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          respondVehicleFileState: REQUEST_IN_PROGRESS
        }
      );
        
    case Actions.REJECT_VEHICLE_FILE_SUCCESS:
    case Actions.REJECT_VEHICLE_FILE_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
      );

    case Actions.GET_JOB_ORDERS:
    case Actions.GET_JOB_ORDERS_SUCCESS:
    case Actions.GET_JOB_ORDERS_FAIL:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.CREATE_JOB_ORDER:
    case Actions.CREATE_JOB_ORDER_SUCCESS:
    case Actions.CREATE_JOB_ORDER_FAIL:
    case Actions.CREATE_JOB_ORDER_CLEANUP:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.UPDATE_JOB_ORDER:
    case Actions.UPDATE_JOB_ORDER_SUCCESS:
    case Actions.UPDATE_JOB_ORDER_FAIL:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.GET_MEDICAL_HISTORY:
    case Actions.GET_MEDICAL_HISTORY_SUCCESS:
    case Actions.GET_MEDICAL_HISTORY_FAIL:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.GENERATE_VEHICLE_EXPENSE_REPORT:
    case Actions.GENERATE_VEHICLE_EXPENSE_REPORT_SUCCESS:
    case Actions.GENERATE_VEHICLE_EXPENSE_REPORT_FAIL:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.RESET_VEHICLE_FILE_STATE:
      return Object.assign(
        {},
        state,
        {
          createVehicleFileState: REQUEST_UNTOUCHED
        }
      );

    default:
      return state;
  }
}