import * as Actions from './motorpool.actions';
import { MotorpoolState, MotorpoolAction } from './motorpool.actions';
import { REQUEST_SUCCESS, REQUEST_FAILED, REQUEST_IN_PROGRESS, REQUEST_UNTOUCHED } from '../../../../types/request-state.model';

const initialState: MotorpoolState = {

}

export default function Motorpool(
  state: MotorpoolState = initialState,
  action: MotorpoolAction 
) {

  switch (action.type) {
    case Actions.GET_ITEMS:
      return Object.assign(
          {},
          state,
          action.payload,
          {
            itemRequestState: REQUEST_IN_PROGRESS
          }
      );

    case Actions.GET_ITEMS_SUCCESS:
    case Actions.GET_ITEMS_FAIL:
      return Object.assign(
          {},
          state,
          action.payload
      );

    case Actions.GET_SUPPLIERS:
      return Object.assign(
          {},
          state,
          action.payload,
          {
            supplierRequestState: REQUEST_IN_PROGRESS
          }
      );

    case Actions.GET_SUPPLIERS_SUCCESS:
    case Actions.GET_PURCHASE_DOCUMENTS_FAIL:
      return Object.assign(
          {},
          state,
          action.payload
      );

    case Actions.GET_PURCHASE_DOCUMENTS:
      return Object.assign(
          {},
          state,
          action.payload,
          {
            pdRequestState: REQUEST_IN_PROGRESS,
            postPutRequestState: REQUEST_UNTOUCHED
          }
      );

    case Actions.GET_PURCHASE_DOCUMENTS_SUCCESS:
    case Actions.GET_PURCHASE_DOCUMENTS_FAIL:
      return Object.assign(
          {},
          state,
          action.payload
      );

    case Actions.GET_JO:
      return Object.assign(
          {},
          state,
          action.payload,
          {
            itemRequestState: REQUEST_IN_PROGRESS,
            postPutRequestState: REQUEST_UNTOUCHED
          }
      );

    case Actions.GET_JO_SUCCESS:
    case Actions.GET_JO_FAIL:
      return Object.assign(
          {},
          state,
          action.payload
      );

    case Actions.GET_IW:
      return Object.assign(
          {},
          state,
          action.payload,
          {
            itemRequestState: REQUEST_IN_PROGRESS
          }
      );

    case Actions.GET_IW_SUCCESS:
    case Actions.GET_IW_FAIL:
      return Object.assign(
          {},
          state,
          action.payload
      );

    case Actions.CREATE_PURCHASE_DOCUMENT:
    case Actions.UPDATE_PURCHASE_DOCUMENT:
    case Actions.SIGN_PURCHASE_DOCUMENT:
    case Actions.CREATE_RECEIVING_REPORT:
    case Actions.CREATE_SUPPLIER:
    case Actions.EDIT_SUPPLIER:
    case Actions.CREATE_ITEM:
    case Actions.CREATE_JO:
    case Actions.CREATE_IW:
    case Actions.UPDATE_IW:
    case Actions.PROCESS_IW:
    case Actions.APPROVE_IW:
    case Actions.UPDATE_V2_JOB_ORDER:
    case Actions.CREATE_PO:
    case Actions.APPROVE_PO:
      return Object.assign(
          {},
          state,
          action.payload,
          {
            postPutRequestState: REQUEST_IN_PROGRESS
          }
      );

    case Actions.CREATE_PURCHASE_DOCUMENT_SUCCESS:
    case Actions.UPDATE_PURCHASE_DOCUMENT_SUCCESS:
    case Actions.SIGN_PURCHASE_DOCUMENT_SUCCESS:
    case Actions.CREATE_RECEIVING_REPORT_SUCCESS:
    case Actions.CREATE_SUPPLIER_SUCCESS:
    case Actions.EDIT_SUPPLIER_SUCCESS:
    case Actions.CREATE_ITEM_SUCCESS:
    case Actions.CREATE_JO_SUCCESS:
    case Actions.CREATE_IW_SUCCESS:
    case Actions.UPDATE_IW_SUCCESS:
    case Actions.PROCESS_IW_SUCCESS:
    case Actions.APPROVE_IW_SUCCESS:
    case Actions.UPDATE_V2_JOB_ORDER_SUCCESS:
    case Actions.CREATE_PO_SUCCESS:
    case Actions.APPROVE_PO_SUCCESS:
      return Object.assign(
          {},
          state,
          action.payload,
          {
            postPutRequestState: REQUEST_SUCCESS
          }
      );

    case Actions.CREATE_PURCHASE_DOCUMENT_FAIL:
    case Actions.UPDATE_PURCHASE_DOCUMENT_FAIL:
    case Actions.SIGN_PURCHASE_DOCUMENT_FAIL:
    case Actions.CREATE_RECEIVING_REPORT_FAIL:
    case Actions.CREATE_SUPPLIER_FAIL:
    case Actions.EDIT_SUPPLIER_FAIL:
    case Actions.CREATE_ITEM_FAIL:
    case Actions.CREATE_JO_FAIL:
    case Actions.CREATE_IW_FAIL:
    case Actions.UPDATE_IW_FAIL:
    case Actions.PROCESS_IW_FAIL:
    case Actions.APPROVE_IW_FAIL:
    case Actions.UPDATE_V2_JOB_ORDER_FAIL:
    case Actions.CREATE_PO_FAIL:
    case Actions.APPROVE_PO_FAIL:
      return Object.assign(
          {},
          state,
          action.payload,
          {
            postPutRequestState: REQUEST_FAILED
          }
      );

    default:
      return state;
  }
}

