import { h, Component } from 'preact';
import cx from 'classnames';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import linkState from 'linkstate';
import moment from 'moment-timezone';

import { ModalProps, Modal } from '../../common/modal';
import { Form, FieldList, Field } from '../../../../../types/form.model';
import * as Validators from '../../../../../types/validator.model';
import { validateForm, cxValidateField } from '../../../../../types/validator.model';
import linkform, { updateFormFieldValue } from '../../../../utils/linkform';

import getStore, { appState$ } from '../../../store';
import { CREATE_LOAN_PAYMENT, UPDATE_LOAN_STATUS } from '../../../reducers/cashbond-loan/cashbond-loan.actions';
import { REQUEST_FAILED, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../../types/request-state.model';

import { Sector } from '../../../../../types/sector.model';
import { GET_BANKS, UserAction } from '../../../reducers/user/user.actions';
import { User } from '../../../../../types/user.model';
import { LOAN_STATUS_REJECTED, LOAN_STATUS_UNPAID, Loan, LoanPayment, LoanStatusUpdate } from '../../../../../types/loan.model';
import { BankAccount } from '../../../../../types/bank-account.model';
import { DatePicker } from '../../common/date-picker';

export interface ChangeLoanStateModalProps extends ModalProps {
    currentUser?: User;
    loan?: Loan;
}

interface ChangeLoanStateModalState {
  loanApprovalForm?: Form;
  isFormVisible?: boolean;
  isEditing?: boolean;
  isPostInProgress?: boolean;
}

export class ChangeLoanStateModal extends Modal<ChangeLoanStateModalProps, ChangeLoanStateModalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props) {
    super(props);

    this.createForm();
  }

  createForm() {
    let fields: FieldList = {
      'responseReason': new Field([], null, ''),
    }

    this.form$ = new Subject<Form>();
    this.setState({
      loanApprovalForm: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['loanApprovalForm'] = form;
      this.setState(state);
    });
  }

  componentDidMount() {
    super.componentDidMount();

    const cashbondloan$ = appState$.pipe(
      map(appState => appState.cashbondloan)
    );

    const user$ = appState$.pipe(
      map(appState => appState.user)
    );

    this.subs.push(
      cashbondloan$.pipe(
        map(cashbondloan => cashbondloan.postPutRequestState),
        distinctUntilChanged()
      ).subscribe(postPutRequestState => {
        if (postPutRequestState === REQUEST_SUCCESS) {

          this.setState({
            isPostInProgress: false
          });
          this.props.close();

        } else if (postPutRequestState === REQUEST_IN_PROGRESS) {
          this.setState({
            isPostInProgress: true
          });
        } else if (postPutRequestState === REQUEST_FAILED) {
          this.setState({
            isPostInProgress: false
          });
          this.props.close();
        }
      })
    );
  }


  doModalClose = () => {
    this.props.close();
  }

  onSubmit = (status: string) => {
    const form = this.state.loanApprovalForm;
    validateForm(form);

    this.setState({
      loanApprovalForm: form
    });

    if (!this.state.loanApprovalForm.isValid) {
      return;
    }

    let loanStatus: LoanStatusUpdate = {
      loanId: this.props.loan.loanId,
      status: status
    }

    const store = getStore();

    store.dispatch({
      type: UPDATE_LOAN_STATUS,
      payload: {
        loanStatusToUpdate: loanStatus
      }
    });
  }

  getFormBlock(props: ChangeLoanStateModalProps, state: ChangeLoanStateModalState) {
    let form = state.loanApprovalForm;
    
    if (form) {
      return (
        <div class="row">
          <div class='col-12 create-loan-modal__form'>
              <div class="row">
                <div class="col-12 mt-3">
                  <label class="anglo-remittance__submit-form__label">Amount</label>
                  <input className={ cx('form-control', 
                      
                    )}
                    type="text"
                    disabled={true}
                    value={props.loan.amount}
                    onChange={linkform(this.form$, form, 'amount')}
                    placeholder="Amount"/>
                </div>
              </div>

              <div class="row">
                <div class="col-12 mt-3">
                  <label class="anglo-remittance__submit-form__label">Description</label>
                  <input className={ cx('form-control', 
                      
                    )}
                    type="text"
                    disabled={true}
                    value={props.loan.description}
                    onChange={linkform(this.form$, form, 'description')}
                    placeholder="Description"/>
                </div>
              </div>

              <div class="row">
                <div class="col-12 mt-3">
                  <label class="anglo-remittance__submit-form__label">Response Reason*</label>
                  <input className={ cx('form-control', 
                      { 'invalid': cxValidateField(form.fields['responseReason']) }
                    )}
                    type="text"
                    value={form.fields['responseReason'].value}
                    onChange={linkform(this.form$, form, 'responseReason')}
                    placeholder="Response Reason"/>
                </div>
              </div>
  
  
          </div>
        </div>
      );
    }
  }

  getSpinnerBlock() {
    return (
      <div class="col offset-md-9 d-flex justify-content-end">
        <div class="spinner-border text-primary mt-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );  
  }

  getActionsRow(props: ChangeLoanStateModalProps, state: ChangeLoanStateModalState) {
    if (state.isPostInProgress) {
      return this.getSpinnerBlock();
    } else {
      return (
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-3 offset-lg-6">
              <button class="btn btn-secondary w-100"
                onClick={() => this.onSubmit(LOAN_STATUS_REJECTED)}>
                Reject Loan
              </button>
            </div>
            <div class="col-12 col-lg-3">
              <button class="btn btn-primary w-100"
                onClick={() => this.onSubmit(LOAN_STATUS_UNPAID)}>
                Approve Loan
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  render(props: ChangeLoanStateModalProps, state: ChangeLoanStateModalState) {
    return (
      <div class="container anglo-modal create-disposal-modal"
        ref={ node => this.refNode = node }>

          <div class="row anglo-modal__head align-items-center">
            <div class="col-9 col-md-10">
              <h3 class="mb-0">
                Change Loan Status - {props.loan.loanId}
              </h3>
            </div>
            <div class="col-3 col-md-2">
              <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>

          <div class="row anglo-modal__body py-3">
            <div class="col-12 create-withdrawal-modal__body pb-3">
              { this.getFormBlock(props, state) }   
            </div>    
          </div>

          <div class="row vehicle-card__actions mt-3">
            { this.getActionsRow(props, state) }
          </div>

      </div>
    );
  }
}

/*



*/