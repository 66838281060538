import { h, Component } from 'preact';
import cx from 'classnames';

import { ModalProps, Modal } from '../../common/modal';
import { User } from '../../../../../types/user.model';
import { Withdrawal, WithdrawalBrand, WITHDRAWAL_STATUS_REEDIT } from '../../../../../types/withdrawal.model';
import moment from 'moment-timezone';

import { Formats, REAMS_PER_CASE, PACKS_PER_REAM } from '../../../../../constants/api';
import { toFixed } from '../../../../utils/decimal';
import getStore from '../../../store';
import { REEDIT_ACCEPT_WITHDRAWAL } from '../../../reducers/withdrawals/withdrawals.actions';
import { GET_CURRENT_USER } from '../../../reducers/user/user.actions';

export interface ReviewStocksModalProps extends ModalProps {
  currentUser?: User;
}

interface ReviewStocksModalState  {
}

export class ReviewStocksModal extends Modal<ReviewStocksModalProps, ReviewStocksModalState> {
  
  componentDidMount() {
    super.componentDidMount();

    const store = getStore();
      const action = {
        type: GET_CURRENT_USER
      }
      store.dispatch(action);
  }

  doModalClose = () => {
    this.props.close();
  }

  getBrandRow(brand: WithdrawalBrand, index: number, length: number) {
    let value: number = 0;

    value += (brand.numOfCases * REAMS_PER_CASE) * brand.pricePerReam;

    if (brand.numOfReams) {
      value += brand.numOfReams * brand.pricePerReam;
    }
    // value += (brand.numOfPacks / PACKS_PER_REAM) * brand.pricePerReam;

    return (
      <div className={cx(
        'row view-withdrawal__brands__grid__row',
        {
          'last': index === length - 1
        }
      )}>
        <div class="col view-withdrawal__brands__grid__col">
          { brand.name }
        </div>
        <div class="col view-withdrawal__brands__grid__col">
          { brand.numOfCases ? brand.numOfCases : 0 }
        </div>
        <div class="col view-withdrawal__brands__grid__col last">
          { brand.numOfReams ? brand.numOfReams : 0 }
        </div>
        <div class="col view-withdrawal__brands__grid__col last">
          { brand.numOfPacks ? brand.numOfPacks : 0 }
        </div>
      </div>
    );
  }

  getBrandsBlock(props: ReviewStocksModalProps) {
    console.log(props.currentUser);
    let brands = props.currentUser.supervisor.currentStock;

    return (
      <div class="row">
        <div class="col-12">
          <div class="row mb-4">
            <div class="col-12">
              <div class="row">
                <div class="col view-withdrawal__brands__grid__header">
                  Brand
                </div>
                <div class="col view-withdrawal__brands__grid__header">
                  Number of Cases
                </div>
                <div class="col view-withdrawal__brands__grid__header">
                  Number of Reams
                </div>
                <div class="col view-withdrawal__brands__grid__header">
                  Number of Packs
                </div>
              </div>
              {
                brands.map((brand, index) => this.getBrandRow(brand, index, brands.length))
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
  render(props: ReviewStocksModalProps, state: ReviewStocksModalState) {
    return (
      <div class="container anglo-modal"
        ref={ node => this.refNode = node }>

          <div class="row anglo-modal__head align-items-center">
            <div class="col-9 col-md-10">
              <h3 class="mb-0">
                Current Stocks
                <small class="anglo-modal__head__small ml-2">
                  {props.currentUser.firstName} {props.currentUser.lastName}
                </small>
              </h3>
            </div>
            <div class="col-3 col-md-2">
              <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>

          <div class="row anglo-modal__body py-3">
            <div class="col-12">
              { this.getBrandsBlock(props) }      
            </div>    
          </div>

          {/* <div class="row vehicle-card__actions mt-3">
            { this.getActionsRow(props, state) }
          </div> */}
      </div>
    );
  }

}