import { Epic, ofType } from "redux-observable";

import * as AuditActions from './audit.actions'
import { AuditAction } from './audit.actions';

import { switchMap, mergeMap, catchError } from "rxjs/operators";
import { from, of } from "rxjs";

import { showToast } from '../../../utils/toast.service';
import Axios from "axios";
import { AuditLog } from "../../../../types/audit-log.model";

import { API_ENDPOINT } from '../../../../constants/api';
import { TotalCount } from "../../../../types/total-count.model";

let auditEffects: Epic[] = [];


const getGridData: Epic = getGridData$ => getGridData$.pipe(
  ofType<AuditAction>(AuditActions.GET_GRID_AUDIT),
  switchMap((action: AuditAction) => {

    const promise = Axios.get<AuditLog[]>(
      `${API_ENDPOINT}/audit-log`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const gridData = JSON.parse(JSON.stringify(response.data['results']));

        const action: AuditAction = {
          type: AuditActions.GET_GRID_AUDIT_SUCCESS,
          payload: {
            auditGridData: gridData
          }
        }

        return of(action);
      }),
      catchError((errResponse) => {
        const action: AuditAction = {
          type: AuditActions.GET_GRID_AUDIT_FAIL,
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to get grid data',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to get grid data',
            message: message,
            duration: 3000
          });
        } 
        
        return of(action);
      })
    );
  })
);

// ! Make sure to add created epics to array 

auditEffects.push(getGridData);

export default auditEffects;
