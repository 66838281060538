import { Epic, ofType } from "redux-observable";
import { switchMap, mergeMap, catchError } from "rxjs/operators";


import { MicroscopeAction } from "./microscope.actions";
import * as Actions from './microscope.actions';
import { of, from } from "rxjs";
import Axios from "axios";
import { API_ENDPOINT } from "../../../../constants/api";


let microscopeEffects: Epic[] = [];

const retrieveGraphData: Epic = retrieveGraphData$ => retrieveGraphData$.pipe(
  ofType<MicroscopeAction>(Actions.RETRIEVE_GRAPH_DATA),
  switchMap((action: MicroscopeAction) => {
    const config = action.payload.remittanceConfig;

    const promise = Axios.post(
    `${API_ENDPOINT}/microscope/remittance`, 
    config,
    {
      withCredentials: true
    });

    return from(promise).pipe(
      mergeMap(resp => {
        console.log('resp', resp);

        const action: MicroscopeAction = {
          type: Actions.RETRIEVE_GRAPH_DATA_SUCCESS,
          payload: {
           remittanceGraphData: resp.data['res'] 
          }
        }

        return of(action);
      }),
      catchError(errRes => {
        const action: MicroscopeAction = {
          type: Actions.RETRIEVE_GRAPH_DATA_FAIL
        }

        return of(action);
      })
    );
  })
)

microscopeEffects.push(retrieveGraphData);

export default microscopeEffects;