import { h, Component } from 'preact';
import cx from 'classnames';
import getStore, { appState$ } from '../../store';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import Portal from 'preact-portal';
import moment from 'moment-timezone';
import { GET_ALL_USERS, UserAction } from '../../reducers/user/user.actions';
import { User } from '../../../../types/user.model';
import { CreateBankAccountModal } from './create-bank-account.modal';
import { CreateUserModal } from './create-user.modal';
import { ROLE_SUPERVISOR, ROLE_AUDITOR, ROLE_VEHICLE_MANAGER, ROLE_MANAGEMENT, ROLE_SUPER_ADMIN, ROLE_ADMIN_SALES } from '../../../../constants/role';
import { ChangePasswordModal } from './change-password.modal';
import { AssignBankAccountModal } from './assign-bank-account.modal';
import { ChangeStatusModal } from './change-status.modal';
import { AdjustStockModal } from './adjust-stock.modal';
import { CreateSupervisorDataModal } from './create-supervisor-data.modal';

export interface DashUserManagementState {

  currentUser?: User;

  allUsers?: User[];
  focusedUser?: User;
  
  // jeez need to revamp this to use the newer modalState style
  isCreateSupervisorDataOpen?: boolean

  // if set to -1, close all
  modalState?: number;
}

export class DashUserManagement extends Component<any, DashUserManagementState> {
  subs: Subscription[] = [];

  private MODAL_EDIT_PASSWORD: number = 0;
  private MODAL_CREATE_USERS: number = 1;
  private MODAL_ASSIGN_BANK_ACCOUNT: number = 2;
  private MODAL_CREATE_BANK_ACCOUNT: number = 3;
  private MODAL_CHANGE_STATUS: number = 4;
  private MODAL_CREATE_SUPERVISOR_DATA: number = 5;
  private MODAL_ADJUST_STOCK: number = 6;

  constructor(props) {
    super(props);
  }

  componentDidMount() {

    const user$ = appState$.pipe(
      map(appState => appState.user)
    );

    this.setState({
      modalState: -1,
    });

    this.subs.push(
      user$.pipe(
        map(user => user.currentUser),
        distinctUntilChanged()
      ).subscribe(currentUser=> {
        this.setState({
          currentUser: currentUser
          }
        );
      })
    );

    this.subs.push(
      user$.pipe(
        map(user => user.allUsers),
        distinctUntilChanged()
      ).subscribe((allUsers) => {
        if (allUsers) {
          this.setState({
            allUsers: allUsers
          });
        }
      })
    );

    this.initialStoreDispatches();

  }

  initialStoreDispatches() {

    const store = getStore();
    store.dispatch({
      type: GET_ALL_USERS
    } as UserAction);

  }

  onModalOpened = (paramState: number, row?: User) => {
    this.setState({
      modalState: paramState,
      focusedUser: row
    });
  }

  onModalClose = () => {
    this.setState({
      modalState: -1
    });
    
  }

  getSpinnerBlock() {
    return (
      <div class="col d-flex justify-content-start">
        <div class="spinner-border text-primary mt-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );  
  }

  getPortalModal = (state: DashUserManagementState) => {
    let modalNumber = Math.floor(Math.random() * 1000);
    let modalId = `anglo-modal-${modalNumber}`;

    let modalState = state.modalState;

    if (modalState < 0) {
      return null;
    }
    else if (modalState === this.MODAL_CREATE_USERS) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id={modalId}>
            <CreateUserModal 
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId={modalId}/>
          </div>
        </Portal>
      );
    }
    else if (modalState === this.MODAL_EDIT_PASSWORD) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id={modalId}>
            <ChangePasswordModal 
              selectedUser={state.focusedUser}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId={modalId}/>
          </div>
        </Portal>
      );
    }
    else if (modalState === this.MODAL_CREATE_BANK_ACCOUNT) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id={modalId}>
            <CreateBankAccountModal 
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId={modalId}/>
          </div>
        </Portal>
      );
    }
    else if (modalState === this.MODAL_ASSIGN_BANK_ACCOUNT) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id={modalId}>
            <AssignBankAccountModal 
              selectedUser={state.focusedUser}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId={modalId}/>
          </div>
        </Portal>
      );
    }
    else if (modalState === this.MODAL_CHANGE_STATUS) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id={modalId}>
            <ChangeStatusModal 
              selectedUser={state.focusedUser}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId={modalId}/>
          </div>
        </Portal>
      );
    }
    else if (modalState === this.MODAL_CREATE_SUPERVISOR_DATA) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id={modalId}>
            <CreateSupervisorDataModal 
              selectedUser={state.focusedUser}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId={modalId}/>
          </div>
        </Portal>
      );
    }
    else if (modalState === this.MODAL_ADJUST_STOCK) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id={modalId}>
            <AdjustStockModal 
              selectedUser={state.focusedUser}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId={modalId}/>
          </div>
        </Portal>
      );
    }
  }

  getActions(state: DashUserManagementState, row: User, index: number) {
    return (
      <div class="dropdown-menu" aria-labelledby={`actions${index}`}>
        <button class="dropdown-item" onClick={e => this.onModalOpened(this.MODAL_EDIT_PASSWORD, row)}>
          Change Password
          </button>

        {
          row.role === ROLE_SUPERVISOR ?
          <button class="dropdown-item" onClick={e => this.onModalOpened(this.MODAL_ASSIGN_BANK_ACCOUNT, row)}>
            Assign Bank Account
          </button> : null
        }
        {
          row.role === ROLE_SUPERVISOR && !row.supervisor ?
          <button class="dropdown-item" onClick={e => this.onModalOpened(this.MODAL_CREATE_SUPERVISOR_DATA, row)}>
            Create Supervisor Data
          </button> : null
        }
        {
          row.role === ROLE_SUPERVISOR && row.supervisor ?
          <button class="dropdown-item" onClick={e => this.onModalOpened(this.MODAL_ADJUST_STOCK, row)}>
            Adjust Supervisor Stock
          </button> : null
        }

        <button class="dropdown-item" onClick={e => this.onModalOpened(this.MODAL_CHANGE_STATUS, row)}>
          Change Status
        </button>
      </div>
    );
  }

  getRoleLabel(role: string) {
    if (role === ROLE_SUPERVISOR) {
      return 'Supervisor';
    }

    if (role === ROLE_AUDITOR) {
      return 'Auditor';
    }

    if (role === ROLE_VEHICLE_MANAGER) {
      return 'Vehicle Manager';
    }

    if (role === ROLE_MANAGEMENT) {
      return 'Management';
    }

    if (role === ROLE_SUPER_ADMIN) {
      return 'Super Admin'
    }

    if (role === ROLE_ADMIN_SALES) {
      return 'Admin Sales'
    }
  }

  getGridBlock(state: DashUserManagementState) {
    if (state.allUsers) {
      return (
        <div class="data-grid">
          <div class="data-grid__row header">
            <div class="data-grid__col">
              Actions
            </div>
            <div class="data-grid__col">
              Username
            </div>
            <div class="data-grid__col">
              First Name
            </div>
            {/* <div class="data-grid__col">
              Middle Name
            </div> */}
            <div class="data-grid__col">
              Last Name
            </div>
            <div class="data-grid__col">
              Role
            </div>
            <div class="data-grid__col">
              Active
            </div>
          </div>

          {
            state.allUsers.map((row, index) => {

              if (row.role === ROLE_SUPER_ADMIN) {
                if (this.state.currentUser.role !== ROLE_SUPER_ADMIN)
                return;
              } else {
                return (
                  <div class="data-grid__row">
                    <div class="data-grid__col">
                      <div class="dropright">
                        <button class="btn btn-outline-primary dropdown-toggle" type="button" id={`actions${index}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Select action
                        </button>
                        { this.getActions(state, row, index) }
                      </div>
                    </div>
                    <div class="data-grid__col">
                      { row.username }
                    </div>
                    <div class="data-grid__col">
                      { row.firstName }
                    </div>
                    {/* <div class="data-grid__col">
                      { row.middleName }
                    </div> */}
                    <div class="data-grid__col">
                      { row.lastName }
                    </div>
                    <div class="data-grid__col">
                      { this.getRoleLabel(row.role) }
                    </div>
                    <div class="data-grid__col">
                      { row.isActive ? 'Yes' : 'No' }
                    </div>
                  </div>
                );
              }
            })
          }

        </div>
      );
    } else {
      return this.getSpinnerBlock();
    }
  }

  render(props, state: DashUserManagementState) {

    return (
      <div class="dash-user-management">

        { this.getPortalModal(state) }
        <div class="container-fluid">
          <div class="row">
            {/* cookie crumbs */}

            <div class="col-8 col-lg-4">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/remittance">User Management</a></li>
                </ol>
              </nav>
            </div>


            <div class="col-4 col-lg-2 offset-lg-6 d-flex justify-content-end align-items-center">
              <a class="anglo__dashboard__faq" href="#">
                FAQs <i class="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3">
              <button class="btn btn-primary w-100" onClick={e => this.onModalOpened(this.MODAL_CREATE_USERS)}>
                Create User
              </button>
            </div>

            <div class="col-md-3 mt-3 mt-md-0">
              <button class="btn btn-secondary w-100" onClick={e => this.onModalOpened(this.MODAL_CREATE_BANK_ACCOUNT)}>
                Create Bank Account
              </button>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <div class="dash-user-management__grid-container">
                { this.getGridBlock(state) }
              </div>
            </div>
          </div>
          
        </div>
      </div>
    );

  }

}