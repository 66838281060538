import { RequestState } from '../../../../types/request-state.model';

import { CashBond, BondPayment, BondPaymentUpdate } from '../../../../types/cashbond.model';
import { Loan, LoanPayment, LoanPaymentUpdate, LoanStatusUpdate } from '../../../../types/loan.model';

const PREFIX = '[CashbondLoan]';

export const GET_GRID_CASHBOND = `${PREFIX} GET_GRID_CASHBOND`;
export const GET_GRID_CASHBOND_SUCCESS = `${PREFIX} GET_GRID_CASHBOND_SUCCESS`;
export const GET_GRID_CASHBOND_FAIL = `${PREFIX} GET_GRID_CASHBOND_FAIL`;

export const CREATE_CASHBOND = `${PREFIX} CREATE_CASHBOND`;
export const CREATE_CASHBOND_SUCCESS = `${PREFIX} CREATE_CASHBOND_SUCCESS`;
export const CREATE_CASHBOND_FAIL = `${PREFIX} CREATE_CASHBOND_FAIL`;

export const CREATE_CASHBOND_PAYMENT = `${PREFIX} CREATE_CASHBOND_PAYMENT`;
export const CREATE_CASHBOND_PAYMENT_SUCCESS = `${PREFIX} CREATE_CASHBOND_PAYMENT_SUCCESS`;
export const CREATE_CASHBOND_PAYMENT_FAIL = `${PREFIX} CREATE_CASHBOND_PAYMENT_FAIL`;

export const GET_GRID_LOAN = `${PREFIX} GET_GRID_LOAN`;
export const GET_GRID_LOAN_SUCCESS = `${PREFIX} GET_GRID_LOAN_SUCCESS`;
export const GET_GRID_LOAN_FAIL = `${PREFIX} GET_GRID_LOAN_FAIL`;

export const CREATE_LOAN = `${PREFIX} CREATE_LOAN`;
export const CREATE_LOAN_SUCCESS = `${PREFIX} CREATE_LOAN_SUCCESS`;
export const CREATE_LOAN_FAIL = `${PREFIX} CREATE_LOAN_FAIL`;

export const CREATE_LOAN_PAYMENT = `${PREFIX} CREATE_LOAN_PAYMENT`;
export const CREATE_LOAN_PAYMENT_SUCCESS = `${PREFIX} CREATE_LOAN_PAYMENT_SUCCESS`;
export const CREATE_LOAN_PAYMENT_FAIL = `${PREFIX} CREATE_LOAN_PAYMENT_FAIL`;

export const UPDATE_LOAN_STATUS = `${PREFIX} UPDATE_LOAN_STATUS`;
export const UPDATE_LOAN_STATUS_SUCCESS = `${PREFIX} UPDATE_LOAN_STATUS_SUCCESS`;
export const UPDATE_LOAN_STATUS_FAIL = `${PREFIX} UPDATE_LOAN_STATUS_FAIL`;

export const GET_LOAN_PAYMENTS = `${PREFIX} GET_LOAN_PAYMENTS`;
export const GET_LOAN_PAYMENTS_SUCCESS = `${PREFIX} GET_LOAN_PAYMENTS_SUCCESS`;
export const GET_LOAN_PAYMENTS_FAIL = `${PREFIX} GET_LOAN_PAYMENTS_FAIL`;

export const GET_BOND_PAYMENTS = `${PREFIX} GET_BOND_PAYMENTS`;
export const GET_BOND_PAYMENTS_SUCCESS = `${PREFIX} GET_BOND_PAYMENTS_SUCCESS`;
export const GET_BOND_PAYMENTS_FAIL = `${PREFIX} GET_BOND_PAYMENTS_FAIL`;

export const GENERATE_SERVERSIDE_LOAN_REPORT = `${PREFIX} GENERATE_SERVERSIDE_LOAN_REPORT`;
export const GENERATE_SERVERSIDE_LOAN_REPORT_SUCCESS = `${PREFIX} GENERATE_SERVERSIDE_LOAN_REPORT_SUCCESS`;
export const GENERATE_SERVERSIDE_LOAN_REPORT_FAIL = `${PREFIX} GENERATE_SERVERSIDE_LOAN_REPORT_FAIL`;

export const GENERATE_SERVERSIDE_CASHBOND_REPORT = `${PREFIX} GENERATE_SERVERSIDE_CASHBOND_REPORT`;
export const GENERATE_SERVERSIDE_CASHBOND_REPORT_SUCCESS = `${PREFIX} GENERATE_SERVERSIDE_CASHBOND_REPORT_SUCCESS`;
export const GENERATE_SERVERSIDE_CASHBOND_REPORT_FAIL = `${PREFIX} GENERATE_SERVERSIDE_CASHBOND_REPORT_FAIL`;

export const APPROVE_LOAN_PAYMENT = `${PREFIX} APPROVE_LOAN_PAYMENT`;
export const APPROVE_LOAN_PAYMENT_SUCCESS = `${PREFIX} APPROVE_LOAN_PAYMENT_SUCCESS`;
export const APPROVE_LOAN_PAYMENT_FAILURE = `${PREFIX} APPROVE_LOAN_PAYMENT_FAILURE`;

export const REJECT_LOAN_PAYMENT = `${PREFIX} REJECT_LOAN_PAYMENT`;
export const REJECT_LOAN_PAYMENT_SUCCESS = `${PREFIX} REJECT_LOAN_PAYMENT_SUCCESS`;
export const REJECT_LOAN_PAYMENT_FAILURE = `${PREFIX} REJECT_LOAN_PAYMENT_FAILURE`;

export const APPROVE_CASHBOND_PAYMENT = `${PREFIX} APPROVE_CASHBOND_PAYMENT`;
export const APPROVE_CASHBOND_PAYMENT_SUCCESS = `${PREFIX} APPROVE_CASHBOND_PAYMENT_SUCCESS`;
export const APPROVE_CASHBOND_PAYMENT_FAILURE = `${PREFIX} APPROVE_CASHBOND_PAYMENT_FAILURE`;

export const REJECT_BOND_PAYMENT = `${PREFIX} REJECT_BOND_PAYMENT`;
export const REJECT_BOND_PAYMENT_SUCCESS = `${PREFIX} REJECT_BOND_PAYMENT_SUCCESS`;
export const REJECT_BOND_PAYMENT_FAILURE = `${PREFIX} REJECT_BOND_PAYMENT_FAILURE`;

export const GENERATE_SERVERSIDE_LOAN_PAYMENT_REPORT_SUCCESS = `${PREFIX} GENERATE_SERVERSIDE_LOAN_PAYMENT_REPORT_SUCCESS`;
export const GENERATE_SERVERSIDE_LOAN_PAYMENT_REPORT = `${PREFIX} GENERATE_SERVERSIDE_LOAN_PAYMENT_REPORT`;
export const GENERATE_SERVERSIDE_LOAN_PAYMENT_REPORT_FAIL = `${PREFIX} GENERATE_SERVERSIDE_LOAN_PAYMENT_REPORT_FAIL`;

export const GENERATE_SERVERSIDE_BOND_PAYMENT_REPORT_SUCCESS = `${PREFIX} GENERATE_SERVERSIDE_BOND_PAYMENT_REPORT_SUCCESS`;
export const GENERATE_SERVERSIDE_BOND_PAYMENT_REPORT = `${PREFIX} GENERATE_SERVERSIDE_BOND_PAYMENT_REPORT`;
export const GENERATE_SERVERSIDE_BOND_PAYMENT_REPORT_FAIL = `${PREFIX} GENERATE_SERVERSIDE_BOND_PAYMENT_REPORT_FAIL`;


export const RESET_CASHBONDLOAN_STATE = `${PREFIX} RESET_CASHBONDLOAN_STATE`;

export interface CashbondLoanState {
  filters?: Map<string, any>;
  total?: number;

  gridRequestState?: RequestState;
  postPutRequestState?: RequestState;
  paymentsRequestState?: RequestState;
  reportRequestState?: RequestState;

  loans?: Loan[];
  loanPayments?: LoanPayment[];
  loanToCreate?: Loan;
  loanPaymentToCreate?: LoanPayment;
  loanPaymentToUpdate?: LoanPaymentUpdate;
  loanStatusToUpdate?: LoanStatusUpdate;
  
  cashbonds?: CashBond[];
  bondPayment?: BondPayment[];
  cashbondToCreate?: CashBond;
  cashbondPaymentToCreate?: BondPayment;
  cashbondPaymentToUpdate?: BondPaymentUpdate;
}

export interface CashbondLoanAction {
  type: string;
  payload?: CashbondLoanState;
}