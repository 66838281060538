import { h, Component } from 'preact';
import cx from 'classnames';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import linkState from 'linkstate';
import moment from 'moment-timezone';

import { ModalProps, Modal } from '../../common/modal';
import { Form, FieldList, Field } from '../../../../../types/form.model';
import * as Validators from '../../../../../types/validator.model';
import { validateForm, cxValidateField } from '../../../../../types/validator.model';
import linkform, { updateFormFieldValue } from '../../../../utils/linkform';

import getStore, { appState$ } from '../../../store';
import { CREATE_LOAN_PAYMENT, CashbondLoanAction, GET_LOAN_PAYMENTS, UPDATE_LOAN_STATUS } from '../../../reducers/cashbond-loan/cashbond-loan.actions';
import { REQUEST_FAILED, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../../types/request-state.model';

import { Sector } from '../../../../../types/sector.model';
import { GET_BANKS, UserAction } from '../../../reducers/user/user.actions';
import { User } from '../../../../../types/user.model';
import { LOAN_STATUS_REJECTED, LOAN_STATUS_UNPAID, Loan, LoanPayment, LoanStatusUpdate, getLoanStatusString } from '../../../../../types/loan.model';
import { BankAccount } from '../../../../../types/bank-account.model';
import { DatePicker } from '../../common/date-picker';

export interface ViewLoanModalProps extends ModalProps {
    currentUser?: User;
    loan?: Loan;
}

interface ViewLoanModalState {
  loanApprovalForm?: Form;
  isFormVisible?: boolean;
  isEditing?: boolean;
  isPostInProgress?: boolean;
  isPaymentsGetInProgress?: boolean;
  loanPayments?: LoanPayment[];
}

export class ViewLoanModal extends Modal<ViewLoanModalProps, ViewLoanModalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props) {
    super(props);

  }

  componentDidMount() {
    super.componentDidMount();

    const cashbondloan$ = appState$.pipe(
      map(appState => appState.cashbondloan)
    );
    
    this.subs.push(
      cashbondloan$.pipe(
        map(cashbondloan => cashbondloan.loanPayments),
        distinctUntilChanged()
      ).subscribe(loanPayments => {
        this.setState({
          loanPayments: loanPayments
        });
      })
    );
    
    this.initialStoreDispatches();
  }

  initialStoreDispatches() {
    const store = getStore();

    let filters: Map<string, any> = new Map();
    filters.set('loanId', this.props.loan.loanId);

    store.dispatch({
      type: GET_LOAN_PAYMENTS,
      payload: {
        filters: filters
      }
    } as CashbondLoanAction);
  }


  doModalClose = () => {
    this.props.close();
  }

  getFormBlock(props: ViewLoanModalProps, state: ViewLoanModalState) {
    return (
      <div class="row">
        <div class='col-12 create-loan-modal__form'>
            <div class="row">
              <div class="col-12 col-md-6 mt-3">
                <label class="anglo-remittance__submit-form__label">Amount Loaned</label>
                <input className={ cx('form-control', 
                    
                  )}
                  type="text"
                  disabled={true}
                  value={props.loan.amount}
                  placeholder="Amount Loaned"/>
              </div>

              <div class="col-12 col-md-6 mt-3">
                <label class="anglo-remittance__submit-form__label">Remaining</label>
                <input className={ cx('form-control', 
                    
                  )}
                  type="text"
                  disabled={true}
                  value={props.loan.remaining}
                  placeholder="Remaining"/>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6 mt-3">
                <label class="anglo-remittance__submit-form__label">Status</label>
                <input className={ cx('form-control', 
                    
                  )}
                  type="text"
                  disabled={true}
                  value={getLoanStatusString(props.loan.status)}
                  placeholder="Status"/>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label class="anglo-remittance__submit-form__label">Area/Sector</label>
                <input className={ cx('form-control', 
                    
                  )}
                  type="text"
                  disabled={true}
                  value={props.loan.sectorId}
                  placeholder="Area/Sector"/>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6 mt-3">
                <label class="anglo-remittance__submit-form__label">Date Created</label>
                <input className={ cx('form-control', 
                    
                  )}
                  type="text"
                  disabled={true}
                  value={moment(props.loan.dateCreated).format('MMMM DD, YYYY')}
                  placeholder="Date Created"/>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label class="anglo-remittance__submit-form__label">Date Approved</label>
                <input className={ cx('form-control', 
                    
                  )}
                  type="text"
                  disabled={true}
                  value={moment(props.loan.dateApproved).format('MMMM DD, YYYY')}
                  placeholder="Date Approved"/>
              </div>
            </div>

            <div class="row">
              <div class="col-12 mt-3">
                <label class="anglo-remittance__submit-form__label">Description/Notes</label>
                <input className={ cx('form-control', 
                    
                  )}
                  type="text"
                  disabled={true}
                  value={props.loan.description}
                  placeholder="Description"/>
              </div>
            </div>

            <div class="row">
              <div class="col-12 mt-3">
                <label class="anglo-remittance__submit-form__label">Response Reason</label>
                <input className={ cx('form-control', 
                    
                  )}
                  type="text"
                  disabled={true}
                  value={props.loan.responseReason}
                  placeholder="Response Reason"/>
              </div>
            </div>
        </div>
      </div>
    );
    
  }

  getPaymentsBlock(props: ViewLoanModalProps, state: ViewLoanModalState) {
    if (state.loanPayments) {
      return (
        <div class='col-12'>
          <div class="data-grid">
            <div class="data-grid__row header">
              <div class="data-grid__col">
                Payment ID
              </div>
              <div class="data-grid__col">
                Amount
              </div>
              <div class="data-grid__col">
                Date of Transaction
              </div>
              <div class="data-grid__col">
                Receipt Number
              </div>
            </div>
            {
              state.loanPayments.map((row, index) => {
                return (
                  <div class="data-grid__row">
                    <div class="data-grid__col">
                      { row.loanPaymentId }
                    </div>
                    <div class="data-grid__col">
                      { row.amount }
                    </div>
                    <div class="data-grid__col">
                      { row.dateOfTransaction }
                    </div>
                    <div class="data-grid__col">
                      { row.receiptNumber }
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      );
    } else {
      return this.getSpinnerBlock();
    }
  }

  getSpinnerBlock() {
    return (
      <div class="col offset-md-9 d-flex justify-content-end">
        <div class="spinner-border text-primary mt-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );  
  }

  getActionsRow(props: ViewLoanModalProps, state: ViewLoanModalState) {
    if (state.isPostInProgress) {
      return this.getSpinnerBlock();
    } else {
      
    }
  }

  render(props: ViewLoanModalProps, state: ViewLoanModalState) {
    return (
      <div class="container anglo-modal view-loan-modal"
        ref={ node => this.refNode = node }>

          <div class="row anglo-modal__head align-items-center">
            <div class="col-9 col-md-10">
              <h3 class="mb-0">
                View Loan - {props.loan.loanId} - {props.loan.agent.firstName} {props.loan.agent.lastName}
              </h3>
            </div>
            <div class="col-3 col-md-2">
              <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>

          <div class="row anglo-modal__body py-3">
            <div class="col-12 view-loan-modal__body pb-3">
              { this.getFormBlock(props, state) }

              <div class="row mt-3 view-loan-modal__payments">
                { this.getPaymentsBlock(props, state) }
              </div>   
            </div>    
          </div>

          <div class="row vehicle-card__actions mt-3">
            { this.getActionsRow(props, state) }
          </div>

      </div>
    );
  }
}

/*



*/