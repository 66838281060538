import { Vehicle, VehicleEdit, VehicleFile } from '../../../../types/vehicle.model';
import { RequestState } from '../../../../types/request-state.model';
import { TotalCount } from '../../../../types/total-count.model';
import { JobOrder } from '../../../../types/job-order.model';
import { MedicalHistory } from '../../../../types/medical-history.model';

const PREFIX = '[Vehicle]';

export const GET_VEHICLES = `${PREFIX} GET_VEHICLES`;
export const GET_VEHICLES_SUCCESS = `${PREFIX} GET_VEHICLES_SUCCESS`;
export const GET_VEHICLES_FAIL = `${PREFIX} GET_VEHICLES_FAIL`;

export const CREATE_VEHICLE = `${PREFIX} CREATE_VEHICLE`;
export const CREATE_VEHICLE_SUCCESS = `${PREFIX} CREATE_VEHICLE_SUCCESS`;
export const CREATE_VEHICLE_FAIL = `${PREFIX} CREATE_VEHICLE_FAIL`;

export const EDIT_VEHICLE = `${PREFIX} EDIT_VEHICLE`;
export const EDIT_VEHICLE_SUCCESS = `${PREFIX} EDIT_VEHICLE_SUCCESS`;
export const EDIT_VEHICLE_FAIL = `${PREFIX} EDIT_VEHICLE_FAIL`;

export const CREATE_VEHICLE_FILE = `${PREFIX} CREATE_VEHICLE_FILE`;
export const CREATE_VEHICLE_FILE_SUCCESS = `${PREFIX} CREATE_VEHICLE_FILE_SUCCESS`;
export const CREATE_VEHICLE_FILE_FAIL = `${PREFIX} CREATE_VEHICLE_FILE_FAIL`;

export const GET_VEHICLE_FILES = `${PREFIX} GET_VEHICLE_FILES`;
export const GET_VEHICLE_FILES_SUCCESS = `${PREFIX} GET_VEHICLE_FILES_SUCCESS`;
export const GET_VEHICLE_FILES_FAIL = `${PREFIX} GET_VEHICLE_FILES_FAIL`;

export const APPROVE_VEHICLE_FILE = `${PREFIX} APPROVE_VEHICLE_FILE`;
export const APPROVE_VEHICLE_FILE_SUCCESS = `${PREFIX} APPROVE_VEHICLE_FILE_SUCCESS`;
export const APPROVE_VEHICLE_FILE_FAIL = `${PREFIX} APPROVE_VEHICLE_FILE_FAIL`;

export const REJECT_VEHICLE_FILE = `${PREFIX} REJECT_VEHICLE_FILE`;
export const REJECT_VEHICLE_FILE_SUCCESS = `${PREFIX} REJECT_VEHICLE_FILE_SUCCESS`;
export const REJECT_VEHICLE_FILE_FAIL = `${PREFIX} REJECT_VEHICLE_FILE_FAIL`;

export const GET_JOB_ORDERS = `${PREFIX} GET_JOB_ORDERS`;
export const GET_JOB_ORDERS_SUCCESS = `${PREFIX} GET_JOB_ORDERS_SUCCESS`;
export const GET_JOB_ORDERS_FAIL = `${PREFIX} GET_JOB_ORDERS_FAIL`;

export const CREATE_JOB_ORDER = `${PREFIX} CREATE_JOB_ORDER`;
export const CREATE_JOB_ORDER_SUCCESS = `${PREFIX} CREATE_JOB_ORDER_SUCCESS`;
export const CREATE_JOB_ORDER_FAIL = `${PREFIX} CREATE_JOB_ORDER_FAIL`;
export const CREATE_JOB_ORDER_CLEANUP = `${PREFIX} CREATE_JOB_ORDER_CLEANUP`;

export const UPDATE_JOB_ORDER = `${PREFIX} UPDATE_JOB_ORDER`;
export const UPDATE_JOB_ORDER_SUCCESS = `${PREFIX} UPDATE_JOB_ORDER_SUCCESS`;
export const UPDATE_JOB_ORDER_FAIL = `${PREFIX} UPDATE_JOB_ORDER_FAIL`;

export const GET_MEDICAL_HISTORY = `${PREFIX} GET_MEDICAL_HISTORY`;
export const GET_MEDICAL_HISTORY_SUCCESS = `${PREFIX} GET_MEDICAL_HISTORY_SUCCESS`;
export const GET_MEDICAL_HISTORY_FAIL = `${PREFIX} GET_MEDICAL_HISTORY_FAIL`;

export const GENERATE_VEHICLE_EXPENSE_REPORT = `${PREFIX} GENERATE_VEHICLE_EXPENSE_REPORT`;
export const GENERATE_VEHICLE_EXPENSE_REPORT_SUCCESS = `${PREFIX} GENERATE_VEHICLE_EXPENSE_REPORT_SUCCESS`;
export const GENERATE_VEHICLE_EXPENSE_REPORT_FAIL = `${PREFIX} GENERATE_VEHICLE_EXPENSE_REPORT_FAIL`;

export const RESET_VEHICLE_FILE_STATE = `${PREFIX} RESET_VEHICLE_FILE_STATE`;

export interface VehicleState {
  vehicles?: Vehicle[];
  vehicleFiles?: VehicleFile[];
  gridRequestState?: RequestState;
  filters?: any;
  totalVehicles?: number;
  totalVehicleFiles?: number;

  createVehicleState?: RequestState;
  vehicleToCreate?: Vehicle;

  editVehicleState?: RequestState;
  vehicleToUpdate?: VehicleEdit;

  vehicleFileToCreate?: VehicleFile;
  vehicleFileToUpdate?: string;
  createVehicleFileState?: RequestState;

  vehicleId?: string;
  responseReason?: string;
  respondVehicleFileState?: RequestState;

  jobOrders?: JobOrder[];
  jobOrderToCreate?: JobOrder;
  jobOrderToUpdate?: JobOrder;
  createJobOrderState?: RequestState;
  updateJobOrderState?: RequestState;
  vehicleMedicalHistory?: MedicalHistory;
}

export interface VehicleAction {
  type: string,
  payload?: VehicleState
}