import { Epic, ofType } from "redux-observable";

import * as WithdrawalActions from './withdrawals.actions'
import { WithdrawalAction } from './withdrawals.actions';

import { switchMap, mergeMap, catchError, withLatestFrom } from "rxjs/operators";
import { from, of } from "rxjs";

import { showToast } from '../../../utils/toast.service';
import Axios from "axios";
import { AuditLog } from "../../../../types/audit-log.model";

import { API_ENDPOINT } from '../../../../constants/api';
import { TotalCount } from "../../../../types/total-count.model";
import { appState$ } from "../../store";
import { Withdrawal } from "../../../../types/withdrawal.model";
import { REQUEST_SUCCESS, REQUEST_FAILED } from "../../../../types/request-state.model";
import { BrandPrice } from "../../../../types/brand-price.model";

let withdrawalEffects: Epic[] = [];

const getBrandPrices: Epic = getBrandPrices$ => getBrandPrices$.pipe(
  ofType<WithdrawalAction>(WithdrawalActions.GET_PRICES),
  switchMap((action: WithdrawalAction) => {

    const promise = Axios.get<BrandPrice[]>(
      `${API_ENDPOINT}/prices`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const prices = JSON.parse(JSON.stringify(response.data['results']));

        const action: WithdrawalAction = {
          type: WithdrawalActions.GET_PRICES_SUCCESS,
          payload: {
            brandPrices: prices,
            priceRequestState: REQUEST_SUCCESS
          }
        }

        return of(action);
      }),
      catchError((errResponse) => {
        const action: WithdrawalAction = {
          type: WithdrawalActions.GET_PRICES_FAIL,
          payload: {
            priceRequestState: REQUEST_FAILED
          }
        }

        showToast({
          title: 'Failed to get brand prices',
          message: 'Please contact the system admin.',
          duration: 3000
        });

        return of(action);
      })
    );

  })
);

const getGridData: Epic = getGridData$ => getGridData$.pipe(
  ofType<WithdrawalAction>(WithdrawalActions.GET_GRID_WITHDRAWAL),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {

    /*
      Let's make a rule that the filter that is passed in the action
      contains both old and new. Meaning, we will never need to do
      the combination of current + new filters.
    // */

    let filters: string = '?';
    const currentFilters = action.payload.filters;
    let index = 0;
    currentFilters.forEach((value, key) => {
      if (index === 0) {
        filters = filters.concat(`${key}=${value}`);
      } else {
        filters = filters.concat(`&${key}=${value}`);
      }
      index++;
    });

    const promise = Axios.get<Withdrawal[]>(
      `${API_ENDPOINT}/withdrawal${filters}`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const gridData = JSON.parse(JSON.stringify(response.data['results']));

        const action: WithdrawalAction = {
          type: WithdrawalActions.GET_GRID_WITHDRAWAL_SUCCESS,
          payload: {
            withdrawals: gridData.data,
            total: gridData['totalCount'] && gridData['totalCount'][0] ? gridData['totalCount'][0].count : 0,
            gridRequestState: REQUEST_SUCCESS
          }
        }

        return of(action);
      }),
      catchError((errResponse) => {
        console.log(errResponse);
        const action: WithdrawalAction = {
          type: WithdrawalActions.GET_GRID_WITHDRAWAL_FAIL,
          payload: {
            gridRequestState: REQUEST_FAILED
          }
        }

        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response && errResponse.response.status == 400) {
          showToast({
            title: 'Failed to get withdrawal grid data',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to get withdrawal grid data',
            message: message,
            duration: 3000
          });
        } 
        
        return of(action);
      })
    );
  })
);

const createWithdrawal: Epic = submitWithdrawal$ => submitWithdrawal$.pipe(
  ofType<WithdrawalAction>(WithdrawalActions.CREATE_WITHDRAWAL),
  withLatestFrom(appState$),
  switchMap(([action , state]) => {
    
    const promise = Axios.post<Withdrawal>(
      `${API_ENDPOINT}/withdrawal`,
      {
        supervisor: state.user.currentUser.supervisor.supervisorId,
        brandGroups: action.payload.brandGroupsToCreate
      }, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: WithdrawalAction = {
          type: WithdrawalActions.CREATE_WITHDRAWAL_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Withdrawal submission',
          message: 'You have successfully submitted a Withdrawal request',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to submit Withdrawal request',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to submit Withdrawal request',
            message: message,
            duration: 3000
          });
        } 

        const action: WithdrawalAction = {
          type: WithdrawalActions.CREATE_WITHDRAWAL_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const approveWithdrawal: Epic = approveWithdrawal$ => approveWithdrawal$.pipe(
  ofType<WithdrawalAction>(WithdrawalActions.APPROVE_WITHDRAWAL),
  switchMap((action: WithdrawalAction) => {
    
    const promise = Axios.put<Withdrawal>(
      `${API_ENDPOINT}/withdrawal/approve`,
      {
        withdrawalId: action.payload.withdrawalId
      }, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: WithdrawalAction = {
          type: WithdrawalActions.APPROVE_WITHDRAWAL_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Withdrawal approval',
          message: 'You have successfully approved a Withdrawal request',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to approve Withdrawal request',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to approve Withdrawal request',
            message: message,
            duration: 3000
          });
        } 

        const action: WithdrawalAction = {
          type: WithdrawalActions.APPROVE_WITHDRAWAL_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const rejectWithdrawal: Epic = rejectWithdrawal$ => rejectWithdrawal$.pipe(
  ofType<WithdrawalAction>(WithdrawalActions.REJECT_WITHDRAWAL),
  switchMap((action: WithdrawalAction) => {
    
    const promise = Axios.put<Withdrawal>(
      `${API_ENDPOINT}/withdrawal/reject`,
      {
        withdrawalId: action.payload.withdrawalId
      }, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: WithdrawalAction = {
          type: WithdrawalActions.REJECT_WITHDRAWAL_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Withdrawal reject',
          message: 'You have successfully rejected a Withdrawal request',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to approve Withdrawal request',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to approve Withdrawal request',
            message: message,
            duration: 3000
          });
        } 

        const action: WithdrawalAction = {
          type: WithdrawalActions.REJECT_WITHDRAWAL_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const updateWithdrawal: Epic = updateWithdrawal$ => updateWithdrawal$.pipe(
  ofType<WithdrawalAction>(WithdrawalActions.UPDATE_WITHDRAWAL),
  switchMap((action: WithdrawalAction) => {
    
    const promise = Axios.put<Withdrawal>(
      `${API_ENDPOINT}/withdrawal/update`,
      {
        withdrawalId: action.payload.withdrawalId,
        currentStage: action.payload.currentStage,
        targetStage: action.payload.targetStage,
        dateOfWithdrawal: action.payload.dateOfWithdrawal,
        dateOfShipment: action.payload.dateOfShipment,
        supervisor: action.payload.supervisor,
      }, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: WithdrawalAction = {
          type: WithdrawalActions.UPDATE_WITHDRAWAL_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Withdrawal update',
          message: 'You have successfully updated a Withdrawal request',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to update Withdrawal request',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to update Withdrawal request',
            message: message,
            duration: 3000
          });
        } 

        const action: WithdrawalAction = {
          type: WithdrawalActions.UPDATE_WITHDRAWAL_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const reeditWithdrawal: Epic = reeditWithdrawal$ => reeditWithdrawal$.pipe(
  ofType<WithdrawalAction>(WithdrawalActions.REEDIT_WITHDRAWAL),
  switchMap((action: WithdrawalAction) => {
    
    const promise = Axios.put<Withdrawal>(
      `${API_ENDPOINT}/withdrawal/reedit`,
      {
        withdrawalId: action.payload.withdrawalId,
        updatedBrands: action.payload.updatedBrands
      }, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: WithdrawalAction = {
          type: WithdrawalActions.REEDIT_WITHDRAWAL_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Withdrawal update',
          message: 'You have successfully updated a Withdrawal request',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to update Withdrawal request',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to update Withdrawal request',
            message: message,
            duration: 3000
          });
        } 

        const action: WithdrawalAction = {
          type: WithdrawalActions.REEDIT_WITHDRAWAL_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const reeditAcceptWithdrawal: Epic = reeditAcceptWithdrawal$ => reeditAcceptWithdrawal$.pipe(
  ofType<WithdrawalAction>(WithdrawalActions.REEDIT_ACCEPT_WITHDRAWAL),
  switchMap((action: WithdrawalAction) => {
    
    const promise = Axios.put<Withdrawal>(
      `${API_ENDPOINT}/withdrawal/reedit-accept`,
      {
        withdrawalId: action.payload.withdrawalId
      }, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: WithdrawalAction = {
          type: WithdrawalActions.REEDIT_ACCEPT_WITHDRAWAL_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Withdrawal reedit acceptance',
          message: 'You have successfully accepted the Withdrawal reedit',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to accept Withdrawal reedit',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to accept Withdrawal reedit',
            message: message,
            duration: 3000
          });
        } 

        const action: WithdrawalAction = {
          type: WithdrawalActions.REEDIT_ACCEPT_WITHDRAWAL_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const reeditDeclineWithdrawal: Epic = reeditDeclineWithdrawal$ => reeditDeclineWithdrawal$.pipe(
  ofType<WithdrawalAction>(WithdrawalActions.REEDIT_DECLINE_WITHDRAWAL),
  switchMap((action: WithdrawalAction) => {
    
    const promise = Axios.put<Withdrawal>(
      `${API_ENDPOINT}/withdrawal/reedit-decline`,
      {
        withdrawalId: action.payload.withdrawalId
      }, 
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(resp => {
        const action: WithdrawalAction = {
          type: WithdrawalActions.REEDIT_DECLINE_WITHDRAWAL_SUCCESS,
          payload: {
            postPutRequestState: REQUEST_SUCCESS
          }
        }

        showToast({
          title: 'Success Withdrawal reedit acceptance',
          message: 'You have successfully accepted the Withdrawal reedit',
          duration: 3000
        })
    
        return of(action);
      }),
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to accept Withdrawal reedit',
            message: message ? message : 'Please contact the system admin.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to accept Withdrawal reedit',
            message: message,
            duration: 3000
          });
        } 

        const action: WithdrawalAction = {
          type: WithdrawalActions.REEDIT_DECLINE_WITHDRAWAL_FAIL,
          payload: {
            postPutRequestState: REQUEST_FAILED
          }
        }
    
        return of(action);

      })
    );

  })
);

const resetWithdrawalChain: Epic = resetWithdrawalChain$ => resetWithdrawalChain$.pipe(
  ofType<WithdrawalAction>(
    WithdrawalActions.CREATE_WITHDRAWAL_SUCCESS,
    WithdrawalActions.APPROVE_WITHDRAWAL_SUCCESS,
    WithdrawalActions.REJECT_WITHDRAWAL_SUCCESS,
    WithdrawalActions.REEDIT_WITHDRAWAL_SUCCESS,
    WithdrawalActions.UPDATE_WITHDRAWAL_SUCCESS,
    WithdrawalActions.REEDIT_ACCEPT_WITHDRAWAL_SUCCESS,
    WithdrawalActions.REEDIT_DECLINE_WITHDRAWAL_SUCCESS,
  ),
  switchMap(() => {
    const action: WithdrawalAction = {
      type: WithdrawalActions.RESET_WITHDRAWAL_STATE
    }

    return of(action);
  })
);

// ! Make sure to add created epics to array 

withdrawalEffects.push(getBrandPrices);
withdrawalEffects.push(getGridData);
withdrawalEffects.push(createWithdrawal);
withdrawalEffects.push(approveWithdrawal);
withdrawalEffects.push(rejectWithdrawal);
withdrawalEffects.push(updateWithdrawal);
withdrawalEffects.push(reeditWithdrawal);
withdrawalEffects.push(reeditAcceptWithdrawal);
withdrawalEffects.push(reeditDeclineWithdrawal);
withdrawalEffects.push(resetWithdrawalChain);

export default withdrawalEffects;
