import { h, Component } from 'preact';
import linkState from 'linkstate';

import moment, { Moment } from 'moment-timezone';
import { Formats } from '../../../../constants/api';
import { DatePicker } from '../common/date-picker';
import { ModalProps, Modal } from '../common/modal';
import { Remittance } from '../../../../types/remittance.model';
import { Subject, Subscription } from 'rxjs';
import { FieldList, Field, Form } from '../../../../types/form.model';

import { validateForm, cxValidateField } from '../../../../types/validator.model';
import getStore, { appState$ } from '../../store';
import { SUBMIT_EDIT_REQUEST } from '../../reducers/remittance/remittance.actions';
import { EditRequest } from '../../../../types/edit-request.model';
import { map } from 'rxjs/operators';
import { RequestState, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../types/request-state.model';
import { AutocompleteSector } from '../common/autocomplete-sector.component';
import { Sector } from '../../../../types/sector.model';
import { updateFormFieldValue } from '../../../utils/linkform';

export interface RequestEditModalProps extends ModalProps {
  remittance: Remittance;
}

interface RequestEditModalState  {
  isAmountSelected?: boolean;
  isReceivingSelected?: boolean;
  isDateOfTransactionSelected?: boolean;
  isBranchSelected?: boolean;

  amountValue?: number;
  area?: string;
  salesAgent?: string;

  fields?: Object[];
  reason?: string;
  isFormValid?: boolean;

  editRequestState?: RequestState;
}

export class RequestEditModal extends Modal<RequestEditModalProps, RequestEditModalState> {
  subs: Subscription[] = [];

  constructor(props: RequestEditModalProps) {
    super(props);

    this.setState({
      fields: [
        {
          fieldName: 'amount',
          newValue: props.remittance.amount
        },
        {
          fieldName: 'salesAgent',
          newValue: props.remittance.salesAgent
        },
        {
          fieldName: 'dateOfTransaction',
          newValue: props.remittance.dateOfTransaction
        },
        {
          fieldName: 'area',
          newValue: props.remittance.area
        }
      ],
      reason: null,
      amountValue: null,
      area: null,
      salesAgent: null
    });
  }

  componentDidMount() {
    super.componentDidMount();

    const remittance$ = appState$.pipe(
      map(app => app.remittance)
    );

    this.subs.push(
      remittance$.pipe(
        map(remittance => remittance.editRequestState)
      ).subscribe(reqState => {
        this.setState({
          editRequestState: reqState
        });

        if (reqState === REQUEST_SUCCESS) {
          this.doModalClose();
        }
      })
    );
  }

  componentWillUnmount() {
    console.log('unmounting');
  }

  doModalClose = () => {
    this.props.close();
  }

  getStartOfMonth() {
    return (moment(new Date()).startOf('month')).format(Formats.date);
  }

  onDateSelected = (date: string) => {
    let dateField = this.state.fields.find(member => { return member['fieldName'] === 'dateOfTransaction' })

    if (dateField) {
      dateField['newValue'] = date;
    } else {
      dateField = {
        fieldName: 'dateOfTransaction',
        newValue: date
      }

      const fields = this.state.fields;
      fields.push(dateField);

      this.setState({
        fields: fields,
        isFormValid: this.isFormValid()
      });
    }
  }

  onValueChange = (e) => {
    // const value = Number.parseInt(e.target.value);
    const value = e.target.value;

    let valueField = this.state.fields.find(member => { return member['fieldName'] === 'amount' });

    if (valueField) {
      valueField['newValue'] = value;
    } else {
      valueField = {
        fieldName: 'amount',
        newValue: value
      }
    }

    const fields = this.state.fields;
    fields.push(valueField);

    this.setState({
      fields: fields,
      amountValue: value,
      isFormValid: this.isFormValid()
    });
  }

  onsalesAgentChange = (e) => {
    const value = e.target.value;
    let valueField = this.state.fields.find(member => { return member['fieldName'] === 'salesAgent' });

    if (valueField) {
      valueField['newValue'] = value;
    } else {
      valueField = {
        fieldName: 'salesAgent',
        newValue: value
      }      
    }

    const fields = this.state.fields;
    fields.push(valueField);

    this.setState({
      fields: fields,
      isFormValid: this.isFormValid()
    });
  }

  onBranchChange = (e) => {
    const value = e.target.value;
    let valueField = this.state.fields.find(member => { return member['fieldName'] === 'area' })

    if (valueField) {
      valueField['newValue'] = value;
    } else {
      valueField = {
        fieldName: 'area',
        newValue: value
      }
    }

    const fields = this.state.fields;
    fields.push(valueField);

    this.setState({
      fields: fields
    });
  }

  onReasonChange = (e) => {
    const value = e.target.value;
    this.setState({
      reason: value,
      isFormValid: this.isFormValid()
    });
  }

  getSpinnerBlock(state: RequestEditModalState) {
    if (state.editRequestState === REQUEST_IN_PROGRESS) {
      return (
        <div class="col offset-md-9 d-flex justify-content-end">
          <div class="spinner-border text-primary mt-3" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }    
  }

  onSectorSearchChange = (sector: Sector) => {
    console.log(sector);

    let valueField = this.state.fields.find(member => { return member['fieldName'] === 'area' })

    if (valueField) {
      valueField['newValue'] = sector.name;
    } else {
      valueField = {
        fieldName: 'area',
        newValue: sector.name
      }
    }

    const fields = this.state.fields;
    fields.push(valueField);

    this.setState({
      fields: fields,
      isFormValid: this.isFormValid()
    });
  }

  onSubmit = () => {

    if (!this.isFormValid()) {
      this.setState({isFormValid: false});
      return;
    }

    this.setState({isFormValid: true});
    
    let finalFields = [];
    let fields = this.state.fields;
    fields.forEach(field => {
      let found = finalFields.find(m => m['fieldName'] === field['fieldName']);
      
      if (!found) {
        finalFields.push(field);
      }
    });
    

    const editRequest: EditRequest = {
      remittanceId: this.props.remittance.remittanceId,
      fields: finalFields,
      reason: this.state.reason
    }

    const store = getStore();
    store.dispatch({
      type: SUBMIT_EDIT_REQUEST,
      payload: {
        editRequest: editRequest
      }
    })
  }

  isFormValid() {
    let isValid = true;

    // if (!this.state.fields || this.state.fields.length < 1) {
    //   isValid = false;
    // }

    if (!this.state.isAmountSelected && !this.state.isReceivingSelected 
          && !this.state.isDateOfTransactionSelected && !this.state.isBranchSelected) {
      isValid = false;
    }

    let amount:any = this.state.fields.find(member => { return member['fieldName'] === 'amount' });
    let salesAgent:any = this.state.fields.find(member => { return member['fieldName'] === 'salesAgent' });
    let dateOfTransaction:any = this.state.fields.find(member => { return member['fieldName'] === 'dateOfTransaction' });
    let area:any = this.state.fields.find(member => { return member['fieldName'] === 'area' });

    if (this.state.isAmountSelected && !amount.newValue) {
      isValid = false;
    }

    if (this.state.isReceivingSelected && !salesAgent.newValue) {
      isValid = false;
    }

    if (this.state.isDateOfTransactionSelected && !dateOfTransaction.newValue) {
      isValid = false;
    }

    if (this.state.isBranchSelected && !area.newValue) {
      isValid = false;
    }

    if (!this.state.reason) {
      isValid = false;
    }

    return isValid;
  }

  render(props: RequestEditModalProps, state: RequestEditModalState) {
    // Need to use the actual form 
    let amount:any = state.fields.find(member => { return member['fieldName'] === 'amount' });
    let salesAgent:any = state.fields.find(member => { return member['fieldName'] === 'salesAgent' });
    let dateOfTransaction:any = state.fields.find(member => { return member['fieldName'] === 'dateOfTransaction' });
    let area:any = state.fields.find(member => { return member['fieldName'] === 'area' });

    return (
      <div class="anglo-request-edit-modal container anglo-modal"
          ref={ node => this.refNode = node }>
        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">
              Request to edit remittance | <small>{ props.remittance.remittanceId }</small>
            </h3>
          </div>
          <div class="col-3 col-md-2">
            <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        
        <div class="row anglo-modal__body py-3">
          <div class="col-12 col-md-6">
            <div class="anglo-request-edit-modal__field">
              <div class="anglo-request-edit-modal__field__head">
                <h4>
                  Amount
                </h4>

                <input type="checkbox" onChange={linkState(this, 'isAmountSelected')}
                  checked={state.isAmountSelected}/>
              </div>

              <div class="mt-2">
                <input type="number" class="form-control"
                  step="0.01" 
                  disabled={!state.isAmountSelected}
                  value={amount.newValue}
                  onInput={e => this.onValueChange(e)}
                  placeholder="Value"/>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="anglo-request-edit-modal__field">
              <div class="anglo-request-edit-modal__field__head">
                <h4>
                  Sales Agent
                </h4>

                <input type="checkbox" onChange={linkState(this, 'isReceivingSelected')}
                  checked={state.isReceivingSelected}/>
              </div>

              <div class="mt-2">
                <input type="text" class="form-control" 
                  disabled={!state.isReceivingSelected}
                  value={salesAgent.newValue}
                  onInput={e => this.onsalesAgentChange(e)}
                  placeholder="Sales Agent Name"/>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="anglo-request-edit-modal__field">
              <div class="anglo-request-edit-modal__field__head">
                <h4>
                  Date of Transaction
                </h4>

                <input type="checkbox" onChange={linkState(this, 'isDateOfTransactionSelected')}
                  checked={state.isDateOfTransactionSelected}/>
              </div>

              <div class="mt-2">
                <DatePicker
                  initialValue={dateOfTransaction.newValue}
                  onDateSelected={this.onDateSelected}
                  disabled={!state.isDateOfTransactionSelected}
                  min={ this.getStartOfMonth() }
                  isValid={true}>

                </DatePicker>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="anglo-request-edit-modal__field">
              <div class="anglo-request-edit-modal__field__head">
                <h4>
                  Area
                </h4>

                <input type="checkbox" onChange={linkState(this, 'isBranchSelected')}
                  checked={state.isBranchSelected}/>
              </div>

              <div class="mt-2">
                {/* <input type="text" class="form-control" 
                  disabled={!state.isBranchSelected}
                  value={area.newValue}
                  onInput={e => this.onBranchChange(e)}
                  placeholder="Area"/> */}
                <AutocompleteSector 
                  disabled={!state.isBranchSelected}
                  initialValue={area.newValue}
                  onResultSelected={this.onSectorSearchChange}/>
              </div>
            </div>
          </div>

          <div class="col-12 mt-3">
            <h4 class="anglo-request-edit-modal__label">
              Reason*
            </h4>
            <textarea class="form-control anglo-request-edit-modal__reason"
              onInput={e => this.onReasonChange(e)}
              value={state.reason}
              placeholder="Reason for changes"/>
          </div>

          <div class="col-9 mt-3 pt-2">
            {
              !state.isFormValid ? 
                <span class="anglo-request-edit-modal__error">
                  You have a field with missing values or not selected a field to edit.
                </span> 
                : null
            }
          </div>

          <div class="col-1">
            {
              this.getSpinnerBlock(state)
            }
          </div>

          <div class="col-2 mt-3">

            <button class="btn btn-primary w-100" onClick={this.onSubmit}>
              Submit
            </button>
          </div>
        </div>

        
      </div>
    );
  }
}