import { h, Component } from 'preact';
import linkState from 'linkstate';

import { ModalProps, Modal } from '../common/modal';
import { Remittance } from '../../../../types/remittance.model';
import { Subject, Subscription } from 'rxjs';
import getStore, { appState$ } from '../../store';
import { SUBMIT_EDIT_REQUEST } from '../../reducers/remittance/remittance.actions';
import { EditRequest, DeleteRequest } from '../../../../types/edit-request.model';
import { map } from 'rxjs/operators';
import { RequestState, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../types/request-state.model';
import { EDIT_REQUEST_PENDING, EDIT_REQUEST_APPROVED, EDIT_REQUEST_DECLINED } from '../../../../constants/edit-requests';
import { User } from '../../../../types/user.model';
import { APPROVE_REQUEST_EDIT, REJECT_REQUEST_EDIT, APPROVE_REQUEST_DELETE, REJECT_REQUEST_DELETE } from '../../reducers/updates/updates.actions';

export interface ViewUpdateDetailModalProps extends ModalProps {
  request: EditRequest;
  isEdit: boolean;
  currentUser?: User;
}

interface ViewUpdateDetailModalState  {
  responseReason?: string;
  editRequestState?: RequestState;
}

export class ViewUpdateDetailModal extends Modal<ViewUpdateDetailModalProps, ViewUpdateDetailModalState> {
  subs: Subscription[] = [];

  constructor(props: ViewUpdateDetailModalProps) {
    super(props);

    this.setState({
      responseReason: props.request.responseReason
    });
  }

  componentDidMount() {
    super.componentDidMount();

    const updates$ = appState$.pipe(
      map(app => app.updates)
    );

    this.subs.push(
      updates$.pipe(
        map(updates => updates.editRequestState)
      ).subscribe(reqState => {
        this.setState({
          editRequestState: reqState
        });

        if (reqState === REQUEST_SUCCESS) {
          this.doModalClose();
        }
      })
    );
  }

  doModalClose = () => {
    this.props.close();
  }

  onApprove = (props: ViewUpdateDetailModalProps) => {
    const store = getStore();
    
    if (props.isEdit) {
      store.dispatch({
        type: APPROVE_REQUEST_EDIT,
        payload: {
          remittanceId: props.request.remittanceId,
          responseReason: this.state.responseReason
        }
      })
    } else {
      store.dispatch({
        type: APPROVE_REQUEST_DELETE,
        payload: {
          remittanceId: props.request.remittanceId,
          responseReason: this.state.responseReason
        }
      })
    }

  }

  onReject = (props: ViewUpdateDetailModalProps) => {
    const store = getStore();

    if (props.isEdit) {
      store.dispatch({
        type: REJECT_REQUEST_EDIT,
        payload: {
          remittanceId: props.request.remittanceId,
          responseReason: this.state.responseReason
        }
      })
    } else {
      store.dispatch({
        type: REJECT_REQUEST_DELETE,
        payload: {
          remittanceId: props.request.remittanceId,
          responseReason: this.state.responseReason
        }
      })
    }
  }

  getStatusLabel(status: number) {
    switch(status) {
      case EDIT_REQUEST_PENDING:
        return 'Pending';

      case EDIT_REQUEST_APPROVED:
        return 'Approved';

      case EDIT_REQUEST_DECLINED:
        return 'Declined';
    }
  }

  getFieldsBlock(props: ViewUpdateDetailModalProps) {
    if (props.isEdit) {
      return (
        <div class="row mt-3 view-update-detail-modal__fields-container">
          <div class="col-12">
            <h4 class="view-update-detail-modal__fields-container__header">
              Fields to be updated
            </h4>
          </div>

          {
            props.request.fields.map(field => 
              <div class="col-3">
                <h4 class="view-update-detail-modal__label text-capitalize">
                  { field['fieldName'] }
                </h4>
                <div class="view-update-detail-modal__value">
                  { field['newValue'] }
                </div>
              </div>  
            )
          }
        </div>
      );
    }
  }

  getUsernameBlock(props: ViewUpdateDetailModalProps) {
    if (props.currentUser.role === 'role_auditor' || props.currentUser.role === 'role_management') {
      return (
        <div class="col-12 d-flex view-update-detail-modal__status-reason">
          <h4 class="view-update-detail-modal__status-reason__label">
            Username
          </h4>
          <div class="view-update-detail-modal__status-reason__value">
            { props.request.username }
          </div>
        </div>
      );
    }
  }

  getResponseReasonBlock(props: ViewUpdateDetailModalProps, state: ViewUpdateDetailModalState) {
    if (props.currentUser.role === 'role_auditor' || props.currentUser.role === 'role_management') {
      return (
        <div class="row mt-3">
          <div class="col-12">
            <h4 class="anglo-request-edit-modal__label">
              Response Reason*
            </h4>
            <textarea class="form-control anglo-request-edit-modal__reason"
              onInput={linkState(this, 'responseReason')}
              value={state.responseReason}
              placeholder="Reason for accepting or rejecting changes"/>
          </div>
        </div>
      );
    }
  }

  getActionsBlock(props: ViewUpdateDetailModalProps) {
    if (props.currentUser.role === 'role_auditor' || props.currentUser.role === 'role_management') {
      if (props.request.status != EDIT_REQUEST_PENDING) {
        return;
      }
      
      return (
        <div class="anglo-modal__actions mb-3">
           <button class="btn view-update-detail-modal__action reject"
            disabled={!this.state.responseReason}
            onClick={e => this.onReject(props)}>
            Reject
          </button>
          <button class="btn view-update-detail-modal__action approve ml-3"
            disabled={!this.state.responseReason}
            onClick={e => this.onApprove(props)}>
            Approve
          </button>
        </div>
      );
    }
  }

  render(props: ViewUpdateDetailModalProps, state: ViewUpdateDetailModalState) {
    return (
      <div class="view-update-detail-modal container anglo-modal"
          ref={ node => this.refNode = node }>
        <div class="row anglo-modal__head align-items-center">
          <div class="col-10">
            <h3 class="mb-0">
              Viewing details of request | <small>{ props.request.remittanceId }</small>
            </h3>
          </div>
          <div class="col-2">
            <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        
        <div class="anglo-modal__body py-3">

          <div class="row">

          </div>

          <div class="row view-update-detail-modal__status-reason">
            { this.getUsernameBlock(props) }
            <div class="col-12 d-flex view-update-detail-modal__status-reason">
              <h4 class="view-update-detail-modal__status-reason__label">
                Status
              </h4>
              <div class="view-update-detail-modal__status-reason__value">
                { this.getStatusLabel(props.request.status) }
              </div>
            </div>
            <div class="col-12 d-flex view-update-detail-modal__status-reason">
              <h4 class="view-update-detail-modal__status-reason__label">
                Request reason
              </h4>
              <div class="view-update-detail-modal__status-reason__value">
                { props.request.reason }
              </div>
            </div>
          </div>

          { this.getFieldsBlock(props) }

          { this.getResponseReasonBlock(props, state) }

        </div>

        { this.getActionsBlock(props) }

        
      </div>
    );
  }
}