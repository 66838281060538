import { h, Component } from "preact";
import cx from "classnames";
import { map, filter, distinctUntilChanged } from "rxjs/operators";
import { Subscription, Subject } from "rxjs";
import linkState from "linkstate";
import moment from "moment-timezone";

import { Form, FieldList, Field } from "../../../../../types/form.model";
import { validateForm } from "../../../../../types/validator.model";
import linkform, { updateFormFieldValue } from "../../../../utils/linkform";
import { DatePicker } from "../../common/date-picker";
import { ModalActionKey, onModalOpened } from "../../common/modal";
import { GridPagination } from "../../common/grid-pagination";
import getStore, { appState$ } from "../../../store";
import { PAGE_SIZE } from "../../remittance/dash-remittance.component";

import {
  MOTORPOOL_MODAL_APPROVE_PO,
  MOTORPOOL_MODAL_CREATE_PO,
  MOTORPOOL_MODAL_CREATE_RR,
  MOTORPOOL_MODAL_SIGN_PD,
  MOTORPOOL_MODAL_UPDATE_PD,
  MOTORPOOL_MODAL_VIEW_PD,
} from "../motorpool.dashboard";
import { PurchaseDocument } from "../../../../../types/purchase-document.model";
import { GET_PURCHASE_DOCUMENTS } from "../../../reducers/motorpool/motorpool.actions";

export interface PurchaseDocumentGridProps {
  initialFilters: Map<string, any>;
  onModalAction: Function;
  refreshGrid?: Function;
}

interface PurchaseDocumentGridState {
  data: PurchaseDocument[];

  filters?: Map<string, any>;
  filterForm?: Form;
  isFilterVisible?: boolean;

  pages?: number[];
  currentPage?: number;
}

export class PurchaseDocumentGrid extends Component<
  PurchaseDocumentGridProps,
  PurchaseDocumentGridState
> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  resetStartDate: Function;
  resetEndDate: Function;

  constructor(props: PurchaseDocumentGridProps) {
    super(props);

    let filters: Map<string, any> = new Map();
    filters.set("page", 1);

    this.setState({
      filters: filters,
      currentPage: 1,
    });

    this.createForm(props);
    if (props.refreshGrid) {
      this.props.refreshGrid(this.refreshGrid);
    }
    // props.onModalAction(onModalOpened('', {}))
  }

  createForm(props: PurchaseDocumentGridProps) {
    let fields: FieldList = {
      startDate: new Field([], [], props.initialFilters.get("startDate")),
      endDate: new Field([], [], props.initialFilters.get("endDate")),
      status: new Field([], [], props.initialFilters.get("status")),
      purchaseDocumentId: new Field(
        [],
        [],
        props.initialFilters.get("purchaseDocumentId")
      ),
      requestedBy: new Field([], [], props.initialFilters.get("requestedBy")),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      filterForm: new Form(fields),
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state["filterForm"] = form;
      this.setState(state);
    });
  }

  componentDidMount() {
    const motorpool$ = appState$.pipe(map((appState) => appState.motorpool));

    this.subs.push(
      motorpool$
        .pipe(
          map((motorpool) => motorpool.purchaseDocuments),
          distinctUntilChanged()
        )
        .subscribe((purchaseDocuments) => {
          this.setState({
            data: purchaseDocuments,
          });
        })
    );

    this.subs.push(
      motorpool$
        .pipe(map((motorpool) => motorpool.pdTotal))
        .subscribe((pdTotal) => {
          if (pdTotal) {
            let pages: number[] = [];
            let limit = Math.ceil(pdTotal / PAGE_SIZE);

            for (let i = 0; i < limit; i++) {
              pages.push(i + 1);
            }

            this.setState({
              pages: pages,
            });
          }
        })
    );

    this.initialStoreDispatches();
  }

  initialStoreDispatches() {
    this.refreshGrid();
  }

  refreshGrid() {
    const store = getStore();

    store.dispatch({
      type: GET_PURCHASE_DOCUMENTS,
      payload: {
        filters: this.state.filters,
      },
    });
  }

  onFilterToggleClicked = () => {
    this.setState({
      isFilterVisible: !this.state.isFilterVisible,
    });
  };

  onPageClicked = (page: number) => {
    let filterForm = {};
    const fieldList = this.state.filterForm.fields;

    for (let k in fieldList) {
      if (fieldList[k].value) {
        filterForm[k] = fieldList[k].value;
      }
    }

    filterForm["page"] = page;

    let filters = this.state.filters;
    filters.set("page", page);

    this.refreshGrid();
    this.setState({
      currentPage: page,
    });
  };

  startDateSelected = (date: string) => {
    updateFormFieldValue(this.form$, this.state.filterForm, "startDate", date);
  };

  endDateSelected = (date: string) => {
    updateFormFieldValue(this.form$, this.state.filterForm, "endDate", date);
  };

  resetStartDates = (returned: Function) => {
    this.resetStartDate = returned;
  };

  resetEndDates = (returned: Function) => {
    this.resetEndDate = returned;
  };

  resetFilters = () => {
    const fieldList = this.state.filterForm.fields;

    for (let k in fieldList) {
      fieldList[k].value = null;
    }

    this.resetStartDate();
    this.resetEndDate();

    let filters: Map<string, any> = new Map();
    filters.set("page", 1);

    this.setState({
      filters: filters,
      currentPage: 1,
    });

    this.initialStoreDispatches();
  };

  applyFilters = () => {
    let filters = this.state.filters;
    const fieldList = this.state.filterForm.fields;

    for (let k in fieldList) {
      if (fieldList[k].value) {
        filters.set(k, fieldList[k].value);
      }
    }

    this.setState({
      filters: filters,
    });

    this.refreshGrid();
  };

  getGridBlock(
    props: PurchaseDocumentGridProps,
    state: PurchaseDocumentGridState
  ) {
    if (state.data) {
      return (
        <div class="grid-container">
          <div class="row mt-3">
            <div className="col-12">
              <div className="data-grid">
                <div className="data-grid__row header">
                  <div className="data-grid__col">Actions</div>
                  <div className="data-grid__col">Purchase Document ID</div>
                  <div className="data-grid__col">Requested By</div>
                  <div className="data-grid__col">Status</div>
                  <div className="data-grid__col">Total Amount</div>
                  <div className="data-grid__col">Date Created</div>
                </div>

                {state.data.map((row, index) => {
                  return (
                    <div class="data-grid__row">
                      <div className="data-grid__col">
                        <div class="dropright">
                          <button
                            class="btn btn-outline-primary dropdown-toggle"
                            type="button"
                            id={`actions${index}`}
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Select action
                          </button>
                          {this.getRowActions(props, row, index)}
                        </div>
                      </div>
                      <div class="data-grid__col">{row.purchaseDocumentId}</div>
                      <div class="data-grid__col">{row.requestedBy}</div>
                      <div class="data-grid__col text-capitalize">
                        {getPDStatusReadable(row.status)}
                      </div>
                      <div class="data-grid__col">{row.totalAmount}</div>
                      <div class="data-grid__col">
                        {moment(row.dateCreated).format("MMMM DD, YYYY")}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  getRowActions(
    props: PurchaseDocumentGridProps,
    row: PurchaseDocument,
    index: number
  ) {
    return (
      <div class="dropdown-menu" aria-labelledby={`actions${index}`}>
        <button
          class="dropdown-item"
          onClick={(e) =>
            props.onModalAction(
              onModalOpened(MOTORPOOL_MODAL_VIEW_PD, { pd: row })
            )
          }
        >
          View Purchase Document
        </button>
        <button
          class="dropdown-item"
          onClick={(e) =>
            props.onModalAction(
              onModalOpened(MOTORPOOL_MODAL_UPDATE_PD, { pd: row })
            )
          }
        >
          Edit
        </button>
        <button
          class="dropdown-item"
          onClick={(e) =>
            props.onModalAction(
              onModalOpened(MOTORPOOL_MODAL_SIGN_PD, {
                pd: row,
                signAction: "notedBy",
              })
            )
          }
        >
          Note
        </button>
        <button
          class="dropdown-item"
          onClick={(e) =>
            props.onModalAction(
              onModalOpened(MOTORPOOL_MODAL_SIGN_PD, {
                pd: row,
                signAction: "requestApprovedBy",
              })
            )
          }
        >
          Approve
        </button>
        <button
          class="dropdown-item"
          onClick={(e) =>
            props.onModalAction(
              onModalOpened(MOTORPOOL_MODAL_CREATE_PO, {
                pd: row,
                signAction: "requestApprovedBy",
              })
            )
          }
        >
          Create PO
        </button>
        <button
          class="dropdown-item"
          onClick={(e) =>
            props.onModalAction(
              onModalOpened(MOTORPOOL_MODAL_APPROVE_PO, {
                pd: row,
              })
            )
          }
        >
          Approve PO
        </button>
        <button
          class="dropdown-item"
          onClick={(e) =>
            props.onModalAction(
              onModalOpened(MOTORPOOL_MODAL_CREATE_RR, {
                pd: row,
              })
            )
          }
        >
          Create Receiving Report
        </button>
      </div>
    );
  }

  getFilterBlock(
    props: PurchaseDocumentGridProps,
    state: PurchaseDocumentGridState
  ) {
    return (
      <div class="">
        <div className="row justify-content-end">
          <div className="col-12 col-lg-3 text-right">
            <a
              class="dash-remittance__filter-row__filter-btn"
              onClick={this.onFilterToggleClicked}
            >
              Filter <i class="fas fa-filter"></i>
            </a>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-12">
            {state.isFilterVisible ? this.getFilterForm(props, state) : null}
            {state.isFilterVisible ? this.getFilterActions(props, state) : null}
          </div>
        </div>
      </div>
    );
  }

  getFilterActions(
    props: PurchaseDocumentGridProps,
    state: PurchaseDocumentGridState
  ) {
    return (
      <div class="row mt-3">
        <div class="col-12 col-lg-2 offset-lg-8">
          <button
            class="btn btn-outline-dark w-100"
            onClick={this.resetFilters}
          >
            Reset Filters
          </button>
        </div>
        <div class="col-12 col-lg-2">
          <button class="btn btn-secondary w-100" onClick={this.applyFilters}>
            Apply Filters
          </button>
        </div>
      </div>
    );
  }

  getFilterForm = (
    props: PurchaseDocumentGridProps,
    state: PurchaseDocumentGridState
  ) => {
    let form = state.filterForm;

    return (
      <div class="row mt-3">
        <div className="col-12 col-lg-4">
          <label className="dash-remittance__filter-row__filters__label">
            Purchase Document ID
          </label>
          <input
            className={cx("form-control")}
            type="text"
            value={form.fields["purchaseDocumentId"].value}
            onChange={linkform(this.form$, form, "purchaseDocumentId")}
            placeholder="Purchase Document ID"
          />
        </div>

        <div className="col-12 col-lg-4">
          <label className="dash-remittance__filter-row__filters__label">
            Requested By
          </label>
          <input
            className={cx("form-control")}
            type="text"
            value={form.fields["requestedBy"].value}
            onChange={linkform(this.form$, form, "requestedBy")}
            placeholder="Requested By"
          />
        </div>

        <div class="col-12 col-lg-4">
          <label class="dash-remittance__filter-row__filters__label">
            Start date
          </label>
          <DatePicker
            onDateSelected={this.startDateSelected}
            initialValue={form.fields["startDate"].value}
            isValid={form.fields["startDate"].isValid}
            isPristine={form.fields["startDate"].isPristine}
            reset={this.resetStartDates}
          ></DatePicker>
        </div>

        <div class="col-12 col-lg-4 mt-1">
          <label class="dash-remittance__filter-row__filters__label">
            End date
          </label>
          <DatePicker
            onDateSelected={this.endDateSelected}
            initialValue={form.fields["endDate"].value}
            isValid={form.fields["endDate"].isValid}
            isPristine={form.fields["endDate"].isPristine}
            reset={this.resetEndDates}
          ></DatePicker>
        </div>
      </div>
    );
  };

  render(props: PurchaseDocumentGridProps, state: PurchaseDocumentGridState) {
    return (
      <div class="dash-cashbond-loans anglo__dashboard card">
        <div class="container-fluid">
          {/* {this.getActionsBlock(state)} */}

          {this.getFilterBlock(props, state)}
          {this.getGridBlock(props, state)}
          <GridPagination
            pages={state.pages}
            currentPage={state.currentPage}
            onPageClicked={this.onPageClicked}
          />
          {/* {this.getPaginationBlock(state)} */}
        </div>
      </div>
    );
  }
}

export const getPDStatusReadable = (key: string) => {
  const split = key.split("-");

  if (split.length === 1) {
    return split[0];
  } else {
    const clean = split.reduce((prev, cur) => `${prev} ${cur}`);
    return clean;
  }
};
