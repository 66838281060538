import { RequestState } from '../../../../types/request-state.model';
import { EditRequest, DeleteRequest } from '../../../../types/edit-request.model';
import { Disposal } from '../../../../types/disposal.model';
import { BrandPrice } from '../../../../types/brand-price.model';
import { Location } from '../../../../types/location.model';

const PREFIX = '[Disposal]';

export const GET_GRID_DISPOSAL = `${PREFIX} GET_GRID_DISPOSAL`;
export const GET_GRID_DISPOSAL_SUCCESS = `${PREFIX} GET_GRID_DISPOSAL_SUCCESS`;
export const GET_GRID_DISPOSAL_FAIL = `${PREFIX} GET_GRID_DISPOSAL_FAIL`;

export const CREATE_DISPOSAL = `${PREFIX} CREATE_DISPOSAL`;
export const CREATE_DISPOSAL_SUCCESS = `${PREFIX} CREATE_DISPOSAL_SUCCESS`;
export const CREATE_DISPOSAL_FAIL = `${PREFIX} CREATE_DISPOSAL_FAIL`;

export const RESET_DISPOSAL_STATE = `${PREFIX} RESET_DISPOSAL_STATE`;


// putting this here because time rush
export const CREATE_LOCATION = `${PREFIX} CREATE_LOCATION`;
export const CREATE_LOCATION_SUCCESS = `${PREFIX} CREATE_LOCATION_SUCCESS`;
export const CREATE_LOCATION_FAIL = `${PREFIX} CREATE_LOCATION_FAIL`;

export interface DisposalState {
  filters?: Map<string, any>;
  disposals?: Disposal[];
  total?: number;

  gridRequestState?: RequestState;

  disposalToCreate?: Disposal;
  postPutRequestState?: RequestState;

  locationToCreate?: Location;
}

export interface DisposalAction {
  type: string;
  payload?: DisposalState;
}