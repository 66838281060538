import { h, Component } from "preact";
import cx from "classnames";
import { map, filter, distinctUntilChanged } from "rxjs/operators";
import { Subscription, Subject } from "rxjs";
import linkState from "linkstate";
import moment from "moment-timezone";

import { Form, FieldList, Field } from "../../../../../types/form.model";
import { validateForm } from "../../../../../types/validator.model";
import linkform, { updateFormFieldValue } from "../../../../utils/linkform";
import { DatePicker } from "../../common/date-picker";
import { ModalActionKey, onModalOpened } from "../../common/modal";
import { GridPagination } from "../../common/grid-pagination";
import getStore, { appState$ } from "../../../store";
import { PAGE_SIZE } from "../../remittance/dash-remittance.component";

import {
  MOTORPOOL_MODAL_CREATE_RR,
  MOTORPOOL_MODAL_EDIT_SUPPLIER,
  MOTORPOOL_MODAL_SIGN_PD,
  MOTORPOOL_MODAL_UPDATE_PD,
  MOTORPOOL_MODAL_VIEW_PD,
} from "../motorpool.dashboard";
import { PurchaseDocument } from "../../../../../types/purchase-document.model";
import {
  GET_JO,
  GET_PURCHASE_DOCUMENTS,
  GET_SUPPLIERS,
} from "../../../reducers/motorpool/motorpool.actions";
import { Supplier } from "../../../../../types/supplier.model";
import { V2_JobOrder } from "../../../../../types/job-order.model";
import {
  JOB_ORDER_MODAL_EDIT_JO,
  JOB_ORDER_MODAL_VIEW_JO,
} from "../job-order.dashboard";

export interface JobOrderGridProps {
  initialFilters: Map<string, any>;
  onModalAction: Function;
  refreshGrid?: Function;
}

interface JobOrderGridState {
  data: V2_JobOrder[];

  filters?: Map<string, any>;
  filterForm?: Form;
  isFilterVisible?: boolean;

  pages?: number[];
  currentPage?: number;
}

export class JobOrderGrid extends Component<
  JobOrderGridProps,
  JobOrderGridState
> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props: JobOrderGridProps) {
    super(props);

    let filters: Map<string, any> = new Map();
    filters.set("page", 1);

    this.setState({
      filters: filters,
      currentPage: 1,
    });

    this.createForm(props);
    if (props.refreshGrid) {
      this.props.refreshGrid(this.refreshGrid);
    }
    // props.onModalAction(onModalOpened('', {}))
  }

  createForm(props: JobOrderGridProps) {
    let fields: FieldList = {
      supplierName: new Field([], [], props.initialFilters.get("supplierName")),
      supplierId: new Field([], [], props.initialFilters.get("supplierId")),
      address: new Field([], [], props.initialFilters.get("address")),
      tin: new Field([], [], props.initialFilters.get("tin")),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      filterForm: new Form(fields),
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state["filterForm"] = form;
      this.setState(state);
    });
  }

  componentDidMount() {
    const motorpool$ = appState$.pipe(map((appState) => appState.motorpool));

    this.subs.push(
      motorpool$
        .pipe(
          map((motorpool) => motorpool.jobOrders),
          distinctUntilChanged()
        )
        .subscribe((jobOrders) => {
          this.setState({
            data: jobOrders,
          });
        })
    );

    this.subs.push(
      motorpool$
        .pipe(map((motorpool) => motorpool.total))
        .subscribe((total) => {
          if (total) {
            let pages: number[] = [];
            let limit = Math.ceil(total / PAGE_SIZE);

            for (let i = 0; i < limit; i++) {
              pages.push(i + 1);
            }

            this.setState({
              pages: pages,
            });
          }
        })
    );

    this.initialStoreDispatches();
  }

  initialStoreDispatches() {
    this.refreshGrid();
  }

  refreshGrid() {
    const store = getStore();

    store.dispatch({
      type: GET_JO,
      payload: {
        filters: this.state.filters,
      },
    });
  }

  onFilterToggleClicked = () => {
    this.setState({
      isFilterVisible: !this.state.isFilterVisible,
    });
  };

  onPageClicked = (page: number) => {
    let filterForm = {};
    const fieldList = this.state.filterForm.fields;

    for (let k in fieldList) {
      if (fieldList[k].value) {
        filterForm[k] = fieldList[k].value;
      }
    }

    filterForm["page"] = page;

    let filters = this.state.filters;
    filters.set("page", page);

    this.refreshGrid();
    this.setState({
      currentPage: page,
    });
  };

  resetFilters = () => {
    const fieldList = this.state.filterForm.fields;

    for (let k in fieldList) {
      fieldList[k].value = null;
    }

    let filters: Map<string, any> = new Map();
    filters.set("page", 1);

    this.setState({
      filters: filters,
      currentPage: 1,
    });

    this.initialStoreDispatches();
  };

  applyFilters = () => {
    let filters = this.state.filters;
    const fieldList = this.state.filterForm.fields;

    for (let k in fieldList) {
      if (fieldList[k].value) {
        filters.set(k, fieldList[k].value);
      }
    }

    this.setState({
      filters: filters,
    });

    this.refreshGrid();
  };

  getGridBlock(props: JobOrderGridProps, state: JobOrderGridState) {
    if (state.data) {
      return (
        <div class="grid-container">
          <div class="row mt-3">
            <div className="col-12">
              <div className="data-grid">
                <div className="data-grid__row header">
                  <div className="data-grid__col">Actions</div>
                  <div className="data-grid__col">Job Order ID</div>
                  <div className="data-grid__col">Vehicle ID</div>
                  <div className="data-grid__col">Type</div>
                  <div className="data-grid__col">Status</div>
                  <div className="data-grid__col">Assigned</div>
                </div>

                {state.data.map((row, index) => {
                  return (
                    <div class="data-grid__row">
                      <div className="data-grid__col">
                        <div class="dropright">
                          <button
                            class="btn btn-outline-primary dropdown-toggle"
                            type="button"
                            id={`actions${index}`}
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Select action
                          </button>
                          {this.getRowActions(props, row, index)}
                        </div>
                      </div>
                      <div class="data-grid__col">{row.jobOrderId}</div>
                      <div class="data-grid__col">{row.vehicleId}</div>
                      <div class="data-grid__col">{row.type}</div>
                      <div class="data-grid__col">{row.status}</div>
                      <div class="data-grid__col">{row.assignedPerson}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  getRowActions(props: JobOrderGridProps, row: V2_JobOrder, index: number) {
    return (
      <div class="dropdown-menu" aria-labelledby={`actions${index}`}>
        <button
          class="dropdown-item"
          onClick={(e) =>
            props.onModalAction(
              onModalOpened(JOB_ORDER_MODAL_VIEW_JO, { jo: row })
            )
          }
        >
          View
        </button>
        <button
          class="dropdown-item"
          onClick={(e) =>
            props.onModalAction(
              onModalOpened(JOB_ORDER_MODAL_EDIT_JO, { jo: row })
            )
          }
        >
          Edit
        </button>
      </div>
    );
  }

  getFilterBlock(props: JobOrderGridProps, state: JobOrderGridState) {
    return (
      <div class="">
        <div className="row justify-content-end">
          <div className="col-12 col-lg-3 text-right">
            <a
              class="dash-remittance__filter-row__filter-btn"
              onClick={this.onFilterToggleClicked}
            >
              Filter <i class="fas fa-filter"></i>
            </a>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-12">
            {state.isFilterVisible ? this.getFilterForm(props, state) : null}
            {state.isFilterVisible ? this.getFilterActions(props, state) : null}
          </div>
        </div>
      </div>
    );
  }

  getFilterActions(props: JobOrderGridProps, state: JobOrderGridState) {
    return (
      <div class="row mt-3">
        <div class="col-12 col-lg-2 offset-lg-8">
          <button
            class="btn btn-outline-dark w-100"
            onClick={this.resetFilters}
          >
            Reset Filters
          </button>
        </div>
        <div class="col-12 col-lg-2">
          <button class="btn btn-secondary w-100" onClick={this.applyFilters}>
            Apply Filters
          </button>
        </div>
      </div>
    );
  }

  getFilterForm = (props: JobOrderGridProps, state: JobOrderGridState) => {
    let form = state.filterForm;

    return (
      <div class="row mt-3">
        <div className="col-12 col-lg-4">
          <label className="dash-remittance__filter-row__filters__label">
            Supplier ID
          </label>
          <input
            className={cx("form-control")}
            type="text"
            value={form.fields["purchaseDocumentId"].value}
            onChange={linkform(this.form$, form, "purchaseDocumentId")}
            placeholder="Purchase Document ID"
          />
        </div>

        <div className="col-12 col-lg-4">
          <label className="dash-remittance__filter-row__filters__label">
            Supplier Name
          </label>
          <input
            className={cx("form-control")}
            type="text"
            value={form.fields["purchaseDocumentId"].value}
            onChange={linkform(this.form$, form, "purchaseDocumentId")}
            placeholder="Purchase Document ID"
          />
        </div>

        <div className="col-12 col-lg-4">
          <label className="dash-remittance__filter-row__filters__label">
            Address
          </label>
          <input
            className={cx("form-control")}
            type="text"
            value={form.fields["requestedBy"].value}
            onChange={linkform(this.form$, form, "requestedBy")}
            placeholder="Requested By"
          />
        </div>

        <div className="col-12 col-lg-4">
          <label className="dash-remittance__filter-row__filters__label">
            TIN
          </label>
          <input
            className={cx("form-control")}
            type="text"
            value={form.fields["requestedBy"].value}
            onChange={linkform(this.form$, form, "requestedBy")}
            placeholder="Requested By"
          />
        </div>
      </div>
    );
  };

  render(props: JobOrderGridProps, state: JobOrderGridState) {
    return (
      <div class="dash-cashbond-loans anglo__dashboard card">
        <div class="container-fluid">
          {/* {this.getActionsBlock(state)} */}

          {this.getFilterBlock(props, state)}
          {this.getGridBlock(props, state)}
          <GridPagination
            pages={state.pages}
            currentPage={state.currentPage}
            onPageClicked={this.onPageClicked}
          />
          {/* {this.getPaginationBlock(state)} */}
        </div>
      </div>
    );
  }
}
