import { h, Component } from 'preact';
import cx from 'classnames';
import getStore, { appState$ } from '../../store';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import Portal from 'preact-portal';

import moment from 'moment-timezone';

import { Remittance } from '../../../../types/remittance.model';
import { RequestState, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../types/request-state.model';
import { RemittanceAction, GET_GRID_REMITTANCE, GET_REMITTANCE_TOTAL, GET_REMITTANCE_TOTAL_MONTH, APPROVE_REMITTANCE, GET_REPORT_GRID_REMITTANCE, GENERATE_SERVERSIDE_REPORT } from '../../reducers/remittance/remittance.actions';

import { FieldList, Field, Form } from '../../../../types/form.model';
import { validateForm, cxValidateField } from '../../../../types/validator.model';
import linkform, { updateFormFieldValue } from '../../../utils/linkform';

import { toDecimal } from '../../../utils/decimal';
import { TotalCount } from '../../../../types/total-count.model';
import { DataDisplayWidget } from '../common/data-display.widget';
import { User } from '../../../../types/user.model';
import { RequestEditModal } from './request-edit.modal';
import { RequestDeleteModal } from './request-delete.modal';
import { DatePicker } from '../common/date-picker';
import { ReportModal } from './report.modal';
import { GET_SUPERVISORS, UserAction, GET_BANKS } from '../../reducers/user/user.actions';
import { ClearRemittanceModal } from './clear-remittance.modal';
import { AutocompleteSearch } from '../common/autocomplete-search.component';
import { BankAccount } from '../../../../types/bank-account.model';

export interface DashRemittanceProps {
}

export interface DashRemittanceState {
  
  remittanceGridData?: Remittance[];
  reportGridData?: Remittance[];
  pages?: number[];
  currentPage?: number;
  gridRequestState?: RequestState;

  overallTotal?: TotalCount;
  currentMonthTotal?: TotalCount;
  previousMonthTotal?: TotalCount;

  isFilterVisible?: boolean;

  currentUser?: User;

  isEditModalOpen?: boolean;
  isDeleteModalOpen?: boolean;
  focusedRemitance?: Remittance;

  isReportModalOpen?: boolean;

  filters?: Form;

  isClearModalOpen?: boolean;

  allBankAccounts: BankAccount[];
}

export const PAGE_SIZE = 20;

export class DashRemittance extends Component<any, DashRemittanceState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  resetStartDate: Function;
  resetEndDate: Function;

  constructor(props) {
    super(props);

    this.setState({
      currentPage: 1
    });

    this.createForm();
  }

  createForm() {
    let fields: FieldList = {
      'startDate': new Field([], null, ''),
      'endDate': new Field([] , null, ''),
      'minAmount': new Field([] , null, ''),
      'maxAmount': new Field([] , null, ''),
      'isCleared': new Field([] , null, ''),
      'stage': new Field([] , null, ''),
      'username': new Field([] , null, ''),
      'receivingBankAccount': new Field([] , null, ''),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      filters: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['filters'] = form;
      this.setState(state);
    });
  }

  handleDatepickerValidation = (date: string, fieldName: string) => {
    const currentForm = this.state.filters; 

    // Manually do this because in order to follow the 
    // hopplerLinkstate validation system
    let clonedFields: FieldList = {};
    for (let k in currentForm.fields) {
      let prevField = currentForm.fields[k];
      clonedFields[k] = new Field(prevField.validators, prevField.customValidators, prevField.value);
      clonedFields[k].isValid = prevField.isValid;
    }

    let clone: Form = new Form(clonedFields);
    clone.fields[fieldName].value = date;
    clone.fields[fieldName].isPristine = false;
    // clone.fields[fieldName].customValidators.forEach(a => a(clone.fields[fieldName], clone));
    this.form$.next(clone);
  }

  componentDidMount() {

    const user$ = appState$.pipe(
      map(appState => appState.user)
    );

    this.subs.push(user$.subscribe((user) => {
        if (user.currentUser) {
          this.setState({
            currentUser: user.currentUser
          });
        }
      })
    );

    const remittance$ = appState$.pipe(
      map(appState => appState.remittance)
    );

    // set request state
    this.subs.push(remittance$.pipe(
        map(remittance => remittance.gridRequestState)
      ).subscribe((gridRequestState) => {

        this.setState({
          gridRequestState: gridRequestState
        });

      })
    );

    // set grid data
    this.subs.push(remittance$.pipe(
      map(remittance => remittance.remittanceGridData)
    ).subscribe((remittanceGridData) => {

        this.setState({
          remittanceGridData: remittanceGridData
        });

      })
    );

    this.subs.push(remittance$.pipe(
      map(remittance => remittance.reportGridData),
      distinctUntilChanged()
    ).subscribe((reportGridData) => {

        if (reportGridData) {
          this.setState({
            reportGridData: reportGridData
          });
  
          this.setState({
            isEditModalOpen: false,
            isDeleteModalOpen: false,
            isReportModalOpen: true
          });
        }

      })
    );

    this.subs.push(remittance$.pipe(
      map(remittance => remittance.total)
    ).subscribe((total) => {
        if (total) {
          let pages: number[] = [];
          let limit = Math.ceil(total / PAGE_SIZE);

          for(let i = 0; i < limit; i++) {
            pages.push(i + 1);
          }

          this.setState({
            pages: pages
          });
        }
      })
    );

    // set overall Total
    this.subs.push(remittance$.pipe(
      map(remittance => remittance.overallTotal)
    ).subscribe((overallTotal) => {

        this.setState({
          overallTotal: overallTotal
        });

      })
    );

    // set curMonth Total
    this.subs.push(remittance$.pipe(
      map(remittance => remittance.currentMonthTotal)
    ).subscribe((currentMonthTotal) => {

        this.setState({
          currentMonthTotal: currentMonthTotal
        });

      })
    );

    // set previousMonthTotal Total
    this.subs.push(remittance$.pipe(
      map(remittance => remittance.previousMonthTotal)
    ).subscribe((previousMonthTotal) => {

        this.setState({
          previousMonthTotal: previousMonthTotal
        });

      })
    );

    this.subs.push(user$.pipe(
      map(user => user.allBankAccounts),
      distinctUntilChanged()
    ).subscribe((allBankAccounts) => {
        if (allBankAccounts) {
          this.setState({
            allBankAccounts: allBankAccounts
          });
        }
      })
    );

    this.initialStoreDispatches();
  }

  initialStoreDispatches() {

    const store = getStore();

    store.dispatch({
      type: GET_GRID_REMITTANCE,
      payload: {
        filters: {
          page: 1
        }
      }
    } as RemittanceAction);

    store.dispatch({
      type: GET_REMITTANCE_TOTAL
    } as RemittanceAction);

    store.dispatch({
      type: GET_REMITTANCE_TOTAL_MONTH
    } as RemittanceAction);

    store.dispatch({
      type: GET_BANKS
    } as UserAction);

  }

  componentWillUnmount() {
    this.subs.forEach(s => s.unsubscribe());
  }

  onFilterToggleClicked = () => {
    this.setState({
      isFilterVisible: !this.state.isFilterVisible
    });
  }

  onModalClose = () => {
    this.setState({
      isEditModalOpen: false,
      isDeleteModalOpen: false,
      isReportModalOpen: false,
      isClearModalOpen: false,

      reportGridData: null
    });
  }

  getPortalModal = (state: DashRemittanceState) => {
    if (state.isEditModalOpen) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-111">
            <RequestEditModal 
              remittance={state.focusedRemitance}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId="anglo-modal-111"/>
          </div>
        </Portal>
      );
    } else if (state.isDeleteModalOpen) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-111">
            <RequestDeleteModal 
              remittance={state.focusedRemitance}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId="anglo-modal-111"/>
          </div>
        </Portal>
      );
    } else if (state.isReportModalOpen) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container full-page" id="anglo-modal-111">
            <ReportModal 
              gridData={state.reportGridData}
              status={state.filters.fields['stage'].value}
              startDate={state.filters.fields['startDate'].value}
              endDate={state.filters.fields['endDate'].value}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId="anglo-modal-111"/>
          </div>
        </Portal>
      );
    } else if (state.isClearModalOpen) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-111">
            <ClearRemittanceModal 
              remittance={state.focusedRemitance}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId="anglo-modal-111"/>
          </div>
        </Portal>
      );
    }
  }

  onRequestEdit = (row: Remittance) => {
    this.setState({
      isEditModalOpen: true,
      focusedRemitance: row
    });
  }

  onRequestDelete = (row: Remittance) => {
    this.setState({
      isDeleteModalOpen: true,
      focusedRemitance: row
    });
  }

  onApproveRemit = (row: Remittance) => {
    this.setState({
      isClearModalOpen: true,
      focusedRemitance: row
    })
  }

  openReportModal = () => {
    // this.setState({
    //   isEditModalOpen: false,
    //   isDeleteModalOpen: false,
    //   isReportModalOpen: true
    // });

    let filters: Map<string, any> = new Map<string, any>();
    const fieldList = this.state.filters.fields;

    for (let k in fieldList) {
      if (fieldList[k].value) {
        filters.set(k, fieldList[k].value);
      }
    }

    filters.set('pageSize', 5000);
    filters.set('page', 1);

    const store = getStore();
    store.dispatch({
      type: GENERATE_SERVERSIDE_REPORT,
      payload: {
        filters: filters
      }
    });
  }

  openSupervisorReportModal = () => {
    // this.setState({
    //   isEditModalOpen: false,
    //   isDeleteModalOpen: false,
    //   isReportModalOpen: true
    // });

    let filters: Map<string, any> = new Map<string, any>();
    const fieldList = this.state.filters.fields;

    for (let k in fieldList) {
      if (fieldList[k].value) {
        filters.set(k, fieldList[k].value);
      }
    }

    filters.set('pageSize', 5000);
    filters.set('page', 1);
    filters.set('username', this.state.currentUser.username);

    const store = getStore();
    store.dispatch({
      type: GENERATE_SERVERSIDE_REPORT,
      payload: {
        filters: filters
      }
    });
  }

  getActions(state: DashRemittanceState, row: Remittance, index: number) {
    if (state.currentUser && state.currentUser.role == 'role_supervisor') {
      return (
        <div class="dropdown-menu" aria-labelledby={`actions${index}`}>
          <button class="dropdown-item" onClick={e => this.onRequestEdit(row)}>
            Request Edit
            </button>
          <button class="dropdown-item" onClick={e => this.onRequestDelete(row)}>
            Request Delete
          </button>
        </div>
      );
    } else {
      return (
        <div class="dropdown-menu" aria-labelledby={`actions${index}`}>
          
          {/* <button class="dropdown-item" onClick={e => this.onRequestEdit(row)}>
            Request Edit
            </button>
          <button class="dropdown-item" onClick={e => this.onRequestDelete(row)}>
            Request Delete
          </button> */}

          {/* HAXXX */}

          <button class="dropdown-item" onClick={e => this.onApproveRemit(row)}>
            Clear
            </button>
          {/* <button class="dropdown-item" onClick={e => this.onRequestDelete(row)}>
            Decline
          </button> */}
        </div>
      );
    }
  }

  getGridBlock(props: DashRemittanceProps, state: DashRemittanceState) {

    if (state.gridRequestState == REQUEST_IN_PROGRESS) {
      return(
        <div class="spinner-border text-primary mt-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      );
    } else if (state.gridRequestState == REQUEST_SUCCESS && state.remittanceGridData.length > 0) {
      return (
        <div class="data-grid dash-remittance__grid-container__grid">
          <div class="data-grid__row header">
            <div class="data-grid__col">
              Actions
            </div>
            <div class="data-grid__col col-short">
              Remittance ID
            </div>
            {
              state.currentUser && state.currentUser.role != 'role_supervisor' ?
                <div class="data-grid__col">
                  Remitter
                </div>
                :
                ''
            }
            <div class="data-grid__col">
              Bank Account
            </div>
            <div class="data-grid__col">
              Amount
            </div>
            <div class="data-grid__col col-long">
              Date of Transaction
            </div>
            <div class="data-grid__col col-long">
              Date Created
            </div>
            <div class="data-grid__col">
              Status
            </div>
            <div class="data-grid__col">
              Area
            </div>
            <div class="data-grid__col col-long">
              Sales Agent
            </div>
            {
              state.currentUser && state.currentUser.role != 'role_supervisor' ?
                <div class="data-grid__col">
                  Auditted
                </div>
                :
                ''
            }
            {
              state.currentUser && state.currentUser.role != 'role_supervisor' ?
                <div class="data-grid__col">
                  OR Number
                </div>
                :
                null
            }
          </div>

          {
            state.remittanceGridData.map((row, index) => {

              return (
                <div class="data-grid__row" id={row.remittanceId} >
                  <div class="data-grid__col">
                    <div class="dropright">
                      <button class="btn btn-outline-primary dropdown-toggle" type="button" id={`actions${index}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Select action
                      </button>
                      { this.getActions(state, row, index) }
                    </div>
                  </div>
                  <div class="data-grid__col col-short">
                    { row.remittanceId }
                  </div>
                  {
                    state.currentUser && state.currentUser.role != 'role_supervisor' ?
                      <div class="data-grid__col">
                        { row.user.firstName } { row.user.lastName }
                      </div>
                      :
                      ''
                  }
                  <div class="data-grid__col">
                    { row.receivingBankAccount }
                  </div>
                  <div class="data-grid__col">
                    ₱ { toDecimal(row.amount) }
                  </div>
                  <div class="data-grid__col col-long">
                    { moment(row.dateOfTransaction).format('MMMM DD, YYYY') }
                  </div>
                  <div class="data-grid__col col-long">
                    { moment(row.dateCreated).format('MMMM DD, YYYY hh:mm:ss A') }
                  </div>
                  <div class="data-grid__col text-capitalize">
                    { row.stage }
                  </div>
                  <div class="data-grid__col">
                    { row.area }
                  </div>
                  <div class="data-grid__col col-long">
                    { row.salesAgent }
                  </div>
                  {
                    state.currentUser && state.currentUser.role != 'role_supervisor' ?
                      <div class="data-grid__col">
                        { row.isCleared ? 'Yes' : 'No' }
                      </div>
                      :
                      ''
                  }
                  {
                    state.currentUser && state.currentUser.role != 'role_supervisor' ?
                      <div class="data-grid__col">
                        { row.receiptNumber }
                      </div>
                      :
                      ''
                  }
                </div>
              );
            })
          }
        </div>
      );
    } else if (state.gridRequestState == REQUEST_SUCCESS && state.remittanceGridData.length < 1) {
      return (
        <div class="data-grid dash-remittance__grid-container__grid">
          <div class="data-grid__row header">
            <div class="data-grid__col">
              Actions
            </div>
            <div class="data-grid__col col-short">
              Remittance ID
            </div>
            {
              state.currentUser && state.currentUser.role != 'role_supervisor' ?
                <div class="data-grid__col">
                  Remitter
                </div>
                :
                ''
            }
            <div class="data-grid__col">
              Bank Account
            </div>
            <div class="data-grid__col">
              Amount
            </div>
            <div class="data-grid__col col-long">
              Date of Transaction
            </div>
            <div class="data-grid__col">
              Status
            </div>
            <div class="data-grid__col">
              Area
            </div>
            <div class="data-grid__col col-long">
              Sales Agent
            </div>
            {
              state.currentUser && state.currentUser.role != 'role_supervisor' ?
                <div class="data-grid__col">
                  Auditted
                </div>
                :
                ''
            }
            {
              state.currentUser && state.currentUser.role != 'role_supervisor' ?
                <div class="data-grid__col">
                  OR Number
                </div>
                :
                null
            }
          </div>

          <div class="data-grid__row py-4 text-center align-items-center justify-content-center">
            No available remittance data.
          </div>

        </div>
      );
    }

  }

  applyFilters = () => {
    // let filters: Map<string, any> = new Map<string, any>();
    let filters = {};
    const fieldList = this.state.filters.fields;

    for (let k in fieldList) {
      if (fieldList[k].value) {
        // filters.set(k, fieldList[k].value);
        filters[k] = fieldList[k].value;
      }
    }

    // when new filters are applied, always set back to first page
    filters['page'] = 1;

    this.setState({
      currentPage: 1
    });

    const store = getStore();
    store.dispatch({
      type: GET_GRID_REMITTANCE,
      payload: {
        filters: filters,
        gridRequestState: REQUEST_IN_PROGRESS
      }
    });

  }

  resetFilters = () => {
    
    const fieldList = this.state.filters.fields;

    for (let k in fieldList) {
      fieldList[k].value = null;
    }

    const form = this.state.filters;
    form.fields = fieldList;

    this.resetStartDate();
    this.resetEndDate();

    form['page'] = 1;

    this.setState({
      filters: form
    });

  }

  onStartDateSelected = (date: string) => {
    this.handleDatepickerValidation(date, 'startDate');
  }

  onEndDateSelected = (date: string) => {
    this.handleDatepickerValidation(date, 'endDate');
  }

  onSupervisorSearchChange = (supervisor: User) => {
    updateFormFieldValue(
      this.form$,
      this.state.filters,
      'username',
      supervisor.username
    )
  }


  resetStartDates = (returned: Function) => {
    this.resetStartDate = returned;
  }

  resetEndDates = (returned: Function) => {
    this.resetEndDate = returned;
  }

  getFilterBlock(state: DashRemittanceState) {
    let form = state.filters;

    if (state.isFilterVisible) {
      return (
        <div class="col-12 mt-3">
          <div class="dash-remittance__filter-row__filters">
            
            <div class="row align-items-end">
              <div class="col-12 col-lg-3">
                <label class="dash-remittance__filter-row__filters__label">Start date</label>
                <DatePicker
                  onDateSelected={this.onStartDateSelected}
                  initialValue={ form.fields['startDate'].value }
                  isValid={form.fields['startDate'].isValid}
                  isPristine={form.fields['startDate'].isPristine}
                  reset={this.resetStartDates}>
                </DatePicker>
              </div>

              <div class="col-12 col-lg-3">
                <label class="dash-remittance__filter-row__filters__label">End date</label>
                <DatePicker
                  onDateSelected={this.onEndDateSelected}
                  initialValue={ form.fields['endDate'].value }
                  isValid={form.fields['endDate'].isValid}
                  isPristine={form.fields['endDate'].isPristine}
                  reset={this.resetEndDates}>
                </DatePicker>
              </div>

              <div class="col-12 col-lg-3 mt-3 mt-md-0">
                <button class="btn btn-outline-dark w-100" onClick={this.resetFilters}>
                  Reset Filters
                </button>
              </div>

              <div class="col-12 col-lg-3 mt-3 mt-md-0">
                <button class="btn btn-secondary w-100" onClick={this.applyFilters}>
                  Apply Filters
                </button>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12 col-lg-3">
                <label class="dash-remittance__filter-row__filters__label">Mininmum Amount</label>
                <input type="number" className={ cx('form-control', 
                  { 'invalid': cxValidateField(form.fields['minAmount']) }
                )}
                value={ form.fields['minAmount'].value }
                placeholder=""
                onChange={ linkform(this.form$, form, 'minAmount') }/>
              </div>

              <div class="col-12 col-lg-3">
                <label class="dash-remittance__filter-row__filters__label">Maximum Amount</label>
                <input type="number" className={ cx('form-control', 
                  { 'invalid': cxValidateField(form.fields['maxAmount']) }
                )}
                value={ form.fields['maxAmount'].value }
                placeholder=""
                onChange={ linkform(this.form$, form, 'maxAmount') }/>
              </div>
              
              <div class="col-12 col-lg-3">
                <label class="dash-remittance__filter-row__filters__label">Status</label>
                <select class="form-control"
                  className={ cx('form-control text-capitalize', 
                    { 'invalid': cxValidateField(form.fields['stage']) }
                  )}
                  placeholder="Status"
                  value={form.fields['stage'].value}
                  onChange={linkform(this.form$, form, 'stage')}>

                  <option class="text-muted" value="" disabled={true} selected>Select Status</option>
                  <option class="text-capitalize" value="">All</option>
                  {
                    ['approved', 'pending', 'archive', 'updated'].map(status => {
                      return <option class="text-capitalize" value={status}>{ status }</option>
                    })
                  }

                </select>
                
              </div>
              
              {
                state.currentUser.role !== 'role_supervisor' ?
                <div class="col-12 col-lg-3">
                  <label class="dash-remittance__filter-row__filters__label">Supervisor</label>
                  <AutocompleteSearch onResultSelected={this.onSupervisorSearchChange} />
                </div> : null
              }

              {
                state.currentUser.role !== 'role_supervisor' ?
                <div class="col-12 col-lg-3">
                  <label class="dash-remittance__filter-row__filters__label">Receiving Bank Account</label>

                  <select
                    className={ cx('form-control', 
                      { 'invalid': cxValidateField(form.fields['receivingBankAccount']) }
                    )}
                    placeholder="Bank Account"
                    value={form.fields['receivingBankAccount'].value}
                    onChange={linkform(this.form$, form, 'receivingBankAccount')}>

                      <option class="text-muted" value="" disabled={true} selected>Select Account</option>
                      {
                        state.allBankAccounts.map(acc => {
                          return <option value={acc.bankAccountName}>{ acc.bankAccountName }</option>
                        })
                      }
                  </select>
                </div> : null
              }

            </div>

            
          </div>
        </div>
      );
    }

  }

  getOverallTotalBlock(state: DashRemittanceState) {
    if (state.overallTotal) {
      return (
        <div class="col-12 col-md-6 col-xl-4 mt-4 mt-xl-0">
          <DataDisplayWidget 
            name="Overall Total"
            data1={ `₱ ${ toDecimal(state.overallTotal.total) }` }
            data1Label="Total Remitted Amount (Overall)"
            data2={ state.overallTotal.count }
            data2Label="Number of Remittances Submitted (Overall)"   
          />
        </div>
      );
    }
  }

  getMonthAmountBlock(state: DashRemittanceState) {
    if (state.currentMonthTotal && state.previousMonthTotal) {
      let change = state.currentMonthTotal.total - state.previousMonthTotal.total;
      let isPos = state.currentMonthTotal.total > state.previousMonthTotal.total;

      return (
        <div class="col-12 col-md-6 col-xl-4 mt-4 mt-xl-0">
          <DataDisplayWidget 
            name="Monthly Amount"
            data1={ `₱ ${ toDecimal(state.currentMonthTotal.total) }` }
            data1Label="Total Remitted Amount (Current Month)"
            data2={ `₱ ${ toDecimal(state.previousMonthTotal.total) }` }
            data2Label="Total Remitted Amount (Previous Month)"
            changeAmount={change}  
            isChangePositive={isPos}  
          />
        </div>
      );
    }
  }

  getMonthCountBlock(state: DashRemittanceState) {
    if (state.currentMonthTotal && state.previousMonthTotal) {
      let change = state.currentMonthTotal.count - state.previousMonthTotal.count;
      let isPos = state.currentMonthTotal.count > state.previousMonthTotal.count;

      return (
        <div class="col-12 col-md-6 col-xl-4 mt-4 mt-xl-0">
          <DataDisplayWidget 
            name="Monthly Count"
            data1={ state.currentMonthTotal.count }
            data1Label="Number of Remittances Submitted (Current Month)"
            data2={ state.previousMonthTotal.count }
            data2Label="Number of Remittances Submitted (Previous Month)"
            changeAmount={change}  
            isChangePositive={isPos}
          />
        </div>
      );
    }
  }

  getNoteSection(state: DashRemittanceState) {
    if (state.currentUser && state.currentUser.role != 'role_supervisor') {
      return (
        <div class="row mb-4">
            {/* broker tips | Note, the mt-4 on this row and the mt-2 on the element help stack */}

          <div class="col-12">
            <div class="anglo__dashboard__tips mt-2">
              Note: 
              <span class="ml-2">
                You are viewing all remittances. This an auditor level feature.
              </span>
            </div>
          </div>
        </div>
      );
    }
  }

  onPageClicked = (page: number) => {
    let filters = {};
    const fieldList = this.state.filters.fields;

    for (let k in fieldList) {
      if (fieldList[k].value) {
        // filters.set(k, fieldList[k].value);
        filters[k] = fieldList[k].value;
      }
    }

    filters['page'] = page;

    const store = getStore();
    store.dispatch({
      type: GET_GRID_REMITTANCE,
      payload: {
        filters: filters
      }
    });

    this.setState({
      currentPage: page
    })
  }

  render(props: DashRemittanceProps, state: DashRemittanceState) {
    return (
      <div class="dash-remittance">
        
        { this.getPortalModal(state) }
        <div class="container-fluid">
          <div class="row">
            {/* cookie crumbs */}

            <div class="col-8 col-lg-4">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/remittance">Remittance</a></li>
                </ol>
              </nav>
            </div>


            <div class="col-4 col-lg-2 offset-lg-6 d-flex justify-content-end align-items-center">
              <a class="anglo__dashboard__faq" href="#">
                FAQs <i class="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>

          { this.getNoteSection(state) }

          <div class="row">
            { this.getOverallTotalBlock(state) }
            { this.getMonthAmountBlock(state) }
            { this.getMonthCountBlock(state) }
          </div>

          <div class="row mt-4">
            {
              state.currentUser && state.currentUser.role !== 'role_supervisor' ?
              <div class="col-3">
                <button class="btn btn-secondary" onClick={this.openReportModal}>
                  Generate Report
                </button>
              </div> : 
              <div class="col-3">
                <button class="btn btn-secondary" onClick={this.openSupervisorReportModal}>
                  Generate Supervisor Report
                </button>
              </div>
            }
            <div class="col d-flex justify-content-end align-items-center">
              <a class="dash-remittance__filter-row__filter-btn mr-3" onClick={ this.onFilterToggleClicked }>
                Filter <i class="fas fa-filter"></i>
              </a>
            </div>

            { this.getFilterBlock(state) }
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <div class="dash-remittance__grid-container mb-2">
                { this.getGridBlock(props, state) }
              </div>
              {
                state.pages ?
                <div class="dash-remittance__pagination mb-3">
                  <button class="btn dash-remittance__pagination__page arrow"
                      disabled={ state.currentPage === 1 } 
                      onClick={() => this.onPageClicked(state.currentPage - 1)}>
                    <i class="fas fa-chevron-left"></i>
                  </button>
                  {
                    state.currentPage > 5 ?
                    <button className={ cx('btn dash-remittance__pagination__page', 
                      { 'active': state.pages[0] === state.currentPage }) }
                      onClick={() => this.onPageClicked(state.pages[0])}>
                      { state.pages[0] }
                    </button> : null
                  }
                  {
                    state.currentPage > 5 ?
                    <span class="mx-2">
                      ...
                    </span> : null
                  }
                  {
                    state.pages ? state.pages.map(page => {
                      if (page > state.currentPage - 5 && page < state.currentPage + 5) {
                        return (
                          <button className={ cx('btn dash-remittance__pagination__page', 
                            { 'active': page === state.currentPage }) }
                            onClick={() => this.onPageClicked(page)}>
                            { page }
                          </button>
                        );
                      }
                    }) : null
                  }
                  {
                    state.currentPage < (state.pages.length) - 5 ?
                    <span class="mx-2">
                      ...
                    </span> : null
                  }
                  {
                    state.currentPage < (state.pages.length) - 5 ?
                    <button className={ cx('btn dash-remittance__pagination__page', 
                      { 'active': state.pages[state.pages.length - 1] === state.currentPage }) }
                      onClick={() => this.onPageClicked(state.pages[state.pages.length - 1])}>
                      { state.pages[state.pages.length - 1] }
                    </button> : null
                  }
                  <button class="btn dash-remittance__pagination__page arrow"
                      disabled={ state.currentPage === state.pages[state.pages.length - 1] } 
                      onClick={() => this.onPageClicked(state.currentPage + 1)}>
                    <i class="fas fa-chevron-right"></i>
                  </button>
                </div> : null
              }
            </div>
          </div>

        </div>

      </div>
    );
  }

}