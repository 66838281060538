import { AuditLog } from '../../../../types/audit-log.model';
import { RequestState } from '../../../../types/request-state.model';
import { TotalCount } from '../../../../types/total-count.model';

const PREFIX = '[Audit]';

export const GET_GRID_AUDIT = `${PREFIX} GET_GRID_AUDIT`;
export const GET_GRID_AUDIT_SUCCESS = `${PREFIX} GET_GRID_AUDIT_SUCCESS`;
export const GET_GRID_AUDIT_FAIL = `${PREFIX} GET_GRID_AUDIT_FAIL`;

export interface AuditState {
  auditGridData?: AuditLog[];
  gridRequestState?: RequestState;
}

export interface AuditAction {
  type: string,
  payload?: AuditState
}