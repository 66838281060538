import { h, Component } from 'preact';
import linkState from 'linkstate';
import cx from 'classnames';

import { ModalProps, Modal } from '../common/modal';
import { Remittance } from '../../../../types/remittance.model';
import { Subject, Subscription } from 'rxjs';
import getStore, { appState$ } from '../../store';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { RequestState, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../types/request-state.model';
import { FieldList, Field, Form } from '../../../../types/form.model';
import { validateForm, cxValidateField } from '../../../../types/validator.model';
import * as Validators from '../../../../types/validator.model';
import linkform from '../../../utils/linkform';
import { CREATE_BANK_ACCOUNT, UserAction, GET_BANKS, CREATE_USER, CHANGE_PASSWORD, UPDATE_SUPERVISOR_STOCK } from '../../reducers/user/user.actions';
import { User } from '../../../../types/user.model';
import { WithdrawalBrand } from '../../../../types/withdrawal.model';
import { PACKS_PER_REAM, REAMS_PER_CASE } from '../../../../constants/api';
import { toFixed } from '../../../utils/decimal';
import { backgroundSize } from 'html2canvas/dist/types/css/property-descriptors/background-size';
import { BrandPrice } from '../../../../types/brand-price.model';
import { GET_PRICES, WithdrawalAction } from '../../reducers/withdrawals/withdrawals.actions';
import { showToast, TOAST_TYPE_ERROR } from '../../../utils/toast.service';

export interface AdjustStockModalProps extends ModalProps {
  selectedUser: User;
}

interface AdjustStockModalState  {
  brandPrices?: BrandPrice[];

  submitStatus?: RequestState;
  form?: Form;
  currentlySelectedBrandIndex?: number;

  isEditing?: boolean;
  isAdding?: boolean;
  brands: WithdrawalBrand[];
}

export class AdjustStockModal extends Modal<AdjustStockModalProps, AdjustStockModalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props: AdjustStockModalProps) {
    super(props);

    this.setState({
      brands: props.selectedUser.supervisor.currentStock
    });

    this.createForm();
  }

  createForm() {
    let fields: FieldList = {
      'name': new Field([Validators.required], null, ''),
      'numOfCases': new Field([] , null, ''),
      'numOfReams': new Field([] , null, ''),
      'numOfPacks': new Field([] , null, ''),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      form: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['form'] = form;
      this.setState(state);
    });
  }

  componentDidMount() {
    super.componentDidMount();

    const user$ = appState$.pipe(
      map(app => app.user)
    );

    this.subs.push(
      user$.pipe(
        map(user => user.postPutRequestState),
        distinctUntilChanged()
      ).subscribe(reqState => {
        this.setState({
          submitStatus: reqState
        });
        
        if (reqState === REQUEST_SUCCESS) {
          this.doModalClose();
        }
      })
    );

    const withdrawal$ = appState$.pipe(
      map(appState => appState.withdrawal)
    );

    this.subs.push(
      withdrawal$.pipe(
        map(withdrawal => withdrawal.brandPrices),
        distinctUntilChanged()
      ).subscribe(prices => {
        this.setState({
          brandPrices: prices
        });
      })
    );

    this.initialStoreDispatches();
  }

  initialStoreDispatches() {
    const store = getStore();
    
    store.dispatch({
      type: GET_PRICES
    } as WithdrawalAction);
  }

  componentWillUnmount() {
    this.subs.forEach(s => s.unsubscribe());
  }

  doModalClose = () => {
    this.props.close();
  }

  onSubmit = () => {

    const store = getStore();
    store.dispatch({
      type: UPDATE_SUPERVISOR_STOCK,
      payload: {
        stocksToUpdate: this.state.brands,
        supervisorToUpdate: this.props.selectedUser.supervisor.supervisorId
      }
    });
  }

  onAddBrand = () => {
    this.setState({
      isEditing: false,
      isAdding: true
    });
  }

  onEditBrand = (brand: WithdrawalBrand, index: number) => {
    let form = this.state.form;

    form.fields['name'].value = brand.name;
    form.fields['numOfCases'].value = brand.numOfCases ? brand.numOfCases : 0;
    form.fields['numOfReams'].value = brand.numOfReams ? brand.numOfReams : 0;
    form.fields['numOfPacks'].value = brand.numOfPacks ? brand.numOfPacks : 0;
    
    this.setState({
      isEditing: true,
      isAdding: false,
      currentlySelectedBrandIndex: index
    });
  }

  onRemoveBrand = (index: number) => {
    let brands = this.state.brands;
    brands.splice(index, 1);

    this.forceUpdate();
  }

  onCompleteAddBrand = () => {
    const form = this.state.form;
    validateForm(form);

    if (!form.isValid) {
      return;
    }

    const selectedBrand = this.state.brandPrices.find(member => {
      return member.brandName === form.fields['name'].value;
    });

    const brand: WithdrawalBrand = {
      name: form.fields['name'].value,
      brandPriceId: selectedBrand.brandId,
      numOfCases: Number.parseInt(form.fields['numOfCases'].value),
      numOfReams: Number.parseInt(form.fields['numOfReams'].value),
      numOfPacks: Number.parseInt(form.fields['numOfPacks'].value),
    };

    const found = this.state.brands.find((brandMember) => {
      return brand.name === brandMember.name;
    });

    if (found) {
      showToast({
        title: 'Failed to add brand',
        message: 'This brand already exists in current group.',
        duration: 3000,
        type: TOAST_TYPE_ERROR
      });
    } else {
      this.state.brands.push(brand);
    }

    this.setState({
      isAdding: false
    });

    // this.forceUpdate();
  }

  onCompleteEdit = () => {
    const form = this.state.form;
    validateForm(form);

    if (!form.isValid) {
      return;
    }

    const selectedBrand = this.state.brandPrices.find(member => {
      return member.brandName === form.fields['name'].value;
    });

    const brand: WithdrawalBrand = {
      name: form.fields['name'].value,
      brandPriceId: selectedBrand.brandId,
      numOfCases: Number.parseInt(form.fields['numOfCases'].value),
      numOfReams: Number.parseInt(form.fields['numOfReams'].value),
      numOfPacks: Number.parseInt(form.fields['numOfPacks'].value),
    };

    const currentBrandIndex = this.state.currentlySelectedBrandIndex;
    this.state.brands[currentBrandIndex] = brand;

    this.setState({
      isEditing: false,
      currentlySelectedBrandIndex: -1
    });
  }

  getSpinnerBlock() {
    return (
      <div class="col offset-md-9 d-flex justify-content-end">
        <div class="spinner-border text-primary mt-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );  
  }

  getBrandCard(brand: WithdrawalBrand, index: number, length: number) {
        return (
      <div class="col-12 col-lg-6 mb-2">
        <div className={cx(
          'container-fluid create-withdrawal-modal__form__brand-card py-2',
          {
            'editing': index === this.state.currentlySelectedBrandIndex
          }
        )}>
          <div class="row mt-2">
            <div class="col-12">
              <div class="create-withdrawal-modal__form__brand-card__name">
                { brand.name }
              </div>
            </div>
            <div class="col-4">
              <div class="create-withdrawal-modal__form__brand-card__label">
                Cases
              </div>
              <div class="create-withdrawal-modal__form__brand-card__value">
                { brand.numOfCases ? brand.numOfCases : 0 }
              </div>
            </div>

            <div class="col-4">
              <div class="create-withdrawal-modal__form__brand-card__label">
                Reams
              </div>
              <div class="create-withdrawal-modal__form__brand-card__value">
                { brand.numOfReams ? brand.numOfReams : 0 }
              </div>
            </div>

            <div class="col-4">
              <div class="create-withdrawal-modal__form__brand-card__label">
                Packs
              </div>
              <div class="create-withdrawal-modal__form__brand-card__value">
                { brand.numOfPacks ? brand.numOfPacks : 0 }
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-12 col-lg-6">
              <button class="btn btn-outline-dark w-100"
                onClick={() => this.onEditBrand(brand, index)}>
                Edit
              </button>
            </div>
            <div class="col-12 col-lg-6 mt-2 mt-lg-0">
              <button class="btn btn-outline-dark w-100"
                onClick={() => this.onRemoveBrand(index)}>
                Remove
              </button>
            </div>
          </div>
        </div>
          
      </div>
    )
  }

  getStocksBlock(props: AdjustStockModalProps, state: AdjustStockModalState) {
    let brands = state.brands;

    return (
      <div class="row">
        { brands.map((member, index) =>  this.getBrandCard(member, index, brands.length)) }
      </div>
    );
  }

  getFormBlock(props: AdjustStockModalProps, state: AdjustStockModalState) {
    if (state.isAdding || state.isEditing) {
      let form = state.form;

      return (
        <div class="row create-withdrawal-modal__form__brand py-3">
          <div class="col-12">
            <label class="anglo-remittance__submit-form__label">Brand Name*</label>

            <select
              className={ cx('form-control', 
                { 'invalid': cxValidateField(form.fields['name']) }
              )}
              placeholder="Brand Name"
              value={form.fields['name'].value}
              onChange={linkform(this.form$, form, 'name')}>

                <option class="text-muted" value="" disabled={true} selected>Select Brand</option>
                {
                  state.brandPrices.map(price => {
                  return <option value={price.brandName}>{ price.brandName } - ₱ { price.pricePerReam }</option>
                  })
                }
            </select>
          </div>
          <div class="col-12 col-lg-4 mt-3">
            <label class="anglo-remittance__submit-form__label">Number of Cases</label>
            <input class="form-control" type="text"
              onInput={linkform(this.form$, form, 'numOfCases')}
              value={form.fields['numOfCases'].value}
              placeholder="# of Cases"/>
          </div>

          <div class="col-12 col-lg-4 mt-3">
            <label class="anglo-remittance__submit-form__label">Number of Reams</label>
            <input class="form-control" type="text"
              onInput={linkform(this.form$, form, 'numOfReams')}
              value={form.fields['numOfReams'].value}
              placeholder="# of Reams"/>
          </div>

          <div class="col-12 col-lg-4 mt-3">
            <label class="anglo-remittance__submit-form__label">Number of Packs</label>
            <input class="form-control" type="text"
              onInput={linkform(this.form$, form, 'numOfPacks')}
              value={form.fields['numOfPacks'].value}
              placeholder="# of Packs"/>
          </div>

          <div class="col-6 col-lg-3 offset-6 offset-lg-9 mt-3">
            { this.getFormActionRow(state) }
          </div>
        </div>
      );
    }
  }

  getFormActionRow(state: AdjustStockModalState) {
    if (!state.isAdding && state.isEditing) {
      return (
        <button class="btn btn-outline-primary w-100"
          onClick={this.onCompleteEdit}>
          Edit Brand
        </button>
      );
    } else {
      return (
        <button class="btn btn-outline-primary w-100"
          onClick={this.onCompleteAddBrand}>
          Add Brand
        </button>
      );
    }
  }

  getActionsRow(props: AdjustStockModalProps, state: AdjustStockModalState) {
    if (state.submitStatus === REQUEST_IN_PROGRESS) {
      return this.getSpinnerBlock();
    } else {
      return (
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-3 offset-lg-6">
              <button class="btn btn-primary w-100"
                onClick={this.onAddBrand}>
                Add New Brand
              </button>
            </div>
            <div class="col-12 col-lg-3">
              <button class="btn btn-secondary w-100"
                onClick={this.onSubmit}>
                Update Stocks
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  render(props: AdjustStockModalProps, state: AdjustStockModalState) {
    let form = state.form;

    return (
      <div class="container anglo-modal create-disposal-modal"
        ref={ node => this.refNode = node }>

          <div class="row anglo-modal__head align-items-center">
            <div class="col-9 col-md-10">
              <h3 class="mb-0">
                Create Disposal
              </h3>
            </div>
            <div class="col-3 col-md-2">
              <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>

          <div class="row anglo-modal__body py-3">
            <div class="col-12 create-withdrawal-modal__body pb-3">
              { this.getStocksBlock(props, state) }   
              { this.getFormBlock(props, state) }   
            </div>    
          </div>

          <div class="row vehicle-card__actions mt-3">
            { this.getActionsRow(props, state) }
          </div>
      </div>
    );
  }
}