import { h, Component } from 'preact';
import cx from 'classnames';

import { ModalProps, Modal } from '../../common/modal';
import { User } from '../../../../../types/user.model';
import { Withdrawal, WithdrawalBrand, WITHDRAWAL_STATUS_REEDIT, WITHDRAWAL_STATUS_PENDING, WITHDRAWAL_STATUS_REEDIT_ACCEPTED, WITHDRAWAL_STATUS_APPROVED, WITHDRAWAL_STAGE_READY_FOR_PICKUP, WITHDRAWAL_STAGE_PICKED_UP, WITHDRAWAL_STAGE_SHIPMENT_RECEIVED, WITHDRAWAL_STAGE_IN_TRANSIT, WITHDRAWAL_STAGE_AWAITING_SHIPMENT, WITHDRAWAL_STAGE_STOCK_PREPARING } from '../../../../../types/withdrawal.model';
import moment from 'moment-timezone';

import { Formats, REAMS_PER_CASE, PACKS_PER_REAM } from '../../../../../constants/api';
import { toFixed } from '../../../../utils/decimal';
import getStore, { appState$ } from '../../../store';
import { REEDIT_ACCEPT_WITHDRAWAL, APPROVE_WITHDRAWAL, UPDATE_WITHDRAWAL } from '../../../reducers/withdrawals/withdrawals.actions';
import { ROLE_MANAGEMENT } from '../../../../../constants/role';
import { MODAL_CHANGE_WITHDRAWAL_REEDIT, MODAL_CHANGE_WITHDRAWAL_STAGE } from '../dash-withdrawal.component';

import { Subject, Subscription } from 'rxjs';
import { Form, FieldList, Field, inputToField } from '../../../../../types/form.model';
import * as Validators from '../../../../../types/validator.model';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { REQUEST_SUCCESS, REQUEST_IN_PROGRESS } from '../../../../../types/request-state.model';
import linkform, { updateFormFieldValue } from '../../../../utils/linkform';
import { DatePicker } from '../../common/date-picker';

export interface ChangeStageModalProps extends ModalProps {
  currentUser?: User;
  withdrawal?: Withdrawal;
}

interface ChangeStageModalState  {
  isLoading?: boolean;
  form?: Form;
}

export class ChangeStageModal extends Modal<ChangeStageModalProps, ChangeStageModalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor() {
    super();

    this.createForm();
  }
  
  doModalClose = () => {
    this.props.close();
  }

  pickedUpWithdrawal: Validators.Validator = (field: Field, form: Form) => {
    if (form.fields['selectedStage'].value === WITHDRAWAL_STAGE_PICKED_UP ||
      form.fields['selectedStage'].value === WITHDRAWAL_STAGE_SHIPMENT_RECEIVED) {
        return (field.value ? true : false);
    } 
  }

  inTransitWithdrawal: Validators.Validator = (field: Field, form: Form) => {
    if (form.fields['selectedStage'].value === WITHDRAWAL_STAGE_IN_TRANSIT) {
        return (field.value ? true : false);
    } 
  }

  createForm() {
    let fields: FieldList = {
      'selectedStage': new Field([Validators.required], null, ''),
      'dateOfWithdrawal': new Field([] , [this.pickedUpWithdrawal], ''),
      'dateOfShipment': new Field([] , [this.inTransitWithdrawal], '')
    };

    this.form$ = new Subject<Form>();
    this.setState({
      form: new Form(fields)
    });

    this.form$.subscribe((form) => {
      Validators.validateForm(form);

      let state = {};
      state['form'] = form;
      this.setState(state);
    });
  }

  componentDidMount() {

    const withdrawal$ = appState$.pipe(
      map(appState => appState.withdrawal)
    );

    this.subs.push(
      withdrawal$.pipe(
        map(withdrawal => withdrawal.postPutRequestState),
        distinctUntilChanged()
      ).subscribe((postPutRequestState) => {
        if (postPutRequestState === REQUEST_SUCCESS) {
          this.props.close();
        } else if (postPutRequestState === REQUEST_IN_PROGRESS) {
          this.setState({
            isLoading: true
          });
        }
      })
    );

  }

  getDataBlock(props: ChangeStageModalProps) {
    return (
      <div class="row">
        <div class="col-12 col-lg-3 mb-3">
          <h4 class="view-withdrawal__data__label">
            Status
          </h4>
          <b class="view-withdrawal__data__value">
            {props.withdrawal.status}
          </b>
        </div>

        <div class="col-12 col-lg-3 mb-3">
          <h4 class="view-withdrawal__data__label">
            Stage
          </h4>
          <b class="view-withdrawal__data__value">
            {props.withdrawal.stage}
          </b>
        </div>

        <div class="col-12 col-lg-3 mb-3">
          <h4 class="view-withdrawal__data__label">
            Total Value
          </h4>
          <b class="view-withdrawal__data__value">
            ₱ {toFixed(props.withdrawal.totalAmount)}
          </b>
        </div>

        <div class="col-12 col-lg-3 mb-3">
          <h4 class="view-withdrawal__data__label">
            Date Created
          </h4>
          <b class="view-withdrawal__data__value">
            {props.withdrawal.dateCreated ? moment(props.withdrawal.dateCreated).format(Formats.dateWorded) : 'Not Available'}
          </b>
        </div>

        <div class="col-12 col-lg-3 mb-3">
          <h4 class="view-withdrawal__data__label">
            Date Withdrawn
          </h4>
          <b class="view-withdrawal__data__value">
            {props.withdrawal.dateOfWithdrawal ? moment(props.withdrawal.dateOfWithdrawal).format(Formats.dateWorded) : 'Not Available'}
          </b>
        </div>
      </div>
    );
  }

  onChangeStage = () => {
    let form = this.state.form;

    Validators.validateForm(form);

    for (let k in form.fields) {
      if (!form.fields[k].isValid) {
        console.log(k);
      }
    }

    if (!form.isValid) {
      
      return;
    }

    const store = getStore();

    store.dispatch({
      type: UPDATE_WITHDRAWAL,
      payload: {
        withdrawalId: this.props.withdrawal.withdrawalId,
        currentStage: this.props.withdrawal.stage,
        targetStage: this.state.form.fields['selectedStage'].value,
        supervisor: this.props.withdrawal.supervisor,
        dateOfWithdrawal: this.state.form.fields['dateOfWithdrawal'].value,
        dateOfShipment: this.state.form.fields['dateOfShipment'].value
      }
    });
  }

  onDateSelected = (date: string) => {
    updateFormFieldValue(this.form$, this.state.form, 'dateOfWithdrawal', date)
  }

  onDateShipmentSelected = (date: string) => {
    updateFormFieldValue(this.form$, this.state.form, 'dateOfShipment', date)
  }

  render(props: ChangeStageModalProps, state: ChangeStageModalState) {
    return (
      <div class="container anglo-modal"
        ref={ node => this.refNode = node }>

          <div class="row anglo-modal__head align-items-center">
            <div class="col-9 col-md-10">
              <h3 class="mb-0">
                Withdrawal Details
                <small class="anglo-modal__head__small ml-2">
                  {props.withdrawal.withdrawalId}
                </small>
              </h3>
            </div>
            <div class="col-3 col-md-2">
              <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>

          <div class="row anglo-modal__body py-3">
            <div class="col-12">
              { this.getDataBlock(props) }
              <hr/>
              <div class="row">
                <div class="col-12">
                  <label class="anglo-remittance__submit-form__label">New Stage</label>
                  <select className={ cx( "form-control",
                    { 'invalid': Validators.cxValidateField(state.form.fields['selectedStage']) }
                  )}
                    value={state.form.fields['selectedStage'].value}
                    onChange={ linkform(this.form$, state.form, 'selectedStage') }>
                    <option value={WITHDRAWAL_STAGE_STOCK_PREPARING}>Stock Preparing</option>
                    <option value={WITHDRAWAL_STAGE_READY_FOR_PICKUP}>Walk-in | Ready for pickup</option>
                    <option value={WITHDRAWAL_STAGE_PICKED_UP}>Walk-in | Picked up</option>

                    <option value={WITHDRAWAL_STAGE_AWAITING_SHIPMENT}>Shipment | Awaiting Shipment</option>
                    <option value={WITHDRAWAL_STAGE_IN_TRANSIT}>Shipment | In Transit</option>
                    <option value={WITHDRAWAL_STAGE_SHIPMENT_RECEIVED}>Shipment | Shipment Received</option>
                  </select>
                </div>
                {
                  state.form.fields['selectedStage'].value === WITHDRAWAL_STAGE_PICKED_UP ||
                  state.form.fields['selectedStage'].value === WITHDRAWAL_STAGE_SHIPMENT_RECEIVED ?
                  <div class="col-12 mt-2">
                    <label class="anglo-remittance__submit-form__label">Date of Withdrawal/Shipment Received</label>
                    <DatePicker
                      onDateSelected={this.onDateSelected}
                      isValid={Validators.cxValidateField(state.form.fields['dateOfWithdrawal'])}
                      isPristine={state.form.fields['dateOfWithdrawal'].isPristine}>
                    </DatePicker>
                  </div> : null
                }

                {
                  state.form.fields['selectedStage'].value === WITHDRAWAL_STAGE_IN_TRANSIT ?
                  <div class="col-12 mt-2">
                    <label class="anglo-remittance__submit-form__label">Date of Shipment</label>
                    <DatePicker
                      onDateSelected={this.onDateShipmentSelected}
                      isValid={Validators.cxValidateField(state.form.fields['dateOfShipment'])}
                      isPristine={state.form.fields['dateOfShipment'].isPristine}>
                    </DatePicker>
                  </div> : null
                }
              </div>      
            </div>    
          </div>

          <div class="row vehicle-card__actions mt-3">
            <div class="col-12">
              <div class="row">
                <div class="col-12 col-lg-3 offset-lg-9">
                  
                  {
                    state.isLoading ?
                    <div class="">
                      <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div> 
                    </div> : 
                    <button class="btn btn-primary w-100"
                      onClick={this.onChangeStage}>
                      Update Stage
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }

}