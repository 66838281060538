import { h, Component } from "preact";
import cx from "classnames";
import { Subscription, Subject } from "rxjs";
import moment from "moment-timezone";
import { map, filter, distinctUntilChanged } from "rxjs/operators";

import { ModalProps, Modal } from "../../common/modal";
import { Form, FieldList, Field } from "../../../../../types/form.model";
import * as Validators from "../../../../../types/validator.model";
import {
  validateForm,
  cxValidateField,
} from "../../../../../types/validator.model";
import linkform, { updateFormFieldValue } from "../../../../utils/linkform";
import getStore, { appState$ } from "../../../store";

import {
  PurchaseDocument,
  ReceivingReport,
} from "../../../../../types/purchase-document.model";
import {
  REQUEST_IN_PROGRESS,
  REQUEST_SUCCESS,
} from "../../../../../types/request-state.model";
import { Item, ItemPiece } from "../../../../../types/item.model";
import { ItemSearch } from "../../common/item-search.component";
import { toFixed } from "../../../../utils/decimal";
import { getSpinnerBlock } from "../../common/spinner-block";
import {
  CREATE_ITEM,
  CREATE_PURCHASE_DOCUMENT,
  CREATE_SUPPLIER,
  SIGN_PURCHASE_DOCUMENT,
  UPDATE_PURCHASE_DOCUMENT,
} from "../../../reducers/motorpool/motorpool.actions";
import { Supplier } from "../../../../../types/supplier.model";
import { SupplierSearch } from "../../common/supplier-search.component";

interface CreateItemProps extends ModalProps {}

interface CreateItemState {
  form?: Form;
  isPostInProgress?: boolean;
}

export class CreateItemModal extends Modal<CreateItemProps, CreateItemState> {
  form$: Subject<Form>;
  subs: Subscription[] = [];

  constructor(props: CreateItemProps) {
    super(props);

    this.createForm(props);
  }

  createForm(props: CreateItemProps) {
    let fields: FieldList = {
      name: new Field([Validators.required], null, null),
      itemCode: new Field([], null, null),
      supplierId: new Field([Validators.required], null, null),
      unitPrice: new Field([Validators.required], null, null),
      amount: new Field([], null, null),
      description: new Field([Validators.required], null, null),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      form: new Form(fields),
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state["form"] = form;
      this.setState(state);
    });
  }

  componentDidMount(): void {
    super.componentDidMount();

    const motorpool$ = appState$.pipe(map((appState) => appState.motorpool));

    this.subs.push(
      motorpool$
        .pipe(
          map((motorpool) => motorpool.postPutRequestState),
          distinctUntilChanged()
        )
        .subscribe((postPutRequestState) => {
          if (postPutRequestState === REQUEST_SUCCESS) {
            this.setState({
              isPostInProgress: false,
            });
            this.props.close();
          } else if (postPutRequestState === REQUEST_IN_PROGRESS) {
            this.setState({
              isPostInProgress: true,
            });
          }
        })
    );
  }

  doModalClose = () => {
    if (this.props.close) {
      this.props.close();
    }
  };

  onSupplierSelected = (a: Supplier) => {
    let form = this.state.form;
    form.fields["supplierId"].value = a.supplierId;

    this.setState({
      form: form,
    });
  };

  onSubmit = () => {
    const form = this.state.form;
    validateForm(form);

    if (!form.isValid) {
      return;
    }

    let item: Item = {
      name: form.fields["name"].value,
      itemCode: form.fields["itemCode"].value,
      supplierId: form.fields["supplierId"].value,
      unitPrice: form.fields["unitPrice"].value,
      amount: form.fields["amount"].value,
      description: form.fields["description"].value,
    };

    const store = getStore();
    store.dispatch({
      type: CREATE_ITEM,
      payload: {
        itemToCreate: item,
      },
    });
  };

  getFormBlock(props: CreateItemProps, state: CreateItemState) {
    const form = this.state.form;

    return (
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div class="col-12 col-lg-6 mt-3">
              <label class="anglo-remittance__submit-form__label">Name*</label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["name"]),
                })}
                type="text"
                value={form.fields["name"].value}
                onChange={linkform(this.form$, form, "name")}
                placeholder="Name"
              />
            </div>
            <div class="col-12 col-lg-6 mt-3">
              <label class="anglo-remittance__submit-form__label">
                Item Code
              </label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["itemCode"]),
                })}
                type="text"
                value={form.fields["itemCode"].value}
                onChange={linkform(this.form$, form, "itemCode")}
                placeholder="Item Code"
              />
            </div>
            <hr />
            {/* supplier */}
            <div class="col-12 mt-3">
              <SupplierSearch onResultSelected={this.onSupplierSelected} />
            </div>
            {form.fields["supplierId"].value ? (
              <div class="col-12 mt-3">
                <label class="anglo-remittance__submit-form__label">
                  Selected Supplier*
                </label>
                <input
                  className={cx("form-control", {
                    invalid: cxValidateField(form.fields["supplierId"]),
                  })}
                  type="text"
                  disabled={true}
                  value={form.fields["supplierId"].value}
                  onChange={linkform(this.form$, form, "supplierId")}
                  placeholder="Selected Supplier"
                />
              </div>
            ) : null}
            <hr />
            <div class="col-12 col-lg-6 mt-3">
              <label class="anglo-remittance__submit-form__label">
                Unit Price*
              </label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["unitPrice"]),
                })}
                type="text"
                value={form.fields["unitPrice"].value}
                onChange={linkform(this.form$, form, "unitPrice")}
                placeholder="Unit Price"
              />
            </div>
            <div class="col-12 col-lg-6 mt-3">
              <label class="anglo-remittance__submit-form__label">Amount</label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["amount"]),
                })}
                type="text"
                value={form.fields["amount"].value}
                onChange={linkform(this.form$, form, "amount")}
                placeholder="Amount"
              />
            </div>
            <div class="col-12 col-lg-6 mt-3">
              <label class="anglo-remittance__submit-form__label">
                Description
              </label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["description"]),
                })}
                type="text"
                value={form.fields["description"].value}
                onChange={linkform(this.form$, form, "description")}
                placeholder="Description"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  getActionsRow(props: CreateItemProps, state: CreateItemState) {
    if (state.isPostInProgress) {
      return getSpinnerBlock(state.isPostInProgress);
    } else {
      return (
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-3 offset-lg-9">
              <button
                class="btn btn-primary w-100"
                onClick={this.onSubmit}
                disabled={!state.form.isValid}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  render(props: CreateItemProps, state: CreateItemState) {
    return (
      <div
        class="container anglo-modal create-pd-modal"
        ref={(node) => (this.refNode = node)}
      >
        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">Create Item</h3>
          </div>
          <div class="col-3 col-md-2">
            <button
              class="btn btn-outline-light w-100"
              onClick={this.doModalClose}
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>

        <div class="row anglo-modal__body py-3">
          <div class="col-12 create-withdrawal-modal__body pb-3">
            {this.getFormBlock(props, state)}
          </div>
        </div>

        <div class="row vehicle-card__actions">
          {this.getActionsRow(props, state)}
        </div>
      </div>
    );
  }
}
