import { h, Component } from "preact";
import cx from "classnames";
import { Subscription, Subject } from "rxjs";
import moment from "moment-timezone";
import { map, filter, distinctUntilChanged } from "rxjs/operators";

import { ModalProps, Modal } from "../../common/modal";
import { Form, FieldList, Field } from "../../../../../types/form.model";
import * as Validators from "../../../../../types/validator.model";
import {
  validateForm,
  cxValidateField,
} from "../../../../../types/validator.model";
import linkform, { updateFormFieldValue } from "../../../../utils/linkform";
import getStore, { appState$ } from "../../../store";

import {
  PurchaseDocument,
  ReceivingReport,
} from "../../../../../types/purchase-document.model";
import {
  REQUEST_IN_PROGRESS,
  REQUEST_SUCCESS,
} from "../../../../../types/request-state.model";
import { Item, ItemPiece } from "../../../../../types/item.model";
import { ItemSearch } from "../../common/item-search.component";
import { toFixed } from "../../../../utils/decimal";
import { getSpinnerBlock } from "../../common/spinner-block";
import {
  CREATE_PURCHASE_DOCUMENT,
  CREATE_SUPPLIER,
  SIGN_PURCHASE_DOCUMENT,
  UPDATE_PURCHASE_DOCUMENT,
  UPDATE_V2_JOB_ORDER,
} from "../../../reducers/motorpool/motorpool.actions";
import { Supplier } from "../../../../../types/supplier.model";
import { EDIT_REQUEST_STATUSES } from "../../../../../constants/edit-requests";
import {
  JO_STATUSES,
  JO_TYPES,
  V2_JobOrder,
} from "../../../../../types/job-order.model";

interface UdpateJOProps extends ModalProps {
  jo: V2_JobOrder;
  isEditingDisabled?: boolean;
}

interface UdpateJOState {
  form?: Form;
  isPostInProgress?: boolean;
}

export class UdpateJOModal extends Modal<UdpateJOProps, UdpateJOState> {
  form$: Subject<Form>;
  subs: Subscription[] = [];

  constructor(props: UdpateJOProps) {
    super(props);

    this.createForm(props);
    const form = this.state.form;
    validateForm(form);
  }

  createForm(props: UdpateJOProps) {
    let fields: FieldList = {
      type: new Field([Validators.required], null, props.jo.type),
      status: new Field([Validators.required], null, props.jo.status),
      vehicleId: new Field([Validators.required], null, props.jo.vehicleId),
      itemWithdrawalId: new Field([], null, props.jo.itemWithdrawalId),
      description: new Field([Validators.required], null, props.jo.description),
      assignedPerson: new Field([], null, props.jo.assignedPerson),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      form: new Form(fields),
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state["form"] = form;
      this.setState(state);
    });
  }

  componentDidMount(): void {
    super.componentDidMount();

    const motorpool$ = appState$.pipe(map((appState) => appState.motorpool));

    this.subs.push(
      motorpool$
        .pipe(
          map((motorpool) => motorpool.postPutRequestState),
          distinctUntilChanged()
        )
        .subscribe((postPutRequestState) => {
          if (postPutRequestState === REQUEST_SUCCESS) {
            console.log("huh");
            this.setState({
              isPostInProgress: false,
            });
            this.props.close();
          } else if (postPutRequestState === REQUEST_IN_PROGRESS) {
            this.setState({
              isPostInProgress: true,
            });
          }
        })
    );
  }

  doModalClose = () => {
    if (this.props.close) {
      this.props.close();
    }
  };

  onSubmit = () => {
    const form = this.state.form;
    validateForm(form);

    if (!form.isValid) {
      return;
    }

    let jo: V2_JobOrder = {
      itemWithdrawalId: form.fields["itemWithdrawalId"].value,
      vehicleId: form.fields["vehicleId"].value,
      type: form.fields["type"].value,
      description: form.fields["description"].value,
      assignedPerson: form.fields["assignedPerson"].value,
      jobOrderId: this.props.jo.jobOrderId,
    };

    const store = getStore();
    store.dispatch({
      type: UPDATE_V2_JOB_ORDER,
      payload: {
        joToUpdate: jo,
      },
    });
  };

  getFormBlock(props: UdpateJOProps, state: UdpateJOState) {
    const form = this.state.form;

    return (
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div class="col-12 col-lg-4 mt-3">
              <label class="anglo-remittance__submit-form__label">
                Vehicle ID
              </label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["vehicleId"]),
                })}
                disabled={props.isEditingDisabled}
                type="text"
                value={form.fields["vehicleId"].value}
                onChange={linkform(this.form$, form, "vehicleId")}
                placeholder="Vehicle ID"
              />
            </div>
            <div class="col-12 col-lg-4 mt-3">
              <label class="anglo-remittance__submit-form__label">
                Status*
              </label>
              <select
                className={cx("form-control")}
                placeholder="Status"
                value={form.fields["status"].value}
                onChange={linkform(this.form$, form, "status")}
              >
                <option class="text-muted" value="" disabled={true} selected>
                  Select Status
                </option>
                {JO_STATUSES.map((acc) => {
                  return <option value={acc}>{acc}</option>;
                })}
              </select>
            </div>
            <div class="col-12 col-lg-4 mt-3">
              <label class="anglo-remittance__submit-form__label">
                Job Order Type*
              </label>
              <select
                className={cx("form-control")}
                placeholder="JO Type"
                value={form.fields["type"].value}
                onChange={linkform(this.form$, form, "type")}
              >
                <option class="text-muted" value="" disabled={true} selected>
                  Select Job Order Type
                </option>
                {JO_TYPES.map((acc) => {
                  return <option value={acc}>{acc}</option>;
                })}
              </select>
            </div>
            <div class="col-12 col-lg-6 mt-3">
              <label class="anglo-remittance__submit-form__label">
                Description
              </label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["description"]),
                })}
                type="text"
                disabled={props.isEditingDisabled}
                value={form.fields["description"].value}
                onChange={linkform(this.form$, form, "description")}
                placeholder="Description"
              />
            </div>
            <div class="col-12 col-lg-6 mt-3">
              <label class="anglo-remittance__submit-form__label">
                Assigned Person
              </label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["assignedPerson"]),
                })}
                type="text"
                value={form.fields["assignedPerson"].value}
                onChange={linkform(this.form$, form, "assignedPerson")}
                placeholder="Assigned Person"
              />
            </div>
            <div class="col-12 mt-3">
              <label class="anglo-remittance__submit-form__label">
                Item Withdrawal ID
              </label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["itemWithdrawalId"]),
                })}
                type="text"
                disabled={props.isEditingDisabled}
                value={form.fields["itemWithdrawalId"].value}
                onChange={linkform(this.form$, form, "itemWithdrawalId")}
                placeholder="Item Withdrawal ID"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  getActionsRow(props: UdpateJOProps, state: UdpateJOState) {
    if (state.isPostInProgress) {
      return getSpinnerBlock(state.isPostInProgress);
    } else {
      return (
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-3 offset-lg-9">
              <button
                class="btn btn-primary w-100"
                onClick={this.onSubmit}
                disabled={!state.form.isValid}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  render(props: UdpateJOProps, state: UdpateJOState) {
    return (
      <div
        class="container anglo-modal create-pd-modal"
        ref={(node) => (this.refNode = node)}
      >
        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">Edit Job Order | {props.jo.jobOrderId}</h3>
          </div>
          <div class="col-3 col-md-2">
            <button
              class="btn btn-outline-light w-100"
              onClick={this.doModalClose}
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>

        <div class="row anglo-modal__body py-3">
          <div class="col-12 create-withdrawal-modal__body pb-3">
            {this.getFormBlock(props, state)}
          </div>
        </div>

        <div class="row vehicle-card__actions">
          {this.getActionsRow(props, state)}
        </div>
      </div>
    );
  }
}
