import { h, Component } from 'preact';
import cx from 'classnames';
import { Remittance } from '../../../../types/remittance.model';
import { Modal, ModalProps } from '../common/modal';
import { toDecimal, toFixed } from '../../../utils/decimal';

import moment from 'moment-timezone';

import html2canvas from 'html2canvas';
import jsPdf from 'jspdf';

interface ReportModalProps extends ModalProps {
  gridData: Remittance[];
  startDate?: string;
  endDate?: string;
  status: string;
}

interface ReportModalState {
  total?: number;
  header?: string;
}

export class ReportModal extends Modal<ReportModalProps, ReportModalState> {
  gridDomId: string = 'SR-REPORTS-GRID';

  constructor(props: ReportModalProps) {
    super(props);

    this.calculateTotal(props.gridData);

    if (props.startDate && props.endDate) {
      this.setState(
        {
          header: `Remittance Report | ${moment(props.startDate).format('MMMM DD, YYYY')} to ${moment(props.endDate).format('MMMM DD, YYYY')}`
        }
      );
    } else if (props.startDate && props.endDate) {
      this.setState(
        {
          header: `Remittance Report | ${moment(props.startDate).format('MMMM DD, YYYY')}`
        }
      );
    } else {
      this.setState(
        {
          header: `Remittance Report`
        }
      );
    }

  }

  calculateTotal(gridData: Remittance[]) {
    let calc: number = 0;

    gridData.forEach(member => {
      // if (member.isCleared) {
      //   calc += member.amount;
      // }

      if (this.props.status === 'approved') {
        if (member.isCleared) {
          calc += member.amount;
        }
      } else if (!this.props.status) {
        calc += member.amount;
      } else {
        if (!member.isCleared) {
          calc += member.amount;
        }
      }
      
    });

    this.setState({
      total: calc
    });
  }

  doModalClose = () => {
    this.props.close();
  }

  componentDidMount() {
    document.body.classList.add('scroll-lock');
  }

  componentWillUnmount() {
    document.body.classList.remove('scroll-lock');
  }

  getPdf = () => {
    const el = document.getElementById(this.gridDomId);

    html2canvas(el, {
      onclone: (doc) => {

      },
      allowTaint: true
    }).then((canvas) => {
      const img = canvas.toDataURL('image/jpeg', 1.0);
      
      console.log('canvas width', canvas.width);
      console.log('canvas height', canvas.height);
      let canvasWidthForced = 1724;

      var imgWidth = 210 - 8; 
      // var pageHeight = 298; 
      // var pageHeight = 318; 
      var pageHeight = 298; 
      var imgHeight = canvas.height * imgWidth / canvasWidthForced;
      var heightLeft = imgHeight;
      var pdf = new jsPdf('p', 'mm', 'a4');
      var position = 0;

      pdf.addImage(img, 'PNG', 4, position + 8, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(img, 'PNG', 4, position + 12, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      window.open(URL.createObjectURL(pdf.output("blob")));
    });

  }

  render (props: ReportModalProps, state: ReportModalState) {
    return (
      <div class="anglo-modal container-fluid" ref={ node => this.refNode = node }>
        <div class="row anglo-modal__head align-items-center">
          <div class="col-10">
            <h3 class="mb-0">
              Report generation
            </h3>
          </div>
          <div class="col-2">
            <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        
        <div class="row anglo-modal__body py-3">
          <div class="col-12">
            <button class="btn btn-secondary" onClick={this.getPdf}>
              Print PDF
            </button>
          </div>

          <div class="col-12 mt-3">
            <div class="anglo-remittance-report" id={this.gridDomId}>

              <div class="anglo-remittance-report__head text-capitalize">
                { state.header } { props.status ? ' | Status - ' + props.status : '' }
              </div>

              <div class="data-grid dash-remittance__grid-container__grid mt-3">
                <div class="data-grid__row header">

                  <div class="data-grid__col col-long">
                    Date of Transaction
                  </div>
                  <div class="data-grid__col col-long">
                    Area
                  </div>
                  {/* <div class="data-grid__col col-short">
                    Remittance ID
                  </div>
                  <div class="data-grid__col col-short">
                    Remitter
                  </div> */}
                  <div class="data-grid__col col-long">
                    Sales Agent
                  </div>
                  <div class="data-grid__col col-long">
                    Bank Account
                  </div>
                  <div class="data-grid__col">
                    Amount
                  </div>
                  <div class="data-grid__col col-short">
                    Status
                  </div>
                  <div class="data-grid__col col-long">
                    OR Number
                  </div>
                </div>

                {
                  props.gridData.map((row, index) => {
                    // max of 32 per page
                    // if (props.status !== 'approved' && !row.isCleared) {
                      let indexPageBreak = index % 35 == 0 && index != 0 && index <= 35;
                      let indexNextPage = index % 35 == 0 && index >= 70;
                      return (
                        <div className={ cx('data-grid__row', { 
                            'page-end': indexPageBreak ,
                            'page-beginning': indexNextPage
                            }
                          ) }>
                          <div class="data-grid__col col-long">
                            { moment(row.dateOfTransaction).format('MMMM DD, YYYY') }
                          </div>
                          <div class="data-grid__col col-long">
                            { row.area }
                          </div>
                          {/* <div class="data-grid__col col-short">
                            { row.remittanceId }
                          </div>
                          <div class="data-grid__col col-short">
                            { row.user.firstName } { row.user.lastName }
                          </div> */}
                          <div class="data-grid__col col-long">
                            { row.salesAgent }
                          </div>
                          <div class="data-grid__col col-long">
                            { row.receivingBankAccount }
                          </div>
                          <div class="data-grid__col">
                            ₱ { toFixed(row.amount) }
                          </div>
                          <div class="data-grid__col col-short text-capitalize">
                            { row.stage }
                          </div>
                          <div class="data-grid__col col-long text-capitalize">
                            { row.receiptNumber }
                          </div>
                        </div>
                      );
                    // }

                  })
                }
              </div>

              <div class="anglo-remittance-report__total mt-3 mb-5">
                Total: <b>₱ { toFixed(state.total) } </b>
              </div>

            </div>
            
          </div>
        </div>
      </div>
    );
  }

}