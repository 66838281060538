import { h, Component } from 'preact';
import cx from 'classnames';
import getStore, { appState$ } from '../../store';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import Portal from 'preact-portal';
import moment from 'moment-timezone';

import { Form, FieldList, Field } from '../../../../types/form.model';
import { Disposal } from '../../../../types/disposal.model';
import { RequestState, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../types/request-state.model';
import { GET_GRID_DISPOSAL } from '../../reducers/disposals/disposals.actions';
import { toFixed } from '../../../utils/decimal';
import { ROLE_MANAGEMENT, ROLE_SUPERVISOR } from '../../../../constants/role';
import { WithdrawalBrand } from '../../../../types/withdrawal.model';
import { User } from '../../../../types/user.model';
import { Location } from '../../../../types/location.model';
import { ViewDisposalModal } from './modal/view-disposal.modal';
import { ReviewStocksModal } from '../withdrawal/modal/review-stocks.modal';
import { CreateDisposalModal } from './modal/create-disposal.modal';
import { CreateLocationModal } from '../common/create-location.modal';
import { PAGE_SIZE } from '../remittance/dash-remittance.component';

import { GET_CURRENT_USER } from '../../reducers/user/user.actions';
import { cxValidateField, validateForm } from '../../../../types/validator.model';
import { AutocompleteSearch } from '../common/autocomplete-search.component';
import { DatePicker } from '../common/date-picker';
import linkform, { updateFormFieldValue } from '../../../utils/linkform';
import { AutocompleteLocation } from '../common/autocomplete-location.component';

export const MODAL_CHANGE_CREATE_LOCATION: string = 'create-location';

export interface DashDisposalProps {

}

export interface DashDisposalState {
  gridData?: Disposal[];
  gridRequestState?: RequestState;

  selectedDisposal?: Disposal;

  pages?: number[];
  currentPage?: number;
  filters?: Map<string, any>;
  filterForm?: Form;
  isFilterVisible?: boolean;
  
  currentUser?: User;

  // if set to -1, close all
  modalState?: number;
}

export function getDisposalValue(disposal: Disposal) {
  let value: number = 0;

  disposal.brandBreakdown.forEach((member: WithdrawalBrand) => {

    if (member.numOfCases) {
      value += (member.numOfCases * 50) * member.pricePerReam;
    }

    if (member.numOfReams) {
      value += member.numOfReams * member.pricePerReam;
    }

    if (member.numOfPacks) {
      value += (member.numOfPacks / 10) * member.pricePerReam;
    }
  });

  return toFixed(value);
}

export class DashDisposals extends Component<DashDisposalProps, DashDisposalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  resetStartDate: Function;
  resetEndDate: Function;

  private MODAL_CREATE_DISPOSAL: number = 0;
  private MODAL_VIEW_DETAILS: number = 1;
  private MODAL_REVIEW_CURRENT_STOCKS: number = 2;
  private MODAL_CREATE_LOCATION: number = 3;

  constructor(props: DashDisposalProps) {
    super(props);

    let filters: Map<string, any> = new Map();
    filters.set('page', 1);

    this.setState({
      modalState: -1,
      filters: filters,
      currentPage: 1
    });

    this.createForm();
  }

  createForm() {
    let fields: FieldList = {
      'startDate': new Field([], null, ''),
      'endDate': new Field([] , null, ''),
      'minAmount': new Field([] , null, ''),
      'maxAmount': new Field([] , null, ''),
      'location': new Field([] , null, ''),
      'supervisor': new Field([] , null, ''),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      filterForm: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['filterForm'] = form;
      this.setState(state);
    });
  }

  componentDidMount() {

    const user$ = appState$.pipe(
      map(appState => appState.user)
    );

    this.subs.push(user$.subscribe((user) => {
        if (user.currentUser) {
          this.setState({
            currentUser: user.currentUser
          });
        }
      })
    );

    const disposal$ = appState$.pipe(
      map(appState => appState.disposal)
    );

    this.subs.push(
      disposal$.pipe(
        map(disposal => disposal.disposals),
        distinctUntilChanged()
      ).subscribe(disposals => {
        this.setState({
          gridData: disposals
        });
      })
    );

    this.subs.push(
      disposal$.pipe(
        map(disposal => disposal.gridRequestState),
        distinctUntilChanged()
      ).subscribe(gridRequestState => {
        this.setState({
          gridRequestState: gridRequestState
        });
      })
    );

    this.subs.push(disposal$.pipe(
      map(disposal => disposal.total),
      distinctUntilChanged()
    ).subscribe((total) => {
        if (total) {
          let pages: number[] = [];
          let limit = Math.ceil(total / PAGE_SIZE);

          for(let i = 0; i < limit; i++) {
            pages.push(i + 1);
          }

          this.setState({
            pages: pages
          });
        }
      })
    );

    this.initialStoreDispatches();
  }

  initialStoreDispatches() {
    const store = getStore();

    if (this.state.currentUser.role === ROLE_SUPERVISOR) {
      this.state.filters.set('supervisor', this.state.currentUser.supervisor.supervisorId);
    }

    store.dispatch({
      type: GET_GRID_DISPOSAL,
      payload: {
        filters: this.state.filters
      }
    });
  }

  onModalOpened = (paramState: number) => {
    this.setState({
      modalState: paramState
    });
  }

  onViewDetails = (disposal: Disposal) => {
    this.setState({
      selectedDisposal: disposal
    });

    this.onModalOpened(this.MODAL_VIEW_DETAILS);
  }

  onPageClicked = (page: number) => {
    let filterForm = {};
    const fieldList = this.state.filterForm.fields;

    for (let k in fieldList) {
      if (fieldList[k].value) {
        // filters.set(k, fieldList[k].value);
        filterForm[k] = fieldList[k].value;
      }
    }

    filterForm['page'] = page;

    let filters = this.state.filters;
    filters.set('page', page);

    const store = getStore();
    store.dispatch({
      type: GET_GRID_DISPOSAL,
      payload: {
        filters: filters
      }
    });

    this.setState({
      currentPage: page
    })
  }

  getBrandsDisposed = (disposal: Disposal) => {
    let brands: string = '';

    for (let count = 0; count < disposal.brandBreakdown.length; count++) {
      if (count > 0) {
        brands = brands.concat(', ');
      }

      brands = brands.concat(disposal.brandBreakdown[count].name);
    }

    return brands;
  }

  onModalClose = (returnParms: any) => {
    this.setState({
      modalState: -1
    });

    if (returnParms) {
      if (returnParms.changeMode && returnParms.changeMode === MODAL_CHANGE_CREATE_LOCATION) {
        this.onModalOpened(this.MODAL_CREATE_LOCATION);
      }
    }

    this.initialStoreDispatches();
  }

  onFilterToggleClicked = () => {
    this.setState({
      isFilterVisible: !this.state.isFilterVisible
    });
  }

  onSupervisorSearchChange = (supervisor: User) => {
    console.log(supervisor);
    updateFormFieldValue(
      this.form$,
      this.state.filterForm,
      'supervisor',
      supervisor.supervisor.supervisorId
    );
  }

  onLocationSearchChange = (location: Location) => {
    updateFormFieldValue(
      this.form$,
      this.state.filterForm,
      'location',
      location.locationId
    )
  }

  startDateSelected = (date: string) => {
    updateFormFieldValue(
      this.form$,
      this.state.filterForm,
      'startDate',
      date
    );
  }

  resetStartDates = (returned: Function) => {
    this.resetStartDate = returned;
  }

  resetEndDates = (returned: Function) => {
    this.resetEndDate = returned;
  }

  applyFilters = () => {
    let filters = this.state.filters;
    const fieldList = this.state.filterForm.fields;

    for (let k in fieldList) {
      if (fieldList[k].value) {
        filters.set(k, fieldList[k].value);
      }
    }

    const store = getStore();
    store.dispatch({
      type: GET_GRID_DISPOSAL,
      payload: {
        filters: filters
      }
    });
  }

  resetFilters = () => {
    const fieldList = this.state.filterForm.fields;

    for (let k in fieldList) {
      fieldList[k].value = null;
    }

    this.resetStartDate();
    this.resetEndDate();

    let filters: Map<string, any> = new Map();
    filters.set('page', 1);

    this.setState({
      filters: filters,
      currentPage: 1
    });

    if (this.state.currentUser.role === ROLE_SUPERVISOR) {
      this.state.filters.set('supervisor', this.state.currentUser.supervisor.supervisorId);
    }

    const store = getStore();
    store.dispatch({
      type: GET_GRID_DISPOSAL,
      payload: {
        filters: filters
      }
    });
  }

  getPortalModal = (state: DashDisposalState) => {
    let modalState = state.modalState;

    if (modalState < 0) {
      return null;
    } else if (modalState === this.MODAL_CREATE_DISPOSAL) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-124">
            <CreateDisposalModal 
              currentUser={state.currentUser}
              disposal={state.selectedDisposal}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId="anglo-modal-123"/>
          </div>
        </Portal>
      );
    } else if (modalState === this.MODAL_VIEW_DETAILS) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-123">
            <ViewDisposalModal 
              currentUser={state.currentUser}
              disposal={state.selectedDisposal}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId="anglo-modal-123"/>
          </div>
        </Portal>
      );
    } else if (modalState === this.MODAL_REVIEW_CURRENT_STOCKS) {

            return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-123">
            <ReviewStocksModal 
              currentUser={state.currentUser}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId="anglo-modal-123"/>
          </div>
        </Portal>
      );
    } else if (modalState === this.MODAL_CREATE_LOCATION) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-123">
            <CreateLocationModal 
              currentUser={state.currentUser}
              close={this.onModalClose} 
              isOutsideClickClose={false} 
              containerId="anglo-modal-123"/>
          </div>
        </Portal>
      );
    }
  }

  getActionsBlock(props: DashDisposalProps, state: DashDisposalState) {
    if (state.currentUser && state.currentUser.role === ROLE_SUPERVISOR) {
      return (
        <div class="row">
          <div class="col-12 offset-lg-6 col-lg-2 mb-3 mb-lg-0">
            <button class="btn btn-outline-dark w-100"
              onClick={() => {this.onModalOpened(this.MODAL_CREATE_LOCATION)}}>
              Create Location
            </button>
          </div>
          <div class="col-12 col-lg-2 mb-3 mb-lg-0">
            <button class="btn btn-outline-dark w-100"
              onClick={() => {this.onModalOpened(this.MODAL_REVIEW_CURRENT_STOCKS)}}>
              Review Current Stocks
            </button>
          </div>
          <div class="col-12 col-lg-2">
            <button class="btn btn-secondary w-100"
              onClick={() => {this.onModalOpened(this.MODAL_CREATE_DISPOSAL)}}>
              Create Disposal
            </button>
          </div>
        </div>
      );
    }
  }

  getGridBlock(props: DashDisposalProps, state: DashDisposalState) {
    if (state.gridRequestState === REQUEST_IN_PROGRESS) {
      return (
        <div class="row mt-3">
          <div class="col-12">
            <div class="spinner-border text-primary mt-3" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      );
    } else if (state.gridRequestState === REQUEST_SUCCESS) {

      // in the grid, we only show 4-5 columns
      // should the user wish to see the full breakdown
      // a modal will be used instead

      return (
        <div class="dash-remittance__grid-container">
          <div class="row mt-3">
            <div class="col-12">
              <div class="data-grid dash-withdrawal__grid">
                <div class="data-grid__row header">
                  <div class="data-grid__col col-short">
                    Actions
                  </div>
                  {
                    state.currentUser.role === ROLE_MANAGEMENT ? 
                    <div class="data-grid__col">
                      Supervisor
                    </div>
                    : null
                  }
                  <div class="data-grid__col">
                    Agent Involved
                  </div>
                  <div class="data-grid__col">
                    Location
                  </div>
                  <div class="data-grid__col">
                    Brands Disposed
                  </div>
                  <div class="data-grid__col">
                    Disposal Value
                  </div>
                  <div class="data-grid__col">
                    Date Disposed
                  </div>
                </div>

                {
                  state.gridData.map((row, index) => {
                    return (
                      <div class="data-grid__row" id={row._id}>
                        <div class="data-grid__col col-short">
                          <button class="btn btn-outline-primary w-100"
                            onClick={() => {this.onViewDetails(row)}}>
                            View
                          </button>
                        </div>
                        {
                          state.currentUser.role === ROLE_MANAGEMENT ? 
                          <div class="data-grid__col">
                            {row.supervisorData.username}
                          </div>
                          : null 
                        }
                        
                        <div class="data-grid__col text-capitalize">
                          {row.agentInvolved}
                        </div>
                        <div class="data-grid__col text-capitalize">
                        {row.location.area ? `${row.location.area}, ` : null} {row.location.name}
                        </div>
                        <div class="data-grid__col text-capitalize">
                          {this.getBrandsDisposed(row)}
                        </div>
                        <div class="data-grid__col text-capitalize">
                          ₱ {getDisposalValue(row)}
                        </div>
                        <div class="data-grid__col">
                          {moment(row.dateDisposed).format("MMMM DD, YYYY")}
                        </div>
                      </div>
                    );
                  })
                }

              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div class="row">
          <div class="col-12">
            Failed to load grid data.
          </div>
        </div>
      );
    }
  }

  getPaginationBlock(props: DashDisposalProps, state: DashDisposalState) {
    return (
      <div class="row">
        <div class="col-12">
          {
            state.pages ?
            <div class="dash-remittance__pagination mb-3">
              <button class="btn dash-remittance__pagination__page arrow"
                  disabled={ state.currentPage === 1 } 
                  onClick={() => this.onPageClicked(state.currentPage - 1)}>
                <i class="fas fa-chevron-left"></i>
              </button>
              {
                state.currentPage > 5 ?
                <button className={ cx('btn dash-remittance__pagination__page', 
                  { 'active': state.pages[0] === state.currentPage }) }
                  onClick={() => this.onPageClicked(state.pages[0])}>
                  { state.pages[0] }
                </button> : null
              }
              {
                state.currentPage > 5 ?
                <span class="mx-2">
                  ...
                </span> : null
              }
              {
                state.pages ? state.pages.map(page => {
                  if (page > state.currentPage - 5 && page < state.currentPage + 5) {
                    return (
                      <button className={ cx('btn dash-remittance__pagination__page', 
                        { 'active': page === state.currentPage }) }
                        onClick={() => this.onPageClicked(page)}>
                        { page }
                      </button>
                    );
                  }
                }) : null
              }
              {
                state.currentPage < (state.pages.length) - 5 ?
                <span class="mx-2">
                  ...
                </span> : null
              }
              {
                state.currentPage < (state.pages.length) - 5 ?
                <button className={ cx('btn dash-remittance__pagination__page', 
                  { 'active': state.pages[state.pages.length - 1] === state.currentPage }) }
                  onClick={() => this.onPageClicked(state.pages[state.pages.length - 1])}>
                  { state.pages[state.pages.length - 1] }
                </button> : null
              }
              <button class="btn dash-remittance__pagination__page arrow"
                  disabled={ state.currentPage === state.pages[state.pages.length - 1] } 
                  onClick={() => this.onPageClicked(state.currentPage + 1)}>
                <i class="fas fa-chevron-right"></i>
              </button>
            </div> : null
          }
        </div>
      </div>
    );
  }

  getFilterBlock(props: DashDisposalProps, state: DashDisposalState) {
    return (
      <div class="container-fluid px-0 mt-3">
        <div class="row justify-content-end">
          <div class="col-12 col-lg-3 offset-9 d-flex justify-content-end">
            <a class="dash-remittance__filter-row__filter-btn" onClick={ this.onFilterToggleClicked }>
              Filter <i class="fas fa-filter"></i>
            </a>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-12">
            {
              state.isFilterVisible ?
              <div class="dash-remittance__filter-row__filters mt-2">
                { this.getFilterForm(props, state) }
                { this.getFilterActions(props, state) }
              </div> : null
            }
          </div>
        </div>
      </div>
    )
  }

  getFilterActions(props: DashDisposalProps, state: DashDisposalState) {
    if (state.isFilterVisible) {
      return (
        <div class="row mt-3">
          <div class="col-12 col-lg-2 offset-lg-8">
            <button class="btn btn-outline-dark w-100"
              onClick={this.resetFilters}>
              Reset Filters
            </button>
          </div>
          <div class="col-12 col-lg-2">
            <button class="btn btn-secondary w-100"
              onClick={this.applyFilters}>
              Apply Filters
            </button>
          </div>
        </div>
      );
    }
  }

  getFilterForm(props: DashDisposalProps, state: DashDisposalState) {
    let form = state.filterForm;
    
    if (state.currentUser && state.isFilterVisible) {
      if (state.currentUser.role === ROLE_MANAGEMENT) {
        return (
          <div class="row mt-3">
            <div class="col-12 col-lg-3">
              <label class="dash-remittance__filter-row__filters__label">Supervisor</label>
              <AutocompleteSearch onResultSelected={this.onSupervisorSearchChange} />
            </div>

            <div class="col-12 col-lg-3">
              <label class="dash-remittance__filter-row__filters__label">Location</label>
              <AutocompleteLocation onResultSelected={this.onLocationSearchChange} />
            </div>

            <div class="col-12 col-lg-3">
              <label class="dash-remittance__filter-row__filters__label">Start Disposal date</label>
              <DatePicker
                onDateSelected={this.startDateSelected}
                initialValue={ form.fields['startDate'].value }
                isValid={form.fields['startDate'].isValid}
                isPristine={form.fields['startDate'].isPristine}
                reset={this.resetStartDates}>
              </DatePicker>
            </div>

            <div class="col-12 col-lg-3">
              <label class="dash-remittance__filter-row__filters__label">End Disposal date</label>
              <DatePicker
                onDateSelected={this.startDateSelected}
                initialValue={ form.fields['endDate'].value }
                isValid={form.fields['endDate'].isValid}
                isPristine={form.fields['endDate'].isPristine}
                reset={this.resetEndDates}>
              </DatePicker>
            </div>
          </div>
        );
      } else {
        return (
          <div class="row mt-3">
            <div class="col-12 col-lg-3">
              <label class="dash-remittance__filter-row__filters__label">Location</label>
              <AutocompleteLocation onResultSelected={this.onLocationSearchChange} />
            </div>

            <div class="col-12 col-lg-3">
              <label class="dash-remittance__filter-row__filters__label">Start Disposal date</label>
              <DatePicker
                onDateSelected={this.startDateSelected}
                initialValue={ form.fields['startDate'].value }
                isValid={form.fields['startDate'].isValid}
                isPristine={form.fields['startDate'].isPristine}
                reset={this.resetStartDates}>
              </DatePicker>
            </div>

            <div class="col-12 col-lg-3">
              <label class="dash-remittance__filter-row__filters__label">End Disposal date</label>
              <DatePicker
                onDateSelected={this.startDateSelected}
                initialValue={ form.fields['endDate'].value }
                isValid={form.fields['endDate'].isValid}
                isPristine={form.fields['endDate'].isPristine}
                reset={this.resetEndDates}>
              </DatePicker>
            </div>
          </div>
        );
      }
    }
  }

  render(props: DashDisposalProps, state: DashDisposalState) {
    return (
      <div class="dash-disposal anglo__dashboard">

        { this.getPortalModal(state) }

        <div class="container-fluid">
          <div class="row">
            <div class="col-8 col-lg-4">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/disposal">Disposals</a></li>
                </ol>
              </nav>
            </div>
          </div>

          { this.getActionsBlock(props, state) }

          { this.getFilterBlock(props, state) }
          { this.getGridBlock(props, state) }

          { this.getPaginationBlock(props, state) }


          {/* { this.getInsightBlock(props, state) }

           */}
        </div>

      </div>
    );
  }
}