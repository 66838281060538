import { h, Component } from 'preact';
import cx from 'classnames';

import { ModalProps, Modal } from '../../common/modal';

import { Subject, Subscription } from 'rxjs';
import { FieldList, Field, Form } from '../../../../../types/form.model';

import { validateForm, cxValidateField } from '../../../../../types/validator.model';
import getStore, { appState$ } from '../../../store';
import { SUBMIT_EDIT_REQUEST } from '../../../reducers/remittance/remittance.actions';

import { map } from 'rxjs/operators';
import { RequestState, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../../types/request-state.model';
import { User } from '../../../../../types/user.model';
import linkform, { updateFormFieldValue } from '../../../../utils/linkform';
import { GET_SUPERVISORS, UserAction } from '../../../reducers/user/user.actions';
import { Vehicle } from '../../../../../types/vehicle.model';
import { GENERATE_VEHICLE_EXPENSE_REPORT } from '../../../reducers/vehicle/vehicle.actions';
import { AutocompleteSearch } from '../../common/autocomplete-search.component';
import { DatePicker } from '../../common/date-picker';

export interface GenerateExpenseReportModalProps extends ModalProps {
  currentUser?: User;
}

interface GenerateExpenseReportModalState  {
  createVehicleState?: RequestState;
  expenseReportForm?: Form;
  supervisors?: User[];
}

export class GenerateExpenseReportModal extends Modal<GenerateExpenseReportModalProps, GenerateExpenseReportModalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props: GenerateExpenseReportModalProps) {
    super(props);

    this.createForm();
  }

  componentDidMount() {
    super.componentDidMount();

    const vehicles$ = appState$.pipe(
      map(app => app.vehicles)
    );

    this.subs.push(
      vehicles$.pipe(
        map(vehicles => vehicles.createVehicleState)
      ).subscribe(reqState => {
        this.setState({
          createVehicleState: reqState
        });

        if (reqState === REQUEST_SUCCESS) {
          this.doModalClose();
        }
      })
    );

    this.initialStoreDispatches();
  }

  initialStoreDispatches() {

    const store = getStore();
    store.dispatch({
      type: GET_SUPERVISORS
    } as UserAction);

  }

  componentWillUnmount() {
    this.subs.forEach(s => s.unsubscribe());
  }

  createForm() {
    let fields: FieldList = {
      'username': new Field(['required'], null, ''),
      'startDate': new Field([''], null, ''),
      'endDate': new Field([''], null, ''),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      expenseReportForm: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['expenseReportForm'] = form;
      this.setState(state);
    });
  }

  handleDatepickerValidation = (date: string, fieldName: string) => {
    const currentForm = this.state.expenseReportForm; 

    // Manually do this because in order to follow the 
    // hopplerLinkstate validation system
    let clonedFields: FieldList = {};
    for (let k in currentForm.fields) {
      let prevField = currentForm.fields[k];
      clonedFields[k] = new Field(prevField.validators, prevField.customValidators, prevField.value);
      clonedFields[k].isValid = prevField.isValid;
    }

    let clone: Form = new Form(clonedFields);
    clone.fields[fieldName].value = date;
    clone.fields[fieldName].isPristine = false;
    // clone.fields[fieldName].customValidators.forEach(a => a(clone.fields[fieldName], clone));
    this.form$.next(clone);
  }

  onSupervisorSearchChange = (supervisor: User) => {
    updateFormFieldValue(
      this.form$,
      this.state.expenseReportForm,
      'username',
      supervisor.username
    )
  }

  onStartDateSelected = (date: string) => {
    this.handleDatepickerValidation(date, 'startDate');
  }

  onEndDateSelected = (date: string) => {
    this.handleDatepickerValidation(date, 'endDate');
  }

  doModalClose = () => {
    this.props.close();
  }

  getSpinnerBlock(state: GenerateExpenseReportModalState) {
    if (state.createVehicleState === REQUEST_IN_PROGRESS) {
      return (
        <div class="col offset-md-9 d-flex justify-content-end">
          <div class="spinner-border text-primary mt-3" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }    
  }

  onSubmit = (e) => {

    e.preventDefault();
    
    const form = this.state.expenseReportForm;
    validateForm(form);

    this.setState({
      expenseReportForm: form
    });

    if (!this.state.expenseReportForm.isValid) {
      return;
    }

    let filters = {
      username: form.fields['username'].value,
      startDate: form.fields['startDate'].value,
      endDate: form.fields['endDate'].value,
    }

    const store = getStore();
    store.dispatch({
      type: GENERATE_VEHICLE_EXPENSE_REPORT,
      payload: {
        filters: filters
      }
    });
  }

  render(props: GenerateExpenseReportModalProps, state: GenerateExpenseReportModalState) {
    let form = state.expenseReportForm;

    return (
      <div class="anglo-generate-expose-report-modal container anglo-modal"
          ref={ node => this.refNode = node }>
        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">
              Generate Expense Report
            </h3>
          </div>
          <div class="col-3 col-md-2">
            <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        
        <div class="row anglo-modal__body py-3">
          <div class="col-12 col-md-4 mb-3">
            <label class="anglo-remittance__submit-form__label">Supervisor*</label>
            <AutocompleteSearch onResultSelected={this.onSupervisorSearchChange} />

          </div>
          <div class="col-12 col-md-4 mb-3">
            <label class="anglo-remittance__submit-form__label">Start Date</label>
            <DatePicker
              onDateSelected={this.onStartDateSelected}
              initialValue={ form.fields['startDate'].value }
              isValid={form.fields['startDate'].isValid}
              isPristine={form.fields['startDate'].isPristine}>

            </DatePicker>
          </div>
          <div class="col-12 col-md-4 mb-3">
            <label class="anglo-remittance__submit-form__label">End Date</label>
            <DatePicker
              onDateSelected={this.onEndDateSelected}
              initialValue={ form.fields['endDate'].value }
              isValid={form.fields['endDate'].isValid}
              isPristine={form.fields['endDate'].isPristine}>

            </DatePicker>
          </div>

          <div class="col-12">
            <div class="row">
              <div class="col-md-1 offset-md-9">
                {
                  this.getSpinnerBlock(state)
                }
              </div>

              <div class="col-12 col-md-2">

                <button class="btn btn-primary w-100" 
                  onClick={this.onSubmit}
                  disabled={state.createVehicleState == REQUEST_IN_PROGRESS}>
                  Generate
                </button>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}