import { h, Component } from "preact";
import cx from "classnames";
import { Subscription, Subject } from "rxjs";
import moment from "moment-timezone";

import { ModalProps, Modal } from "../../common/modal";
import { Form, FieldList, Field } from "../../../../../types/form.model";
import * as Validators from "../../../../../types/validator.model";
import {
  validateForm,
  cxValidateField,
} from "../../../../../types/validator.model";
import linkform, { updateFormFieldValue } from "../../../../utils/linkform";

import {
  PurchaseDocument,
  ReceivingReport,
} from "../../../../../types/purchase-document.model";
import { ItemPiece } from "../../../../../types/item.model";

interface ViewPDProps extends ModalProps {
  pd: PurchaseDocument;
}

interface ViewPDState {
  form?: Form;
  focusedRR?: ReceivingReport;
}

export class ViewPDModal extends Modal<ViewPDProps, ViewPDState> {
  form$: Subject<Form>;
  subs: Subscription[] = [];

  constructor(props) {
    super(props);

    this.createForm(props);
  }

  createForm(props: ViewPDProps) {
    let fields: FieldList = {
      status: new Field([Validators.required], null, props.pd.status),
      totalAmount: new Field([Validators.required], null, props.pd.totalAmount),
      requestedBy: new Field([Validators.required], null, props.pd.requestedBy),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      form: new Form(fields),
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state["form"] = form;
      this.setState(state);
    });
  }

  doModalClose = () => {
    if (this.props.close) {
      this.props.close();
    }
  };

  getFormBlock(props: ViewPDProps, state: ViewPDState) {
    const form = this.state.form;

    return (
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div class="col-12 col-lg-4 mt-3">
              <label class="anglo-remittance__submit-form__label">
                Requested By
              </label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["requestedBy"]),
                })}
                disabled={true}
                type="text"
                value={form.fields["requestedBy"].value}
                onChange={linkform(this.form$, form, "requestedBy")}
                placeholder="Requested By"
              />
            </div>
            <div class="col-12 col-lg-4 mt-3">
              <label class="anglo-remittance__submit-form__label">Status</label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["status"]),
                })}
                type="text"
                disabled={true}
                value={form.fields["status"].value}
                onChange={linkform(this.form$, form, "status")}
                placeholder="Status"
              />
            </div>
            <div class="col-12 col-lg-4 mt-3">
              <label class="anglo-remittance__submit-form__label">
                Total Amount
              </label>
              <input
                className={cx("form-control", {
                  invalid: cxValidateField(form.fields["totalAmount"]),
                })}
                type="text"
                disabled={true}
                value={form.fields["totalAmount"].value}
                onChange={linkform(this.form$, form, "totalAmount")}
                placeholder="Total Amount"
              />
            </div>
          </div>
          {this.getSignatures(props)}
          <div className="row mt-3">
            <div className="col-12">{this.getItemList(props.pd.itemList)}</div>
          </div>
        </div>
      </div>
    );
  }

  getSignatureBlock(
    title: string,
    fieldKey: string,
    dateFieldKey: string,
    pd: PurchaseDocument
  ) {
    if (pd[fieldKey]) {
      return (
        <div className="col-3">
          <div className="card signature-block">
            <h4>{title}</h4>
            <div className="values">
              <p>{pd[fieldKey]}</p>
              <p>
                <i>{moment(pd[dateFieldKey]).format("MMMM DD, YYYY")}</i>
              </p>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  getSignatures(props: ViewPDProps) {
    return (
      <div className="row mt-3">
        {this.getSignatureBlock(
          "Requested By",
          "requestedBy",
          "dateCreated",
          props.pd
        )}
        {this.getSignatureBlock("Noted By", "notedBy", "dateNoted", props.pd)}
        {this.getSignatureBlock(
          "PR Approved By",
          "requestApprovedBy",
          "dateRequestApproved",
          props.pd
        )}
        {this.getSignatureBlock(
          "PO Approved By",
          "orderApprovedBy",
          "dateOrdered",
          props.pd
        )}
      </div>
    );
  }

  getItemList(itemList: ItemPiece[]) {
    return (
      <div class="item-list data-grid">
        <div className="data-grid__row header">
          <div class="data-grid__col">Name</div>
          <div class="data-grid__col">Item Code</div>
          {/* <div class="data-grid__col">Supplier</div> */}
          <div class="data-grid__col">Amount</div>
          <div class="data-grid__col">Unit Price</div>
          <div class="data-grid__col">Remark</div>
        </div>
        {itemList.map((row, index) => {
          return (
            <div className="data-grid__row">
              <div class="data-grid__col">{row.name}</div>
              <div class="data-grid__col">{row.itemCode}</div>
              {/* <div class="data-grid__col">{row.supplierId}</div> */}
              <div class="data-grid__col">{row.amount}</div>
              <div class="data-grid__col">{row.unitPrice}</div>
              <div class="data-grid__col">{row.remark}</div>
            </div>
          );
        })}
      </div>
    );
  }

  focusRR = (rr: ReceivingReport) => {
    this.setState({
      focusedRR: rr,
    });
  };

  getRRCard(rr: ReceivingReport) {
    return (
      <div className="col-4">
        <div className="card rr-card" onClick={() => this.focusRR(rr)}>
          {rr.receivedBy} {moment(rr.dateReceived).format("MMMM DD, YYYY")}
        </div>
      </div>
    );
  }

  getReceivingReportBlock(props: ViewPDProps) {
    return (
      <div className="row">
        <div className="col-12">
          <hr />
          Receiving Reports
          <div className="row">
            {props.pd.receivingReportList.map((row, index) => {
              return this.getRRCard(row);
            })}
          </div>
          <div className="row mt-2">
            <div className="col-12">{this.getRRItemList()}</div>
          </div>
        </div>
      </div>
    );
  }

  getRRItemList() {
    if (this.state.focusedRR) {
      return this.getItemList(this.state.focusedRR.itemList);
    }
    return null;
  }

  getReceivingReport(data: ReceivingReport) {
    return (
      <div className="card receiving-report">
        <div className="row">
          <div className="col-6">
            <div className="card signature-block">
              <h4>Checked By</h4>
              <div className="values">
                <p>{data.checkedBy}</p>
                <p>
                  <i>{moment(data.dateChecked).format("MMMM DD, YYYY")}</i>
                </p>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="card signature-block">
              <h4>Received By</h4>
              <div className="values">
                <p>{data.receivedBy}</p>
                <p>
                  <i>{moment(data.dateReceived).format("MMMM DD, YYYY")}</i>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">{this.getItemList(data.itemList)}</div>
        </div>
      </div>
    );
  }

  render(props: ViewPDProps, state: ViewPDState) {
    return (
      <div
        class="container anglo-modal create-disposal-modal"
        ref={(node) => (this.refNode = node)}
      >
        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">
              Purchase Document | {props.pd.purchaseDocumentId}
            </h3>
          </div>
          <div class="col-3 col-md-2">
            <button
              class="btn btn-outline-light w-100"
              onClick={this.doModalClose}
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>

        <div class="row anglo-modal__body py-3">
          <div class="col-12 create-withdrawal-modal__body pb-3">
            {this.getFormBlock(props, state)}
            {props.pd.receivingReportList
              ? this.getReceivingReportBlock(props)
              : null}
          </div>
        </div>
      </div>
    );
  }
}
