import { h, Component, ComponentChild } from "preact";
import cx from "classnames";
import getStore, { appState$ } from "../../store";
import { map, distinctUntilChanged } from "rxjs/operators";
import { Subscription, Subject } from "rxjs";
import Portal from "preact-portal";

import { Form, FieldList, Field } from "../../../../types/form.model";
import { Supervisor, User } from "../../../../types/user.model";
import { Agent } from "../../../../types/agent.model";
import {
  GET_AGENTS,
  GET_SUPERVISORS,
  RESET_STATES,
} from "../../reducers/user/user.actions";
import { validateForm } from "../../../../types/validator.model";
import linkform from "utils/linkform";

import { CreateEmployeeModal } from "./modal/create-employee.modal";
import { EditEmployeeModal } from "./modal/edit-employee.modal";

export interface EmployeeDashboardState {
  modalState: number;
  currentUser?: User;

  selectedSupervisor?: User;
  allSupervisors?: User[];
  supervisorFilters?: Map<string, any>;
  supervisorForm?: Form;
  isSupervisorFilterVisible?: boolean;

  selectedEmployee?: Agent;
  allEmployees?: Agent[];
  employeeFilters?: Map<string, any>;
  employeeForm?: Form;
}

export class EmployeeDashboard extends Component<any, EmployeeDashboardState> {
  subs: Subscription[] = [];
  supervisorForm$: Subject<Form>;
  employeeForm$: Subject<Form>;

  private MODAL_EDIT_EMPLOYEE: number = 0;
  private MODAL_REASSIGN_EMPLOYEE: number = 1;
  private MODAL_CREATE_EMPLOYEE: number = 2;

  constructor(props) {
    super(props);

    let supervisorFilters: Map<string, any> = new Map();
    let employeeFilters: Map<string, any> = new Map();
    this.setState({
      modalState: -1,
      supervisorFilters: supervisorFilters,
      employeeFilters: employeeFilters,
      currentUser: null,
      isSupervisorFilterVisible: false,
    });

    this.setupSupervisorFilterForm();
    this.setupEmployeeFilterForm();
  }

  componentDidMount() {
    const user$ = appState$.pipe(map((appState) => appState.user));

    this.subs.push(
      user$
        .pipe(
          map((user) => user.supervisors),
          distinctUntilChanged()
        )
        .subscribe((supervisors) => {
          this.setState({
            allSupervisors: supervisors,
          });
        })
    );

    this.subs.push(
      user$
        .pipe(
          map((user) => user.agents),
          distinctUntilChanged()
        )
        .subscribe((agents) => {
          this.setState({
            allEmployees: agents,
          });
        })
    );

    this.initialStoreDispatches();
  }

  initialStoreDispatches() {
    const store = getStore();

    store.dispatch({
      type: GET_SUPERVISORS,
    });
  }

  setupSupervisorFilterForm() {
    let fields: FieldList = {
      supervisor: new Field([]),
      userSearch: new Field([]),
    };

    this.supervisorForm$ = new Subject<Form>();
    this.setState({
      supervisorForm: new Form(fields),
    });

    this.supervisorForm$.subscribe((form) => {
      validateForm(form);

      const state = {
        supervisorForm: form,
      };
      this.setState(state);
    });
  }

  setupEmployeeFilterForm() {
    let fields: FieldList = {
      supervisor: new Field([]),
      agentId: new Field([]),
      employeeType: new Field([]),
    };

    this.employeeForm$ = new Subject<Form>();
    this.setState({
      employeeForm: new Form(fields),
    });

    this.employeeForm$.subscribe((form) => {
      validateForm(form);

      const state = {
        employeeForm: form,
      };
      this.setState(state);
    });
  }

  // -------------------- HANDLERS --------------------

  onSupervisorClick(row: User) {
    let employeeFilters = this.state.employeeFilters;
    const employeeFieldList = this.state.employeeForm.fields;

    for (let k in employeeFieldList) {
      if (employeeFieldList[k].value) {
        employeeFilters.set(k, employeeFieldList[k].value);
      }
    }

    employeeFilters.set("supervisorUsername", row.username);

    const store = getStore();

    store.dispatch({
      type: GET_AGENTS,
      payload: {
        employeeFilters: employeeFilters,
      },
    });

    this.setState({
      selectedSupervisor: row,
    });
  }

  onModalOpened = (paramState: number, row?: any) => {
    if (paramState === this.MODAL_CREATE_EMPLOYEE) {
      this.setState({
        modalState: paramState,
      });
    } else if (paramState === this.MODAL_EDIT_EMPLOYEE) {
      this.setState({
        modalState: paramState,
        selectedEmployee: row,
      });
    }
  };

  onModalClose = (returnParams: any) => {
    this.setState({
      modalState: -1,
    });

    this.state.selectedSupervisor
      ? this.onSupervisorClick(this.state.selectedSupervisor)
      : this.initialStoreDispatches();

    const store = getStore();

    store.dispatch({
      type: RESET_STATES,
    });
  };

  onFilterSupervisorsToggleClicked = () => {
    this.setState({
      isSupervisorFilterVisible: !this.state.isSupervisorFilterVisible,
    });
  };

  resetFilters = () => {
    const fieldList = this.state.supervisorForm.fields;

    for (let k in fieldList) {
      fieldList[k].value = null;
    }

    let filters: Map<string, any> = new Map();

    this.setState({
      supervisorFilters: filters,
    });

    this.initialStoreDispatches();
  };

  applyFilters = () => {
    let supervisorFilters = this.state.supervisorFilters;
    const supervisorFieldList = this.state.supervisorForm.fields;

    for (let k in supervisorFieldList) {
      if (supervisorFieldList[k].value) {
        supervisorFilters.set(k, supervisorFieldList[k].value);
      }
    }

    const store = getStore();

    store.dispatch({
      type: GET_SUPERVISORS,
      payload: {
        filters: supervisorFilters,
      },
    });
  };

  // -------------------- RENDER & BLOCKS --------------------

  getPortalModal(state: EmployeeDashboardState) {
    let modalState = state.modalState;

    if (modalState < 0) {
      return null;
    } else if (modalState === this.MODAL_CREATE_EMPLOYEE) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-124">
            <CreateEmployeeModal
              selectedSupervisor={state.selectedSupervisor}
              currentUser={state.currentUser}
              close={this.onModalClose}
              isOutsideClickClose={false}
              containerId="anglo-modal-123"
            />
          </div>
        </Portal>
      );
    } else if (modalState === this.MODAL_EDIT_EMPLOYEE) {
      return (
        <Portal into="body">
          <div class="anglo-modal-container" id="anglo-modal-124">
            <EditEmployeeModal
              selectedSupervisor={state.selectedSupervisor}
              selectedEmployee={state.selectedEmployee}
              currentUser={state.currentUser}
              close={this.onModalClose}
              isOutsideClickClose={false}
              containerId="anglo-modal-123"
            />
          </div>
        </Portal>
      );
    }

    return <div></div>;
  }

  getSupervisorFilterForm(state: EmployeeDashboardState) {
    let form = state.supervisorForm;
    if (form) {
      return (
        <div className="container-fluid px-0 mt-3">
          <div class="row mt-3">
            <div class="col-12 mt-1 mb-1">
              <label class="dash-remittance__filter-row__filters__label">
                User Search
              </label>
              <input
                className={cx("form-control")}
                type="text"
                value={form.fields["userSearch"].value}
                onChange={linkform(this.supervisorForm$, form, "userSearch")}
                placeholder="User Search"
              />
            </div>
          </div>
        </div>
      );
    }
  }

  getFilterActions(state: EmployeeDashboardState) {
    if (state.isSupervisorFilterVisible) {
      return (
        <div class="row mt-3">
          <div
            className={cx("col-12 ", {
              "offset-lg-8 col-lg-2": !state.selectedSupervisor,
              "col-lg-6": state.selectedSupervisor,
            })}
          >
            <button
              class="btn btn-outline-dark w-100"
              onClick={this.resetFilters}
            >
              Reset Filters
            </button>
          </div>
          <div
            className={cx("col-12", {
              "col-lg-2": !state.selectedSupervisor,
              "col-lg-6": state.selectedSupervisor,
            })}
          >
            <button class="btn btn-secondary w-100" onClick={this.applyFilters}>
              Apply Filters
            </button>
          </div>
        </div>
      );
    }
  }

  getEmployeeActions(state: EmployeeDashboardState, row: Agent, index: number) {
    return (
      <div class="dropdown-menu" aria-labelledby={`actions${index}`}>
        <button
          class="dropdown-item"
          onClick={(e) => this.onModalOpened(this.MODAL_EDIT_EMPLOYEE, row)}
        >
          Edit
        </button>
        {/* <button
          class="dropdown-item"
          onClick={(e) => this.onModalOpened(this.MODAL_REASSIGN_EMPLOYEE, row)}
        >
          Reassign
        </button> */}
      </div>
    );
  }

  getEmployeesList(state: EmployeeDashboardState) {
    return (
      <div class="data-grid">
        <div class="data-grid__row header">
          <div class="data-grid__col">Action</div>
          <div class="data-grid__col">Name</div>
          <div class="data-grid__col">Type</div>
          <div class="data-grid__col">Active</div>
          <div class="data-grid__col">Total Loan Balance</div>
          <div class="data-grid__col">Total CB Balance</div>
        </div>

        {state.allEmployees
          ? state.allEmployees?.map((row, index) => {
              return (
                <div class="data-grid__row">
                  <div class="data-grid__col">
                    <div class="dropright">
                      <button
                        class="btn btn-outline-primary dropdown-toggle"
                        type="button"
                        id={`actions${index}`}
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Select action
                      </button>
                      {this.getEmployeeActions(state, row, index)}
                    </div>
                  </div>
                  <div class="data-grid__col">
                    {row.firstName} {row.lastName}
                  </div>
                  <div class="data-grid__col text-capitalize">
                    {row.employeeType}
                  </div>
                  <div class="data-grid__col">
                    {row.isActive ? "Yes" : "No"}
                  </div>
                  <div class="data-grid__col text-capitalize">
                    {row.balance &&
                    row.balance[0] &&
                    row.balance[0].runningLoanBalance
                      ? row.balance[0].runningLoanBalance
                      : "N/A"}
                  </div>
                  <div class="data-grid__col text-capitalize">
                    {row.balance &&
                    row.balance[0] &&
                    row.balance[0].runningCashbondBalance
                      ? row.balance[0].runningCashbondBalance
                      : "N/A"}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    );
  }

  getEmployeeActionBar(state: EmployeeDashboardState) {
    return (
      <div className="col-7">
        <div className="row">
          <div className="col-12 col-lg-6 offset-lg-6">
            <button
              class="btn btn-secondary w-100"
              onClick={(e) => this.onModalOpened(this.MODAL_CREATE_EMPLOYEE)}
            >
              Create Employee
            </button>
          </div>
        </div>
      </div>
    );
  }

  getFilterBlock(state: EmployeeDashboardState) {
    return (
      <div class="container-fluid px-0 mt-3 mb-2">
        <div class="row">
          <div className="col">
            <div class="row justify-content-end">
              <div class="col-12 col-lg-6 offset-6 d-flex justify-content-end">
                <a
                  class="dash-remittance__filter-row__filter-btn"
                  onClick={this.onFilterSupervisorsToggleClicked}
                >
                  Filter Supervisors <i class="fas fa-filter"></i>
                </a>
              </div>
            </div>
            <div className="row no-gutters">
              <div class="col-12">
                {state.isSupervisorFilterVisible ? (
                  <div class="dash-remittance__filter-row__filters mt-2">
                    {this.getSupervisorFilterForm(state)}
                    {this.getFilterActions(state)}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {state.selectedSupervisor ? this.getEmployeeActionBar(state) : null}
        </div>
      </div>
    );
  }

  getGridBlock(state: EmployeeDashboardState) {
    return (
      <div class="row">
        <div
          className={cx({
            "col-12": !state.selectedSupervisor,
            "col-5": state.selectedSupervisor,
          })}
        >
          <div class="data-grid">
            <div class="data-grid__row header">
              <div class="data-grid__col col-short">Full Name</div>
              <div class="data-grid__col col-short">Username</div>
              <div class="data-grid__col col-short">Active</div>
            </div>

            {state.allSupervisors
              ? state.allSupervisors?.map((row, index) => {
                  return (
                    <div
                      className={cx("data-grid__row", {
                        active: state.selectedSupervisor === row,
                      })}
                      onClick={() => this.onSupervisorClick(row)}
                    >
                      <div class="data-grid__col col-short">
                        {row.firstName} {row.lastName}
                      </div>
                      <div class="data-grid__col col-short">{row.username}</div>
                      <div class="data-grid__col col-short">
                        {row.isActive ? "Yes" : "No"}
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
        <div class="col-7">
          {state.selectedSupervisor ? this.getEmployeesList(state) : null}
        </div>
      </div>
    );
  }

  render(props, state: EmployeeDashboardState) {
    return (
      <div class="dash-cashbond-employee anglo__dashboard">
        {this.getPortalModal(state)}

        <div class="container-fluid">
          <div class="row">
            <div class="col-8 col-lg-4">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/disposal">Employees</a>
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          {this.getFilterBlock(state)}
          {this.getGridBlock(state)}
        </div>
      </div>
    );
  }
}
