import { h, Component } from 'preact';
import linkState from 'linkstate';
import cx from 'classnames';

import { ModalProps, Modal } from '../common/modal';
import { Remittance } from '../../../../types/remittance.model';
import { Subject, Subscription } from 'rxjs';
import getStore, { appState$ } from '../../store';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { RequestState, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../types/request-state.model';
import { FieldList, Field, Form } from '../../../../types/form.model';
import { validateForm, cxValidateField } from '../../../../types/validator.model';
import * as Validators from '../../../../types/validator.model';
import linkform from '../../../utils/linkform';
import { CREATE_SUPERVISOR_DATA, UserAction } from '../../reducers/user/user.actions';
import { SupervisorRegistrationData, User } from '../../../../types/user.model';

export interface CreateSupervisorDataModalProps extends ModalProps {
  selectedUser: User;
}

interface CreateSupervisorDataModalState  {

  submitStatus?: RequestState;
  form?: Form;
}

export class CreateSupervisorDataModal extends Modal<CreateSupervisorDataModalProps, CreateSupervisorDataModalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props: CreateSupervisorDataModalProps) {
    super(props);

    this.createForm();
  }

  createForm() {
    let fields: FieldList = {
      'areaAssigned': new Field([Validators.required] , null, ''),
      'yearsAssigned': new Field([Validators.required] , null, ''),
      'isWalkIn': new Field([] , null, false),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      form: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['form'] = form;
      this.setState(state);
    });
  }

  componentDidMount() {
    super.componentDidMount();

    const user$ = appState$.pipe(
      map(app => app.user)
    );

    this.subs.push(
      user$.pipe(
        map(user => user.postPutRequestState),
        distinctUntilChanged()
      ).subscribe(reqState => {
        this.setState({
          submitStatus: reqState
        });

        if (reqState === REQUEST_SUCCESS) {
          this.doModalClose();
        }
      })
    );

    this.initialStoreDispatches();
  }

  initialStoreDispatches() {

    const store = getStore();

  }

  componentWillUnmount() {
    this.subs.forEach(s => s.unsubscribe());
  }

  doModalClose = () => {
    this.props.close();
  }

  getSpinnerBlock(state: CreateSupervisorDataModalState) {
    if (state.submitStatus === REQUEST_IN_PROGRESS) {
      return (
        <div class="col offset-md-9 d-flex justify-content-end">
          <div class="spinner-border text-primary mt-3" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }    
  }

  onSubmit = () => {

    let form = this.state.form;

    validateForm(form);

    if (!form.isValid) {
      return;
    }

    let supervisorRegistrationData: SupervisorRegistrationData = {
      user: this.props.selectedUser.username,
      areaAssigned: form.fields['areaAssigned'].value,
      yearsAssigned: form.fields['yearsAssigned'].value,
      isWalkIn: form.fields['isWalkIn'].value,
    }

    const store = getStore();
    store.dispatch({
      type: CREATE_SUPERVISOR_DATA,
      payload: {
        supervisorRegistrationData: supervisorRegistrationData
      }
    });
  }

  render(props: CreateSupervisorDataModalProps, state: CreateSupervisorDataModalState) {
    let form = state.form;

    return (
      <div class="container anglo-modal"
          ref={ node => this.refNode = node }>
        <div class="row anglo-modal__head align-items-center">
          <div class="col-9 col-md-10">
            <h3 class="mb-0">
              Create Supervisor Data | <small>{ props.selectedUser.firstName } { props.selectedUser.lastName }</small>
            </h3>
          </div>
          <div class="col-3 col-md-2">
            <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        
        <div class="row anglo-modal__body py-3">

          <div class="col-12 mt-2">
            <label class="dash-remittance__filter-row__filters__label">Sector / Area Assigned*</label>
            <input type="text" className={ cx('form-control', 
              { 'invalid': cxValidateField(form.fields['areaAssigned']) }
            )}
            value={ form.fields['areaAssigned'].value }
            placeholder=""
            onChange={ linkform(this.form$, form, 'areaAssigned') }/>
          </div>

          <div class="col-12 mt-2">
            <label class="dash-remittance__filter-row__filters__label">Number of Years Assigned*</label>
            <input type="number" className={ cx('form-control', 
              { 'invalid': cxValidateField(form.fields['yearsAssigned']) }
            )}
            value={ form.fields['yearsAssigned'].value }
            placeholder=""
            onChange={ linkform(this.form$, form, 'yearsAssigned') }/>
          </div>

          <div class="col-12 mt-3 d-flex align-items-center">
            <input class="ml-2" type="checkbox" onChange={linkform(this.form$, form, 'isWalkIn', true)} /> 
            &nbsp; Is this Supervisor a Walk-In? <i>(Different pricing applies)</i>
          </div>

          <div class="col-2 offset-8 mt-4">
            {
              this.getSpinnerBlock(state)
            }
          </div>

          <div class="col-2 mt-4">

            <button class="btn btn-primary w-100" onClick={this.onSubmit}>
              Submit
            </button>
          </div>
        </div>

        
      </div>
    );
  }
}