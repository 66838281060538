import { h, Component } from 'preact';
import { route } from 'preact-router';

import getStore, { appState$ } from '../store';
import { map } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { FieldList, Field, Form } from '../../../types/form.model';
import { validateForm } from '../../../types/validator.model';

import linkform from '../../utils/linkform';
import { UserState, LOGIN, UserAction } from '../reducers/user/user.actions';

interface AngloLoginProps {

}

interface AngloLoginState {
  loginForm?: Form;
}

'anglo-login';
export class AngloLogin extends Component<AngloLoginProps, AngloLoginState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props) {
    super(props);

    // if user exists in store, redirect
    this.createForm();
  }

  createForm() {
    let fields: FieldList = {
      'username': new Field(['required'], null, ''),
      'password': new Field(['required'], null, '')
    };

    this.form$ = new Subject<Form>();
    this.setState({
      loginForm: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['loginForm'] = form;
      this.setState(state);
    });
  }

  componentWillMount() {
    const user$ = appState$.pipe(
      map(appState => appState.user)
    );

    this.subs.push(user$.pipe(
        map(user => user.currentUser)
      ).subscribe((user) => {
        console.log(user);
        if (user && user.username && user.isActive) {
          
          // location.href = '/dashboard';
          window.location = '/' as any;

        } 
      })
    );
  }

  onLogin = (e, state: AngloLoginState) => {
    e.preventDefault();

    validateForm(this.state.loginForm);

    if (!this.state.loginForm.isValid) {
      return;
    }

    const store = getStore();
    const payload: UserState = {
      authUsername: this.state.loginForm.fields['username'].value,
      authPassword: this.state.loginForm.fields['password'].value
    };

    store.dispatch({
      type: LOGIN,
      payload: payload
    } as UserAction);

  }

  render(props, state: AngloLoginState) {
    return (
      <div class="anglo-login">

        <div class="anglo-login__login-card">
          <div class="anglo-login__logo">
            <img src="assets/aatcLogo.jpg" alt="anglo-american-tobacco-corp-logo"/>
          </div>

          <h1 class="anglo-login__login-card__h1 mt-3">
            Sign In
          </h1>

          <form>
            <div class="mt-3">
              <input type="text" class="form-control"
                value={ state.loginForm.fields['username'].value }
                placeholder="Username"
                onChange={ linkform(this.form$, state.loginForm, 'username') }/>
            </div>

            <div class="mt-3">
              <input type="password" class="form-control"
                value={ state.loginForm.fields['password'].value }
                placeholder="Password"
                onInput={ linkform(this.form$, state.loginForm, 'password') }/>
            </div>

            <button class="btn btn-info mt-4 w-100" disabled={ !state.loginForm.isValid }
              type="submit"
              onClick={ (e) => {this.onLogin(e, state)} }>
              Login
            </button>
          </form>

          <p class="anglo-login__login-card__forgot mt-4">
            If you've forgotten your login credentials, you may request
            for assistance from your corresponding manager or you can mail
            support at
            <a href="mailto:carta.mailer@gmail.com" class="ml-1">
              carta.mailer@gmail.com
            </a>
            .
            
          </p>

        </div>
      </div>
    );
  }
}