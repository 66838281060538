import { h, Component } from 'preact';
import cx from 'classnames';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import linkState from 'linkstate';
import moment from 'moment-timezone';

import { ModalProps, Modal } from '../../common/modal';
import { Form, FieldList, Field } from '../../../../../types/form.model';
import * as Validators from '../../../../../types/validator.model';
import { validateForm, cxValidateField } from '../../../../../types/validator.model';
import linkform, { updateFormFieldValue } from '../../../../utils/linkform';

import { User } from '../../../../../types/user.model';
import getStore, { appState$ } from '../../../store';
import { AutocompleteSearch } from '../../common/autocomplete-search.component';
import { AutocompleteLocation } from '../../common/autocomplete-location.component';
import { CREATE_CASHBOND } from '../../../reducers/cashbond-loan/cashbond-loan.actions';
import { REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../../types/request-state.model';
import { CashBond } from '../../../../../types/cashbond.model';
import { Sector } from '../../../../../types/sector.model';
import { AutocompleteSector } from '../../common/autocomplete-sector.component';

export interface CreateCashbondModalProps extends ModalProps {
    currentUser?: User;
    cashbond?: CashBond;
}

interface CreateCashbondModalState {
  cashbondForm?: Form;
  isFormVisible?: boolean;
  isEditing?: boolean;
  isPostInProgress?: boolean;
}

export class CreateCashbondModal extends Modal<CreateCashbondModalProps, CreateCashbondModalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props) {
    super(props);

    this.createForm();
  }

  createForm() {
    let fields: FieldList = {
      'location': new Field([Validators.required], null, ''),
      'agentId': new Field([Validators.required], null, ''),
    }

    this.form$ = new Subject<Form>();
    this.setState({
      cashbondForm: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['cashbondForm'] = form;
      this.setState(state);
    });
  }

  componentDidMount() {
    super.componentDidMount();

    const cashbondloan$ = appState$.pipe(
      map(appState => appState.cashbondloan)
    );

    this.subs.push(
      cashbondloan$.pipe(
        map(cashbondloan => cashbondloan.postPutRequestState),
        distinctUntilChanged()
      ).subscribe(postPutRequestState => {
        if (postPutRequestState === REQUEST_SUCCESS) {

          this.setState({
            isPostInProgress: false
          });
          this.props.close();

        } else if (postPutRequestState === REQUEST_IN_PROGRESS) {
          this.setState({
            isPostInProgress: true
          });
        }
      })
    );

  }

  doModalClose = () => {
    this.props.close();
  }

  onSubmit = () => {
    const form = this.state.cashbondForm;
    validateForm(form);

    this.setState({
      cashbondForm: form
    });

    if (!this.state.cashbondForm.isValid) {
      return;
    }

    let cashbond: CashBond = {
      agentId: form.fields['agentId'].value,
      sectorId: form.fields['location'].value,
    }

    const store = getStore();

    store.dispatch({
      type: CREATE_CASHBOND,
      payload: {
        cashbondToCreate: cashbond
      }
    });
  }

  onAgentSearchChange = (agentId: string) => {
    updateFormFieldValue(
      this.form$,
      this.state.cashbondForm,
      'agentId',
      agentId
    )
  }

  onLocationSearchChange = (sector: Sector) => {
    updateFormFieldValue(
      this.form$,
      this.state.cashbondForm,
      'location',
      sector.name
    )
  }

  getFormBlock(props: CreateCashbondModalProps, state: CreateCashbondModalState) {
    let form = state.cashbondForm;
    
    return (
      <div class="row">
        <div class='col-12 create-cashbond-modal__form'>
            
            <div class="row">
              <div class="col-12 col-md-6 mt-3">
                <label class="anglo-remittance__submit-form__label">Agent*</label>
                <AutocompleteSearch 
                  onResultSelected={this.onAgentSearchChange}
                  placeholder='Search by Agent'
                  searchUrl='agent'
                />
              </div>

              <div class="col-12 col-md-6 mt-3">
                <label class="anglo-remittance__submit-form__label">Sector/Location*</label>
                <AutocompleteSector onResultSelected={this.onLocationSearchChange} />
              </div>
            </div>
        </div>
      </div>
    );
  }

  getSpinnerBlock() {
    return (
      <div class="col offset-md-9 d-flex justify-content-end">
        <div class="spinner-border text-primary mt-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );  
  }

  getActionsRow(props: CreateCashbondModalProps, state: CreateCashbondModalState) {
    if (state.isPostInProgress) {
      return this.getSpinnerBlock();
    } else {
      return (
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-3 offset-lg-9">
              <button class="btn btn-primary w-100"
                onClick={this.onSubmit}
                disabled={!state.cashbondForm.isValid}>
                Submit Cashbond
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  render(props: CreateCashbondModalProps, state: CreateCashbondModalState) {
    return (
      <div class="container anglo-modal create-disposal-modal"
        ref={ node => this.refNode = node }>

          <div class="row anglo-modal__head align-items-center">
            <div class="col-9 col-md-10">
              <h3 class="mb-0">
                Create Cashbond
              </h3>
            </div>
            <div class="col-3 col-md-2">
              <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>

          <div class="row anglo-modal__body py-3">
            <div class="col-12 create-withdrawal-modal__body pb-3">
              { this.getFormBlock(props, state) }   
            </div>    
          </div>

          <div class="row vehicle-card__actions mt-3">
            { this.getActionsRow(props, state) }
          </div>

      </div>
    );
  }
}

/*



*/