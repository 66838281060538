import { h, Component } from 'preact';
import cx from 'classnames';

import { ModalProps, Modal } from '../common/modal';

import { Subject, Subscription } from 'rxjs';
import { FieldList, Field, Form } from '../../../../types/form.model';

import { validateForm, cxValidateField } from '../../../../types/validator.model';
import getStore, { appState$ } from '../../store';
import { SUBMIT_EDIT_REQUEST } from '../../reducers/remittance/remittance.actions';

import { map } from 'rxjs/operators';
import { RequestState, REQUEST_IN_PROGRESS, REQUEST_SUCCESS } from '../../../../types/request-state.model';
import { User } from '../../../../types/user.model';
import linkform from '../../../utils/linkform';
import { GET_SUPERVISORS, UserAction } from '../../reducers/user/user.actions';
import { Vehicle, VehicleEdit } from '../../../../types/vehicle.model';
import { CREATE_VEHICLE, EDIT_VEHICLE } from '../../reducers/vehicle/vehicle.actions';
import { stat } from 'fs';

export interface EditVehicleModalProps extends ModalProps {
  currentUser: User;
  vehicle: Vehicle;
}

interface EditVehicleModalState  {
  editVehicleState?: RequestState;
  vehicleForm?: Form;
  supervisors?: User[];
  
  editType?: string;
}

export class EditVehicleModal extends Modal<EditVehicleModalProps, EditVehicleModalState> {
  subs: Subscription[] = [];
  form$: Subject<Form>;

  constructor(props: EditVehicleModalProps) {
    super(props);

    this.createForm();
  }

  componentDidMount() {
    super.componentDidMount();

    const vehicles$ = appState$.pipe(
      map(app => app.vehicles)
    );

    this.subs.push(
      vehicles$.pipe(
        map(vehicles => vehicles.editVehicleState)
      ).subscribe(reqState => {
        this.setState({
          editVehicleState: reqState
        });

        if (reqState === REQUEST_SUCCESS) {
          this.doModalClose();
        }
      })
    );

    const user$ = appState$.pipe(
      map(app => app.user)
    );

    this.subs.push(
      user$.pipe(
        map(user => user.supervisors)
      ).subscribe(supervisors => {
        this.setState({
          supervisors: supervisors
        });
      })
    );

    this.initialStoreDispatches();
  }

  initialStoreDispatches() {

    const store = getStore();
    store.dispatch({
      type: GET_SUPERVISORS
    } as UserAction);

  }

  componentWillUnmount() {
    console.log('unmounting');
  }

  createForm() {
    let fields: FieldList = {
      'status': new Field([], null, this.props.vehicle.status),
      'supervisor': new Field([], null, this.props.vehicle.username),
      'modelMake': new Field([], null, this.props.vehicle.modelMake),
      'plateNumber': new Field([], null, this.props.vehicle.plateNumber),
      'lodgingLocation': new Field([] , null, this.props.vehicle.lodgingLocation),
    };

    this.form$ = new Subject<Form>();
    this.setState({
      vehicleForm: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['vehicleForm'] = form;
      this.setState(state);
    });
  }

  doModalClose = () => {
    this.props.close();
  }

  getSpinnerBlock(state: EditVehicleModalState) {
    if (state.editVehicleState === REQUEST_IN_PROGRESS) {
      return (
        <div class="col offset-md-9 d-flex justify-content-end">
          <div class="spinner-border text-primary mt-3" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }    
  }

  onSubmit = (e) => {

    e.preventDefault();
    
    const form = this.state.vehicleForm;
    validateForm(form);

    this.setState({
      vehicleForm: form
    });

    if (!this.state.vehicleForm.isValid) {
      return;
    }

    let vehicle: VehicleEdit = {
      vehicleId: this.props.vehicle.vehicleId,
      supervisor: form.fields['supervisor'].value,
      modelMake: form.fields['modelMake'].value,
      plateNumber: form.fields['plateNumber'].value,
      lodgingLocation: form.fields['lodgingLocation'].value,
      type: this.state.editType,
      status: form.fields['status'].value
    }

    const store = getStore();
    store.dispatch({
      type: EDIT_VEHICLE,
      payload: {
        vehicleToUpdate: vehicle
      }
    });
  }

  getFormBlock(props: EditVehicleModalProps, state: EditVehicleModalState) {
    let form = state.vehicleForm;

    if (state.editType === 'edit') {
      return (
        <div class="row anglo-modal__body py-3">
          <div class="col-6 mb-3">
            <label class="anglo-remittance__submit-form__label">Supervisor</label>

            <select disabled={true}
              className={ cx('form-control', 
                { 'invalid': cxValidateField(form.fields['supervisor']) }
              )}
              placeholder="Supervisor"
              value={form.fields['supervisor'].value}
              onChange={linkform(this.form$, form, 'supervisor')}>

                <option class="text-muted" value="" disabled={true} selected>{ props.vehicle.username }</option>
                {
                  state.supervisors.map(supervisor => {
                    return <option value={supervisor.username}>{ supervisor.firstName } { supervisor.lastName }</option>
                  })
                }
            </select>

          </div>
          <div class="col-6 mb-3">
            <label class="anglo-remittance__submit-form__label">Plate Number*</label>

            <input type="text" className={ cx('form-control', 
                { 'invalid': cxValidateField(form.fields['plateNumber']) }
              )}
              value={ form.fields['plateNumber'].value }
              placeholder="Plate Number"
              onChange={ linkform(this.form$, form, 'plateNumber') }/>
          </div>
          <div class="col-6 mb-3">
            <label class="anglo-remittance__submit-form__label">Model/Make*</label>

            <input type="text" className={ cx('form-control', 
                { 'invalid': cxValidateField(form.fields['modelMake']) }
              )}
              value={ form.fields['modelMake'].value }
              placeholder="Model/Make"
              onChange={ linkform(this.form$, form, 'modelMake') }/>
          </div>
          <div class="col-6 mb-3">
            <label class="anglo-remittance__submit-form__label">Lodging Location</label>

            <input type="text" className={ cx('form-control', 
                { 'invalid': cxValidateField(form.fields['lodgingLocation']) }
              )}
              value={ form.fields['lodgingLocation'].value }
              placeholder="Lodging Location"
              onChange={ linkform(this.form$, form, 'lodgingLocation') }/>
          </div>
          
          <div class="col-6 mb-3">
            <label class="anglo-remittance__submit-form__label">Status</label>

            <select class="form-control"
                className={ cx('form-control text-capitalize', 
                  { 'invalid': cxValidateField(form.fields['status']) }
                )}
                placeholder="Status"
                value={form.fields['status'].value}
                onChange={linkform(this.form$, form, 'status')}>

                <option class="text-muted" value="" disabled={true} selected>Select Status</option>
                <option class="text-muted" value="damaged" disabled={true}>Damaged</option>
                <option class="text-muted" value="updated" disabled={true}>Updated</option>
                {
                  ['in repair', 'decommissioned', 'orcr renewal'].map(status => {
                    return <option class="text-capitalize" value={status}>{ status }</option>
                  })
                }

            </select>
          </div>

          <div class="col-12">
            <div class="row">
              <div class="col-1 offset-lg-9">
                {
                  this.getSpinnerBlock(state)
                }
              </div>

              <div class="col-12 col-lg-2">

                <button class="btn btn-primary w-100" 
                  onClick={this.onSubmit}
                  disabled={state.editVehicleState == REQUEST_IN_PROGRESS}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (state.editType === 'reassign') {
      return (
        <div class="row anglo-modal__body py-3">
          <div class="col-12 mb-3">
            <label class="anglo-remittance__submit-form__label">Supervisor*</label>

            <select
              className={ cx('form-control', 
                { 'invalid': cxValidateField(form.fields['supervisor']) }
              )}
              placeholder="Supervisor"
              value={form.fields['supervisor'].value}
              onChange={linkform(this.form$, form, 'supervisor')}>

                <option class="text-muted" value="" disabled={true} selected>Supervisor</option>
                {
                  state.supervisors.map(supervisor => {
                    return <option value={supervisor.username}>{ supervisor.firstName } { supervisor.lastName }</option>
                  })
                }
            </select>

          </div>

          <div class="col-12">
            <div class="row">
              <div class="col-1 offset-9">
                {
                  this.getSpinnerBlock(state)
                }
              </div>

              <div class="col-12 col-lg-2">

                <button class="btn btn-primary w-100" 
                  onClick={this.onSubmit}
                  disabled={state.editVehicleState == REQUEST_IN_PROGRESS}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  getChooseTypeBlock(props: EditVehicleModalProps, state: EditVehicleModalState) {
    if (!state.editType) {
      return (
        <div class="row anglo-modal__body py-3">
          <button class="col-4 col-lg-2 offset-2 offset-lg-3 btn btn-secondary"
            onClick={e => { this.setState({ editType: 'edit' }) }}>
            Edit
          </button>
          <button class="col-4 col-lg-2 offset-1 btn btn-dark"
            onClick={e => { this.setState({ editType: 'reassign' }) }}>
            Reassign
          </button>
        </div>
      );
    }
  }

  render(props: EditVehicleModalProps, state: EditVehicleModalState) {
    let form = state.vehicleForm;

    if (!state.supervisors) {
      return;
    }

    return (
      <div class="anglo-request-edit-modal container anglo-modal"
          ref={ node => this.refNode = node }>
        <div class="row anglo-modal__head align-items-center">
          <div class="col-10">
            <h3 class="mb-0">
              Create Vehicle Entry
            </h3>
          </div>
          <div class="col-2">
            <button class="btn btn-outline-light w-100" onClick={this.doModalClose}>
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        
        { this.getFormBlock(props, state) }
        { this.getChooseTypeBlock(props, state) }
        
      </div>
    );
  }
}