import { RequestState } from '../../../../types/request-state.model';
import { EditRequest, DeleteRequest } from '../../../../types/edit-request.model';

const PREFIX = '[Update]';

export const GET_GRID_UPDATE = `${PREFIX} GET_GRID_UPDATE`;
export const GET_GRID_UPDATE_SUCCESS = `${PREFIX} GET_GRID_UPDATE_SUCCESS`;
export const GET_GRID_UPDATE_FAIL = `${PREFIX} GET_GRID_UPDATE_SUCCESS`;

export const APPROVE_REQUEST_EDIT = `${PREFIX} APPROVE_REQUEST_EDIT`;
export const APPROVE_REQUEST_EDIT_SUCCESS = `${PREFIX} APPROVE_REQUEST_EDIT_SUCCESS`;
export const APPROVE_REQUEST_EDIT_FAIL = `${PREFIX} APPROVE_REQUEST_EDIT_FAIL`;

export const REJECT_REQUEST_EDIT = `${PREFIX} REJECT_REQUEST_EDIT`;
export const REJECT_REQUEST_EDIT_SUCCESS = `${PREFIX} REJECT_REQUEST_EDIT_SUCCESS`;
export const REJECT_REQUEST_EDIT_FAIL = `${PREFIX} REJECT_REQUEST_EDIT_FAIL`;

export const APPROVE_REQUEST_DELETE = `${PREFIX} APPROVE_REQUEST_DELETE`;
export const APPROVE_REQUEST_DELETE_SUCCESS = `${PREFIX} APPROVE_REQUEST_DELETE_SUCCESS`;
export const APPROVE_REQUEST_DELETE_FAIL = `${PREFIX} APPROVE_REQUEST_DELETE_FAIL`;

export const REJECT_REQUEST_DELETE = `${PREFIX} REJECT_REQUEST_DELETE`;
export const REJECT_REQUEST_DELETE_SUCCESS = `${PREFIX} REJECT_REQUEST_DELETE_SUCCESS`;
export const REJECT_REQUEST_DELETE_FAIL = `${PREFIX} REJECT_REQUEST_DELETE_FAIL`;

export interface UpdateState {
  editGridData?: EditRequest[];
  deleteGridData?: DeleteRequest[];
  
  gridRequestState?: RequestState;
  type?: string;
  filters?: any;

  remittanceId?: string;
  responseReason?: string;
  editRequestState?: RequestState;
}

export interface UpdateAction {
  type: string,
  payload?: UpdateState
}