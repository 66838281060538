import { Remittance } from '../../../../types/remittance.model';
import { RequestState } from '../../../../types/request-state.model';
import { TotalCount } from '../../../../types/total-count.model';

import { EditRequest, DeleteRequest } from '../../../../types/edit-request.model';

const PREFIX = '[REMITTANCE]';

export const SUBMIT_REMITTANCE = `${PREFIX} SUBMIT_REMITTANCE`;
export const SUBMIT_REMITTANCE_SUCCESS = `${PREFIX} SUBMIT_REMITTANCE_SUCCESS`;
export const SUBMIT_REMITTANCE_FAIL = `${PREFIX} SUBMIT_REMITTANCE_FAIL`;

export const GET_GRID_REMITTANCE = `${PREFIX} GET_GRID_REMITTANCE`;
export const GET_GRID_REMITTANCE_SUCCESS = `${PREFIX} GET_GRID_REMITTANCE_SUCCESS`;
export const GET_GRID_REMITTANCE_FAIL = `${PREFIX} GET_GRID_REMITTANCE_FAIL`;

export const GET_REPORT_GRID_REMITTANCE = `${PREFIX} GET_REPORT_GRID_REMITTANCE`;
export const GET_REPORT_GRID_REMITTANCE_SUCCESS = `${PREFIX} GET_REPORT_GRID_REMITTANCE_SUCCESS`;
export const GET_REPORT_GRID_REMITTANCE_FAIL = `${PREFIX} GET_REPORT_GRID_REMITTANCE_FAIL`;

export const GET_REMITTANCE_TOTAL = `${PREFIX} GET_REMITTANCE_TOTAL`;
export const GET_REMITTANCE_TOTAL_SUCCESS = `${PREFIX} GET_REMITTANCE_TOTAL_SUCCESS`;
export const GET_REMITTANCE_TOTAL_FAIL = `${PREFIX} GET_REMITTANCE_TOTAL_FAIL`;

export const GET_REMITTANCE_TOTAL_MONTH = `${PREFIX} GET_REMITTANCE_TOTAL_MONTH`;
export const GET_REMITTANCE_TOTAL_MONTH_SUCCESS = `${PREFIX} GET_REMITTANCE_TOTAL_MONTH_SUCCESS`;
export const GET_REMITTANCE_TOTAL_MONTH_FAIL = `${PREFIX} GET_REMITTANCE_TOTAL_MONTH_FAIL`;

export const SUBMIT_EDIT_REQUEST = `${PREFIX} SUBMIT_EDIT_REQUEST`;
export const SUBMIT_EDIT_REQUEST_SUCCESS = `${PREFIX} SUBMIT_EDIT_REQUEST_SUCCESS`;
export const SUBMIT_EDIT_REQUEST_FAIL = `${PREFIX} SUBMIT_EDIT_REQUEST_FAIL`;

export const SUBMIT_DELETE_REQUEST = `${PREFIX} SUBMIT_DELETE_REQUEST`;
export const SUBMIT_DELETE_REQUEST_SUCCESS = `${PREFIX} SUBMIT_DELETE_REQUEST_SUCCESS`;
export const SUBMIT_DELETE_REQUEST_FAIL = `${PREFIX} SUBMIT_DELETE_REQUEST_FAIL`;

export const APPROVE_REMITTANCE = `${PREFIX} APPROVE_REMITTANCE`;
export const APPROVE_REMITTANCE_SUCCESS = `${PREFIX} APPROVE_REMITTANCE_SUCCESS`;
export const APPROVE_REMITTANCE_FAIL = `${PREFIX} APPROVE_REMITTANCE_FAIL`;

export const RESET_REMITTANCE_STATES = `${PREFIX} RESET_REMITTANCE_STATES`;

export const GENERATE_SERVERSIDE_REPORT = `${PREFIX} GENERATE_SERVERSIDE_REPORT`;
export const GENERATE_SERVERSIDE_REPORT_SUCCESS = `${PREFIX} GENERATE_SERVERSIDE_REPORT_SUCCESS`;
export const GENERATE_SERVERSIDE_REPORT_FAIL = `${PREFIX} GENERATE_SERVERSIDE_REPORT_FAIL`;

export interface RemittanceState {
  remittance?: Remittance;
  remittancesToUpload?: Remittance[];
  submitRemitState?: RequestState;
  remittanceId?: string;
  receiptNumber?: string;
  approveRequestState?: RequestState;

  remittanceGridData?: Remittance[];
  total?: number;
  gridRequestState?: RequestState;
  filters?: any;

  reportGridData?: Remittance[];
  reportRequestState?: RequestState;

  overallTotal?: TotalCount;
  currentMonthTotal?: TotalCount;
  previousMonthTotal?: TotalCount;

  editRequest?: EditRequest;
  editRequestState?: RequestState;

  deleteRequest?: DeleteRequest;
  deleteRequestState?: RequestState;
}

export interface RemittanceAction {
  type: string,
  payload?: RemittanceState
}