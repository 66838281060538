import * as Actions from './withdrawals.actions';
import { WithdrawalState, WithdrawalAction } from './withdrawals.actions';
import { REQUEST_SUCCESS, REQUEST_FAILED, REQUEST_IN_PROGRESS, REQUEST_UNTOUCHED } from '../../../../types/request-state.model';

const initialState: WithdrawalState = {

}

export default function AuditLog(state: WithdrawalState = initialState, action: WithdrawalAction) {
  switch (action.type) {

    case Actions.GET_PRICES:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          priceRequestState: REQUEST_IN_PROGRESS
        }
      );
    case Actions.GET_PRICES_SUCCESS:
    case Actions.GET_PRICES_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
      );

    case Actions.GET_GRID_WITHDRAWAL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          gridRequestState: REQUEST_IN_PROGRESS
        }
      );
    case Actions.GET_GRID_WITHDRAWAL_SUCCESS:
    case Actions.GET_GRID_WITHDRAWAL_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
      );

    case Actions.CREATE_WITHDRAWAL:
    case Actions.APPROVE_WITHDRAWAL:
    case Actions.REJECT_WITHDRAWAL:
    case Actions.REEDIT_WITHDRAWAL:
    case Actions.UPDATE_WITHDRAWAL:
    case Actions.REEDIT_ACCEPT_WITHDRAWAL:
    case Actions.REEDIT_DECLINE_WITHDRAWAL:
      
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_IN_PROGRESS
        }
      );

    case Actions.CREATE_WITHDRAWAL_SUCCESS:
    case Actions.APPROVE_WITHDRAWAL_SUCCESS:
    case Actions.REJECT_WITHDRAWAL_SUCCESS:
    case Actions.REEDIT_WITHDRAWAL_SUCCESS:
    case Actions.UPDATE_WITHDRAWAL_SUCCESS:
    case Actions.REEDIT_ACCEPT_WITHDRAWAL_SUCCESS:
    case Actions.REEDIT_DECLINE_WITHDRAWAL_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_SUCCESS
        }
      );

    case Actions.CREATE_WITHDRAWAL_FAIL:
    case Actions.APPROVE_WITHDRAWAL_FAIL:
    case Actions.REJECT_WITHDRAWAL_FAIL:
    case Actions.REEDIT_WITHDRAWAL_FAIL:
    case Actions.UPDATE_WITHDRAWAL_FAIL:
    case Actions.REEDIT_ACCEPT_WITHDRAWAL_FAIL:
    case Actions.REEDIT_DECLINE_WITHDRAWAL_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_FAILED
        }
      );


    case Actions.RESET_WITHDRAWAL_STATE:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          postPutRequestState: REQUEST_UNTOUCHED
        }
      );

    default:
      return state;
  }
}