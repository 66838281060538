import Router, { route } from "preact-router";
import { h, Component } from "preact";

import cx from "classnames";

import { Navbar } from "./components/common/navbar.component";
import { AngloDashboard } from "./components/dashboard.component";
import { PreactTester } from "./components/preact-tester.component";
import { AngloLogin } from "./components/login.component";
import { Subscription } from "rxjs";
import { appState$ } from "./store";
import { map } from "rxjs/operators";
import {
  REQUEST_SUCCESS,
  REQUEST_FAILED,
} from "../../types/request-state.model";
import { User } from "../../types/user.model";
import { SubmitRemittance } from "./components/submit-remittance.component";
import { Sidenav } from "./components/common/sidenav.component";
import { DashRemittance } from "./components/remittance/dash-remittance.component";
import { AuditLogDashboard } from "./components/audit-log.component";
import { DashUpdates } from "./components/updates/dash-updates.component";
import { DashVehicles } from "./components/vehicle/dash-vehicle.component";
import { DashUserManagement } from "./components/user/dash-user-management.component";
import { VehicleDiagnostic } from "./components/motorpool/vehicle-diagnostic.component";
import { MicroscopeRemittance } from "./components/remittance/microscope-remittance.component";
import { DashWithdrawals } from "./components/withdrawal/dash-withdrawal.component";
import { DashDisposals } from "./components/disposal/dash-disposal.component";

import { DashCashbondLoans } from "./components/cashbond-loans/dash-cashbond-loans.component";
import { DashPaymentApproval } from "./components/cashbond-loans/dash-payment-approval.components";
import { DashCashbondPaymentApproval } from "./components/cashbond-loans/dash-cashbond-payment-approval.component";

import { EmployeeDashboard } from "./components/employee/employee-dashboard.component";
import { MotorpoolDashboard } from "./components/motorpool/motorpool.dashboard";
import { JobOrderDashboard } from "./components/motorpool/job-order.dashboard";

interface AngloAppState {
  isSidenavVisible?: boolean;

  isAppVisible?: boolean;

  currentUser?: User;

  currentUrl?: string;
}

("anglo-app");
export class PreactApp extends Component<any, AngloAppState> {
  subs: Subscription[] = [];

  constructor(props) {
    super(props);

    this.setState({
      isAppVisible: false,
    });
  }

  componentWillMount() {
    const user$ = appState$.pipe(map((appState) => appState.user));

    this.subs.push(
      user$.subscribe((user) => {
        if (user.getCurrentUserState == REQUEST_SUCCESS) {
          if (
            user.currentUser &&
            user.currentUser.username &&
            user.currentUser.isActive
          ) {
            this.setState({
              isAppVisible: true,
              currentUser: user.currentUser,
            });
          }
        } else if (user.getCurrentUserState == REQUEST_FAILED) {
          location.href = "/login";
        }
      })
    );
  }

  componentDidMount() {}

  onSidenavClicked = () => {
    this.setState({
      isSidenavVisible: !this.state.isSidenavVisible,
    });
  };

  onRouteChange = (e) => {
    // need to implement a better routing strategy
    if (e.url === "/microscope") {
      if (this.state.currentUser.role === "role_management") {
        this.setState({
          currentUrl: e.url,
          isSidenavVisible: false,
        });
      } else {
        route("/", true);
      }
    } else {
      this.setState({
        currentUrl: e.url,
        isSidenavVisible: false,
      });
    }
  };

  render(props, state: AngloAppState) {
    if (!state.isAppVisible) {
      return <div class="anglo">Loading...</div>;
    }

    return (
      <div class="anglo">
        <Navbar sidenavMenuCallback={this.onSidenavClicked} />

        <div class="anglo__content">
          <Sidenav
            isSidenavVisible={state.isSidenavVisible}
            currentRoute={state.currentUrl}
            currentUser={state.currentUser}
          />

          <div class="anglo__content__routes">
            <Router onChange={this.onRouteChange}>
              <AngloDashboard path="/" />
              <AngloDashboard path="/dashboard" />

              <DashRemittance path="/remittance" />
              <DashWithdrawals path="/withdrawal" />
              <MicroscopeRemittance path="/microscope" />

              <SubmitRemittance path="/remittance/submit" />

              <AuditLogDashboard path="/history" />
              <DashUpdates path="/updates" />
              <DashUpdates path="/audits" />

              <DashVehicles path="/vehicles" />

              <DashUserManagement path="/user-management" />

              {/* <VehicleDiagnostic path="/motorpool"/> */}

              <DashDisposals path="/disposal" />
              <DashCashbondLoans path="/cashbond-loan" />
              <DashPaymentApproval path="/loan-payments" />
              <DashCashbondPaymentApproval path="/cashbond-payments" />
              <EmployeeDashboard path="/employees" />

              <MotorpoolDashboard path="/motorpool" />
              <JobOrderDashboard path="/job-orders" />

              <PreactTester path="/test" />
            </Router>
          </div>
        </div>
      </div>
    );
  }
}
